import React from 'react'

const BasicSettings = () => {
  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <p style={{ color: '#79adb7', fontSize: '20px', fontWeight: '400' }}>Basic settings</p>
        <p style={{ fontWeight: '300', marginTop: '25px' }}>
          One of the most important parts of application is your <strong>Stripe</strong>{' '}
          account. <br />
          You need to setup your <strong>Stripe</strong> account so you can be able to use the
          rest of application!
        </p>
      </div>
    </div>
  )
}

export default BasicSettings
