import React, { Component, useState } from 'react'
import { apiRequest } from 'helpers'
import Button from 'components/CustomButton/CustomButton.jsx'
import './userInfo.scss'
import { IconButton } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import { Col, FormControl, Row } from 'react-bootstrap';

import EditIcon from '@material-ui/icons/Edit'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckIcon from '@material-ui/icons/Check'

import SweetAlert from 'react-bootstrap-sweetalert'

import Loader from 'components/Loader/Loader'

class AllThemes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      isLoading: false,
      isEditing: false,
      newThemeName: '',
      itemOfTheme: '',
      dataForSave: false,
      alert: null,
      showButtons: true
    }
    // this.handleChange = this.handleChange.bind(this)
    // this.editingOneTheme = this.editingOneTheme.bind(this)
    // this.editThemeName = this.editThemeName.bind(this)
    // this.hideAlert = this.hideAlert.bind(this)
  }

  componentDidMount() {
    this.getAllThemes()
  }

  getAllThemes = async () => {
    this.setState({ isLoading: true, showButtons: true })
    try {
      const response = await apiRequest({
        method: 'get',
        url: 'theme/all',
      })
      if (response.status === 200) {
        const datas = []
        response.data.forEach(elem => {
          datas.push({...elem, editable: false})
        })
        this.setState({ data: datas, isLoading: false })
      }
    } catch (error) {
      this.setState({ isLoading: false, errorMessage: error.response.data.message ||  error.response.data.msg })
    }
  }
  editThemeName = async (id) => {
    try {
      this.setState({
        alert: (
          <SweetAlert
            success
            style={{
              display: 'block',
              marginTop: '-100px',
            }}
            title="Changed"
            onConfirm={() => this.hideAlert()}
            confirmBtnBsStyle="info"></SweetAlert>
        ),
      })
      const response = await apiRequest({
        method: 'put',
        url: `theme/${id}`,
        data: { themeName: this.state.newThemeName },
      })
      if (response.status === 200) {
        this.setState({ isLoading: false })
        this.getAllThemes()
      }
    } catch (error) {
      this.setState({ isLoading: false, errorMessage: error.response.data.message ||  error.response.data.msg })
    }
  }
  hideAlert() {
    this.setState({
      alert: null,
    })
  }
  editTheme = (id) => {
    const themes = [...this.state.data];
    themes.forEach(elem => {
      if(id === elem.id){
        elem.editable = true
      }
    })
    this.setState({
      data: [...themes],
      showButtons: false
    })
  }
  closeEdit = () => {
    const themes = [...this.state.data];
    themes.forEach(elem => {
        elem.editable = false
    })
    this.setState({
      data: [...themes],
      showButtons: true
    })
  }

  render() {
    const { data, showButtons } = this.state
    return (
      <div className="main-content" style={{ width: '100%', height: '100%' }}>
        {this.state.alert}
        
        {this.state.isLoading === false ? (
          <Row>
            {data.map(elem => (
              <Col md={4} sm={6} key={elem.id} className='theme-container'>
                <div className='image-holder'>
                  <img src={elem.themeImage} className='theme-image'/>
                </div>
                {!elem.editable ? (
                <div className='info-holder'>
                  <p>{elem.themeName}</p>
                  {showButtons ? <Button className='choose-theme-button' onClick={() => this.editTheme(elem.id)}>Change name</Button> : null}
                </div> 
                ) : (
                <div className='edit-holder'>
                   <TextField 
                    id="standard-basic" 
                    label="Enter theme name"
                    onChange={(e) => this.setState({newThemeName: e.target.value})} />
                    <div className='submit-cancel-holder'>
                      <IconButton aria-label="save">              
                        <CheckIcon onClick={() => this.editThemeName(elem.id)}/>         
                      </IconButton>
                      <IconButton aria-label="cancel">
                        <CancelIcon onClick={this.closeEdit}/>
                      </IconButton>
                    </div>
                </div> 
                )}
              </Col>
            ))}
          </Row>
        ) : (
          <div style={{ margin: '10% 10% 10% 45%' }}>
            <Loader size={50} />
          </div>
        )}
      </div>
    )
  }
}

export default AllThemes
