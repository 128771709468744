import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap';
import FormInputs from 'components/FormInputs/FormInputs';
import './company-info.css'
import ag from 'assets/img/ag.png'
import changeImg from 'assets/img/changeImg.svg'
import addImage from 'assets/img/add-image.png'
import Button from 'components/CustomButton/CustomButton'
import { apiRequest } from 'helpers';
import FileInputComponent from 'react-file-input-previews-base64'

import { ReactComponent as Loader } from 'assets/img/loader-preview.svg'
import SweetAlert from 'react-bootstrap-sweetalert'

import ImageUploading from "react-images-uploading";
import Icon from '@material-ui/core/Icon';


const EditFirmProfile = () => {

    const [companyInfo, setCompanyInfo] = useState({
        name: '',
        address: '', 
        city: '',
        state: '',
        postalCode: '',
        phoneNumber: '',
        companyEmail: '',
        website: '',
        logo: ''
    })
    const [firmId, setFirmId] = useState()
    const [alert, setAlert] = useState()
    const [buttonClasses, setButtonClasses] = useState(['save-button'])
    const maxNumber = 69;
   
    const loadingFunction = (value) => {
        setAlert(
            <SweetAlert
                style={{
                display: 'block',
                marginTop: '-100px',
                }}
                title="Processing"
                showConfirm={false}>
                <div>
                    <Loader style={{ height: 75, width: 100 }} />
                </div>
            </SweetAlert>
        )
    }
    const errorAlert = (msg = "Something went wrong!") => {
        setAlert(
            <SweetAlert
                warning
                style={{
                display: 'block',
                marginTop: '-100px',
                }}
                title={msg}
                showConfirm={true}
                onConfirm={() => setAlert(null)}
                >
            </SweetAlert>
        )
    }
    const successAlert = (msg = 'Done!') => {
        setAlert(
            <SweetAlert
                success
                style={{
                display: 'block',
                marginTop: '-100px',
                }}
                title={msg}
                showConfirm={true}
                onConfirm={() => setAlert(null)}
                >
            </SweetAlert>
        )
    }

    useEffect(() => {
        loadingFunction()
        getCompanyInfo()
    }, [])
    
    const onUploadChange = async (imageList) => {
        loadingFunction()
        let formData = new FormData();
        formData.append('logo', imageList[0].file)
        let data = formData
        try{
            const response = await apiRequest({
                method: 'put',
                url: `firms/${firmId}`,
                data
            })
            if(response.status === 200){
                getCompanyInfo()
                setAlert(null)
                setTimeout(() => {successAlert()}, 500)
            }
        }catch(err){
            setAlert(null)
            setTimeout(() => {errorAlert(err.response.data.message || err.response.data.msg);}, 500)
        }
    };

    const getCompanyInfo = async () => {
        const cpaInfo = {...companyInfo}
        try{
            const companyInfoData = await apiRequest({
                method: 'get',
                url: 'firms/getMyFirm'
            })
            if(companyInfoData.status === 200){
                setAlert(null)
                for(let dataKey in companyInfoData.data){
                    for(let companyKey in cpaInfo){
                        if(dataKey === companyKey){
                            cpaInfo[companyKey] = companyInfoData.data[dataKey]
                        }
                    }
                }
            }
            setFirmId(companyInfoData.data.id)
            setCompanyInfo({...cpaInfo})
        }catch(err){
            console.log(err.response.data.message || err.response.data.msg);
        }
       
    }
    const changeCompanyInfo = async () => {
        loadingFunction()
        let formData = new FormData();
        for(var key in companyInfo){
            formData.append(key, companyInfo[key]);
        }
        let data = formData
        try{
            const response = await apiRequest({
                method: 'put',
                url: `firms/${firmId}`,
                data
            })
            if(response.status === 200){
                getCompanyInfo()
                setAlert(null)
                setTimeout(() => {successAlert()}, 500)
                setButtonClasses(['save-button'])
            }
        }catch(err){
            setAlert(null)
            setTimeout(() => {errorAlert(err.response.data.message || err.response.data.msg);}, 500)
        }
    }
    const btnClassSet = () => {
        setButtonClasses([...buttonClasses, 'save-button-done'])
    }
    
    return (
        <Row className='firm-edit-cont'>
            <Col md={6} className='firm-inputs'>
                <FormInputs
                      ncols={['col-md-6', 'col-md-6']}
                      proprieties={[
                        {
                          label: 'Company name',
                          type: 'text',
                          bsClass: 'form-control input-label',
                          alignLeft: true,
                          // placeholder: 'Company',
                          defaultValue: companyInfo.name,
                          disabled: false,
                          labelClass:"label-firm",
                          onChange: (e) => {companyInfo.name = e.target.value; btnClassSet() },
                        },
                        {
                          label: 'Street address & Number',
                          type: 'text',
                          alignLeft: true,
                          bsClass: 'form-control input-label',
                          disabled: false,
                          defaultValue: companyInfo.address,
                          labelClass:"label-firm",
                          //onChange: (e) => (companyInfo.address = e.target.value),
                          onChange: (e) => {companyInfo.address = e.target.value; btnClassSet()}
                        }
                        ]}/>
                <FormInputs 
                    ncols={['col-md-6', 'col-md-6']}
                    proprieties={[
                        {
                            label: 'City',
                            type: 'text',
                            alignLeft: true,
                            bsClass: 'form-control input-label',
                            disabled: false,
                            defaultValue: companyInfo?.city,
                            labelClass:"label-firm",
                            onChange: (e) => {companyInfo.city = e.target.value; btnClassSet()}
                        },
                        {
                            label: 'Province/State',
                            type: 'text',
                            alignLeft: true,
                            bsClass: 'form-control input-label',
                            disabled: false,
                            defaultValue: companyInfo?.state,
                            labelClass:"label-firm",
                            onChange: (e) => {companyInfo.state = e.target.value; btnClassSet()},
                        },
                ]} />
                <FormInputs 
                    ncols={['col-md-6', 'col-md-6']}
                    proprieties={[
                        {
                            label: 'Website',
                            type: 'email',
                            alignLeft: true,
                            bsClass: 'form-control input-label',
                            disabled: false,
                            defaultValue: companyInfo?.website,
                            labelClass:"label-firm",
                            onChange: (e) => {companyInfo.website = e.target.value; btnClassSet()},
                        },
                        {
                            label: 'Postal code/ZIP',
                            type: 'text',
                            alignLeft: true,
                            bsClass: 'form-control input-label',
                            disabled: false,
                            defaultValue: companyInfo?.postalCode,
                            labelClass:"label-firm",
                            onChange: (e) => {companyInfo.postalCode = e.target.value; btnClassSet()}
                        }
                    ]} />
                <FormInputs
                    ncols={['col-md-6', 'col-md-6']}
                    proprieties={[
                        {
                            label: 'Company email',
                            type: 'email',
                            bsClass: 'form-control input-label',
                            alignLeft: true,
                            defaultValue: companyInfo?.companyEmail,
                            disabled: false,
                            labelClass:"label-firm",
                            onChange: (e) => {companyInfo.companyEmail = e.target.value; btnClassSet()}
                        },
                        {
                            label: 'Phone number',
                            type: 'text',
                            alignLeft: true,
                            bsClass: 'form-control input-label',
                            disabled: false,
                            defaultValue: companyInfo?.phoneNumber,
                            labelClass:"label-firm",
                            onChange: (e) => {companyInfo.phoneNumber = e.target.value; btnClassSet()}
                        },
                    ]} />
                    <div className='save-button-cont'>
                        <Button className={buttonClasses.join(' ')} onClick={changeCompanyInfo}>Save</Button>    
                    </div> 
            </Col>
            <Col md={6}>
                <div className='logo-container'>
                    <img src={companyInfo?.logo} className='company-logo'/>
                    <div className='add-image'>
                        <ImageUploading
                                multiple
                                onChange={onUploadChange}
                                maxNumber={maxNumber}
                                dataURLKey="data_url"
                            >
                                {({
                                onImageUpload,
                                dragProps
                                }) => (
                                    <div style={{margin: '0', padding: '0'}}
                                        onClick={onImageUpload}
                                        {...dragProps}>
                                            <Icon style={{ fontSize: 30, color: 'black' }} >add_circle</Icon>
                                    </div>
                                )}
                            </ImageUploading>
                    </div>
                    {/* <div>
                        <div className="App">
                            <ImageUploading
                                multiple
                                onChange={onUploadChange}
                                maxNumber={maxNumber}
                                dataURLKey="data_url"
                            >
                                {({
                                onImageUpload,
                                isDragging,
                                dragProps
                                }) => (
                                <div className="upload__image-wrapper">
                                    <div 
                                        className={!isDragging ? 'dropZone' : 'dropZone active'}  
                                        onClick={onImageUpload}
                                        {...dragProps}>
                                            Click or Drop here
                                        </div>
                                    &nbsp;
                                </div>
                                )}
                            </ImageUploading>
                            </div>
                    </div> */}
                </div>
            </Col>
            {alert}
        </Row>
    )
}

export default EditFirmProfile
