import React, { Component } from 'react'

const colors = [
  '#1abc9c',
  '#2ecc71',
  '#3498db',
  '#9b59b6',
  '#34495e',
  '#16a085',
  '#27ae60',
  '#2980b9',
  '#8e44ad',
  '#2c3e50',
  '#f1c40f',
  '#e67e22',
  '#e74c3c',
  '#95a5a6',
  '#f39c12',
  '#d35400',
  '#c0392b',
  '#bdc3c7',
  '#7f8c8d',
]

class Avatar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      colorIndex: 0,
      initials: '',
    }
  }

  componentDidMount() {
    this.generateAvatar()
  }

  componentDidUpdate(prevProps) {
    if (this.props.name !== prevProps.name) {
      this.generateAvatar()
    }
  }

  generateAvatar = () => {
    const { name } = this.props
    if (name) {
      let nameSplit = name.split(' ')
      let initials =
        nameSplit.length > 1
          ? nameSplit[0].charAt(0).toUpperCase() + nameSplit[1].charAt(0).toUpperCase()
          : 'NA'

      const charIndex = initials.charCodeAt(0) - 65
      const colorIndex = charIndex % 19
      this.setState({ colorIndex, initials })
    }
  }

  render() {
    const { colorIndex, initials } = this.state
    return (
      <div style={{ ...styles.avatarCircle, backgroundColor: `${colors[colorIndex]}` }}>
        <span className="customTitle" style={styles.initialsText}>
          {initials}
        </span>
      </div>
    )
  }
}

const styles = {
  avatarCircle: {
    width: '100%',
    height: '100%',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  initialsText: {
    fontSize: 16,
    color: '#FFF',
  },
}

export default Avatar
