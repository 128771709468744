import React, { Component } from 'react'
// react component for creating dynamic tables
import ReactTable from 'react-table'
import { Grid, Row, Col, FormControl, FormGroup, InputGroup } from 'react-bootstrap'

import Card from 'components/Card/Card.jsx'
import Button from 'components/CustomButton/CustomButton.jsx'
import Checkbox from 'components/CustomCheckbox/CustomCheckbox.jsx'

import CreateWorkerProfileModal from 'views/Components/CreateWorkerProfileModal'
import SweetAlert from 'react-bootstrap-sweetalert'

import {Link, Redirect} from 'react-router-dom'

import { apiRequest } from 'helpers'


class Workers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      pages: 0,
      searchParam: '',
      data: [],
      tableState: null,
      createProfileModal: null,
      alert: null,
      redirect: null
    }
  }

  componentDidMount(){
    if(localStorage.getItem('role') === 'worker'){
      this.setState({redirect: <Redirect to='/admin/not-allowed' />})
    }
  }

  getWorkers = async (page = 0, pageSize = 10, sorted, filtered) => {
    let orderId = null
    let orderType = null
    let searchParam = this.state.searchParam

    if (sorted && sorted.length > 0) {
      orderId = sorted[0].id
      orderType = sorted[0].desc ? 'DESC' : 'ASC'
    }

    if (orderId === null && orderType === null) {
      orderId = 'firstName'
      orderType = 'ASC'
    }

    const response = await apiRequest({
      method: 'get',
      url: `users?page=${
        page + 1
      }&pageSize=${pageSize}&order=${orderId}&orderType=${orderType}&searchQuery=${searchParam}`,
    })

    if (response.status === 200) {
      console.log('Response')
      console.log(response)
      /* if (searchParam !== '') {
        /* this.setState({
          data: response.data.pageOfItems,
          pages: response.data.pager.totalPages,
        })
        this.refReactTable.fireFetchData()
      }*/
      return response
    }
  }
  // getWorkers = async (page = 0, pageSize = 10, sorted, filtered) => {
  //   // let orderId = null
  //   // let orderType = null
  //   // let searchParam = this.state.searchParam

  //   // if (sorted && sorted.length > 0) {
  //   //   orderId = sorted[0].id
  //   //   orderType = sorted[0].desc ? 'DESC' : 'ASC'
  //   // }

  //   // if (orderId === null && orderType === null) {
  //   //   orderId = 'firstName'
  //   //   orderType = 'ASC'
  //   // }

  //   const response = await apiRequest({
  //     method: 'get',
  //     url: `users?page=${
  //       page + 1
  //     }&pageSize=${10}&order=${"firstName"}&orderType=${"ASC"}&searchQuery=${"Ahmed"}`,
  //   })

  //   if (response.status === 200) {
  //     console.log('Response')
  //     console.log(response)
  //     /* if (searchParam !== '') {
  //       /* this.setState({
  //         data: response.data.pageOfItems,
  //         pages: response.data.pager.totalPages,
  //       })
  //       this.refReactTable.fireFetchData()
  //     }*/
  //     return response
  //   }
  // }

  onFetchData = async (state, instance = null) => {
    this.setState({ loading: true })
    console.log('STATE')
    console.log(state)
    const res = await this.getWorkers(state.page, state.pageSize, state.sorted, state.filtered)

    if (res) {
      let data = res.data.pageOfItems.map((item, key) => {
        return {
          id: item.userId,
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.email,
          phone: item.phone,
          active: (
            <div >
              <Checkbox
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  this.warningWithConfirmMessage(item.active, item.userId)
                }}
                isChecked={item.active}
                number={item.userId && item.userId.toString()}
                //  label="Make this form default"
              />
            </div>
          ),
          actions: (          
            <div className="actions-right">
              <Link to={`/admin/users/${item.userId}`}>
                <Button bsStyle="primary">Open</Button>
              </Link>{' '} 
            </div>
          ),
        }
      })

      this.setState({
        data,
        pages: res.data.pager.totalPages,
        loading: false,
        tableState: state,
      })
    }
  }

  createProfile = async (email, firstName, lastName) => {
    console.log('== CALLED ==')
    const data = {
      email,
      firstName, 
      lastName
    }

    try {
      const response = await apiRequest({
        url: 'users/addWorker',
        method: 'post',
        data,
      })
      console.log('RESPONSE')
      console.log(response)
      if (response.status === 200) {
        this.successAlert('New profile for worker has been created.', true)
      }
    } catch (err) {
      console.log('==ERROR==')
      this.errorAlert(err.response.data.message)
    }
  }

  toggleActive = async (userId) => {
    console.log('== POZVANO ==')
    try {
      const response = await apiRequest({
        method: 'PUT',
        url: 'users/makeUserInactiveActive/' + userId,
      })
      console.log('== RESP ==')
      console.log(response)
      if (response.status === 203 || response.status === 200) {
        this.successAlert('The profile has been changed.', true)
      }
    } catch (err) {
      this.errorAlert()
    }
  }

  warningWithConfirmMessage = (status, userId) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title={`Are you sure you want to make this profile ${
            status ? ' inactive' : 'active'
          } ?`}
          onConfirm={() => this.toggleActive(userId)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes"
          cancelBtnText="Cancel"
          showCancel>
          This profile will be {status ? 'disabled' : 'enabled'}.
        </SweetAlert>
      ),
    })
  }

  removeModal = () => {
    this.setState({ createProfileModal: null })
  }

  /* SUCCESS AND ERROR ALERTS */
  successAlert(msg = '', refresh = false) {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{
            display: 'block',
            marginTop: '-100px',
          }}
          title="Done"
          onConfirm={() => this.hideAlert(refresh)}
          confirmBtnBsStyle="info">
          {msg}
        </SweetAlert>
      ),
    })
  }

  errorAlert(message = 'There was an error. Please try again.') {
    this.setState(
      {
        alert: (
          <SweetAlert
            danger
            style={{
              display: 'block',
              marginTop: '-100px',
            }}
            title="Error"
            onConfirm={() => this.hideAlert()}
            confirmBtnBsStyle="info">
            {message}
          </SweetAlert>
        ),
      },
      () => {
        this.removeModal()
      }
    )
  }

  hideAlert(reloadData = false) {
    this.setState(
      {
        alert: null,
      },
      () => {
        if (reloadData) {
          this.removeModal()
          this.onFetchData(this.state.tableState)
        }
      }
    )
  }

  render() {
    const { data, pages, loading, redirect } = this.state
    console.log('DATA')
    console.log(data)
    return (
      <div className="main-content">
        {this.state.alert}
        {redirect}
        <Grid fluid>
          <Row style={{ marginBottom: 10 }}>
            <Col md={6}>
              <div className="text-left">
                <span className="title customTitle">Workers Management Console</span>
              </div>
            </Col>
            <Col md={6}>
              <div className="text-right">
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Addon>
                      <i className="fa fa-search" />
                    </InputGroup.Addon>
                    <FormControl
                      onChange={(event) => {
                        this.setState({ searchParam: event.target.value }, () => {
                          this.onFetchData(this.state.tableState)
                        })
                      }}
                      type="text"
                      placeholder="Search..."
                    />
                  </InputGroup>
                </FormGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div>
                    <Row style={{ marginBottom: 10 }}>
                      <Col md={6}>
                        <div className="text-left">
                          <h4 className="title">Workers List</h4>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="text-right">
                          <Button
                            bsStyle="primary"
                            fill
                            onClick={() => {
                              this.setState({
                                createProfileModal: (
                                  <CreateWorkerProfileModal
                                    removeModal={this.removeModal}
                                    createProfile={
                                      this.createProfile
                                    }></CreateWorkerProfileModal>
                                ),
                              })
                            }}>
                            <span className="btn-label">
                              <i className="fa fa-plus-square" />{' '}
                            </span>
                            Create new profile
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <ReactTable
                      data={data}
                      ref={(refReactTable) => {
                        this.refReactTable = refReactTable
                      }}
                      filterable
                      columns={[
                        {
                          Header: 'First Name',
                          sortable: true,
                          filterable: false,
                          accessor: 'firstName',
                        },
                        {
                          Header: 'Last Name',
                          sortable: true,
                          filterable: false,
                          accessor: 'lastName',
                        },
                        {
                          Header: 'Email',
                          sortable: false,
                          filterable: false,
                          accessor: 'email',
                        },
                        {
                          Header: 'Phone',
                          sortable: false,
                          filterable: false,
                          accessor: 'phone',
                        },
                        {
                          Header: 'Is Active',
                          sortable: false,
                          filterable: false,
                          accessor: 'active',
                        },
                        {
                          Header: 'Actions',
                          accessor: 'actions',
                          sortable: false,
                          filterable: false,
                        },
                      ]}
                      defaultPageSize={10}
                      //showPaginationTop
                      pages={pages}
                      manual
                      showPaginationBottom={true}
                      loading={loading}
                      showPagination={true}
                      onFetchData={this.onFetchData}
                      className="-striped -highlight"
                    />
                  </div>
                }
              />
            </Col>
          </Row>
          {this.state.createProfileModal}
        </Grid>
      </div>
    )
  }
}

export default Workers
