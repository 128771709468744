import React, { Component } from 'react'
import { Grid, Row, Col, Media, FormControl, FormGroup } from 'react-bootstrap'

import Card from 'components/Card/Card.jsx'

import Button from 'components/CustomButton/CustomButton.jsx'

class RegisterPage extends Component {
  render() {
    return (
      <Grid>
        <Row>
          <Col md={8} mdOffset={2}>
            <div className="header-text">
              <h2>Gail Broadbent CPA</h2>
              <h4>Register account for your company and experience the dashboard today</h4>
              <hr />
              <h6 style={{fontWeight: 'normal'}}>You can register your company on our sales page!</h6>
              <Button style={{color: 'white', border: '1px solid white'}}><a style={{color: 'white', textDecoration: 'none'}} href="https://www.google.com">Click here to visit our sales page</a></Button>
            </div>
          </Col>
         {/* <Col md={4} mdOffset={2}>
             <Media>
              <Media.Left>
                <div className="icon">
                  <i className="pe-7s-user" />
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading>Free Demo Account</Media.Heading>
                You can register for free demo account for your company. With demo account you
                can check everything inside the dashboard before you buy a license.
              </Media.Body>
            </Media>
            <Media>
              <Media.Left>
                <div className="icon">
                  <i className="pe-7s-graph1" />
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading>Awesome Performances</Media.Heading>
                Our dashboard is light, fast and secure.
              </Media.Body>
            </Media>
            <Media>
              <Media.Left>
                <div className="icon">
                  <i className="pe-7s-headphones" />
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading>Global Support</Media.Heading>
                Our support is available 24/7 for help. <b>Contact us</b>
              </Media.Body>
            </Media>
          </Col>
          <Col md={4}>
            <form>
              <Card
                plain
                content={
                  <div>
                    <FormGroup>
                      <FormControl type="text" placeholder="Your First Name" />
                    </FormGroup>
                    <FormGroup>
                      <FormControl type="text" placeholder="Your Last Name" />
                    </FormGroup>
                    <FormGroup>
                      <FormControl type="text" placeholder="Company" />
                    </FormGroup>
                    <FormGroup>
                      <FormControl type="email" placeholder="Enter Email" />
                    </FormGroup>
                    <FormGroup>
                      <FormControl type="password" placeholder="Password" autoComplete="off" />
                    </FormGroup>
                    <FormGroup>
                      <FormControl
                        type="password"
                        autoComplete="off"
                        placeholder="Password Confirmation"
                      />
                    </FormGroup>
                  </div>
                }
                ftTextCenter
                legend={
                  <Button wd fill neutral>
                    Create Free Account
                  </Button>
                }
              />
            </form>
          </Col> */}
        </Row>
      </Grid>
    )
  }
}

export default RegisterPage
