import React, { Component } from 'react'
import { Grid, Row, Col, Table, Modal, ModalHeader, ModalTitle } from 'react-bootstrap'

import Select from 'react-select'
import AsyncSelect from 'react-select'
// react component used to create alerts
import SweetAlert from 'react-bootstrap-sweetalert'
import ReactTable from 'react-table'

import Card from 'components/Card/Card.jsx'
import Button from 'components/CustomButton/CustomButton.jsx'
import Switch from 'react-bootstrap-switch'
import Upload from 'components/Upload/Upload.jsx'
import PDFModal from 'views/Components/PDFModal.jsx'
import { Document, Page } from 'react-pdf/dist/entry.webpack'
import UploadNewVersionModal from 'views/Components/UploadNewVersionModal.jsx';
import ConfirmationModal from 'views/Components/ConsoleConfirmationModal.jsx'
import EngagementUpload from 'views/Components/EngagementUpload';
import SearchCompany from 'views/Components/SearchCompanyModal'


import { ReactComponent as Loader } from 'assets/img/loader-preview.svg'

import { withRouter, Link } from 'react-router-dom'

import { apiRequest } from 'helpers'

class Console extends Component {
  constructor(props) {
    super(props)
    this.state = {
      alert: null,
      forms: [],
      documents: [],
      isLoading: false,
      loading: false,
      showModal: false,
      pdfModal: null,
      approveModal: null,
      role: localStorage.getItem('role'),
      showLoader: (
        <SweetAlert
        style={{
          display: 'block',
          marginTop: '-100px',
        }}
        showConfirm={false}>
        <div>
          <Loader style={{ height: 75, width: 100 }} />
        </div>
      </SweetAlert>
      ),
      pdfContent: null,
      selectValue: null, 
      uploadNewVersion: null,
      firmModal: null,
      selectFirmOptions: [],
      firmValue: null,
      firmLabel: '',
      approveFilter: null,
      indexPage: 1,
      confirmationModal: null,
      engagementUpload: null,
      searchCompany: null,
    }
  }

  componentDidMount() {
    this.getFormsDocuments()
    this.getFirms()
    if(localStorage.getItem("role") !== 'superAdmin'){
      this.getForms()
    }
  }
  statusColor = (status) =>{
    switch(status){
      case true:
        return 'green'
      case false:
        return 'orange'
      default:
        return 'red'
    }
  }

  getForms = async() =>{
    const forms = await apiRequest({
      method: 'get',
      url: 'forms',
    })

    if (forms.status === 200){
      this.setState({ forms: forms.data, showLoader:null, loading: false })
    }    
  }

  getFormsDocuments = async ()=> {

    const { approveFilter } = this.state
    let size = 10;
    this.setState({loading: true, firmModal: null})
    const role = localStorage.getItem('role');

  
    const firms = await apiRequest({
      method: 'get',
      url: `firms?approved=true&searchQuery=${this.state.firmLabel}`
    })

    const documents = await apiRequest({
      method: 'get',
      url: `documents/default?page=${this.state.indexPage}&&approved=${approveFilter}&&size=${size}&&firmId=${this.state.firmValue}`,
    })

    
    if (documents.status === 200){
        // this.setState({
        //   firmOptions : firms.data.pageOfItems
        // })
      let data = documents.data.items.map((elem, index) => {
        return{
          id: elem.documentId,
          documentName: elem.documentName,
          //elem.firmId
          company: elem?.firm?.name,
          documentType : elem.documentType === 'application/pdf' ? <div style={{ textAlign: 'center' }}>PDF</div> : 'DOC',
          documentStatus: (
            <span
              style={{color: this.statusColor(elem.isApproved)}}
            >{elem.isApproved ? 'APPROVED' : 'IN REVIEW'}</span>
          ),
          preview: (
            <Button
              onClick={() =>
                this.setState({
                  pdfModal: (
                    <PDFModal
                      removeModal={this.removeModal}
                      documentName={elem.documentName}
                      documentKey={elem.key}></PDFModal>
                      ),
                    })
                  }
                bsStyle="default">
              Open
            </Button>
          ),
          setDefault:(
            <div style={{textAlign: 'right'}}>
              <Switch
                onChange={() => this.makeDocumentDefault(elem.documentId)}
                onText=""
                offText=""
                value={elem.isDefault}
              />
            </div>
          ),
          approve: (
            this.state.role === 'superAdmin' && !elem.isApproved ?
            <Button 
              onClick={() => 
                this.approveModal(elem)} >
                Approve
            </Button>
            : null
          )
          
        }
        
      })
      this.setState({ 
        documents: data, 
        showLoader:null, 
        loading: false,
        pages:  documents.data.totalPages})
    }   
  }

  refreshForms = async () => {
    const forms = await apiRequest({
      method: 'get',
      url: 'forms/getTopFour',
    })
    console.log('FORMS')
    console.log(forms)
    if (forms.status === 200) {
      console.log('GET TOP FORMS')
      console.log(forms)
      this.setState({ forms: forms.data })
    }
  }

  setFormDefault = async (formId) => {
    this.setState({ isLoading: true })
    const response = await apiRequest({
      method: 'put',
      url: `forms/setDefault/${formId}`,
    })
    console.log('response')
    console.log(response)
    if (response.status === 200) {
      this.successAlert()
      this.getForms()
    }

    this.setState({ isLoading: false })
  }

  removeFormDefault = async (formId) => {
    this.setState({ isLoading: true })
    const response = await apiRequest({
      method: 'put',
      url: `forms/removeDefaultForm/${formId}`,
    })
    console.log('response')
    console.log(response)
    if (response.status === 200) {
      if (response.data.errMessage) {
        this.errorAlert(response.data.errMessage)
      } else {
        this.successAlert()
        this.getFormsDocuments()
      }
    }

    this.setState({ isLoading: false })
  }

  successAlert = (msg = 'Done', title = 'Done') => {
    console.log('CALL')
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title={title}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"></SweetAlert>
      ),
    })
  }


  warningWithConfirmMessage = (formId = null, type = 'add') => {
    if(type === 'add'){
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: 'block', marginTop: '-100px' }}
            title="Are you sure?"
            onConfirm={() =>
              this.setFormDefault(formId)
            }
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes"
            cancelBtnText="Cancel"
            showCancel></SweetAlert>
        ),
      })
    }else{
      this.setState({
        alert: (
          <SweetAlert
            danger
            style={{
              display: 'block',
              marginTop: '-100px',
            }}
            title="You can create default form only!"
            onConfirm={() => this.hideAlert()}
            confirmBtnBsStyle="info">
        </SweetAlert>
        )
      })
      
    }
   
  }

  errorAlert = (text) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{
            display: 'block',
            marginTop: '-100px',
          }}
          title="Error"
          onConfirm={() => this.hideAlert()}
          confirmBtnBsStyle="info">
          {text}
        </SweetAlert>
      ),
    })
  }

  hideAlert() {
    this.setState({
      alert: null,
    })
  }

  convertDocumentType = (type) => {
    if (type.includes('pdf')) {
      return 'PDF'
    }

    return type
  }

  makeDocumentDefault = async (docId) => {
    const response = await apiRequest({
      method: 'put',
      url: `documents/setRemoveDefault/${docId}`,
    })
    console.log('== DOC RESPONSE ==')
    console.log(response)
    if (response.status === 200) {
      this.getFormsDocuments()
    }
  }

  removeModal = () => {
    this.setState({ pdfModal: null })
  }

  // makeFormRegistration = async (formId) => {
  //   const response = await apiRequest({
  //     method: 'put',
  //     url: `forms/setRegistrationForm/${formId}`,
  //   })

  //   if (response.status === 200) {
  //     console.log('RES')
  //     console.log(response)
  //     if (response.data.errMessage) {
  //       this.errorAlert(response.data.errMessage)
  //     } else this.refreshForms()
  //   }
  // }

  hideMessage = () =>{
    this.setState({
      showLoader: null
    })
  }

  approveDocument = async(doc) => {
    const data = {documentId: doc.documentId}
    try{
      const response = await apiRequest({
        method: 'post',
        url: `documents/approve`,
        data
      })

      if(response.status === 200){
        this.setState({
          approveModal: false,
          showLoader: (
            <SweetAlert
              success
              style={{
                display: 'block',
                marginTop: '-100px',
              }}
              title='Document succesfully approved!'
              onConfirm={() => this.hideMessage()}
              confirmBtnBsStyle="info">
            </SweetAlert>
          )
        })
        console.log(response)
        this.getFormsDocuments()
      }
    }catch(err){
      this.setState({
        approveModal: false,
        showLoader: (
          <SweetAlert
            danger
            style={{
            display: 'block',
            marginTop: '-100px',
            }}
            title='Something went wrong'
            onConfirm={() => this.hideMessage()}
            confirmBtnBsStyle="info">
          </SweetAlert>
        )
      })

      console.log(err)
    }
  }

  downloadDocument = (data, pdf) => {
    const docName = data.documentName
    const linkSource = 'data:application/pdf;base64,' + pdf
    const downloadLink = document.createElement('a')
    const fileName = docName ? docName : 'Document.pdf'

    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }
 
  approveModal = async(doc) => {
    this.setState({
      showLoader: (
        <SweetAlert
        style={{
          display: 'block',
          marginTop: '-100px',
        }}
        showConfirm={false}>
        <div>
          <Loader style={{ height: 75, width: 100 }} />
        </div>
      </SweetAlert>
      )
    })
    const response = await apiRequest({
      method: 'get',
      url: 'files/download/' + doc.key,
    })

    if (response.status === 200) {
      this.setState({
        showLoader: null,
        pdfContent: response.data,
        approveModal: (
          <Modal show={true}>
            <ModalHeader 
              style={{backgroundColor: '#447DF7'}}>
                <ModalTitle 
                  style={{color: '#ffffff'}}>
                  Upload new version
                </ModalTitle>
                <p style={{color: 'white', textAlign: 'right',fontWeight: 'bold', cursor: 'pointer', marginTop: '-25px', marginRight: '15px'}} onClick={() =>this.setState({approveModal: null})}>X</p>
            </ModalHeader>

            <Modal.Body>
              <div 
                style={{display:'flex', alignItems: 'center', flexDirection: 'column'}}>
                <Document
                  file={'data:application/pdf;base64,' + response.data}>
                    <Page 
                      pageNumber={1} 
                      width={200} 
                      heigth={200} />
                </Document>
                <p style={{fontSize: '12px', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => this.downloadDocument(doc, response.data)}><i class="fa fa-download" aria-hidden="true"></i>{doc.documentName}</p>
              </div>
              <div style={{display:'flex', justifyContent: 'space-around', flexDirection: 'row', marginTop: '20px'}}>
                <Button onClick={() => this.approveDocument(doc)} className='btn-fill btn-facebook'>Approve this version</Button>
                <Button onClick={() => this.openNewVersionModal(doc.documentId)}>Upload new version</Button>
              </div>
            </Modal.Body>

          </Modal>
            )
      })
    }
  }
  closeUploadNewVersion = () => {
    this.setState({
      uploadNewVersion: null
    })
  }

  getSelectChange = (value) =>{
    this.getFormsDocuments(value.value)
  }
  openNewVersionModal = (docId, firmId) =>{
    this.setState({
      approveModal: false,
      uploadNewVersion: (
        <Modal show={true}>
          <Modal.Header>Upload new version of the document.</Modal.Header>
          <p style={{color: 'black', textAlign: 'right',fontWeight: 'bold', cursor: 'pointer', marginTop: '-25px', marginRight: '15px'}} onClick={() =>this.setState({uploadNewVersion: null})}>X</p>
          <Modal.Body>
            <Upload documentId={docId} closeModal={() =>  this.closeUploadNewVersion()} getDocuments={() => this.getFormsDocuments()}/>
          </Modal.Body>
        </Modal>
      )
    })
  }
  onPageChange = (pageIndex) =>{
    let page = pageIndex+1
    console.log(page)
    this.setState({indexPage: page})
    this.getFormsDocuments()
  }

  getFirms = async(val = '') => {
    try{
      const firms = await apiRequest({
        method: 'get',
        url: `firms?approved=true&searchQuery=${val}`
      })
      if(firms.status === 200) {
        const localFirms = []
        firms.data.pageOfItems.map(elem => {
          localFirms.push({label: elem.name, value: elem.name})
          })
        this.setState({
          firmOptions: [...localFirms]
        })
      }
    }catch(err){
      this.errorAlert(err.response.data.message)
    }
  }
  getSelectedCompany = (val) => {
    if(val.value === null) {
      this.setState({
        searchCompany: null,
        firmValue: '',
        firmLabel: ''
      },() =>  this.getFormsDocuments())
    }else{
      this.setState({
        searchCompany: null,
        firmValue: val.value,
        firmLabel: val.label
      },() =>  this.getFormsDocuments())
    }
  }
  

  openFirmModal = async() =>{
    this.setState({
      searchCompany: <SearchCompany getSelectedCompany={this.getSelectedCompany} closeModal={() => this.setState({searchCompany : null})}/>
    })
  }
  sendEngagement = async (doc) => {
    console.log('send request')
    console.log(doc)
    if(doc.length > 1){
      this.removeEngagement()
      this.errorAlert("You can't add more than one engagement letter!")
    }else{
      var data = new FormData()
      data.append('document', doc[0])
      data.append('isEngagement', true)

      try{
        const response = await apiRequest({
          method: 'post',
          url: 'documents/upload-approval',
          data
        })
        if(response.status === 201){
          this.removeEngagement()
          setTimeout(() => {this.successAlert('Document uploaded successfully')}, 1000)
          this.getFormsDocuments()
        }
      }catch(err){
        this.errorAlert(err.response.data.message)
        this.removeEngagement()
      }
      
   

      //   const jwtoken = localStorage.getItem('usertoken')
      //   var req = new XMLHttpRequest()

      //   const url = `${Config.APP_API_URL}/documents/upload-approval`

      //   req.open('POST', url)

      //   if (jwtoken) {
      //     req.setRequestHeader('Authorization', 'Bearer ' + jwtoken)
      //   }

      //   req.send(formData)

      //   req.onload = () => {
      //     console.log('STATUS')
      //     console.log(req.status)
      //     if (req.status === 200) {
      //       console.log('REQQQ')
      //       const response = JSON.parse(req.response)
      //       console.log('======== RESPONSE gfgfdhdf ==========')
      //       console.log(response)
      //       this.removeEngagement()
      //       setTimeout(() => {this.successAlert('Document uploaded successfully', true)}, 1000)
      //       this.getRequestData()
      //     } else {
            
      //     }
      // }
    }
  }

  removeEngagement = () => {
    this.setState({engagementUpload: null})
  }


  openEngagementUpload = () => {
    this.setState({
      confirmationModal: null
  })
  setTimeout(() => {this.setState({engagementUpload: (<EngagementUpload sendRequest={(doc) => this.sendEngagement(doc)} closeModal={() => this.removeEngagement()}/>)})}, 500)
}

addNormalDocument = () => {
  this.setState({
    confirmationModal: null,
    showModal:  true
  })
}

  showConfirmationModal = () => {
    this.setState({
      confirmationModal: (
        <ConfirmationModal
          closeConfirmation={() => this.setState({confirmationModal: null})}
          engagementLetter={() => this.openEngagementUpload()}
          addNormalDoc={() => this.addNormalDocument()}/>)
    })
  }

  render() {
    const { forms, documents, role, loading } = this.state
    return (
      <div className="main-content">
        {this.state.alert}
        {this.state.showLoader}
        {this.state.approveModal}
        {this.state.uploadNewVersion}
        {this.state.firmModal}
        <Grid fluid>
          <Card
            plain
            title="Manage console"
            category={<span>Manage forms and documents</span>}
          />

         
          <Row style={{ marginBottom: 10 }}>
          {localStorage.getItem('role') !== 'superAdmin' ? 
            <Col md={6}>
              <div className="text-left">
                <h4 style={{ margin: 0 }}>Forms</h4>
              </div>
            </Col> : null}

            {localStorage.getItem('role') === 'admin' ?
            <Col md={6}>
              <div className="text-right">
                <Button
                  bsStyle="primary"
                  fill
                  onClick={() => this.props.history.push('formbuild')}>
                  <span className="btn-label">
                    <i className="fa fa-plus-square" />{' '}
                  </span>
                  Add new
                </Button>
              </div>
            </Col> : null}
          </Row>
          <Row>
            {(forms || []).map((s, key) => {
              console.log('FORMS MAP')
              console.log(s)
              return (
                <Col md={3} key={key}>
                  <Card
                    ctTextCenter
                    content={
                      <div>
                        {/* {s.isDefault && (
                          <div
                            style={{
                              position: 'absolute',
                              top: 5,
                              right: 15,
                              width: 50,
                              height: 50,
                            }}>
                            <i
                              className="fa fa-check-circle"
                              style={{ fontSize: 35, color: '#447df7' }}></i>
                          </div>
                        )} */}

                        <h5>{s.formName}</h5>
                        <h5>
                          <small>
                            {s.description.length > 85
                              ? s.description.substring(0, 85) + '...'
                              : s.description}
                          </small>
                        </h5>
                        {localStorage.getItem('role') === 'admin' ? 
                        <Link to={`/admin/formbuild/${s.formId}`}>
                          <Button bsStyle="warning">Edit form</Button>
                        </Link> : null}
                        {/* {!s.isDefault ? (
                          <Button
                            onClick={() => this.warningWithConfirmMessage(s.formId)}
                            bsStyle="primary"
                            leftMargin>
                            Make default
                            {}
                          </Button>
                        ) : (
                          <Button
                            onClick={() => this.warningWithConfirmMessage(s.formId, 'remove')}
                            bsStyle="primary"
                            leftMargin>
                            Remove default
                            {}
                          </Button>
                        )} */}
                        <div className="top20"></div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                          }}>
                          <h6>Default form?</h6>

                          <Switch
                            onChange={s.isDefault ? () => this.warningWithConfirmMessage(s.formId, 'remove') : () => this.warningWithConfirmMessage(s.formId)}
                            onText=""
                            offText=""
                            value={s.isDefault}
                            //defaultValue={s.isRegistration}
                          />
                        </div>
                      </div>
                    }
                  />
                </Col>
              )
            })}
          </Row>
          {localStorage.getItem('role') === 'admin' || localStorage.getItem('role') === 'worker' ? 
          <Row style={{ marginBottom: 8 }}>
            <Col md={5}>
              <div className="text-left">
                <h4 style={{ margin: 0 }}>Documents</h4>
              </div>
            </Col>
            <Col md={3}>
              <div className="text-right">
                  <Button
                    bsStyle="primary"
                    fill
                    //this.setState({ showModal: true })}
                    onClick={() => this.showConfirmationModal()}>
                    <span className="btn-label">
                      <i className="fa fa-upload" />{' '}
                    </span>
                    Upload
                  </Button>
                </div>
            </Col>
            <Col md={4}>
            <Select 
                  options={[
                    {value: null, label: 'ALL'},
                    {value: true, label: 'APPROVED', },
                    {value: false, label: 'IN REVIEW', }]}
                    onChange={(value) => this.setState({approveFilter: value.value}, () => this.getFormsDocuments())}
                    placeholder='ALL'>
                </Select>
            </Col>
          </Row>  : 
          <Row style={{ marginBottom: 8 }}>
              <Col md={5}>
                <div className="text-left">
                  <h4 style={{ margin: 0 }}>Documents</h4>
                </div>
              </Col>
              <Col md={3}>
                <div className="text-right">
                  <Button
                    className="get-firm-button"
                    bsStyle="primary"
                    fill
                    onClick={() => this.openFirmModal()}>
                    <span className="btn-label">
                    </span>
                    Get documents by firm
                  </Button>
                  </div>
              </Col>
              <Col md={4}>
              <Select 
                    options={[
                      {value: null, label: 'ALL'},
                      {value: true, label: 'APPROVED', },
                      {value: false, label: 'IN REVIEW', }]}
                      onChange={(value) => this.setState({approveFilter: value.value}, () => this.getFormsDocuments())}
                      placeholder='ALL'>
                  </Select>
              </Col>
          </Row> }
            
          <Row>
            <Col md={12}>
              <div className="card">
              <ReactTable
                      data={documents}
                      ref={(refReactTable) => {
                        this.refReactTable = refReactTable
                      }}
                      filterable
                      columns={[
                        {
                          Header: 'Document ID',
                          sortable: true,
                          filterable: false,
                          accessor: 'id',
                        },
                        {
                          Header: 'Document Name',
                          sortable: true,
                          filterable: false,
                          accessor: 'documentName',
                        },
                        {
                          Header: 'Company',
                          sortable: false,
                          filterable: false,
                          accessor: 'company',
                        },
                        {
                          Header: 'Document Type',
                          sortable: false,
                          filterable: false,
                          accessor: 'documentType',
                        },
                        {
                          Header: 'Document status',
                          sortable: false,
                          filterable: false,
                          accessor: 'documentStatus',
                        },
                        {
                          Header: 'Preview',
                          accessor: 'preview',
                          sortable: false,
                          filterable: false,
                        },
                        {
                          Header: 'Approve',
                          accessor: 'approve',
                          sortable: false,
                          filterable: false,
                        },
                         {
                          Header: 'Set Default',
                          accessor: 'setDefault',
                          sortable: false,
                          filterable: false,
                          
                        },
                      ]}
                      defaultPageSize={10}
                      //showPaginationTop
                      pages={this.state.pages}
                      manual
                      showPaginationBottom={true}
                      loading={loading}
                      showPagination={true}
                      className="-striped -highlight"
                      onPageChange={this.onPageChange}
                    />
              </div>
            </Col>
            {this.state.pdfModal}
            <Modal
              show={this.state.showModal}
              onHide={() => {
                this.setState({ showModal: false }, () => this.getFormsDocuments())
              }}>
              <Modal.Header closeButton>
                <Modal.Title>Upload a document</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Upload closeModal={() => this.setState({showModal : null})}  getDocs={()=> this.getFormsDocuments()} success={() => this.successAlert('Done')}/>
              </Modal.Body>
            </Modal>
          </Row>
        </Grid>
        {this.state.confirmationModal}
        {this.state.engagementUpload}
        {this.state.searchCompany}
      </div>
    )
  }
}

export default withRouter(Console)
