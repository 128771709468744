import React from 'react';
import './upload-card.css';
import { Col } from 'react-bootstrap';

import uploadIcon from 'assets/img/uploadSVG.svg'

const UploadCard = (props) =>{

    return(
        <Col sm={4} md={3} lg={2}  className='upload-card-container'>
            {console.log('%c DSFLSMFGDS', 'color: blue')}
            {console.log(props)}
            <img src={uploadIcon} className='upload-icon' alt="upload"/>
            <p>{props?.requestStatus?.requeststatus?.statusKey === 'paymentSubmitted' ? <p className='add-t183' onClick={props.addT183}>ADD T183 DOCUMENT</p> : 'Upload document'}</p>
            {props?.requestStatus?.requeststatus?.statusKey !== 'paymentSubmitted' ? 
            <>
                <p>or</p>
                <button className='request-client' onClick={props.addNormalDoc}>Request new scan</button> 
            </>
            : null }
        </Col>
    )
}

export default UploadCard;