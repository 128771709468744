import React, { useState } from 'react';

import { Row, Col, Modal, Form } from 'react-bootstrap'; 
import SweetAlert from 'react-bootstrap-sweetalert'

import Button from 'components/CustomButton/CustomButton';
import apiRequest from 'helpers/apiRequest';


const TotalPaymentModal = (props) => {

    const [amount, setAmount] = useState(null)
    const [message, setMessage] = useState('')
    const [alert, setAlert] = useState(null)
    const [inputClass, setInputClass] = useState(['inputName'])

    const styles = {
        fontWeight: 'bold',
        fontSize: '20px',
        inputStyle:{
            backgroundColor: '#ffffff',
            width: '70%'
        }
    }

    const hideMessage = () =>{
        setAlert(null)
    }

    const setAlertMessage = (status, message) => {
        setMessage(
            <SweetAlert
                status
                style={{
                  display: 'block',
                  marginTop: '-100px',
                }}
                title={message}
                onConfirm={hideMessage}>
              </SweetAlert>
        )
    }

    const sendPaymentRequest = async() => {
       const { requestId } = props
        const data = {
        requestId,
        message,
        amount,
        userId: localStorage.getItem('userId'),
        }
        try {
            const response = await apiRequest({
            method: 'post',
            url: 'requests/requestPayment',
            data,
            })
            if (response.status === 200) {
                props.removeModal()
                setTimeout(() => {props.successMessage()}, 500)
                
            }
        } catch (err) {
            props.removeModal()
            setTimeout(() => {props.errorMessage(err.response.data.message)}, 500)
        }
    }


    
    return(
        <div >
            <Modal show={true} style={{overflow: 'hidden'}}>
                <Modal.Header style={{backgroundColor: '#58A4B0', borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}>
                    <Modal.Title style={{color: '#ffffff'}}>Total payment amount</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12} className="text-center">
                            <p style={styles}>Total amount to pay</p>
                        </Col>
                    </Row>
                    <div style={{textAlign: 'center'}}>
                        <input 
                            type="text" 
                            className="total-amount-input"
                            placeholder='$ Amount'
                            onChange={ (evt) => 
                                setAmount(evt.target.value)
                            }/>
                    </div>
                    {/* <Row style={{marginTop: '20px', textAlign: 'center'}}>
                        
                        <Col md={12} style={{width: '70%'}}>
                        <input 
                            type="number" 
                            id="name" 
                            required 
                            onChange={ (evt) => 
                                setAmount(evt.target.value)
                            }
                            value={amount}
                            placeholder={"$ Amount" } />
                       {/*  <label
                            style={{backgroundColor: '#ffffff', marginLeft: '15px', width: '20%'}} 
                            htmlFor="name" 
                            className='inputLabel'>Field label:</label> 
                        </Col>
                    </Row> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-fill btn-facebook' onClick={() => sendPaymentRequest()}>Send</Button>
                    <Button className='btn-fill btn-twitter' onClick={() => props.removeModal()}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}



export default TotalPaymentModal;