import React from 'react'

const FirmManagement = () => {
    return (
        <div>
            <div style={{textAlign: 'center'}}>
                <p style={{color: '#79adb7', fontSize: '20px', fontWeight: '400'}}>Firm management</p>
                <p style={{fontWeight: '300', marginTop: '25px'}}>In this section you have ability to change your company info and to set up <b>Terms of Service</b></p>
            </div>
        </div>
    )
}

export default FirmManagement
