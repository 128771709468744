import React, { Component } from 'react'
import { Modal, FormControl } from 'react-bootstrap'
import Button from 'components/CustomButton/CustomButton.jsx'


class AnswerQuestionModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: true,
      message: '',
      errorMessage: null,
    }
  }

  componentDidMount() {}

  closeModal = () => {
    this.setState({ showModal: false })
    this.props.removeModal()
  }

  sendMessage = () => {
    const { message } = this.state
    const { requestQueryId } = this.props
    console.log('== REQUEST QUERY ID ?=')
    console.log(requestQueryId)
    if (message === '') {
      this.setState({
        errorMessage: <small className="text-danger">Message cannot be empty.</small>,
      })
      return
    }

    this.props.sendMessage(message, requestQueryId)
    this.setState({ showModal: false })
  }

  render() {
    const { message } = this.state
    const { question, clientName } = this.props

    return (
      <Modal show={this.state.showModal} onHide={() => this.closeModal()} className='modal-z-index'>
        <Modal.Header className="blueModal">
          <span className="modal-title customTitle" style={{ color: '#FFF' }}>
            Send an answer
          </span>
          <div className="pull-right">
            <Button bsStyle="primary" fill onClick={() => this.sendMessage()}>
              <span className="btn-label">
                <i className="fa fa-paper-plane" />
                {'  '}
              </span>
              <span style={{ fontWeight: 'bold' }}>Send</span>
            </Button>
            <Button bsStyle="primary" fill onClick={() => this.closeModal()}>
              <span className="btn-label">
                <i className="fa fa-times" />
                {'  '}
              </span>
              <span style={{ fontWeight: 'bold' }}>Cancel</span>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage}
          <div>
            <h3 className="customSubtitle top10">Question by {clientName}:</h3>
            <p>{question}</p>
            <FormControl
              style={{ marginTop: 15, borderWidth: 1, borderColor: '#ccc' }}
              rows="5"
              className="modalMessageBox top10"
              componentClass="textarea"
              bsClass="form-control"
              value={message}
              onChange={(event) => {
                this.setState({ message: event.target.value })
              }}
              placeholder="Type your message..."
            />
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default AnswerQuestionModal
