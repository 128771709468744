import $ from 'jquery'

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Grid, Row, Col } from 'react-bootstrap'
import Slugify from 'slugify'

import Card from 'components/Card/Card'
import Loader from 'components/Loader/Loader'

import { apiRequest } from 'helpers'

import SweetAlert from 'react-bootstrap-sweetalert'

import DefaultData from 'config/defaultFormData'
import mandatoryDocuments from 'variables/mandatoryDocuments.js'

window.jQuery = $
window.$ = $
require('jquery-ui')
require('jquery-ui-sortable')
require('formBuilder')
require('formBuilder/dist/form-render.min.js')

//let formData = []

const options = {
  // formData: formData,
  disabledActionButtons: ['clear'],
  editOnAdd: true,
  fields: DefaultData.customFields,
  // fieldRemoveWarn: true,
  controlPosition: 'right',
  controlOrder: DefaultData.controlOrder,
  disabledAttrs: DefaultData.disabledAttrs,
  disableFields: DefaultData.disableFields,
  inputSets: DefaultData.inputSets,
  typeUserEvents: {
    'checkbox-group': {
      onadd: function (fld) {
        $('.option-value').toggle()
      },
    },
    'radio-group': {
      onadd: function (fld) {
        $('.option-value').toggle()
      },
    },
    select: {
      onadd: function (fld) {
        $('.option-value').toggle()
      },
    },
    autocomplete: {
      onadd: function (fld) {
        $('.option-value').toggle()
      },
    },
  },
  templates: {
    newPage: function (fieldData) {
      return {
        field:
          '<hr id="' +
          fieldData.name +
          '" style="border: 2px solid #DFD7CB; border-radius: 3px;">',
        onRender: function () {
          //$(document.getElementById(fieldData.name)).rateYo({ rating: 3.6 })
        },
      }
    },
    inputsGroupName: function (fieldData) {
      return {
        field:
          '<hr id="' +
          fieldData.name +
          '" style="border: 2px solid #DFD7CB; border-radius: 3px;">',
        onRender: function () {
          //$(document.getElementById(fieldData.name)).rateYo({ rating: 3.6 })
        },
      }
    },
  },
  defaultFields: DefaultData.defaultFields,
}

class FormEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: null,
      formId: null,
      alert: null,
      isCheckedDefault: false,
      formName: '',
      errorformName: null,
      isLoading: false,
    }
  }

  fb = React.createRef()
  fbPreview = React.createRef()

  componentDidMount() {
    const {
      match: { params },
    } = this.props
    console.log('PARAMS')
    console.log(params)
    this.setState({
      formId: parseInt(params.formId),
    })

    this.formCreator = $(this.fb.current).formBuilder(options)

    this.getForm(params.formId)
  }

  getForm = async (formId) => {
    const getForm = await apiRequest({
      method: 'get',
      url: 'forms/' + formId,
    })

    let form = null
    if (getForm.status === 200) {
      form = getForm.data.formJSON
      this.fillFormData(form)
    }
  }

  fillFormData = async (form) => {
    this.setState({ isLoading: true })

    let formData = []
    form.forEach((pageData) => {
      console.log('PAGE DATA')
      console.log(pageData)
      let data = pageData.data
      formData.push(...data)
    })

    console.log('FORM DATA')
    console.log(formData)

    let groupDateIndexes = []
    let groupDateInputs = []

    let groupInputsIndexes = []
    let groupInputs = []

    formData.forEach((question, index) => {
      if (question.type === 'inputsGroupName') {
        groupInputsIndexes.push(index)
        groupInputs.push({ groupInputsIndex: index, data: question.data })
      }

      if (question.type === 'radio-group' && question.className === 'date-group') {
        groupDateIndexes.push(index)
        groupDateInputs.push({ groupDateInputsIndex: index, data: question.data })
      }

      if (!question.className) {
        question.className = 'form-control'
      }
    })

    console.log('GROUP INPUT INDEXES')
    console.log(groupInputsIndexes)
    console.log(groupInputs)

    console.log('GROUP DATE INDEXES')
    console.log(groupDateIndexes)
    console.log(groupDateInputs)

    const _formData = [...formData]

    let br = 1
    groupInputs.forEach((input) => {
      console.log('WHOLE INPUT')
      console.log(input)
      input.data.forEach((child) => {
        //let singleChildData = child

        child.forEach((childInput) => {
          if (!childInput.className) {
            childInput.className = 'form-control group1'
          }
          console.log('CHILD INPUT')
          console.log(childInput)
          _formData.splice(input.groupInputsIndex + br, 0, childInput)
          br++
        })
      })
      // _formData.splice(input.groupInputsIndex + br, 0, ...input.data[0])
      // br++
    })

    let brDateInputs = 1
    groupDateInputs.forEach((input) => {
      console.log('WHOLE DATE INPUT')
      console.log(input)
      input.data.forEach((childInput) => {
        if (!childInput.className) {
          childInput.className = 'form-control'
        } else if (!childInput.className.includes('form-control')) {
          childInput.className += ' form-control'
        }
        _formData.splice(input.groupDateInputsIndex + brDateInputs, 0, childInput)
        brDateInputs++
      })
    })

    console.log('=== REFORMAT ===')
    console.log(_formData)

    setTimeout(() => {
      this.formCreator.actions.setData(_formData)
      this.setState({ isLoading: false })
    }, 1000)
  }

  saveForm = () => {
    console.log('==== SAVE form ====')
    // console.log(this.fb);
    console.log(this.formCreator)

    $(this.fb.current).toggle()
    $('#btn-save-form').toggle()

    $(this.fbPreview.current)
      .toggle()
      .ready(() => {
        $('select').removeAttr('multiple')
      })

    $('#btn-clear-form').toggle()

    const formData = JSON.parse(this.formCreator.formData)
    console.log('FORM DATAA')
    console.log(formData)

    // CHECK IF THERE IS NO PAGE DEFINED
    if (formData.length > 0 && formData[0].type !== 'newPage') {
      formData.unshift({
        type: 'newPage',
        required: false,
        label: 'Fill page name',
        className: 'form-control',
        name: 'newPage-1586280841739',
        slug: 'page-1',
      })
    }

    formData.forEach((el, index) => {
      if (el)
        el.slug = Slugify(el.label, {
          lower: true,
        })
      if (el.type !== 'newPage') el.name = 'question' + (index + 1)
      if (
        el.type === 'autocomplete' ||
        el.type === 'radio-group' ||
        el.type === 'select' ||
        el.type === 'checkbox-group'
      ) {
        el.values.forEach((opt) => {
          // TODO
          console.log('MANDATORY DOCS')
          console.log(mandatoryDocuments)

          if (mandatoryDocuments.docs.find((el) => el === opt.label)) {
            // Mark it as a document
            opt.isMandatory = true
          }
          opt.value = Slugify(opt.label, {
            lower: true,
          })
        })
      }
    })

    const templates = options.templates

    $('form', $(this.fbPreview.current)).formRender({
      formData: formData,
      templates,
    })

    // Set New Page labels
    $('.rendered-form .formbuilder-newPage-label').each(function (index) {
      console.log('INDEX: ', index)
      let text = $(this).text()
      $(this).text(text + ' - Page ' + (index + 1))
    })

    // SAVE FORM
    console.log('===== SAVE FORM =====')
    console.log(formData)

    /* PREPARE FORM DATA FOR UPLOAD */
    this.prepareFormDataForUpload(formData)
  }

  prepareFormDataForUpload = (formData) => {
    const _formData = [...formData]
    let indexArr = [] /// 0, 5, 8, 12
    let jsonForm = []
    let groupInputs = []
    let groupInputIndexes = []
    let groupMasterIndex = 0

    // Date groups
    let dateGroupMasterIndex = 0
    let dateGroupIndexes = []
    let dateGroupInputs = []

    let customIndexesData = this.returnCustomIndexes('group1-master', 'group1', _formData)

    // GROUP INPUTS
    groupMasterIndex = customIndexesData.groupMasterIndex
    groupInputIndexes = customIndexesData.groupSingleIndexes
    groupInputs = customIndexesData.groupSingleInputs

    console.log('GROUP INPUTS')
    console.log(groupInputs)

    console.log('GROUP INPUTS INDEXES')
    console.log(groupInputIndexes)

    // Clean single group items from main arr
    let br = 0
    groupInputIndexes.forEach((element, index) => {
      _formData.splice(element - br, 1)
      br++
    })

    // GROUP DATES
    customIndexesData = this.returnCustomIndexes('date-group', 'date-group-single', _formData)
    dateGroupMasterIndex = customIndexesData.groupMasterIndex
    dateGroupIndexes = customIndexesData.groupSingleIndexes
    dateGroupInputs = customIndexesData.groupSingleInputs

    // Clean single date items from main arr
    let brDates = 0
    console.log('DATE GROUP INDEXES')
    console.log(dateGroupIndexes)
    dateGroupIndexes.forEach((element, index) => {
      _formData.splice(element - brDates, 1)
      brDates++
    })

    console.log('FORM DATA SINGLE GROUPPED ITEMS REMOVED')
    console.log(_formData)
    console.log('MASTER GROUP INDEX: ', groupMasterIndex)
    console.log('MASTER DATE GROUP INDEX: ', dateGroupMasterIndex)

    // GROUPING GROUPS
    const arrGroups = []
    arrGroups.push(groupInputs)

    _formData[groupMasterIndex].data = arrGroups
    _formData[dateGroupMasterIndex].data = dateGroupInputs

    console.log('=== FORM DATA NAKON DODAVANJA ====')
    console.log(_formData)

    // Fill page indexes
    _formData.forEach((el, index) => {
      if (el.type === 'newPage') {
        indexArr.push(index)
      }
    })

    indexArr.forEach((current, index) => {
      if (index !== indexArr.length) {
        let dataPart = _formData.slice(current, indexArr[index + 1])
        console.log('== FORM DATA CURRENT ==')
        console.log(_formData[current])
        jsonForm.push({
          page: index + 1,
          pageName: _formData[current].label,
          data: dataPart,
        })
      }
    })

    console.log('ZAVRSENO')
    console.log(jsonForm)

    this.setState({ form: jsonForm })
  }

  returnCustomIndexes = (groupMasterName, groupSingleName, _formData) => {
    let groupMasterIndex = 0
    let groupSingleInputs = []
    let groupSingleIndexes = []
    _formData.forEach((el, index) => {
      console.log('== ELEMENT CLASS NAME ===')
      let elClassNameArr = el.className && el.className.split(' ')

      console.log(elClassNameArr)
      if (el.className && elClassNameArr[0] === groupMasterName) {
        groupMasterIndex = index
      }

      if (
        el.className &&
        (elClassNameArr[1] === groupSingleName ||
          (elClassNameArr[0] !== groupMasterName && elClassNameArr[1] === groupSingleName))
      ) {
        groupSingleInputs.push(el)
        groupSingleIndexes.push(index)
      }
    })

    console.log('RETURN CUSTOM INDEXES')
    let data = {
      groupMasterIndex,
      groupSingleInputs,
      groupSingleIndexes,
    }

    console.log(data)

    return data
  }

  uploadForm = async () => {
    const { form, formId } = this.state
    console.log('(UPLOAD CALL)')
    const data = {
      //userId: 1,
      //formName: formName,
      // description: '',
      //  isActive: 1,
      // isDefault: isCheckedDefault,
      formJSON: JSON.stringify(form),
    }

    const response = await apiRequest({
      method: 'put',
      url: 'forms/' + formId,
      data,
    })

    if (response.status === 200) {
      this.successAlert('Your form has been saved!', 'Done')
    }
  }

  editForm = () => {
    $(this.fbPreview.current).toggle()
    $(this.fb.current).toggle()
    $('#btn-save-form').toggle()
    $('#btn-clear-form').toggle()
  }

  clearForm = () => {
    this.formCreator.actions.clearFields()
    const field = {
      type: 'newPage',
      class: 'form-control',
      label: 'Fill page name',
    }
    this.successAlert()
    setTimeout(() => {
      this.formCreator.actions.addField(field)
    }, 1000)
  }

  /* ALERTS */

  fillFormDataAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          style={{ display: 'block', marginTop: '-100px' }}
          title="You need to fill form data before submit."
          onConfirm={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        />
      ),
    })
  }

  uploadAlert = () => {
    const { form } = this.state
    if (form.length === 1 && form[0].data.length === 1) {
      this.fillFormDataAlert()
      return
    }
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: 'block', marginTop: '-100px' }}
          title="Are you sure?"
          onConfirm={() => this.inputConfirmAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Save"
          cancelBtnText="Cancel"
          showCancel></SweetAlert>
      ),
    })
  }
  inputConfirmAlert = async () => {
    this.uploadForm()
  }

  handleValidate = async () => {
    if (this.state.formName === '') {
      await this.setStateAsync({
        errorformName: <small className="text-danger">Form name is required.</small>,
      })

      this.uploadAlert()
      return false
    } else {
      await this.setStateAsync({ errorformName: null })
      return true
    }
  }

  // ASYNC SETSTATE
  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  successAlert = (msg = 'Your form has been cleared.', title = 'Cleared!') => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title={title}
          onConfirm={() => this.hideAlert(true)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info">
          {msg}
        </SweetAlert>
      ),
    })
  }

  warningWithConfirmMessage = () => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: 'block', marginTop: '-100px' }}
          title="Are you sure?"
          onConfirm={() => this.clearForm()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, clear it!"
          cancelBtnText="Cancel"
          showCancel>
          You will not be able to recover cleared data.
        </SweetAlert>
      ),
    })
  }

  hideAlert(redirect = false) {
    this.setState(
      {
        alert: null,
      },
      () => {
        if (redirect) this.props.history.push('/admin/console')
      }
    )
  }

  render() {
    console.log('RENDER')
    console.log(this.formCreator)
    const { isLoading } = this.state
    return (
      <div className="content">
        {this.state.alert}
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                plain
                title="Edit form"
                ctAllIcons
                category={<span>Use the form below to create a new form </span>}
                content={
                  <Row style={{ marginLeft: 5 }}>
                    {isLoading && <Loader size={50} />}
                    <div id="fb-editor" ref={this.fb} />
                    <div
                      id="fb-rendered-form"
                      ref={this.fbPreview}
                      style={{ display: 'none' }}>
                      <form action="#"></form>
                      <button
                        className="btn btn-success"
                        id="btn-upload-form"
                        onClick={this.uploadAlert}>
                        Save form
                      </button>
                      <button
                        style={{ marginLeft: 5 }}
                        className="btn btn-default"
                        id="btn-edit-form"
                        onClick={this.editForm}>
                        Edit
                      </button>
                    </div>
                    <button
                      className="btn btn-default"
                      id="btn-save-form"
                      onClick={this.saveForm}>
                      Preview form
                    </button>
                    <button
                      style={{ marginLeft: 5 }}
                      className="btn btn-danger"
                      id="btn-clear-form"
                      onClick={() => this.warningWithConfirmMessage()}>
                      Clear
                    </button>
                  </Row>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

export default withRouter(FormEdit)
