import React, { Component } from 'react'
import { Modal, FormControl } from 'react-bootstrap'
import Button from 'components/CustomButton/CustomButton.jsx'

class NewDiscussionModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: true,
      message: '',
      errorMessage: null,
      checkedType: false,
    }
  }

  componentDidMount() {}

  closeModal = () => {
    this.setState({ showModal: false })
    this.props.removeModal()
  }

  openDiscussion = () => {
    const { message, checkedType } = this.state
    const { objType, objName, objId } = this.props

    if (message !== '') {
      let msg = (objType === 'document' ? 'Document Name: ' : 'Form Name: ') + objName + ' => '
      msg += message

      this.props.openDiscussion(msg, checkedType, objId)

      this.setState({ showModal: false })
    } else {
      this.setState({
        errorMessage: (
          <small className="text-danger">
            You need to type message to be able to open discussion.
          </small>
        ),
      })

      return
    }
  }

  renderActionButtons = (type) => {
    const { documentStatus } = this.props
    const isScanned = documentStatus === 'scanned'

    console.log('== DOC STATUS ==')
    console.log(documentStatus)
    console.log('== IS SCANNED ==')
    console.log(isScanned)

    if (type === 'document') {
      return (
        <div>
          <Button
            onClick={() =>
              this.setState({
                message: 'Please can this document again.',
                checkedType: isScanned,
              })
            }
            className="btnQuickAnswer"
            wd
            bsStyle="primary">
            Scan again
          </Button>
          <Button
            onClick={() =>
              this.setState({
                message: 'You scanned/uploaded a wrong document. Please do it again.',
                checkedType: isScanned,
              })
            }
            className="btnQuickAnswer"
            wd
            bsStyle="primary">
            Wrong document
          </Button>
          <Button
            onClick={() =>
              this.setState({
                message: 'Your scan is bad. Please try it again.',
                checkedType: isScanned,
              })
            }
            className="btnQuickAnswer"
            wd
            bsStyle="primary">
            Bad scan
          </Button>
          <Button
            onClick={() =>
              this.setState({ message: 'Document is unreadable.', checkedType: isScanned })
            }
            className="btnQuickAnswer"
            wd
            bsStyle="primary">
            Unreadable
          </Button>
        </div>
      )
    } else {
      return (
        <div>
          <Button
            onClick={() => this.setState({ message: 'Form is not filled correctly.' })}
            className="btnQuickAnswer"
            wd
            bsStyle="primary">
            Wrong fill
          </Button>
          <Button
            onClick={() => this.setState({ message: 'Please enter more data.' })}
            className="btnQuickAnswer"
            wd
            bsStyle="primary">
            More data needed
          </Button>
        </div>
      )
    }
  }

  render() {
    const { message } = this.state
    const { objName, objType } = this.props
    return (
      <Modal
        dialogClassName="modalRadius20"
        show={this.state.showModal}
        onHide={() => this.closeModal()}>
        <Modal.Header className="blueModal">
          <span className="modal-title customTitle" style={{ color: '#FFF' }}>
            Discuss: {objName}
          </span>
          <div className="pull-right">
            <Button bsStyle="primary" fill onClick={() => this.openDiscussion()}>
              <span className="btn-label">
                <i className="fa fa-paper-plane" />
                {'  '}
              </span>
              <span style={{ fontWeight: 'bold' }}>Send</span>
            </Button>
            <Button bsStyle="primary" fill onClick={() => this.closeModal()}>
              <span className="btn-label">
                <i className="fa fa-times" />
                {'  '}
              </span>
              <span style={{ fontWeight: 'bold' }}>Cancel</span>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage}
          <FormControl
            rows="5"
            className="modalMessageBox top10"
            componentClass="textarea"
            bsClass="form-control"
            value={message}
            onChange={(event) => {
              this.setState({ message: event.target.value })
            }}
            placeholder="Type your message..."
          />
          <div className="top20"></div>
          <div className="bottomQuickAnswers">{this.renderActionButtons(objType)}</div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default NewDiscussionModal
