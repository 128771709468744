import React, { Component } from 'react'
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'

import Card from 'components/Card/Card.jsx'

import Button from 'components/CustomButton/CustomButton.jsx'
import SweetAlert from 'react-bootstrap-sweetalert'
import {connect} from 'react-redux'
import { ReactComponent as Loader } from 'assets/img/loader-preview.svg'
import Alert from 'components/Alert/Alert';

import { Redirect } from 'react-router-dom'
import { apiRequest } from 'helpers'
import { TramRounded } from '@material-ui/icons'

class LoginPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cardHidden: true,
      email: '',
      password: '',
      redirect: null,
      alert: null,
      loader: null
    }
  }
  componentDidMount() {
    setTimeout(
      function () {
        this.setState({ cardHidden: false })
      }.bind(this),
      700
    )
  }

  submitLogin = async () => {
    //e.preventDefault()
    this.setState({loader: (
      <SweetAlert
                style={{
                  display: 'block',
                  marginTop: '-100px',
                }}
                title="Logging in..."
                showConfirm={false}>
                <div>
                  <Loader style={{ height: 75, width: 100 }} />
                </div>
              </SweetAlert>
      )})

    const { email, password } = this.state
    const data = {
      email,
      password,
    }

    try {
      const login = await apiRequest({
        url: 'users/login',
        method: 'post',
        data,
      })

      if (login.status === 200) {
        console.log('Logged in')
        console.log(login)

        localStorage.setItem('usertoken', login.data.token)

        const user = await apiRequest({
          method: 'post',
          url: 'users/getByToken',
          data: {
            token: login.data.token,
          },
        })
        console.log(user)

        if (user.status === 200) {
          if(user.data.userTypeId !== 3){
            const company = await apiRequest({
              method: 'get',
              url: `firms/${user.data.firmId}`,
            })
            localStorage.setItem('companyCode', company.data.inviteCode)
            localStorage.setItem('companyName', company.data.name)
            localStorage.setItem('companyId', user.data.firmId)
          }

          localStorage.setItem('userId', user.data.userId)
          localStorage.setItem('senderId', user.data.senderId)
          localStorage.setItem('username', user.data.firstName + ' ' + user.data.lastName)
         // localStorage.setItem('notification', true)
         
          
          
          switch(user.data.userTypeId){
            case 1: 
              localStorage.setItem('role', 'admin')
              break;
            case 2:
              localStorage.setItem('role', 'worker')
              break;
            case 3:
              localStorage.setItem('role', 'superAdmin')
              break;
            default: localStorage.setItem('role', 'none')
          }
          this.setState({ redirect: <Redirect to="/admin/dashboard" />, loader: null })
          window.location.reload()
        }
      }
    } catch (err) {
      this.setState({loader: null})
      if (err.response.status === 404) {
        this.errorAlert("That account doesn't exist.")
      } else if (err.response.status === 401) {
        this.errorAlert('Your account is disabled.')
      } else {
        this.errorAlert('Invalid email or password. Please try again.')
      }
    }
  }

  errorAlert = (text) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{
            display: 'block',
            marginTop: '-100px',
          }}
          title="Error"
          onConfirm={() => this.hideAlert()}
          confirmBtnBsStyle="info">
          {text}
        </SweetAlert>
      ),
    })
  }

  hideAlert() {
    this.setState({
      alert: null,
    })
  }

  render() {
    
    return (
      <Grid>
        {this.state.loader}
        {this.state.alert}
        {this.state.redirect}
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                title="Login"
                content={
                  <div>
                    <FormGroup>
                      <ControlLabel style={{color: '#636363', fontFamily: 'Roboto', fontSize: '13px', fontWeight: '400'}}>Email address</ControlLabel>
                      <FormControl
                        placeholder="Enter email"
                        type="email"
                        onChange={(e) => this.setState({ email: e.target.value })}
                      />
                    </FormGroup>
                    <FormGroup >
                      <ControlLabel style={{color: '#636363', fontFamily: 'Roboto', fontSize: '13px', fontWeight: '400'}}>Password</ControlLabel>
                      <FormControl
                        placeholder="Password"
                        type="password"
                        onChange={(e) => this.setState({ password: e.target.value })}
                        autoComplete="off"
                        onKeyPress={event => {
                          if (event.key === 'Enter') {
                            this.submitLogin()
                          }
                        }}
                      />
                    </FormGroup>
                    {/*<FormGroup>
                      <Checkbox number="1" label="Subscribe to newsletter" />
                    </FormGroup>*/}
                  </div>
                }
                legend={
                  <Button
                    style={{backgroundColor: '#7BC2BB', border: 'none'}}
                    fill
                    wd
                    className="loginButton"
                    onClick={() => this.submitLogin()}
                   >
                    Login
                  </Button>
                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    )
  }
}


export default LoginPage
