import * as actions from '../actions/notificationsActions'

export const initialState = {
  loading: false,
  hasErrors: false,
  notifications: [],
}

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_NOTIFICATIONS:
      return { ...state, loading: true }
    case actions.UPDATE_NOTIFICATIONS:
      let currentNotifications = [action.payload, ...state.notifications]
      return { ...state, notifications: currentNotifications }
    case actions.GET_NOTIFICATIONS_SUCCESS:
      return { ...state, notifications: action.payload, loading: false, hasErrors: false }
    case actions.GET_NOTIFICATIONS_FAILURE:
      return { ...state, loading: false, hasErrors: true }
    case actions.SET_SEEN_NOTIFICATION:
      if (action.payload.isMessage) {
        state.notifications.forEach((n) => {
          if (n.notificationKey === 'newMessage') {
            n.seen = true
          }
        })
      } else {
        const targetNotificationIndex = state.notifications.findIndex(
          (n) => n.activityId === action.payload.notificationId
        )

        if (targetNotificationIndex !== -1)
          state.notifications[targetNotificationIndex].seen = true
      }

      return {
        ...state,
      }
    default:
      return state
  }
}
