import React from 'react'
import { Modal, Row, Col } from 'react-bootstrap';
import Button from 'components/CustomButton/CustomButton'
import FormInputs from 'components/FormInputs/FormInputs.jsx'
import moment from 'moment'


const SuccessPaymentDetailsModal = (props) => {
    return (
        <Modal show={true}>
             <Modal.Header style={{backgroundColor: '#79ADB7'}}>
            <span className="modal-title customTitle" style={{ color: '#FFF' }}>
                Payment details
            </span>
            <div className="pull-right">
                <Button bsStyle="primary" onClick={() => props.closeModal()} style={{border: '1px solid white', color:'white'}}>
                <span className="btn-label">
                    <i className="fa fa-times" />
                    {'  '}
                </span>
                <span style={{ fontWeight: 'bold' }}>Cancel</span>
                </Button>
            </div>
        </Modal.Header>
        <Modal.Body>
        <Row>
            <Col md={12}>
                    <FormInputs
                      ncols={['col-md-4', 'col-md-4', 'col-md-4']}
                      proprieties={[
                        {
                          label: 'Request',
                          type: 'text',
                          bsClass: 'form-control',
                          alignLeft: true,
                          // placeholder: 'Company',
                          value: 'ID:' + props?.paymentData?.requestId,
                          disabled: true,
                        },
                        {
                          label: 'Created at',
                          type: 'email',
                          alignLeft: true,
                          bsClass: 'form-control',
                          disabled: true,
                          value: moment(props?.paymentData?.createdAt).format('MMM Do YY')
                        },
                        {
                          label: 'Amount',
                          type: 'text',
                          bsClass: 'form-control',
                          alignLeft: true,
                          // placeholder: 'Company',
                          value: '$' + props?.paymentData?.amount,
                          disabled: true,
                        },
                      ]}
                    />
                     <FormInputs
                      ncols={['col-md-4', 'col-md-4', 'col-md-4']}
                      proprieties={[
                        {
                          label: 'Client',
                          type: 'text',
                          bsClass: 'form-control',
                          alignLeft: true,
                          // placeholder: 'Company',
                          value: props?.paymentData?.client?.firstName + ' ' + props?.paymentData?.client?.lastName,
                          disabled: true,
                        },
                        {
                          label: 'Payment method',
                          type: 'text',
                          alignLeft: true,
                          bsClass: 'form-control',
                          disabled: true,
                          value: props?.paymentData?.paymentMethod?.type.toUpperCase()
                        },
                        {
                          label: 'Card',
                          type: 'text',
                          alignLeft: true,
                          bsClass: 'form-control',
                          disabled: true,
                          value: props?.paymentData?.paymentMethod?.last4 !== undefined ? '**** **** **** ' + props?.paymentData?.paymentMethod?.last4 : null
                        },
                      ]}
                    />
                </Col>
            </Row>
        </Modal.Body>
        </Modal>
    )
}

export default SuccessPaymentDetailsModal
