import React, { useEffect } from 'react';
import './documentCard.css';
import {Link, useHistory} from 'react-router-dom'
import {Col} from 'react-bootstrap'

/* Status icons */
import signedIcon from 'assets/img/icons/signature.png';
import waitingIcon from 'assets/img/icons/yellow-status.png';
import rejectedIcon from 'assets/img/icons/red-status.png'

/*Document Type Icons */
import pdfDoc from 'assets/img/icons/pdf.png';
import jpgDoc from 'assets/img/icons/jpg.png';
import doc from 'assets/img/icons/doc.png';

/*React Icons*/
import {AiOutlineBorderVerticle, AiOutlineScan, AiFillCheckCircle, AiOutlineLoading} from 'react-icons/ai'
import {VscLoading} from "react-icons/vsc"

const DocumentCard = (props) =>{
  let history = useHistory();

    const iconForStatus = (status) => {
        switch (status) {
          case 'waiting':
            return <img src={waitingIcon} alt='waiting'/>
          case 'rejected':
            return <img src={rejectedIcon} alt='rejected'/>
          case 'signed':
            return <img src={signedIcon} alt='signed'/>
          case 'scan':
            return <AiOutlineScan size="2em"/>
          case 'scanned':
            return <AiOutlineBorderVerticle size="2em"/>
          case 'pending':
            return <AiOutlineLoading size="2em"/>
          case 'completed':
            return <AiFillCheckCircle size="2em"/>
          default: 
            return <VscLoading size="2em"/>
        }
      }

      const iconDocType = (docType) => {
        switch (docType) {
          case 'application/pdf':
            return <img src={pdfDoc} className='document-icon' alt='pdfdoc'/>
          case 'application/jpg':
            return <img src={jpgDoc} className='document-icon' alt='jpgdoc'/>
          case 'application/doc':
            return <img src={doc} className='document-icon' alt='appdoc'/>
          default: 
            return <span>Doc Type</span>
        }
      }
////
    return(
        <Col sm={4} md={3} lg={2} className="card-container" >
            <div className='status-container'>
                <span className='tooltip-status'>{props.reqStatus}</span>
                <div className='tooltip-icon'>{iconForStatus(props.status)}</div>
             </div>
             
            {(props.requestStatus !== 'Completed') && (props.requestStatus !== 'Payment submitted') ? <span className='delete-doc' onClick={() => props.deleteDocument(props.docId, props.reqId)}>X</span> : null} 
            <div className='document-type'>{iconDocType(props.docType)}</div>
            <div className='document-name-container'><p className='document-name'>{props.docName}</p></div>
            <div className='buttons-container'>
                {(!props.formDiscuss) ? 
                  
                  <div className='open-button'  onClick={props.documentURL !== 'N/A' ? props.openDocument : null}>
                      {props.documentURL !== 'N/A' ? 'Open' : 'No document'}
                  </div>
                : 
                  <div className='open-button' onClick={() => history.push(`/admin/formanswers/${props.formId}/${props.requestId}`)}>
                    Check Answers
                  </div>
                }
                <div className='discuss-button' onClick={props.openDiscussion}>
                    Discuss with client
                </div>
            </div>
        </Col>
    )
}
///admin/formanswers/${props.formId}/${props.requestId}`

export default DocumentCard;