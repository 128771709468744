/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { Component } from 'react'

class Footer extends Component {
  render() {
    return (
      <footer
        className={
          'footer footer2' +
          (this.props.transparent !== undefined ? ' footer-transparent' : '')
        }>
        <div
          className={
            'container' + (this.props.fluid !== undefined ? '-fluid' : '')
          }>
          {/*<nav className="pull-left">
            <ul>
              <li>
                <a href="#pablo">Home</a>
              </li>
              <li>
                <a href="#pablo">Company</a>
              </li>
              <li>
                <a href="#pablo">Portfolio</a>
              </li>
              <li>
                <a href="#pablo">Blog</a>
              </li>
            </ul>
        </nav> */}
          <p className="copyright pull-right">
            &copy; {1900 + new Date().getYear()} Made by{' '}
            <a href="https://www.visiot.net" target="_blank">
              Visiot
            </a>
          </p>
        </div>
      </footer>
    )
  }
}
export default Footer
