import React, { Component } from 'react'
import { Modal, FormControl, FormGroup } from 'react-bootstrap'
import Button from 'components/CustomButton/CustomButton.jsx'
import Datetime from 'react-datetime'
import moment from 'moment'

class SetAccountInfoModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: true,
      userEmail: '',
      firstName: '',
      lastName: '',
      dob: '',
      errorMessage: null,
    }
  }

  closeModal = () => {
    this.setState({ showModal: false })
    this.props.removeModal()
  }

  createProfile = () => {
    const { firstName, lastName, dob } = this.state
    let year;
    const currentYear = moment().format('YYYY')
    if(dob !== ''){
      year = +dob._d.toString().split(" ")[3]
      console.log(year)
    }

    
    // const year = dob._d.split(" ");
    // console.log(year[3])
    if (firstName === '') {
      this.setState({
        errorMessage: <small className="text-danger">First name cannot be empty.</small>,
      })
      return
    }

    if (lastName === '') {
      this.setState({
        errorMessage: <small className="text-danger">Last name cannot be empty.</small>,
      })
      return
    }
    if (dob === '') {
      this.setState({
        errorMessage: <small className="text-danger">Date of birth cannot be empty.</small>,
      })
      return
    }
    if(year > (currentYear - 17)) {
      this.setState({
        errorMessage: <small className="text-danger">You must have 18 years or higher.</small>,
      })
      return
    }

    this.props.createProfile(firstName, lastName, dob)
    this.setState({ showModal: false })
  }

  render() {
    const { firstName, lastName, dob } = this.state
    return (
      <Modal show={this.state.showModal} onHide={() => this.closeModal()}>
        <Modal.Header className="blueModal">
          <span className="modal-title customTitle" style={{ color: '#FFF' }}>
            Please fill your profile info
          </span>
          <div className="pull-right">
            <Button bsStyle="primary" fill onClick={() => this.createProfile()}>
              <span className="btn-label">
                <i className="fa fa-paper-plane" />
                {'  '}
              </span>
              <span style={{ fontWeight: 'bold' }}>Send</span>
            </Button>
            <Button bsStyle="primary" fill onClick={() => this.closeModal()}>
              <span className="btn-label">
                <i className="fa fa-times" />
                {'  '}
              </span>
              <span style={{ fontWeight: 'bold' }}>Cancel</span>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage}
          <div>
            <small>You will be able to change this data and more later</small>

            <FormGroup>
              <h3 className="customSubtitle top10">First Name: </h3>
              <FormControl
                className="modalTitleBox"
                placeholder="First name"
                value={firstName}
                type="text"
                onChange={(event) => {
                  this.setState({ firstName: event.target.value })
                }}
              />
            </FormGroup>
            <FormGroup>
              <h3 className="customSubtitle top10">Last Name: </h3>

              <FormControl
                className="modalTitleBox"
                placeholder="Last name"
                value={lastName}
                type="text"
                onChange={(event) => {
                  this.setState({ lastName: event.target.value })
                }}
              />
            </FormGroup>
            <FormGroup>
              <h3 className="customSubtitle top10">Date of birth: </h3>

              <Datetime
                timeFormat={false}
                inputProps={{ placeholder: 'Pick date' }}
                value={dob}
                onChange={(date) => this.setState({ dob: date })}
              />
            </FormGroup>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default SetAccountInfoModal
