import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
// react component used to create alerts
import SweetAlert from 'react-bootstrap-sweetalert'
import { apiRequest } from 'helpers'

import { Link, Redirect } from 'react-router-dom'

import Card from 'components/Card/Card.jsx'
import Button from 'components/CustomButton/CustomButton.jsx'
import NewRequestModal from 'views/Components/NewRequestModal.jsx'
import AnswerQuestionModal from 'views/Components/AnswerQuestionModal'
import Config from 'config'
import UserCard from 'components/Card/UserCard.jsx'

import { ReactComponent as Loader } from 'assets/img/loader-preview.svg'
import FormInputs from 'components/FormInputs/FormInputs.jsx'

import Avatar from 'assets/img/avatar-sil.png'
import moment from 'moment'

class SingleClient extends Component {
  constructor(props) {
    super(props)
    this.state = {
      alert: null,
      clientId: null,
      requests: [],
      clientData: {},
      newRequestModal: null,
      questions: [],
      taxfiles: [],
      answerQuestionModal: null,
      myConversationId: parseInt(localStorage.getItem('senderId')),
      redirect: null
    }
  }

  componentDidMount() {
    if(localStorage.getItem('role') === 'superAdmin'){
      this.setState({redirect : <Redirect to="/admin/not-allowed" />})
    }

    const {
      match: { params },
    } = this.props
    console.log('PARAMS')
    console.log(params)
    this.setState({
      clientId: parseInt(params.id),
    })
    this.getClientData(params.id)
    this.getClientTaxFiles(params.id)
    this.getClientsRequests(params.id)
    this.getClientQuestions(params.id)
  }

  getClientData = async (clientId) => {
    const response = await apiRequest({
      method: 'get',
      url: 'clients/' + clientId,
    })

    if (response.status === 200) {
      console.log('=== client ===')
      console.log(response)
      this.setState({ clientData: response.data })
    }
  }

  getClientTaxFiles = async (clientId) => {
    const response = await apiRequest({
      method: 'get',
      url: 'taxfiles/getTaxByClientId/' + clientId,
    })

    if (response.status === 200) {
      console.log('Requests')
      console.log(response)
      this.setState({ taxfiles: response.data })
    }
  }

  getClientsRequests = async (clientId) => {
    const response = await apiRequest({
      method: 'get',
      url: 'requests/getByClientId/' + clientId,
    })

    if (response.status === 200) {
      console.log('Requests')
      console.log(response)
      this.setState({ requests: response.data })
    }
  }

  getClientQuestions = async (clientId) => {
    const response = await apiRequest({
      method: 'get',
      url: 'requestquery/getQueriesForClient/' + clientId,
    })

    if (response.status === 200) {
      console.log('Requests')
      console.log(response)
      this.setState({ questions: response.data })
    }
  }

  iconForRequestStatus = (status) => {
    switch (status) {
      case 'pending':
        return 'fa fa-mail-reply'
      case 'completed':
        return 'fa fa-check'
      case 'waitingForPayment':
        return 'fa fa-hourglass-start'
      case 'waitingForMoreInfo':
        return 'fa fa-hourglass-start'
      case 'waitingForFinalSignature':
        return 'fa fa-hourglass-start'
      default:
        break
    }
  }

  iconColorForRequestStatus = (status) => {
    switch (status) {
      case 'pending':
        return '#EC5758'
      case 'completed':
        return '#29AE60'
      default:
        return '#F2994B'
    }
  }

  iconPerQuestionStatus = (status) => {
    switch (status) {
      case true:
        return 'fa fa-check'
      case false:
        return 'fa fa-mail-reply'
      default:
        break
    }
  }

  iconColorPerQuestionStatus = (status) => {
    switch (status) {
      case false:
        return '#EC5758'
      case true:
        return '#29AE60'
      default:
        return '#F2994B'
    }
  }

  sendRequest = async (title, message, documentsForUpload) => {
    const { clientId } = this.state
    const userId = localStorage.getItem('userId')

    let docsArr = []

    this.loadingAlert()

    // if (documents.length > 0) {
    //   documents.forEach((doc) => {
    //     docsArr.push({ value: doc.value, isDefault: doc.isDefault })
    //   })
    // }

    // let formsArr = []

    // if (forms.length > 0) {
    //   forms.forEach((form) => {
    //     formsArr.push(form.value)
    //   })
    // }


    if (clientId !== null && userId !== null) {
      const data = {
        userId,
        clientId,
        requestStatusId: 1,
        requestName: title,
        requestDescription: message,
        file: documentsForUpload,

        //       documents: docsArr,
        // forms: formsArr,
      }

      const formData = new FormData()
      formData.append('userId', data.userId)
      formData.append('clientId', data.clientId)
      formData.append('requestName', data.requestName)
      formData.append('requestDescription', data.requestDescription)
      //formData.append('documents', JSON.stringify(data.documents))
      //formData.append('forms', JSON.stringify(data.forms))

      documentsForUpload.forEach((docUpload) => {
        formData.append('documents', docUpload, docUpload.name)
      })
      console.log("==== docs ====")
      console.log(documentsForUpload)

      console.log('=== FORM DATA ==')
      console.log(formData)

      /*  const response = await apiRequest({
        method: 'post',
        url: 'requests',
        data,
      })
*/
      var req = new XMLHttpRequest()

      const url = `${Config.APP_API_URL}/requests`

      const jwtoken = localStorage.getItem('usertoken')

      req.open('POST', url)

      if (jwtoken) {
        req.setRequestHeader('Authorization', 'Bearer ' + jwtoken)
      }

      req.send(formData)

      req.onload = () => {
        console.log('STATUS')
        console.log(req.status)
        if (req.status === 200) {
          this.successAlert()
        } else {
          this.errorAlert(JSON.parse(req.response).message || req.response.message)
        }
      }
    }
  }

  openModal = () => {
    this.setState({
      newRequestModal: (
        <NewRequestModal removeModal={this.removeModal} sendRequest={this.sendRequest} />
      ),
    })
  }

  removeModal = () => {
    this.setState({ newRequestModal: null })
  }

  answerQuestion = async (message, requestQueryId) => {
    const { myConversationId, clientId } = this.state

    let receivers = []
    receivers.push(clientId)

    const data = {
      text: message,
      senderId: myConversationId,
      clientId: clientId,
    }

    console.log('== DATA ==')
    console.log(data)
    const response = await apiRequest({
      method: 'post',
      url: 'chat/openDiscusion',
      data,
    })

    if (response.status === 200) {
      console.log('Sent!')
      console.log(response)

      const markAnswered = await apiRequest({
        method: 'put',
        url: 'requestquery/setAnswered',
        data: {
          requestQueryId: requestQueryId,
        },
      })

      this.getClientQuestions(clientId)
    }

    this.setState({ answerQuestionModal: null })
  }

  removeAnswerQuestionModal = () => {
    this.setState({ answerQuestionModal: null })
  }

  /* SUCCESS */

  successAlert() {
    this.setState(
      {
        alert: (
          <SweetAlert
            success
            style={{
              display: 'block',
              marginTop: '-100px',
            }}
            title="Done"
            onConfirm={() => this.hideAlert()}
            confirmBtnBsStyle="info">
            You request has been added!
          </SweetAlert>
        ),
      },
      () => {
        const { clientId } = this.state
        this.removeModal()
        this.getClientsRequests(clientId)
      }
    )
  }

  errorAlert(msg) {
    this.setState(
      {
        alert: (
          <SweetAlert
            danger
            style={{
              display: 'block',
              marginTop: '-100px',
            }}
            title="Error"
            onConfirm={() => this.hideAlert()}
            confirmBtnBsStyle="info">
            {msg}
          </SweetAlert>
        ),
      },
      () => this.removeModal()
    )
  }

  loadingAlert() {
    this.setState({
      alert: (
        <SweetAlert
          style={{
            display: 'block',
            marginTop: '-100px',
          }}
          title="Processing"
          showConfirm={false}>
          <div>
            <Loader style={{ height: 75, width: 100 }} />
          </div>
        </SweetAlert>
      ),
    })
  }

  hideAlert() {
    this.setState({
      alert: null,
    })
  }

  formatPhoneNumber = (phoneNumberString) => {
    if (phoneNumberString && phoneNumberString.length > 0) {
      let cleaned = ('' + phoneNumberString).replace(/\D/g, '')
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        let intlCode = match[1] ? '+1 ' : ''
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
      }
    }

    return null
  }

  formatSinNumber = (sinNumber) => {
    if (sinNumber && sinNumber.length > 0) {
      return sinNumber.replace(/(\d)(?=(\d{3})+$)/g, '$1 ')
    }

    return null
  }

  render() {
    const { taxfiles, requests, clientData, questions, redirect } = this.state
    return (
      <div className="main-content">
        {console.log('%c PHONE', 'color: yellow')}
        {console.log(clientData.cellPhone)}
        {this.state.alert}
        {redirect}
        <Grid fluid>
          {this.state.newRequestModal}
          <Row>
            <Col md={6}>
              <UserCard
                hideHeaderImg
                avatar={Avatar}
                name={clientData.firstName + ' ' + clientData.lastName}
                description={
                  <span>
                    <br />
                    <FormInputs
                      ncols={['col-md-6', 'col-md-6']}
                      proprieties={[
                        {
                          label: 'Name',
                          type: 'text',
                          bsClass: 'form-control',
                          alignLeft: true,
                          // placeholder: 'Company',
                          value: (clientData.firstName && clientData.lastName) === undefined || (clientData.firstName && clientData.lastName) === '' ? '' : clientData.firstName + ' ' + clientData.lastName,
                          disabled: true,
                        },
                        {
                          label: 'Email address',
                          type: 'email',
                          alignLeft: true,
                          bsClass: 'form-control',
                          disabled: true,
                          value: clientData.email,
                        },

                      ]}
                    />
                    <FormInputs
                      ncols={['col-md-6', 'col-md-6']}
                      proprieties={[
                        {
                          label: 'Cell phone',
                          type: 'phone',
                          bsClass: 'form-control',
                          alignLeft: true,
                          value:
                          clientData.cellPhone , 
                            //this.formatPhoneNumber(clientData.cellPhone),
                          disabled: true,
                        },
                        // {
                        //   label: 'Home phone',
                        //   type: 'text',
                        //   bsClass: 'form-control',
                        //   disabled: true,
                        //   alignLeft: true,
                        //   value:
                        //     clientData.homePhone &&
                        //     this.formatPhoneNumber(clientData.homePhone),
                        // },
                        {
                          label: 'SIN',
                          type: 'text',
                          bsClass: 'form-control',
                          alignLeft: true,
                          // placeholder: 'Company',
                          value: clientData.sin && this.formatSinNumber(clientData.sin),
                          disabled: true,
                        },
                      ]}
                    />

                    <FormInputs
                      ncols={['col-md-6', 'col-md-6']}
                      proprieties={[
                        {
                          label: 'Address',
                          type: 'text',
                          bsClass: 'form-control',
                          alignLeft: true,
                          disabled: true,
                          value: clientData.address=== null ||  clientData.address === undefined ? '' : clientData.address
                        },
                        {
                          label: 'Province',
                          type: 'text',
                          alignLeft: true,
                          bsClass: 'form-control',
                          disabled: true,
                          value: clientData.province && clientData.province.label,
                        },
                      ]}
                    />
                     <FormInputs
                      ncols={['col-md-6', 'col-md-6']}
                      proprieties={[
                        {
                          label: 'City',
                          type: 'text',
                          bsClass: 'form-control',
                          alignLeft: true,
                          disabled: true,
                          value: clientData.city === null || clientData.city === undefined ? '' : clientData.city
                        },
                        {
                          label: 'Postal code',
                          type: 'text',
                          alignLeft: true,
                          bsClass: 'form-control',
                          disabled: true,
                          value: clientData.province && clientData.postalCode,
                        },
                      ]}
                    />
                  </span>
                }
              />
              {/* <div className="clientInfoRow">
                <div className="clientInfoRowLeft">
                  <img
                    style={{
                      height: 'auto',
                      width: '70%',
                      borderRadius: '100%',
                    }}
                    src={Avatar}
                  />
                  <span className="emailSubtitle">{clientData.email}</span>
                </div>
                <div className="clientInfoRowRight">
                  <span className="customTitle">
                    {clientData.firstName + ' ' + clientData.lastName}
                  </span>

                  <div className="inputHolder">
                    <label className="control-label clientInfoLabel">Cell phone</label>
                    <FormControl
                      type="text"
                      value={this.formatPhoneNumber(clientData.cellPhone)}
                      disabled
                      className="clientInfoInput"
                    />
                  </div>
                  <div className="inputHolder">
                    <label className="control-label clientInfoLabel">Home phone</label>
                    <FormControl
                      type="text"
                      value={this.formatPhoneNumber(clientData.homePhone)}
                      disabled
                      className="clientInfoInput"
                    />
                  </div>
                  <div className="inputHolder">
                    <label className="control-label clientInfoLabel">SIN</label>
                    <FormControl
                      type="text"
                      value={clientData.sin && this.formatSinNumber(clientData.sin)}
                      disabled
                      className="clientInfoInput"
                    />
                  </div>
                  <div className="inputHolder">
                    <label className="control-label clientInfoLabel">Address</label>
                    <FormControl
                      type="text"
                      value={
                        clientData.address +
                        ', ' +
                        clientData.city +
                        ', ' +
                        clientData.postalCode
                      }
                      disabled
                      className="clientInfoInput"
                    />
                  </div>
                  <div className="inputHolder">
                    <label className="control-label clientInfoLabel">Province</label>
                    <FormControl
                      type="text"
                      value={clientData.province && clientData.province.label}
                      disabled
                      className="clientInfoInput"
                    />
                  </div>
                </div>
              </div> */}
            </Col>
            <Col md={6}>
              <div className="clientStatusRow">
                <div className="counterBoxes">
                  <div className="counterBoxesRow">
                    <div
                      className="countBox"
                      style={{
                        backgroundColor: '#EB5757',
                      }}>
                      <span className="counterTxt">
                        {clientData.requestsCount && clientData.requestsCount['new']}
                      </span>
                      <span className="statusTxt">New requests</span>
                    </div>
                    <div
                      className="countBox"
                      style={{
                        backgroundColor: '#F2994A',
                      }}>
                      <span className="counterTxt">
                        {clientData.requestsCount && clientData.requestsCount['pending']}
                      </span>
                      <span className="statusTxt">Pending requests</span>
                    </div>
                  </div>
                  <div className="counterBoxesRow">
                    <div
                      className="countBox"
                      style={{
                        backgroundColor: '#27AE60',
                      }}>
                      <span className="counterTxt">
                        {clientData.requestsCount && clientData.requestsCount['completed']}
                      </span>
                      <span className="statusTxt">Completed requests</span>
                    </div>
                    <div
                      className="countBox"
                      style={{
                        backgroundColor: '#447DF7',
                      }}>
                      <span className="counterTxt">{clientData.documentCount}</span>
                      <span className="statusTxt">Documents</span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="top70" style={{marginBottom: '20px'}}>
            <Col md={6} mdOffset={3} className="displayFlex centered">
              <Button
                onClick={() => this.openModal()}
                bsStyle="danger"
                wd
                style={{
                  height: 60,
                  width: 250,
                  fontSize: 16,
                }}>
                <span className="btn-label" style={{ marginRight: 20 }}>
                  <i className="fa fa-paper-plane" style={{ fontSize: 25 }} />
                </span>
                Send new request
              </Button>
              <Button
                bsStyle="primary"
                wd
                onClick={() => {
                  this.props.history.push({
                    pathname: '/admin/messenger',
                    state: { openNewMessage: true },
                  })
                }}
                style={{
                  height: 60,
                  width: 250,
                  fontSize: 16,
                  marginLeft: 10,
                }}>
                <span className="btn-label" style={{ marginRight: 20 }}>
                  <i className="fa fa-comments" style={{ fontSize: 25 }} />
                </span>
                Send a message
              </Button>
            </Col>
          </Row>

          
          <Row>
            {(taxfiles || []).map((t, key) => {
              
              return (
                <Col md={3} key={key}>
                  {console.log('tttttttt')}
                  {console.log(t.requestId)}
                  <Card
                    ctTextCenter
                    content={
                      <div>
                        <div
                          style={{
                            position: 'absolute',
                            top: 10,
                            right: 20,
                            width: 20,
                            height: 20,
                          }}>
                          <i
                            className={this.iconForRequestStatus(
                              t.requeststatus.statusKey
                            )}
                            style={{
                              fontSize: 20,
                              color: this.iconColorForRequestStatus(
                                t.requeststatus.statusKey
                              ),
                            }}></i>
                        </div>

                        <h5>Tax File</h5>
                        <h3>
                          <b>{t.year}</b>
                        </h3>
                        <h5>
                          <small>
                            <b>
                              STATUS:{' '}
                              <span
                                style={{
                                  color: this.iconColorForRequestStatus(
                                    t.requeststatus.statusKey
                                  ),
                                }}>
                                {t.requeststatus.status}
                              </span>
                            </b>
                          </small>
                        </h5>
                        <Link to={`/admin/requests/${t.requestId}`}>
                          <Button bsStyle="primary">Review</Button>
                        </Link>
                        {/*!s.isDefault && (
                          <Button
                            onClick={() =>
                              //this.warningWithConfirmMessage(s.formId)
                            }
                            bsStyle="primary"
                            leftMargin>
                            Make default
                          </Button>
                          )*/}
                      </div>
                    }
                  />
                </Col>
              )
            })}
          </Row>

          {requests.length > 0 && (
            <Row className="top30">
              <Col md={12}>
                <div className="text-left">
                  <h4>Requests</h4>
                </div>
              </Col>
            </Row>
          )}
          {requests.length > 0 && (
            <Row>
              {(requests || []).map((r, key) => {
                return (
                  <Col md={3} key={key}>
                    <Card
                      ctTextCenter
                      content={
                        <div>
                          <div
                            style={{
                              position: 'absolute',
                              top: 10,
                              right: 20,
                              width: 20,
                              height: 20,
                            }}>
                            <i
                              className={this.iconForRequestStatus(r.requeststatus.statusKey)}
                              style={{
                                fontSize: 20,
                                color: this.iconColorForRequestStatus(
                                  r.requeststatus.statusKey
                                ),
                              }}></i>
                          </div>

                          <h5>{r.requestName}</h5>
                          <h5>
                            <small>
                              <b>STATUS:</b> {r.requeststatus.status}
                            </small>
                          </h5>
                          <h5>
                            <small>
                              <b>Attached documents:</b> {r.numberOfDocument}
                            </small>
                          </h5>
                          <Link to={`/admin/requests/${r.requestId}`}>
                            <Button bsStyle="warning">Review</Button>
                          </Link>
                          {/*!s.isDefault && (
                          <Button
                            onClick={() =>
                              //this.warningWithConfirmMessage(s.formId)
                            }
                            bsStyle="primary"
                            leftMargin>
                            Make default
                          </Button>
                          )*/}
                        </div>
                      }
                    />
                  </Col>
                )
              })}
            </Row>
          )}
          {questions.length > 0 && (
            <Row className="top30">
              <Col md={12}>
                <div className="text-left">
                  <h4>New questions</h4>
                </div>
              </Col>
            </Row>
          )}
          {questions.length > 0 && (
            <Row>
              {(questions || []).map((r, key) => {
                return (
                  <Col md={3} key={key}>
                    <Card
                      ctTextCenter
                      content={
                        <div style={{ minHeight: 170 }}>
                          <div
                            style={{
                              position: 'absolute',
                              top: 10,
                              right: 20,
                              width: 20,
                              height: 20,
                            }}>
                            <i
                              className={this.iconPerQuestionStatus(r.answered)}
                              style={{
                                fontSize: 20,
                                color: this.iconColorPerQuestionStatus(r.answered),
                              }}></i>
                          </div>

                          <h5>{r.requestName}</h5>
                          <h5>
                            <small>
                              <b>STATUS:</b> {r.answered ? 'ANSWERED' : 'NEW'}
                            </small>
                          </h5>
                          <h5>
                            <small>
                              <b>QUESTION:</b>{' '}
                              {r.message.length > 50
                                ? r.message.substring(0, 50) + '...'
                                : r.message}
                            </small>
                          </h5>
                          <h5>
                            <small>
                              <b>Created At:</b> {moment(r.createdAt).format('YYYY-MM-DD')}
                            </small>
                          </h5>
                          {!r.answered && (
                            <Button
                              onClick={() =>
                                this.setState({
                                  answerQuestionModal: (
                                    <AnswerQuestionModal
                                      question={r.message}
                                      clientName={
                                        clientData.firstName + ' ' + clientData.lastName
                                      }
                                      requestQueryId={r.requestQueryId}
                                      sendMessage={this.answerQuestion}
                                      removeModal={
                                        this.removeAnswerQuestionModal
                                      }></AnswerQuestionModal>
                                  ),
                                })
                              }
                              bsStyle="primary">
                              Answer
                            </Button>
                          )}
                          {/*!s.isDefault && (
                          <Button
                            onClick={() =>
                              //this.warningWithConfirmMessage(s.formId)
                            }
                            bsStyle="primary"
                            leftMargin>
                            Make default
                          </Button>
                          )*/}
                        </div>
                      }
                    />
                  </Col>
                )
              })}
            </Row>
          )}
          {this.state.answerQuestionModal}
        </Grid>
      </div>
    )
  }
}

export default SingleClient
