import React, { Component } from 'react'
import Dropzone from '../Dropzone/Dropzone'
import './Upload.css'
import Progress from '../Progress/Progress'
import Button from 'components/CustomButton/CustomButton.jsx'
import { ReactComponent as Loader } from 'assets/img/loader-preview.svg'
import apiRequest from 'helpers/apiRequest';
import Config from 'config'

class Upload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      errorMsg: null,
    }

    this.onFilesAdded = this.onFilesAdded.bind(this)
    this.uploadFiles = this.uploadFiles.bind(this)
    this.sendRequest = this.sendRequest.bind(this)
    this.renderActions = this.renderActions.bind(this)
  }

  onFilesAdded(files) {
    console.log('ON FILES ADDED')
    if (files[files.length - 1].type !== 'application/pdf') {
      this.setState({
        errorMsg: (
          <span className="errorMsg">
            File type is not supported. Please choose a PDF file.
          </span>
        ),
      })
      return
    }

    this.setState(
      (prevState) => ({
        files: prevState.files.concat(files),
        errorMsg: null,
      }),
      () => {
        const returnFiles = this.state.files
        console.log('RETURN FILES')
        console.log(returnFiles)
        if (this.props.addNewDocuments) {
          this.props.addNewDocuments(returnFiles)
        }
      }
    )
  }

  async uploadFiles() {
    if (this.state.files.length > 0) {
      this.setState({ uploadProgress: {}, uploading: true })
      const promises = []
      this.state.files.forEach((file) => {
        promises.push(this.sendRequest(file))
      })
      try {
        let uploadFinished = await Promise.all(promises)
        console.log('UPLOAD FINISHED')
        console.log(uploadFinished)
        this.setState({ successfullUploaded: true, uploading: false })
      } catch (e) {
        // Not Production ready! Do some error handling here instead...
        console.log('ERROR')
        console.log(e)
        this.setState({ successfullUploaded: true, uploading: false })
      }
    } else {
      this.setState({
        errorMsg: <span className="errorMsg">Please choose files first.</span>,
      })
    }
  }

  sendRequest(file) {
    return new Promise((resolve, reject) => {
      const jwtoken = localStorage.getItem('usertoken')

      const req = new XMLHttpRequest()

      req.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          const copy = { ...this.state.uploadProgress }
          copy[file.name] = {
            state: 'pending',
            percentage: (event.loaded / event.total) * 100,
          }
          this.setState({ uploadProgress: copy })
          console.log('Progress')
          console.log(event)
        }
      })

      req.upload.addEventListener('load', (event) => {
        const copy = { ...this.state.uploadProgress }
        console.log('STATUS')
        console.log(event)
        copy[file.name] = { state: 'done', percentage: 100 }
        this.setState({ uploadProgress: copy })
        resolve(req.response)
      })

      req.upload.addEventListener('error', (event) => {
        const copy = { ...this.state.uploadProgress }
        copy[file.name] = { state: 'error', percentage: 0 }
        this.setState({ uploadProgress: copy })
        reject(req.response)
      })

      req.addEventListener('readystatechange', (e) => {
        console.log('STATE CHANGE')
        console.log(e)
        const copy = { ...this.state.uploadProgress }
        if (e.target.readyState === 4) {
          console.log('USLO')
          copy[file.name] = { state: 'uploaded', percentage: 100 }
          this.setState({ uploadProgress: copy })
          //this.renderProgress()
          // the transfer has completed and the server closed the connection.
        }
      })
      const data = new FormData();
      data.append('document', file)

      const sendNewVersion = async() => {
        data.append('documentId', this.props.documentId)
        try{
          const response = await apiRequest({
            method: 'POST',
            url: 'documents/approve',
            data
          })

          if(response.status === 200) {
            this.props.closeModal()
            this.props.getDocuments()
          }
        }catch(err){
          console.log(err)
        }
        
      }
      const uploadNew = async() =>{
        try{
          const response = await apiRequest({
            method: 'POST',
            url: 'documents/upload-approval',
            data
          })
          if(response.status === 201){
            this.props.closeModal()
            this.props.success()
            this.props.getDocs()
          }
          
        }catch(err){
          console.log(err)
        }
      }
 
      if(this.props.documentId){
        sendNewVersion()
        //data.append('firmId', this.props.firmId)
      }else{
        uploadNew()
        // let endpoint = 'documents/upload-approval'
        // const url = `${Config.APP_API_URL}/${endpoint}`
        // req.open('POST', url)
        // if (jwtoken) {
        //   req.setRequestHeader('Authorization', 'Bearer ' + jwtoken)
        // }
        // req.send(data)
        // console.log('=== STATUS ========================')
        // //console.log(req)
        // console.log(req.status)
        
      }
    })
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name]
    console.log('UPLOAD PROGRESS')
    console.log(uploadProgress)
    if (this.state.uploading || this.state.successfullUploaded) {
      return (
        <div className="ProgressWrapper">
          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />

          {this.state.successfullUploaded && uploadProgress.state === 'uploaded' ? (
            <i
              className="fa fa-check"
              style={{
                fontSize: 15,
                marginTop: -3,
                opacity: uploadProgress && uploadProgress.state === 'uploaded' ? 0.5 : 0,
              }}></i>
          ) : (
            <Loader
              style={{
                height: 30,
                opacity: uploadProgress && uploadProgress.state === 'done' ? 0.5 : 0,
              }}
            />
          )}
        </div>
      )
    }
    
  }

  renderActions() {
    const noUpload = this.props.noUpload ? this.props.noUpload : false
    if (this.state.successfullUploaded) {
      return (
        <Button
          bsStyle="primary"
          onClick={() => this.setState({ files: [], successfullUploaded: false })}>
          Upload new
        </Button>
      )
    } else {
      return (
        <div>
          {this.state.files.length > 0 && (
            <Button
              bsStyle="danger"
              className="btnClearFiles"
              disabled={this.state.files.length < 0 || this.state.uploading}
              onClick={() => this.setState({ files: [], successfullUploaded: false })}>
              Clear files
            </Button>
          )}
          {!noUpload && (
            <Button
              bsStyle="primary"
              fill
              disabled={this.state.files.length < 0 || this.state.uploading}
              onClick={this.uploadFiles}>
              Upload
            </Button>
          )}
        </div>
      )
    }
  }

  render() {
    return (
      <div className="Upload">
        <div className="UploadHeader">
          <div className="LeftHeader">
            <span className="Title">Upload Files</span>
          </div>
          <div className="RightHeader">
            <span className="Title">Choosen Files</span>
          </div>
        </div>
        <div className="Content">
          <div>
            <Dropzone
              onFilesAdded={this.onFilesAdded}
              disabled={this.state.uploading || this.state.successfullUploaded}
            />
          </div>
          <div className="RightSide">
            <div className="Files">
              {this.state.files.map((file) => {
                return (
                  <div key={file.name} className="Row">
                    <span className="Filename">{file.name}</span>
                    {this.renderProgress(file)}
                  </div>
                )
              })}
            </div>
            {this.state.errorMsg}
          </div>
        </div>
        <div className="Actions">{this.renderActions()}</div>
      </div>
    )
  }
}

export default Upload
