import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import axios from "axios"
import React, { useState } from 'react'
import { apiRequest } from 'helpers'
import './StripeStyle.css'


const CARD_OPTIONS = {
	iconStyle: "solid",
	style: {
		base: {
			iconColor: "#58a4b0",
			color: "#58a4b0",
			fontWeight: 500,
			fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
			fontSize: "16px",
			fontSmoothing: "antialiased",
			":-webkit-autofill": { color: "#58a4b0" },
			"::placeholder": { color: "#58a4b0" }
		},
		invalid: {
			iconColor: "red",
			color: "red"
		}
	}
}

export default function PaymentForm(props) {
    const [success, setSuccess ] = useState(false)
    const stripe = useStripe()
    const elements = useElements()


    const handleSubmit = async (e) => {
        e.preventDefault()
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
        })
      

    if(!error) {
        console.log("-----------------------")
        console.log(paymentMethod)
        try {
            const {id} = paymentMethod
            const data = {isDefault: false, paymentId: id}
            const response =  await apiRequest({
                method: 'post',
                url: 'payments/storeUserPaymentMethod',
                data,
              })
              props.refreshCards() 

            if(response.data.success) {
                console.log(response)
                console.log("--------ovo je response -------")
                
                
            }

        } catch (error) {
            console.log("Error", error)
        }
    } else {
        console.log(error.message)
    }
}

    return (
        <>
        {!success ? 
        <form onSubmit={handleSubmit}>
            <fieldset className="FormGroup">
                <div className="FormRow">
                    <CardElement options={CARD_OPTIONS}/>
                </div>
            </fieldset>
            <button className="buttonthis">Add new card</button>
        </form>
        :
       null
        }
            
        </>
    )
}