import React, { Component, useState } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import './userInfo.scss'

import Card from 'components/Card/Card.jsx'
import FormInputs from 'components/FormInputs/FormInputs.jsx'
import Button from 'components/CustomButton/CustomButton.jsx'
import { apiRequest } from 'helpers'
import Loader from 'components/Loader/Loader'
import ImageUploading from "react-images-uploading";
import imgUpload from 'assets/img/img-upload.png';
import SweetAlert from 'react-bootstrap-sweetalert';

import DeleteIcon from '@material-ui/icons/Delete';


const CreateTheme = (props) => {
  const [themeName, setThemeName] = useState('')
  const [themeKey, setThemeKey] = useState('')
  const [themeImage, setThemeImage] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [showImage, setShowImage] = useState(false)
  const [imageBase64, setImageBase64] = useState('')
  const [errorMessage, setErrorAlert] = useState(null)
  const maxNumber = 69;

  const errorAlert = (message, status) =>  {
    setErrorAlert(
    <SweetAlert
      type={status}
      style={{
      display: 'block',
      marginTop: '-100px',
      }}
      title={message}
      onConfirm={() => setErrorAlert(null)}
      confirmBtnBsStyle="info">
  </SweetAlert>)
  }
  const createTheme = async () => {
    setLoading(true)
    let formData = new FormData() //formdata object
    formData.append('themeName', themeName) //append the values with key, value pair
    formData.append('themeKey', themeKey)
    formData.append('image', themeImage.file)
    const data = formData
    try {
      const response = await apiRequest({
        method: 'post',
        url: 'theme',
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        } 
      })
      if (response.status === 200) {
        setLoading(false)
        errorAlert('Done', 'success')
        setShowImage(false)
      }
    } catch (err) {
      setLoading(false)
      errorAlert(err.response.data.message, 'danger')
      
    }
  }
  const onUploadChange = (imageList) => {
  setThemeImage(imageList[0])
  setImageBase64(imageList[0].data_url)
  setShowImage(true)
  }
  const removeImage = () => {
    setImageBase64('')
    setThemeImage('')
    setShowImage(false)
  }
    return (
      <div className="main-content">
        {isLoading === false ? (
          <Grid fluid>
            <Row>
              <Col md={8}>
                <Card
                  title="Create Theme"
                  content={
                    <form>
                      <FormInputs
                        ncols={['col-md-6', 'col-md-6']}
                        proprieties={[
                          {
                            label: 'Theme name',
                            type: 'text',
                            bsClass: 'form-control',
                            placeholder: 'Theme name',
                            onChange: (e) => {
                              setThemeName(e.target.value)
                            },
                          },
                          {
                            label: 'Theme key',
                            type: 'text',
                            bsClass: 'form-control',
                            placeholder: 'Theme key',
                            onChange: (e) => {
                              setThemeKey(e.target.value )
                            },
                          },
                        ]}
                      />

                      <Button
                        onClick={() => createTheme()}
                        pullRight
                        fill
                        style = {{background: '#58a4b0', border: 'none'}}
                        type="submit">
                        Create theme
                      </Button>
                      <div className="clearfix" />
                    </form>
                  }
                />
              </Col>
              <Col md={3}>
                {showImage ? (
                  <div className='image-preview'>
                    <img src={imageBase64} className='previev-image'/>
                    <div className='remove-theme-image' onClick={removeImage}>
                      <DeleteIcon/>
                      <span>Remove</span>
                    </div>
                    
                  </div>
                ) : (
                <div>
                <ImageUploading
                  multiple
                  onChange={onUploadChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                  >
                  {({
                    onImageUpload,
                    isDragging,
                    dragProps
                    }) => (
                      <div className="upload__image-wrapper">
                        <div 
                          className={!isDragging ? 'image-upload-wrap' : 'image-upload-wrap actives'}  
                          onClick={onImageUpload}
                          {...dragProps}>
                            <img src={imgUpload}/>
                            <p> Drop theme image or <strong>browse</strong></p>
                        </div>
                        &nbsp;
                      </div>
                    )}
                </ImageUploading>
                </div> )}
              </Col>
            </Row>
          </Grid>
        ) : (
          <div style={{ margin: '10% 10% 10% 45%' }}>
            <Loader size={50} />
          </div>
        )}
        {errorMessage}
      </div>
    )
  }


export default CreateTheme
