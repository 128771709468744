import React, { Component } from 'react'
import { Grid, Col, Row, Modal, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap'
// react component used to create charts
import ChartistGraph from 'react-chartist'
// react components used to create a SVG / Vector map
import { VectorMap } from 'react-jvectormap'
import WelcomeModal from './OnboardingReact/Onboarding';

import Card from 'components/Card/Card.jsx'
import StatsCard from 'components/Card/StatsCard.jsx'
import Tasks from 'components/Tasks/Tasks.jsx'
import Button from 'components/CustomButton/CustomButton.jsx'


import { apiRequest } from 'helpers'

import {
  dataSales,
  optionsSales,
  responsiveSales,
  optionsBar,
  responsiveBar,
  provinces_table_data,
} from 'variables/Variables.jsx'
import moment from 'moment'

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      alert: null,
      isLoading: false,
      totals: {},
      ageGroups: [],
      clientsPerProvince: [],
      lastUpdateTime: moment(),
      showAllProvinces: false,
      showWelcomeModal: localStorage.getItem('firstLogin') !== null ? true : false,
      welcomeModal: null
    }
  }

  componentDidMount() {
    this.getTotals()
    this.getClientsByAgeGroups()
    this.getClientsPerProvince()
    if(localStorage.getItem('firstLogin') !== null){
      this.setState({
        welcomeModal: (<WelcomeModal closeModal={this.closeModal}/>)
      })
    }
  }

  getTotals = async () => {
    let totals = {}

    let totalClients = await apiRequest({
      method: 'get',
      url: 'clients/totalClients',
    })
    console.log('TOTAL CLIENTS')
    console.log(totalClients)
    if (totalClients.status === 200) {
      totals.clients = totalClients.data
    }

    let totalRequests = await apiRequest({
      method: 'get',
      url: 'taxfiles/byStatus',
    })

    if (totalRequests.status === 200) {
      totalRequests.data.forEach((req) => {
        if (req.requeststatus.statusKey === 'pending') {
          totals.pendingRequests = req.totalRequests
        }

        if (req.requeststatus.statusKey === 'completed') {
          totals.completedRequests = req.totalRequests
        }
      })
    }

    this.setState({ totals })
  }

  getClientsByAgeGroups = async () => {
    const ageGroups = await apiRequest({
      method: 'get',
      url: 'clients/clientsByAgeGroups',
    })

    if (ageGroups.status === 200) {
      this.setState({ ageGroups: ageGroups.data })
    }
  }

  getClientsPerProvince = async () => {
    try {
      const provinces = await apiRequest({
        method: 'get',
        url: 'clients/clientsPerProvince',
      })

      if (provinces.status === 200) {
        this.setState({ clientsPerProvince: provinces.data })
      }
    } catch (err) {
      console.log(err)
    }
  }

  findTargetProvince = (province) => {
    const { clientsPerProvince } = this.state
    const targetProvince = clientsPerProvince.find((p) => p.key === province.key)

    return targetProvince
  }

  createTableData() {
    var tableRows = []

    for (var i = 0; i < provinces_table_data.length; i++) {
      const targetProvince = this.findTargetProvince(provinces_table_data[i])

      tableRows.push(
        <tr key={i}>
          <td>
            <div className="flag">
              <img src={provinces_table_data[i].flag} alt="us_flag" />
            </div>
          </td>
          <td>{provinces_table_data[i].province}</td>
          <td className="text-right">
            {targetProvince ? targetProvince.count : provinces_table_data[i].count}
          </td>
          <td className="text-right">
            {targetProvince
              ? targetProvince.percentage + '%'
              : provinces_table_data[i].percentage}
          </td>
        </tr>
      )
    }
    return tableRows
  }

  lastUpdateTime = (time) => {
    this.setState({ lastUpdateTime: time })
  }
  closeModal = () =>{
    localStorage.removeItem('firstLogin');
    this.setState({welcomeModal:  null})
  }

  render() {
    const {
      totals,
      ageGroups,
      lastUpdateTime,
      clientsPerProvince,
      showAllProvinces,
    } = this.state

    let completedRequests = totals.completedRequests ? parseInt(totals.completedRequests) : 0
    let sentRequests = totals.sentRequests ? parseInt(totals.pendi) : 0
    let pendingRequests = totals.pendingRequests ? parseInt(totals.pendingRequests) : 0

    console.log('== REQ ==')
    console.log('TOTALS')
    console.log(totals)
    console.log(completedRequests + ' ' + sentRequests + ' ' + pendingRequests)
    // Data for Pie Chart
    const dataPie = {
      labels: [`Completed (${completedRequests})`, `Pending (${pendingRequests})`],
      series: [completedRequests, pendingRequests],
    }
    let ageGroupsLabels = []
    let ageGroupsCounts = []

    ageGroups.forEach((group) => {
      ageGroupsLabels.push(group.age)
      ageGroupsCounts.push(parseInt(group.count))
    })
    console.log('AGE GROUPS')
    console.log(ageGroupsLabels)
    console.log(ageGroupsCounts)
    // Data for Bar Chart
    const dataBar = {
      labels: ageGroupsLabels,
      series: [ageGroupsCounts],
    }

    let vectorMapData = {}
    clientsPerProvince.forEach((province) => {
      vectorMapData[`CA-${province.key}`] = province.count
    })

  

    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col lg={4} sm={6}>
              {console.log("%c clients", "color: red")}
              {console.log(totals)}
              <StatsCard
                bigIcon={<i className="pe-7s-users text-success" />}
                statsText="Total Clients"
                statsValue={totals.clients}
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="Updated now"
              />
            </Col>
            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-upload text-warning" />}
                statsText="Pending Tax Files"
                statsValue={totals.pendingRequests ? totals.pendingRequests : 0}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Today"
              />
            </Col>
            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-mail-open-file text-success" />}
                statsText="Completed Tax Files"
                statsValue={totals.completedRequests ? totals.completedRequests : 0}
                statsIcon={<i className="fa fa-clock-o" />}
                statsIconText="In the last hour"
              />
            </Col>
            {/* <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-graph1 text-danger" />}
                statsText="Returning Clients"
                statsValue="82"
                statsIcon={<i className="fa fa-clock-o" />}
                statsIconText="In the last hour"
              />
            </Col> */}
          </Row>
          <Row>
            <Col md={4}>
                {console.log(" =============================== DATA PIE =============================")}
              {console.log(dataPie)}
              <Card
                title="Requests Statistics"
                category="Requests by status"
                content={dataPie.series[0] === 0 && dataPie.series[1] === 0 ? 'No requests yet' :   <ChartistGraph data={dataPie} type="Pie" />}
                legend={
                  <div>
                    <i className="fa fa-circle text-info" /> Completed
                    <i className="fa fa-circle text-danger" /> Pending
                    <i className="fa fa-circle text-warning" /> New
                  </div>
                }
                stats={
                  <div>
                    <i className="fa fa-clock-o" /> Displaying current data
                  </div>
                }
              />
            </Col>
            <Col md={8}>
              <Card
                title="New info"
                category="New info refreshed on every new update"
                content={
                  <table className="table">
                    <Tasks lastUpdateTime={this.lastUpdateTime} />
                  </table>
                }
                stats={
                  <div>
                    <i className="fa fa-history" /> Updated{' '}
                    {moment(lastUpdateTime).utc().fromNow()}
                  </div>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title="Clients by Top Provinces"
                category="Total number of clients per each province"
                content={
                  <Row>
                    <Col md={5}>
                      <div
                        className={`table-responsive ${
                          !showAllProvinces && 'maxHeightProvinces'
                        }`}>
                        <table className="table">
                          <tbody>{this.createTableData()}</tbody>
                        </table>
                      </div>

                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                          bsStyle="primary"
                          onClick={() =>
                            this.setState({ showAllProvinces: !showAllProvinces })
                          }>
                          {showAllProvinces ? 'Show Less' : 'Show All'}
                        </Button>
                      </div>
                    </Col>
                    <Col md={6} mdOffset={1}>
                      <VectorMap
                        map={'ca_lcc'}
                        backgroundColor="transparent"
                        zoomOnScroll={false}
                        containerStyle={{
                          width: '100%',
                          height: '280px',
                        }}
                        containerClassName="map"
                        regionStyle={{
                          initial: {
                            fill: '#e4e4e4',
                            'fill-opacity': 0.9,
                            stroke: 'none',
                            'stroke-width': 0,
                            'stroke-opacity': 0,
                          },
                        }}
                        series={{
                          regions: [
                            {
                              values: vectorMapData,
                              scale: ['#AAAAAA', '#444444'],
                              normalizeFunction: 'polynomial',
                            },
                          ],
                        }}
                      />
                    </Col>
                  </Row>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Card
                title="Clients"
                category="New and returning clients"
                content={
                  <ChartistGraph
                    data={dataBar}
                    type="Bar"
                    options={optionsBar}
                    responsiveOptions={responsiveBar}
                  />
                }
                legend={
                  <div>
                    <i className="fa fa-circle text-info" /> Number of clients by age groups
                  </div>
                }
                stats={
                  <div>
                    <i className="fa fa-check" /> Data information certified
                  </div>
                }
              />
            </Col>
            <Col md={6}>
              <Card
                title="Users Behavior"
                category="24 Hours performance"
                content={
                  <ChartistGraph
                    data={dataSales}
                    type="Line"
                    options={optionsSales}
                    responsiveOptions={responsiveSales}
                  />
                }
                legend={
                  <div>
                    <i className="fa fa-circle text-info" /> Open
                    <i className="fa fa-circle text-danger" /> Click
                    <i className="fa fa-circle text-warning" /> Click Second Time
                  </div>
                }
                stats={
                  <div>
                    <i className="fa fa-history" /> Updated 3 minutes ago
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
        {this.state.welcomeModal}
      </div>
    )
  }
}

export default Dashboard
