import React, { Component } from 'react'
import { Modal, FormControl } from 'react-bootstrap'
import Button from 'components/CustomButton/CustomButton.jsx'
import { validateEmail } from 'helpers'

class CreateWorkerProfileModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: true,
      userEmail: '',
      firstName: '',
      lastName: '',
      errorMessage: null,
    }
  }

  closeModal = () => {
    this.setState({ showModal: false })
    this.props.removeModal()
  }

  createProfile = () => {
    const { userEmail, firstName, lastName } = this.state

    if (userEmail === '') {
      this.setState({
        errorMessage: <small className="text-danger">Email address cannot be empty.</small>,
      })
      return
    }
    if (firstName === '') {
      this.setState({
        errorMessage: <small className="text-danger">First name cannot be empty.</small>,
      })
      return
    }
    if (lastName === '') {
      this.setState({
        errorMessage: <small className="text-danger">Last name cannot be empty.</small>,
      })
      return
    }

    if (!validateEmail(userEmail)) {
      this.setState({
        errorMessage: <small className="text-danger">Email address needs to be valid.</small>,
      })
      return
    }

    this.props.createProfile(userEmail, firstName, lastName)
    this.setState({ showModal: false })
  }

  render() {
    const { userEmail, firstName, lastName} = this.state
    return (
      <Modal show={this.state.showModal} onHide={() => this.closeModal()}>
        <Modal.Header className="blueModal">
          <span className="modal-title customTitle" style={{ color: '#FFF' }}>
            Create a new profile for worker
          </span>
          <div className="pull-right">
            <Button bsStyle="primary" fill onClick={() => this.createProfile()}>
              <span className="btn-label">
                <i className="fa fa-paper-plane" />
                {'  '}
              </span>
              <span style={{ fontWeight: 'bold' }}>Send</span>
            </Button>
            <Button bsStyle="primary" fill onClick={() => this.closeModal()}>
              <span className="btn-label">
                <i className="fa fa-times" />
                {'  '}
              </span>
              <span style={{ fontWeight: 'bold' }}>Cancel</span>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage}
          <div>
            <h3 className="customSubtitle top10">Enter email address: </h3>
            <small>We'll send an email invitation to the worker's email address</small>
            <FormControl
              className="modalTitleBox"
              placeholder="E-mail"
              value={userEmail}
              type="email"
              onChange={(event) => {
                this.setState({ userEmail: event.target.value })
              }}
            />
          </div>
          <div>
            <h3 className="customSubtitle top10">Enter first name: </h3>
            <FormControl
              className="modalTitleBox"
              placeholder="First name"
              value={firstName}
              type="text"
              onChange={(event) => {
                this.setState({ firstName: event.target.value })
              }}
            />
          </div>
          <div>
            <h3 className="customSubtitle top10">Enter last name: </h3>
            <FormControl
              className="modalTitleBox"
              placeholder="Last name"
              value={lastName}
              type="email"
              onChange={(event) => {
                this.setState({ lastName: event.target.value })
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default CreateWorkerProfileModal
