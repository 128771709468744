import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
// react component that creates notifications (like some alerts with messages)
import NotificationSystem from 'react-notification-system'
import {setApplication} from 'redux/actions/applicationActions'

import Sidebar from 'components/Sidebar/Sidebar.jsx'
import AdminNavbar from 'components/Navbars/AdminNavbar.jsx'
import Footer from 'components/Footer/Footer.jsx'
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";

import Config from 'config'

import image from 'assets/img/full-screen-image-3.jpg'

// dinamically create dashboard routes
import routes from 'routes.js'

import socketIOClient from 'socket.io-client'

// style for notifications
import { style } from 'variables/Variables.jsx'

import { connect } from 'react-redux'
import {
  fetchNotifications,
  updateNotifications,
  setSeenNotification,
} from 'redux/actions/notificationsActions'
import Button from 'components/CustomButton/CustomButton.jsx'

import { generateNotificationLayout, apiRequest } from 'helpers'
import UIfx from 'uifx'

import newMessageAppear from 'assets/sounds/appear.mp3'

import WelcomeModal from 'views/OnboardingReact/Onboarding'
import StripeNotification from 'views/StripeNotification/StripeNotif'
import WorkerOnboarding from 'views/WorkerOnboarding/Onboarding';

import { setStripeNotifcation } from 'redux/actions/stripeNotificationActions'

const newMessageSound = new UIfx(newMessageAppear, {
  volume: 1, // value must be between 0.0 ⇔ 1.0
})

var ps

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      _notificationSystem: null,
      image: image,
      color: 'black',
      hasImage: true,
      navbar: false,
      mini: false,
      fixedClasses: 'dropdown show-dropdown open',
      redirect: null,
      addedNotification: null,
      welcomeModal : null,
      stripeNotif: <StripeNotification />
    }
  }
  componentDidMount() {
    if(localStorage.getItem('firstLogin') !== null){
      this.openWelcomeModal()
    }
    // if(localStorage.getItem('notification') !== null && localStorage.getItem('role') === 'admin'){
    //   this.props.setStripeNotifcation(this.state.stripeNotif)
    // }
    if(localStorage.getItem('role') === 'superAdmin'){
      this.showCompanyApplication()
    }
    this.setState({ _notificationSystem: this.refs.notificationSystem })
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel)
    }

    const userToken = localStorage.getItem('usertoken')
    console.log('USER TOKEN')
    console.log(userToken)

    if (!userToken) {
      this.setState({ redirect: <Redirect to="/auth/login-page" /> })
     } 
    else {
      // SOCKET SETUP
      const socket = socketIOClient(Config.APP_SOCKET_IP)
      // CONNECT OUR USER TO SOCKET
      socket.emit('webUser', userToken)

      // Listen for notifications
      socket.on('newWebNotification', (data) => {
        console.log('== DASHBOARD TRIGGER ==')
        console.log(data)
        this.handleNotificationClick('tr', data)

        // Update notification in redux
        this.props.updateNotifications(data)
      })
      //Listen from new company applications
      socket.on('newApplication', (data) => {
        var datas = {
          notificationKey: 'newApplication',
          companyName: data
        }
        console.log('== APPLICATIONS TRIGGER ==')
        console.log(data)
        this.handleNotificationClick('tr', datas)
        this.props.updateNotifications(datas)
        this.props.setApplication(true)

        // Update notification in redux
        //this.props.setApplication()
        
      })
      this.props.fetchNotifications(100)
    }
  }
  showNotification = () => {
    this.setState({
      stripeNotif: (<StripeNotification />)
    })
  }
  showCompanyApplication = async () => {
    try{
      const applications = await apiRequest({
        method: 'get',
        url: 'firms?approved=false'
      })
      if(applications.status === 200 && applications.data.pageOfItems.length > 0){
        console.log('%c APPLICATIONS DATA', 'color: yellow')
        console.log(applications.data)
        this.props.setApplication(true)
      }
    }catch(err){
      console.log(err)
    }
   
  }
  openWelcomeModal = () => {
    if(localStorage.getItem('role') === 'admin'){
      this.setState({
        welcomeModal: (<WelcomeModal 
          history={(path) => this.props.history.push(path)} closeModal={this.closeWelcomeModal}/>)
      })
    }else{
      this.setState({
        welcomeModal: (<WorkerOnboarding 
          history={(path) => this.props.history.push(path)} closeModal={this.closeWelcomeModal}/>)
      })
    }
  }
  closeWelcomeModal = () => {
    this.setState({
      welcomeModal: null
    })
    localStorage.removeItem('firstLogin')
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy()
    }
  }
  componentDidUpdate(e) {
    if (navigator.platform.indexOf('Win') > -1) {
      setTimeout(() => {
        ps.update()
      }, 350)
    }
    if (e.history.action === 'PUSH') {
      document.documentElement.scrollTop = 0
      document.scrollingElement.scrollTop = 0
      this.refs.mainPanel.scrollTop = 0
    }
    if (
      window.innerWidth < 993 &&
      e.history.action === 'PUSH' &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open')
    }
  }
  componentWillMount() {
    if (document.documentElement.className.indexOf('nav-open') !== -1) {
      document.documentElement.classList.toggle('nav-open')
    }
  }

  toggleSoundNotification = () => {
    newMessageSound.play()
  }

  redirectToRoute = async (route, activityId, notificationKey) => {
    const { addedNotification } = this.state
    if (addedNotification) {
      this.state._notificationSystem.removeNotification(addedNotification)
    }

    const data = {
      activityId,
    }

    if (notificationKey && notificationKey === 'newMessage') {
      this.props.setSeenNotification(activityId, true)
      this.props.history.push(route)
    } else {
      try {
        const response = await apiRequest({
          method: 'post',
          url: 'activities/removeSeen',
          data,
        })

        if (response.status === 200 && route) {
          this.props.setSeenNotification(activityId)
          this.props.history.push(route)
        }
      } catch (err) {
        console.log('Error: ', err)
      }
    }
  }
  // function that shows/hides notifications - it was put here, because the wrapper div has to be outside the main-panel class div
  handleNotificationClick = (position, data = null) => {
    console.log('NOTIFICATION DATA: ')
    console.log(data)

    if (data) {
      const {
        notificationMessage,
        level,
        icon,
        redirectRoute,
        notificationKey,
      } = generateNotificationLayout(data)

      this.toggleSoundNotification()

      const addedNotification = this.state._notificationSystem.addNotification({
        title: <span data-notify="icon" className={icon} />,
        message: <div className="notificationMessageContainer">{notificationMessage}</div>,
        level: level,
        position: position,
        autoDismiss: 15,
        dismissible: 'none',
        children: (
          <Button
            onClick={() =>
              this.redirectToRoute(redirectRoute, data.activityId, notificationKey)
            }
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
              marginTop: 10,
              marginRight: 10,
            }}
            fill
            bsStyle={level}>
            OPEN
          </Button>
        ),
      })

      if (addedNotification) {
        this.setState({ addedNotification })
      }
    }
  }

  handleImageClick = (image) => {
    this.setState({ image: image })
  }
  handleColorClick = (color) => {
    this.setState({ color: color })
  }
  handleHasImage = (hasImage) => {
    this.setState({ hasImage: hasImage })
  }
  handleNavbarClick = (navbar) => {
    this.setState({ navbar: navbar })
  }
  handleMiniClick = () => {
    this.setState({ mini: !this.state.mini })
    document.body.classList.toggle('sidebar-mini')
  }
  handleFixedClick = () => {
    if (this.state.fixedClasses === 'dropdown') {
      this.setState({ fixedClasses: 'dropdown show-dropdown open' })
    } else {
      this.setState({ fixedClasses: 'dropdown' })
    }
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views)
      }

      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={(routeProps) => (
              <prop.component {...routeProps} handleClick={this.handleNotificationClick} />
            )}
          />
        )
      } else {
        return null
      }
    })
  }

  // FOR TESTING PURPOSES
  spawnNotification = () => {
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: <div>New signature by Aidan jenkins.</div>,
      level: 'success',
      position: 'tr',
      autoDismiss: 5,
      //dismissible: 'none',
      children: (
        <Button
          style={{ height: '100%', position: 'absolute', right: 0, top: 0 }}
          fill
          bsStyle="success">
          OPEN
        </Button>
      ),
    })
  }

  render() {
    return (
      <div className="wrapper" style={{position: 'relative'}}>
        {/* {this.props.stripeNotif ? () => console.log('REMOVING') : null} */}
        {this.state.redirect}
        <NotificationSystem ref="notificationSystem" style={style} />
        <Sidebar
          {...this.props}
          image={this.state.image}
          color={this.state.color}
          hasImage={this.state.hasImage}
          mini={this.state.mini}
        />
        <div style={{position: 'relative'}}
          className={
            'main-panel' +
            (this.props.location.pathname === '/maps/full-screen-maps'
              ? ' main-panel-maps'
              : '')
          }
          ref="mainPanel">
          <AdminNavbar
            {...this.props}
            handleMiniClick={this.handleMiniClick}
            navbar={this.state.navbar}
          />

          {/* TESTING PURPOSES}
          <Button
            style={{ position: 'absolute', zIndex: 99999 }}
            onClick={() => this.spawnNotification()}>
            NOTIFY
          </Button>
        */}

          <Switch>{this.getRoutes(routes)}</Switch>
          <Footer fluid />
          {/*<FixedPlugin
            handleImageClick={this.handleImageClick}
            handleColorClick={this.handleColorClick}
            handleHasImage={this.handleHasImage}
            handleNavbarClick={this.handleNavbarClick}
            handleMiniClick={this.handleMiniClick}
            bgColor={this.state["color"]}
            bgImage={this.state["image"]}
            mini={this.state["mini"]}
            handleFixedClick={this.handleFixedClick}
            fixedClasses={this.state.fixedClasses}
          /> */}
        </div>
        {this.state.welcomeModal}
        {/* {this.state.stripeNotif} */}
        {this.props.stripeNotification}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  setStripeNotifcation: (state) => dispatch(setStripeNotifcation(state)),
  setApplication: (state) => dispatch(setApplication(state)),
  fetchNotifications: (state) => dispatch(fetchNotifications(state)),
  updateNotifications: (state) => dispatch(updateNotifications(state)),
  setSeenNotification: (notificationId, isMessage = false) =>
    dispatch(setSeenNotification(notificationId, isMessage)),
})
const mapStateToProps = (state) => ({
  stripeNotif: state.apps.stripeNotif,
  stripeNotification: state.stripeNotification.isVisible
})
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
