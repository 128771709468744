import React from 'react'

const ManagementConsole = () => {
    return (
        <div>
            <div style={{textAlign: 'center'}}>
                <p style={{color: '#79adb7', fontSize: '20px', fontWeight: '400'}}>Management console</p>
                <p style={{fontWeight: '300', marginTop: '25px'}}>In management console you can see all company documents and forms that are connected to your company!</p>
            </div>
        </div>
    )
}

export default ManagementConsole
