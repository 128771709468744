import Config from 'config'
import axios from 'axios'

export const setInitialConfig = () => {
  /*showMessage({
    message: 'Setting config',
    type: 'info',
    duration: 1000
  }) */

  axios.defaults.validateStatus = (status) => status >= 200 && status <= 299
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      let errorData = null
      if (error.response) {
        if (
          error.response.status >= 500 ||
          [401, 404].includes(error.response.status)
        ) {
          if (Config.APP_ENVIRONMENT === 'local') {
            console.warn(
              `Error with request. Status: ${error.response.status}.`
            )
          }
          /*showMessage({
            message: 'Unexpected error occurred. Please try again or contact a Customer Representative.',
            type: 'danger',
            duration: 5000
          }) */
        } else if (error.response.data) {
          errorData = error.response.data
        }
      }
      return Promise.reject(errorData)
    }
  )
}
