import React, {useState, useEffect} from 'react';
import './company-application.css';
import CompanyCard from './CompanyCard/CompanyCard';
import Select from 'react-select'
import {Link, Redirect} from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert'
import { ReactComponent as Loader } from 'assets/img/loader-preview.svg'
import { connect } from 'react-redux'
import { setApplication } from 'redux/actions/applicationActions';



import apiRequest from 'helpers/apiRequest';

/*ICONS AND IMAGES*/
import ArrowBack from 'assets/img/icons/arrow_back.png';



const CompanyAplications = (props) =>{

    const [companies, setCompanies] = useState([]);
    const [statusMessage, setStatusMessage] = useState();
    const [redirect, setRedirect] = useState(null);
    
    const fetchCompanyApplications = async() => {
        setStatusMessage((
            <div className='loading-animation'>
                <Loader style={{ height: 75, width: 100 }} />
            </div>
        ))
        const role = localStorage.getItem('role')
        if(role !== 'superAdmin'){
            setRedirect(<Redirect to='/admin/not-allowed'/>)
        }
            try{
                const response  = await apiRequest({
                method: 'get',
                url: 'firms?approved=false&pageSize=1000&page=1&orderType=ASC'
                })
                setStatusMessage(null)
                getCompanies(response.data.pageOfItems)
                if(response.data.pageOfItems.length === 0) {
                    props.setApplication(false)
                }
            }catch(err){
                setStatusMessage(
                    <SweetAlert
                         danger
                         style={{
                         display: 'block',
                         marginTop: '-100px',
                         }}
                         title={err.response.data.message}
                         onConfirm={() => hideMessage()}
                         confirmBtnBsStyle="info">
                   </SweetAlert>)
            }   
    }
    useEffect(() =>{
        fetchCompanyApplications()
    }, [])

    
    const getCompanies = (companies) =>{
        setCompanies(companies)
    }
    const mapCompanies = (companies) =>{
       return companies.length > 0 ? companies.map((elem, index) =>(     
            <CompanyCard
                key={index}
                company={elem}
                aproveCompany={() => approveCompany(elem.id)}
                rejectCompany={() =>rejectCompany(elem.id)}
            />
        )): null
    }
    const hideMessage = () =>{
        setStatusMessage(null)
        //window.location.reload()
    }
    const rejectCompany = async (companyId) =>{
        setStatusMessage(
            <SweetAlert
                style={{
                  display: 'block',
                  marginTop: '-100px',
                }}
                title="Processing"
                showConfirm={false}>
                <div>
                  <Loader style={{ height: 75, width: 100 }} />
                </div>
              </SweetAlert>
        )
        try{
            const request = await apiRequest({
                method: 'POST',
                url: `firms/reject/${companyId}`
            })
            if(request.status === 200){
                   setStatusMessage(
                    <SweetAlert
                         success
                         style={{
                         display: 'block',
                         marginTop: '-100px',
                         }}
                         title="Company rejected"
                         onConfirm={() => hideMessage()}
                         confirmBtnBsStyle="info">
                   </SweetAlert>)
                   setTimeout(() => {fetchCompanyApplications()}, 2000)
            }
           
        }catch(err){
            console.log("%c ERROR", 'color: blue');
            console.log(err);
            setStatusMessage(
                <SweetAlert
                     danger
                     style={{
                     display: 'block',
                     marginTop: '-100px',
                     }}
                     title={err.response.data.msg || err.response.data.message}
                     onConfirm={() => hideMessage()}
                     confirmBtnBsStyle="info">
               </SweetAlert>)
        }
    }

    const approveCompany = async (companyId, smt = false) =>{
        setStatusMessage(
            <SweetAlert
                style={{
                  display: 'block',
                  marginTop: '-100px',
                }}
                title="Processing"
                showConfirm={false}>
                <div>
                  <Loader style={{ height: 75, width: 100 }} />
                </div>
              </SweetAlert>
        )
        try{
            const request = await apiRequest({
                method: 'POST',
                url: `firms/approve/${companyId}`
            })
            if(request.status === 200){
                setStatusMessage(
                    <SweetAlert
                         success
                         style={{
                         display: 'block',
                         marginTop: '-100px',
                         }}
                         title="Approved"
                         onConfirm={() => hideMessage()}
                         confirmBtnBsStyle="info">
                   </SweetAlert>)
                   setTimeout(() =>{fetchCompanyApplications()} ,2000)
                   
            }
        }catch(err){
            setStatusMessage(
                        <SweetAlert
                             danger
                             style={{
                             display: 'block',
                             marginTop: '-100px',
                             }}
                             title={err.response.data.message || err.response.data.msg}
                             onConfirm={() => hideMessage()}
                             confirmBtnBsStyle="info">
                       </SweetAlert>)
        }
    
    }

    return(
        <div className='companies-main-content'>
            {statusMessage}
            {redirect}
            <div className='header-container'>
                <h3>
                    <Link to='/admin/dashboard/'>
                        <img src={ArrowBack} className='arrow-back-icon' alt='back'/>
                    </Link>
                    New company aplications</h3>
                <h4 className='new-amount'>{companies.length} New</h4>
            </div>
            <div className="company-cards-container">
                {mapCompanies(companies)}
            </div>
            
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    setApplication: (state) => dispatch(setApplication(state)),
  })

export default connect(null, mapDispatchToProps)(CompanyAplications);