import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from 'components/CustomButton/CustomButton';
import trueImg from 'assets/img/true.png'

const UpgradeSubscriptionModal = (props) => {
    return (
        <Modal show={true} style={{marginTop: '15%'}} onHide={() => props.closeModal()}>
            <Modal.Body>
               <div 
                style={{
                    textAlign: 'center'
                }}
               >
                   <p
                    style={{
                        fontSize: '19px',
                        fontWeight: '500'
                    }}
                   >Upgrade to <span style={{fontWeight: '600', color: '#79ADB7'}}>PREMIUM</span></p>
               </div>
               <div 
                style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    marginTop: '20px'
                }}
               >
                   <div>
                       <ul style={{listStyleType: 'none'}}>
                           <li style={style.listStyle}><img src={trueImg} style={style.img}/>Customized firm logo banner inside the app</li>
                           <li style={style.listStyle}><img src={trueImg} style={style.img}/>Select own colors from color wheel</li>
                           <li style={style.listStyle}><img src={trueImg} style={style.img}/>Includes bold icon find my firm</li>
                           <li style={style.listStyle}><img src={trueImg} style={style.img}/>Minimum 300 annual user purchas</li>
                       </ul>
                   </div>
                   <div>
                       <h3 style={{fontSize: '41px', fontWeight: 'bold', color: '#79ADB7'}}>$500</h3>
                   </div>
               </div>
               <div style={{textAlign: 'center', marginTop: '10px'}}><Button style={{backgroundColor: '#79ADB7', color: '#FFFFFF', border: 'none', width: '30%'}} onClick={props.upgrade}>Upgrade</Button></div>
            </Modal.Body>
        </Modal>
    )
}

export default UpgradeSubscriptionModal

const style = {
    listStyle: {
        padding: '5px'
    },
    img: {
        marginRight: '5px'
    }
    
}
