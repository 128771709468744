import React, { useEffect, useState } from 'react'

import { Row, Col, Modal, Button, Form } from 'react-bootstrap'

import SweetAlert from 'react-bootstrap-sweetalert'
import { loadStripe } from '@stripe/stripe-js'
import visaImg from 'assets/img/visa.png'
import mastercardImg from 'assets/img/mastercard1.png'
import trashBin from 'assets/img/trash-bin.png'
import addImg from 'assets/img/add.png'
import boxImg from 'assets/img/icons/Group 105.png'
import securepayment from 'assets/img/securepayment.png'
import { apiRequest } from 'helpers'
import { ReactComponent as Loader } from 'assets/img/loader-preview.svg'
import StripeContainer from './StripeContainer.jsx'
import './StripeStyle.css'
import FormInputs from 'components/FormInputs/FormInputs.jsx'

const stripePromise = loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx')

const CreditCards = () => {
  const [cards, setCards] = useState([])
  const [alertMsg, setAlertMsg] = useState(null)
  const [loading, setLoading] = useState(null)
  const [addNew, setNewCard] = useState(null)
  const [button, setNextButton] = useState(null)
  const [modal, setModal] = useState(false)

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  useEffect(() => {
    fetchCards()
  }, [])

  const hideMessage = () => {
    setAlertMsg(null)
  }
  const fetchCards = async () => {
    setLoading(
      <SweetAlert
        style={{
          display: 'block',
          marginTop: '-100px',
        }}
        title="Loading"
        showConfirm={false}>
        <div>
          <Loader style={{ height: 75, width: 100 }} />
        </div>
      </SweetAlert>
    )
    setNewCard(null)
    setNextButton(
      <div className="add-new-cont">
        <button className="buttonthis" onClick={() => addNewCard()}>
          <img src={addImg} className='thisImg'/>Add new
        </button>
      </div>
    )
    try {
      const cards = await apiRequest({
        method: 'get',
        url: 'payments/getUserPaymentMethods',
      })
      if (cards.status === 200) {
        console.log(cards.data)
        setCards(cards.data)
        setLoading(null)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const addNewCard = () => {
    setModal(
      <Modal show={true}>
        <Modal.Header>
          <Row>
            <Col xs={10} md={10}>
              <p className="title-modal">Add new payment method</p>
            </Col>
            <Col xs={2} md={2}>
              <Button
                bsStyle="primary"
                fill
                onClick={() => setModal(null)}
                style={{ border: 'none' }}>
                <span className="btn-label">
                  <i className="fa fa-times" />
                  {'  '}
                </span>
              </Button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body style={{paddingTop: '40px'}}>
          <p style={{ marginLeft: '60px', color: '#58a4b0' }}>Add your credit card info:</p>
          <Row
            height={200}
            style={{
              width: '100%',
              backgroundColor: 'white',
              paddingRight: '60px',
              margin: '0 !important',
              paddingLeft: '60px',
              paddingTop: '20px',
              paddingBottom: '60px',
              borderRadius: '20px',
            }}>
            <Col xs={12} md={12}>
               <FormInputs
               color={"#58a4b0 !important"}
               border= {'none !important'}
                        ncols={['col-md-12']}
                        proprieties={[
                          
                          {
                            label: 'Card holder name',
                            type: 'text',
                            color: '#58a4b0 !important',
                            border: 'none !important',
                            bsClass: 'form-control',
                            placeholder: 'John Doe',
                            onChange: (e) => {
                              console.log("adding name")
                            },
                          }
                        ]}
                        style={{color: '#58a4b0 !important', backgroundColor: '#58a4b0'}}
                      />
              {/* <div className="form-group">
                <MDBInput label="Large input" size="lg" />
                <MDBInput label="Medium input" />
                <MDBInput label="Small input" size="sm" />
              </div> */}
            </Col>
            <Col xs={12} md={12}>
              <StripeContainer
                style={{ backgroundColor: 'white', padding: '60px' }}
                refreshCards={() => {
                  fetchCards()
                  setModal(null)
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        {/* <Modal.Footer>
          {/* <Button variant="secondary" onClick={setModal(null)}>
            Close
          </Button> 
        </Modal.Footer> */}
      </Modal>
    )
  }
  const createAlert = (status, message) => {
    setAlertMsg(
      <SweetAlert
        type={status}
        style={{
          display: 'block',
          marginTop: '-100px',
        }}
        title={message}
        onConfirm={() => hideMessage()}
        confirmBtnBsStyle="info"></SweetAlert>
    )
  }

  const deleteCard = async (sourceId) => {
    setLoading(
      <SweetAlert
        style={{
          display: 'block',
          marginTop: '-100px',
        }}
        title="Processing"
        showConfirm={false}>
        <div>
          <Loader style={{ height: 75, width: 100 }} />
        </div>
      </SweetAlert>
    )
    try {
      const response = await apiRequest({
        method: 'put',
        url: `payments/removePaymentMethod/${sourceId}`,
      })
      if (response.status === 200) {
        createAlert('success', 'Card deleted successfully!')
        fetchCards()
        setLoading(null)
      }
    } catch (err) {
      createAlert('danger', err.response.data.message || err.response.data.msg)
      fetchCards()
      setLoading(null)
    }
  }

  const makeDefault = async (sourceId, isDefault) => {
    const data = { sourceId: sourceId }
    if (isDefault) {
      createAlert('info', 'This card is already set to be default!')
    } else {
      setLoading(
        <SweetAlert
          style={{
            display: 'block',
            marginTop: '-100px',
          }}
          title="Loading"
          showConfirm={false}>
          <div>
            <Loader style={{ height: 75, width: 100 }} />
          </div>
        </SweetAlert>
      )
      try {
        const response = await apiRequest({
          method: 'post',
          url: 'payments/makePaymentMethodDefault',
          data,
        })
        if (response.status === 200) {
          createAlert('success', 'You set your default card successfully!')
          fetchCards()
          setLoading(null)
        }
      } catch (err) {
        createAlert('danger', 'Something went wrong!')
        fetchCards()
        setLoading(null)
      }
    }
  }

  const mapCards = () => {
    return cards.map((elem, index) => (
      <Row
        key={index}
        style={{
          width: '80%',
          margin: 'auto',
          borderRadius: '10px',
          height: '200px',
          marginTop: '40px',
          marginBottom: '40px',
          backgroundColor: '#FFFFFF',
          boxShadow: "0 1px 2px rgb(0 0 0 / 5%), 0 0 0 1px rgb(63 63 68 / 10%)"
        }}>
        <Col md={4} style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
          {elem.scheme === 'visa' ? (
            <img
              src={visaImg}
              alt="Visa"
              style={{
                width: '180px',
                height: '56px',
              }}
            />
          ) : (
            <img
            src={mastercardImg}
            alt="Mastercard"
            style={{
              width: '170px',
              height: '86px',
            }}
          />
          )}
         
        </Col>
        <Col
          md={4}
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}>
            <img src={boxImg} style={{ height: '30px', width: '30px' }} />

            <p
              style={{
                color: '#58A4B0',
                fontSize: '15px',
                marginLeft: '10px',
                marginTop: '10px',
              }}>
             Credit Card ending in {elem.last4 % 1000}
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}>
            <img src={securepayment} style={{ height: '35px', width: '30px' }} />
            <p
              style={{
                color: '#58A4B0',
                fontSize: '15px',
                marginLeft: '10px',
                marginTop: '10px',
              }}>
              Secure payment
            </p>
          </div>
        </Col>
        <Col
          md={4}
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '100%',
          }}>
          <button
            onClick={() => makeDefault(elem.sourceId, elem.isDefault)}
            style={{
              width: '120px',
              height: '43px',
              borderRadius: '10px',
              backgroundColor: '#58A4B0',
              border: 'none',
              color: '#FFFFFF',
              fontSize: '14px',
              letterSpacing: '5%',
              marginBottom: '10px',
            }}>
            {!elem.isDefault ? 'Make default' : 'Default'}
          </button>
          <button
            onClick={() => deleteCard(elem.sourceId)}
            style={{
              width: '120px',
              height: '43px',
              borderRadius: '10px',
              backgroundColor: '#F45B69',
              border: 'none',
              color: '#FFFFFF',
              fontSize: '14px',
              letterSpacing: '5%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <img
              src={trashBin}
              style={{ marginRight: '5px', height: '13px', width: '13px' }}
            />
            Delete
          </button>
        </Col>
      </Row>
    ))
  }

  return (
    <div style={{minHeight: '90vh'}}>
      {mapCards()}
      {alertMsg}
      {loading}
      {addNew}
      {button}
      {modal}

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}></div>
    </div>
  )
}

export default CreditCards
