import React from 'react'

const Messenger = () => {
    return (
        <div>
            <div style={{textAlign: 'center'}}>
                <p style={{color: '#79adb7', fontSize: '20px', fontWeight: '400'}}>Messenger</p>
                <p style={{fontWeight: '300', marginTop: '25px'}}>As the name suggests messenger serves you for sending messages to clients and interacting with them!</p>
            </div>
        </div>
    )
}

export default Messenger
