import React, { Component } from 'react'
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap'

function FieldGroup({ label, ...props }) {
  if (props)
    return (
      <FormGroup className={props.alignLeft ? 'alignGroupLeft' : ''}>
        <ControlLabel className={props.labelClass}>{label}</ControlLabel>
        <FormControl {...props} />
      </FormGroup>
    )
}

export class FormInputs extends Component {
  render() {
    var row = []
    for (var i = 0; i < this.props.ncols.length; i++) {
      row.push(
        <div key={i} className={this.props.ncols[i]}>
          <FieldGroup {...this.props.proprieties[i]} />
        </div>
      )
    }
    return <div className="row">{row}</div>
  }
}

export default FormInputs
