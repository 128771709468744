import React, {useEffect, useState} from 'react';
import {Tab, Tabs, Modal, FormControl} from 'react-bootstrap';
import FormInputs from 'components/FormInputs/FormInputs.jsx'
import './form-builder.css';

import Button from 'components/CustomButton/CustomButton';
import apiRequest from 'helpers/apiRequest';
import { ReactComponent as Loader } from 'assets/img/loader-preview.svg'
import dataJson from './data.json'

import SweetAlert from 'react-bootstrap-sweetalert'
import { Redirect } from 'react-router';
import slugify from 'react-slugify';

const FormBuilder = (props) =>{
    const [key, setKey] = useState('income')
    const [msgAlert, setAlert] = useState(null)
    const [formModal, setFormModal] = useState(null)
    const [redirect, setRedirect] = useState(null)
    const [foName, setFormName] = useState(null)
    const [foDesc, setFormDesc] = useState(null)
    const [data, setData] = useState([])

    useEffect(() =>{
        if(localStorage.getItem('role') !== 'admin'){
            setRedirect(<Redirect to="/admin/not-allowed"/>)
        }
    }, [])

    useEffect(() => {

        if(props.match.params.id){
            // THIS IS COMING FROM EDIT FORM
            const fetchData = async() => {
                const form = await apiRequest({
                    method: 'get',
                    url: `forms/${props.match.params.id}`
                })
    
                if(form.status === 200){
                    console.log("======= FORMS=====")
                    console.log(form.data)
                    setData(form.data.formJSON)
                    setFormName(form.data.formName)
                    setFormDesc(form.data.description)
                }
            } 
           fetchData()
        }else{
            // THIS IS WHEN ADMIN CLICKS ON BUILD FORM IN SIDEBAR AND GETS ALREADY PREFILLED FORM
            setData(dataJson)
        }
    }, [])

    const addField = (key) => {
        const allData = [...data]
        if(key === "income"){
            allData[0].values.push({label: '', selected: false, value: ''})
            setData([...allData])
        }else{
            allData[1].values.push({label: '', "selected": false, value: ''})
            setData([...allData])
        }
    }

    const addIncomeValue = (evt, inputIndex, tab) => {
        const allData = [...data]
        if(tab === 'income'){
            allData[0].values.forEach((elem, index) => {
                if(index === inputIndex){
                    elem.label = evt.target.value
                    elem.label = elem.label.replace(/[\\/]/g, '-')
                    elem.value = slugify(evt.target.value)
                }
            })
            setData([...allData])
        }else{
            allData[1].values.forEach((elem, index) => {
                if(index === inputIndex){
                    elem.label = evt.target.value
                    elem.label = elem.label.replace(/[\\/]/g, '-')
                    elem.value = slugify(evt.target.value)
                }
            })
            setData([...allData])
        }
    }

    const changeCheckbox = (inputIndex, tab) => {
        const allData = [...data]
        if(tab === 'income'){
            allData[0].values.forEach((elem, index) => {
                if(index === inputIndex){
                    elem.selected = !elem.selected
                }
            })
            setData([...allData])
        }else{
            allData[1].values.forEach((elem, index) => {
                if(index === inputIndex){
                    elem.selected = !elem.selected
                }
            })
            setData([...allData])
        }  
    }

    const hideMessage = () =>{
        setAlert(null)
    }

    const statusMessage = (status, message) => {
        if(status === 'success'){
            return setAlert(
                <SweetAlert
                    success
                    style={{
                    display: 'block',
                    marginTop: '-100px',
                    }}
                    title={message}
                    onConfirm={() => hideMessage()}
                    confirmBtnBsStyle="info">
                </SweetAlert>
                )
        }else{
            return setAlert(
                <SweetAlert
                    danger
                    style={{
                    display: 'block',
                    marginTop: '-100px',
                    }}
                    title={message}
                    onConfirm={() => hideMessage()}
                    confirmBtnBsStyle="info">
                </SweetAlert>
                )
        }
        
    }

    const sendData = async(fName, formDesc) => {
        console.log(fName)
        console.log(formDesc)
       
        const dataField = {
            formName: fName,
            description: formDesc,
            formJSON: JSON.stringify(data)
        }
        setAlert(
            <SweetAlert
                style={{
                display: 'block',
                marginTop: '-100px',
                }}
                title="Processing"
                showConfirm={false}>
                <div>
                    <Loader style={{ height: 75, width: 100 }} />
                </div>
            </SweetAlert>
            )
            console.log(dataField)

        let emptyField = false;
        if(data[0].values.length === 0 || data[1].values.length === 0){
            statusMessage('danger', 'Please add missing fields!')
        }
        data[0].values.forEach(elem => {
            if(elem.label === ""){
                emptyField = true
            }
        })
        data[1].values.forEach(elem => {
            if(elem.label === ""){
                emptyField = true
            }
        })
        if(fName === '' || formDesc === ''){
            emptyField = true
        }
        if(emptyField){
            statusMessage('danger', 'Please fill missing fields!')
        }else{
            try{
                if(props.match.params.id){
                    console.log(dataField.formJSON)
                    const response = await apiRequest({
                        method: 'put',
                        url: `forms/${props.match.params.id}`,
                        data:dataField
                    })

                    if(response.status === 200){
                        statusMessage('success', 'Your form has been saved!')
                        setFormModal(null)
                    }
                }else{
                    const response = await apiRequest({
                        method: 'post',
                        url: 'forms',
                        data:dataField
                    })

                    if(response.status === 200){
                        statusMessage('success', 'Your form has been saved!')
                        setFormModal(null)
                    }
                }
               
               
            }catch(err){
                statusMessage('danger', 'Something went wrong. Try again!')
            }
        }
        
    }

    const deleteField = (fieldIndex, tab) => {
        const allData = [...data];
        if(tab === 'income'){
            allData[0].values.splice(fieldIndex, 1)
            setData([...allData])
        }else{
            allData[1].values.splice(fieldIndex, 1)
            setData([...allData])
        }
    }

    const sendFormModal = () => {
        let formName;
        let formDesc;
        setFormModal(
            <Modal show={true}>
                <Modal.Header>
                    <Modal.Title>Submit Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormControl
                        className="modalTitleBox"
                        placeholder={props.match.params.id ? foName : 'Enter form name...'}  
                        type="email"
                        style={{marginBottom: '20px'}}
                        onChange={(event) => {formName = event.target.value}}
                    />
                    <FormControl
                        rows="5"
                        className="modalMessageBox top10"
                        componentClass="textarea"
                        bsClass="form-control"
                        onChange={(event) => {formDesc =event.target.value}}
                        placeholder={props.match.params.id ? foDesc : 'Enter form description...'}
                        />
                </Modal.Body>

                <Modal.Footer>
                    <Button 
                        className='btn-fill btn-facebook' 
                        onClick={() => sendData(formName, formDesc)}>
                            Send
                    </Button>
                    <Button 
                        className='btn-fill btn-twitter' 
                        onClick={() => setFormModal(null)}>
                            Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }


    return(
        <div>
            {msgAlert}
            {formModal}
            {redirect}
            <Button 
                className='btn-fill btn-twitter' 
                style={{float: 'right', marginRight: '50px'}}
                onClick={sendFormModal}>Submit form</Button>
                <Tabs
                    activeKey={key}
                    onSelect={(t) => setKey(t)}>
                        <Tab eventKey='income' title='Income'>
                            {data.length === 0 ? 
                                <div 
                                    className='empty-field' 
                                    onClick={() => addField("income")}>
                                    Click to create new fields
                                </div> :
                                    data[0].values.map((elem, index) => (
                                        <div key={index} className='field-container'>
                                            <i className="fa fa-times" id='close-icon' aria-hidden="true" onClick={() => deleteField(index, 'income')}></i>
                                            <div className="input-field">
                                                <input 
                                                    type="text" 
                                                    id="name" 
                                                    required 
                                                    className='inputName' 
                                                    value={elem.label} 
                                                    onChange={(evt) => addIncomeValue(evt, index, "income")}/>
                                                <label 
                                                    htmlFor="name" 
                                                    className='inputLabel'>Field label:</label>
                                            </div>
                                            <div className='checkboxx'>
                                                <input 
                                                    type="checkbox" 
                                                    id='checkbox' 
                                                    checked={elem.selected} 
                                                    onChange={() => changeCheckbox(index, 'income')}/>
                                            </div>     
                                        </div> 
                                    ))}
                            {data.length > 0 ? 
                                <Button 
                                    className='btn-fill btn-facebook' 
                                    onClick={() => addField("income")}>Add field</Button>
                                : null}
                        </Tab>

                        <Tab eventKey='deductions' title='Deductions'>
                            {data.length === 0 ? 
                                    <div 
                                        className='empty-field' 
                                        onClick={() => addField("deductions")}>
                                    Click to create new fields
                                    </div> :
                                        data[1].values.map((elem, index) => (
                                            <div key={index} className='field-container'>
                                                <i className="fa fa-times" id='close-icon' aria-hidden="true" onClick={() => deleteField(index, 'deductions')}></i>
                                                <div className="input-field">
                                                    <input 
                                                        type="text" 
                                                        id="name" 
                                                        required 
                                                        className='inputName' 
                                                        value={elem.label} 
                                                        onChange={(evt) => addIncomeValue(evt, index, "deductions")}/>
                                                    <label 
                                                        htmlFor="name" 
                                                        className='inputLabel'>Field label:</label>
                                                </div>
                                                <div className='checkboxx'>
                                                    <input 
                                                        type="checkbox" 
                                                        id='checkbox' 
                                                        checked={elem.selected} onChange={() => changeCheckbox(index, 'deductions')}/>
                                                </div>     
                                            </div> 
                                        ))}
                            {data.length > 0 ? <Button className='btn-fill btn-facebook' onClick={() => addField("deductions")}>Add field</Button> : null}
                        </Tab>
                </Tabs>

        </div>
    )
}

export default FormBuilder;