import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'assets/sass/light-bootstrap-dashboard-pro-react.scss?v=1.2.0'
import 'assets/css/demo.css'
import 'assets/css/pe-icon-7-stroke.css'

//import redux store
import store from 'redux/store/index'
import { Provider } from 'react-redux'

import App from './App'

ReactDOM.render(
  <Provider store={store}>
      <App />
  </Provider>,
  document.getElementById('root')
)
