import React from 'react'
import { Modal } from 'react-bootstrap';
import Button from 'components/CustomButton/CustomButton';

const ConfirmationModal = (props) => {
    return (
        <Modal show={true}>
            <Modal.Header style={{backgroundColor: '#447DF7', color: '#FFFFFF', borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}>
                <span className="modal-title customTitle" style={{ color: '#FFF' }}>
                    Confirmation modal
                </span>
                <div className="pull-right">
                <Button bsStyle="primary" fill  onClick={props.closeConfirmation}>
                    <span className="btn-label">
                        <i className="fa fa-times" />
                        {'  '}
                    </span>
                    <span style={{ fontWeight: 'bold' }}>Cancel</span>
                </Button>
          </div>
            </Modal.Header>
            <Modal.Body>
                <div style={{width: '90%', margin: 'auto', textAlign: 'center'}}>
                    <strong>Would you like to add an Engagement Letter document or a normal document?</strong>
                </div>
                <div style={{width: '90%', margin: 'auto', textAlign: 'center', marginTop: '3%'}}>
                    <Button 
                        className="btn-fill btn-primary" 
                        style={{marginRight: '5%'}}
                        onClick={props.engagementLetter}>Engagement letter</Button>
                    <Button 
                        className='btn-primary'
                        onClick={props.addNormalDoc}>Normal document</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ConfirmationModal
