import * as action from '../actions/applicationActions'

const INITAL_STATE = {
    applications: false,
    stripeNotif: false
}

export default function applicationReducer (state=INITAL_STATE, action) {
    switch(action.type){
        case 'SET_APPLICATION':
            return{...state, applications: action.payload}
        case 'SET_STRIPE_NOTIFICIATION' : {
            console.log('i want to remove')
            return {...state, stripeNotif: action.payload}
        }
        default: {
            return{...state}
        }
    }
 
}