import React, {useState, useEffect} from 'react';
import {Row, Col} from 'react-bootstrap';

import apiRequest from 'helpers/apiRequest';

import CompanyImage from 'assets/img/company.png'
import AuthUser from 'assets/img/authuser.png'

const SingleFirm = (props) => {

    const [firm, setFirm] = useState();
    const [authorityUser, setAuthorityUser] = useState();

    useEffect(() =>{
        async function fetchData(){
            try{
                const response = await apiRequest({
                    method: 'get',
                    url: `firms/${props.match.params.id}`
                })
                if(response.status === 200){
                    setFirm(response.data)
                }

                const authUser = await apiRequest({
                    method: 'get',
                    url: `users/${response.data.authorityUserId}`
                })
                if(authUser.status === 200){
                    setAuthorityUser(authUser.data)
                }
            }catch(err){
                console.log(err)
            }
        }
        fetchData() 
    },[])

    const style = {
        headStyle: {
            fontSize: '13px',
            color: 'rgba(0, 0, 0, 0.5393)'
        },
        infoStyle: {
            fontSize: '16px',
            fontWeight: '500',
            marginTop: '-10px',
            marginLeft: '15px'
        }  
    }

    
    return ( 
        <div className='main-content'>
            {firm ? 
                <Row style={{margin: 'auto'}}>
                    <Col 
                        md={6}
                        style={{borderRadius: "4px", boxShadow: "0 1px 2px rgb(0 0 0 / 5%), 0 0 0 1px rgb(63 63 68 / 10%)", backgroundColor: "#ffffff", marginBottom: "30px"}}>
                            <div className='text-center' >
                                <img src={CompanyImage} style={{height:"50px", width: '50px'}}/>
                                <h1>{firm.name}</h1>
                            </div>
                            <Row style={{marginTop: "50px",paddingLeft: '12%'}}>
                                <Col md={6}>
                                    <p style={style.headStyle}>Address: </p>
                                    <p style={style.infoStyle}>{firm.address}</p>
                                    <p style={style.headStyle}>City</p>
                                    <p style={style.infoStyle}>{firm.city} {firm.postalCode}</p>
                                    <p style={style.headStyle}>State</p>
                                    <p style={style.infoStyle}>{firm.state}</p>
                                </Col>
                                <Col md={6}>
                                    <p style={style.headStyle}>Company e-mail:</p>
                                    <p style={style.infoStyle}>{firm.email}</p>
                                    <p style={style.headStyle}>Company phone number: </p>
                                    <p style={style.infoStyle}>{firm.phoneNumber}</p>
                                </Col>
                            </Row>  
                    </Col>

                    <Col 
                    md={6}
                    style={{borderRadius: "4px", boxShadow: "0 1px 2px rgb(0 0 0 / 5%), 0 0 0 1px rgb(63 63 68 / 10%)", backgroundColor: "#ffffff", marginBottom: "30px", width: '40%', marginLeft: '5%'}}>
                        <div className='text-center' >
                            <img src={AuthUser} style={{height:"50px", width: '50px'}}/>
                            <h5>Authority user </h5>
                        </div>
                        {authorityUser ? 
                        <Row>
                            <Col md={6}>
                                <p style={style.headStyle}>Name</p>
                                <p style={style.infoStyle}>{authorityUser.firstName} {authorityUser.lastName}</p>
                                <p style={style.headStyle}>City</p>
                                <p style={style.infoStyle}>{authorityUser.city}</p>
                                <p style={style.headStyle}>State</p>
                                <p style={style.infoStyle}>{authorityUser.country} {authorityUser.lastName}</p>
                            </Col>
                            <Col md={6}>
                                <p style={style.headStyle}>E-mail</p>
                                <p style={style.infoStyle}>{authorityUser.email}</p>
                                <p style={style.headStyle}>Phone number</p>
                                <p style={style.infoStyle}>{authorityUser.phone}</p> 
                            </Col>
                        </Row>  : null}
                    </Col>
                    
                </Row> : null}
        </div>
    );
}
 
export default SingleFirm;