import React, { Component } from 'react'
import { Modal, FormControl } from 'react-bootstrap'
import Button from 'components/CustomButton/CustomButton.jsx'
import Select from 'react-select'
import { apiRequest } from 'helpers'

class NewMessageModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: true,
      clients: [],
      selectedClients: [],
      message: '',
      errorMessage: null,
    }
  }

  componentDidMount() {
    this.getAllClients()
  }

  closeModal = () => {
    this.setState({ showModal: false })
    this.props.removeModal()
  }

  sendMessage = () => {
    const { selectedClients, message } = this.state
    if (selectedClients.length <= 0) {
      this.setState({
        errorMessage: (
          <small className="text-danger">Please select at least one client.</small>
        ),
      })
      return
    }

    if (message === '') {
      this.setState({
        errorMessage: <small className="text-danger">Message cannot be empty.</small>,
      })
      return
    }

    this.props.sendMessage(selectedClients, message)
    this.setState({ showModal: false })
  }

  getAllClients = async () => {
    const response = await apiRequest({
      method: 'get',
      url: 'chat/getAllClients',
    })

    if (response.status === 200) {
      let arrClients = []
      response.data.forEach((client) => {
        arrClients.push({
          value: client.id,
          label: client.name,
        })
      })

      this.setState({ clients: arrClients })
    }
  }

  render() {
    const { clients, message } = this.state

    let options = [
      {
        value: '',
        label: ' You can choose multiple options',
        isDisabled: true,
      },
    ]
    let optionsClients = [...options, ...clients]

    return (
      <Modal show={this.state.showModal} onHide={() => this.closeModal()}>
        <Modal.Header className="blueModal">
          <span className="modal-title customTitle" style={{ color: '#FFF' }}>
            Send new message
          </span>
          <div className="pull-right">
            <Button bsStyle="primary" fill onClick={() => this.sendMessage()}>
              <span className="btn-label">
                <i className="fa fa-paper-plane" />
                {'  '}
              </span>
              <span style={{ fontWeight: 'bold' }}>Send</span>
            </Button>
            <Button bsStyle="primary" fill onClick={() => this.closeModal()}>
              <span className="btn-label">
                <i className="fa fa-times" />
                {'  '}
              </span>
              <span style={{ fontWeight: 'bold' }}>Cancel</span>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage}
          <div>
            <h3 className="customSubtitle top10">Choose clients</h3>
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              placeholder="Choose Clients"
              name="multipleSelect"
              closeMenuOnSelect={false}
              isMulti
              value={this.state.selectedClients}
              onChange={(value) => {
                this.setState({ selectedClients: value })
              }}
              options={optionsClients}
            />
            <FormControl
              rows="5"
              className="modalMessageBox top10"
              componentClass="textarea"
              bsClass="form-control"
              value={message}
              onChange={(event) => {
                this.setState({ message: event.target.value })
              }}
              placeholder="Type your message..."
            />
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default NewMessageModal
