import { setInitialConfig } from './initialConfig'

const APP_ENVIRONMENT = process.env.REACT_APP_APP_ENVIRONMENT
const APP_SOCKET_IP = process.env.REACT_APP_SOCKET_IP
const APP_API_URL = process.env.REACT_APP_APP_API_URL
const APP_MAX_UPLOAD_FILE_SIZE =
  process.env.REACT_APP_APP_MAX_UPLOAD_FILE_SIZE || 10485760
let LOCAL_LOGOUT_EACH_RELOAD = false
const REFRESH_DATA_TIME = 5000
let apiUrl = ''
if (APP_ENVIRONMENT === 'local') {
  apiUrl = APP_API_URL
  LOCAL_LOGOUT_EACH_RELOAD =
    process.env.REACT_APP_LOCAL_LOGOUT_EACH_RELOAD || false
} else {
  apiUrl = APP_API_URL
}

export default {
  setInitialConfig,
  APP_ENVIRONMENT,
  APP_API_URL: apiUrl,
  APP_MAX_UPLOAD_FILE_SIZE,
  LOCAL_LOGOUT_EACH_RELOAD,
  REFRESH_DATA_TIME,
  APP_SOCKET_IP,
}
