import React, { useEffect, useState } from 'react';
import './company-card.css';
import { Row, Col } from 'react-bootstrap';
import ThemeModal from './ThemModal'

/*IMAGES AND ICONS*/
import approveImg from 'assets/img/approve-img.png';
import trashIcon from 'assets/img/trash-bin.png';
import { apiRequest } from 'helpers';


const CompanyCard = ({ company, aproveCompany, rejectCompany }) =>{

    const [theme, setTheme] = useState({})
    const [themeModal, setThemeModal] = useState(null)

    useEffect(() => {
        getThemes()
    }, [])

    const getThemes = async () => {
        try{
            const themes = await apiRequest({
                method: 'get',
                url: 'theme/all'
            })
            if(themes.status === 200){
                console.log(themes)
                themes.data.map(elem => {
                    if(elem.id === company.themeId){
                        console.log(elem)
                        setTheme({...elem})
                    }
                })
            }
        }catch(err){
            console.log(err)
        }
    }

    const openThemeModal = (thm) => {
        setThemeModal(
            <ThemeModal 
                closeModal={() => setThemeModal(null)}
                theme={thm}
            />
        )
    }
    
    const getPackage = () => {
        switch(company.subscriptionPackage){
            case 1: 
                return (
                    <div className='pricing-package'>
                        <p className='package-text-1'>Standard package</p>
                        <p className='package-text-2'>$400 / year</p>
                    </div> )
            case 2: 
                return(
                    <div className='pricing-package'>
                        <p className='package-text-1'>Premium package</p>
                        <p className='package-text-2'>$500 / year</p>
                    </div>)
             case 3: 
                return(
                    <div className='pricing-package'>
                        <p className='package-text-1'>Gold package</p>
                        <p className='package-text-2'>$ / year</p>
                    </div>)
        }
    }

    return(
            <Row className='company-card-container'>
                {console.log("========== company ===========")}
                {console.log(Object.keys(theme).length > 0 ? console.log(theme) : null)}
                {themeModal}
                <Col lg={3}>
                    <p className='cards-headlines'>Company info</p>
                    <div className='company-details'>
                        <span className='company-detail-headline'>Company name</span>
                        <span className='company-detail-info'>{company.name}</span>

                        <span className='company-detail-headline'>Address</span>
                        <span className='company-detail-info'>{company.address}</span>

                        <span className='company-detail-headline'>City</span>
                        <span className='company-detail-info'>{company.city}</span>

                        <span className='company-detail-headline'>Province</span>
                        <span className='company-detail-info'>{company.state}</span>

                        <span className='company-detail-headline'>Postal code</span>
                        <span className='company-detail-info'>{company.postalCode}</span>
                    </div>
                </Col>
                <Col lg={3}>
                    <p className='cards-headlines'>Contact info</p>
                    <div className='company-details'>
                        <span className='company-detail-headline'>Phone number</span>
                        <span className='company-detail-info'>{company.phoneNumber}</span>

                        <span className='company-detail-headline'>Email</span>
                        <span className='company-detail-info'>{company.email}</span>

                        <span className='company-detail-headline'>Website</span>
                        <span className='company-detail-info'>{company.website}</span>

                        <span className='company-detail-headline'>CEO</span>
                        <span className='company-detail-info'>{company.representativeFirstName} {company.representativeLastName}</span>

                        <span className='company-detail-headline'>CEO Email</span>
                        <span className='company-detail-info'>{company.signingAuthorityEmail}</span>
                    </div>
                </Col>
                <Col lg={3}>
                    <p className='cards-headlines'>Representative</p>
                    <div className='company-details'>
                            <span className='company-detail-headline'>Full name</span>
                            <span className='company-detail-info'>{company.representativeFirstName} {company.representativeLastName}</span>

                            <span className='company-detail-headline'>Email</span>
                            <span className='company-detail-info'>{company.representativeEmail}</span>

                            <span className='company-detail-headline'>Phone number</span>
                            <span className='company-detail-info'>{company.adminPhoneNumber}</span>
                    </div>
                    <div className='bus-rep-num'>
                        <div className='bus-rep'>
                            <p className='bus-num1'>Business number</p>
                            <p className='bus-num2'>{company.businessNumber}</p>
                        </div>
                        <div className='bus-rep'>
                            <p className='bus-num1'>Rep ID</p>
                            <p className='bus-num2'>{company.repId}</p>
                        </div>
                    </div>
                </Col>
                <Col lg={3}>
                    <img src={company.logo} alt='Logo' className='company-logo-app'/>
                    {getPackage()}

                    <p className='sec-theme' onClick={() => openThemeModal(theme)}>Selected theme: <span className='theme-text'>{theme.themeName}</span></p>
                    
                    <div className='approve-reject-buttons'>
                        <div className='reject-button' onClick={() => rejectCompany()}>
                            <img src={trashIcon} alt='Trash'/>
                        </div>
                        <div className='approve-button' onClick={() => aproveCompany()}>
                            <img src={approveImg} alt='Approve'/>
                            <span>Approve</span>
                        </div>
                    </div>
                </Col>
                
            </Row>
            
    )
}

export default CompanyCard;