/*eslint-disable*/
import React, { Component } from 'react'
// react component for creating dynamic tables
import ReactTable from 'react-table'
import { Grid, Row, Col, FormControl, FormGroup, InputGroup } from 'react-bootstrap'

import CreateClientProfileModal from 'views/Components/CreateClientProfileModal'
import SweetAlert from 'react-bootstrap-sweetalert'

import { withRouter, Link } from 'react-router-dom'

import Card from 'components/Card/Card.jsx'
import Button from 'components/CustomButton/CustomButton.jsx'

import { apiRequest } from 'helpers'

class Firms extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: this.props.data,
      loading: false,
      pages: 0,
      searchParam: '',
      data: [],
      tableState: null,
      createProfileModal: null,
      alert: null,
    }
  }

  getFirms = async (page = 0, pageSize = 10, sorted, filtered) => {

    let orderId = null
    let orderType = null
    let searchParam = this.state.searchParam

    if (sorted && sorted.length > 0) {
      orderId = sorted[0].id
      orderType = sorted[0].desc ? 'DESC' : 'ASC'
    }

    if (orderId === null && orderType === null) {
      orderId = 'firstName'
      orderType = 'ASC'
    }

    const response = await apiRequest({
      method: 'get',
      url:`firms?approved=true&pageSize=${pageSize}&page=${page+1}&orderType=${orderType}&searchQuery=${searchParam}` ,
    })
    //firms?approved=true&pageSize=${pageSize}&page=1&order=${orderId}&orderType=${orderType}
    //`firms?page=${
    //   page + 1
    // }&pageSize=${pageSize}&order=${orderId}&orderType=${orderType}&searchQuery=${searchParam}&approved=true`
    console.log('==== FIRMEEEEE ===')
    console.log(response)

    if (response.status === 200) {
      console.log('===== RESPONSE =======')
      console.log(response.data)
      if (searchParam === '') {
        this.setState({
          data: response.data.pageOfItems,
          pages: response.data.pager.totalPages,
        })
        this.refReactTable.fireFetchData()
      }
      
      return response
    }
  }

  onPageChange = () => {
    console.log('PAGE CHANGE')
  }

  componentDidMount =() =>{
    this.getFirms()
  }

  onFetchData = async (state, instance = null) => {
    this.setState({ loading: true })
    console.log('STATE')
    console.log(state)
    const res = await this.getFirms(state.page, state.pageSize, state.sorted, state.filtered)

    if (res) {
      console.log('RES ======')
      console.log(res)
      let data = res.data.pageOfItems.map((item, key) => {
        return {
          id: item.clientId,
          companyName: item.name,
          email: item.email,
          phone: item.phoneNumber,
          authorityUser: item.representativeFirstName + ' ' + item.representativeLastName,
          city: (<div style={{textAlign: 'right'}}>{item.city}</div>), 
          // actions: (
          //   // we've added some custom button actions
          //   <div className="actions-right">
          //     {/* use this button to add a like kind of action */}
          //     <Link to={`/admin/single-firm/${item.id}`}>
          //       <Button bsStyle="primary">Open</Button>
          //     </Link>{' '}
          //   </div>
          // ),
        }
      })

      this.setState({
        data,
        pages: res.data.pager.totalPages,
        loading: false,
        tableState: state,
      })
    }
  }

  createProfile = async (email) => {
    const data = {
      email,
    }

    console.log('DATA')
    console.log(data)
    try {
      const response = await apiRequest({
        url: 'clients/createClientByUser',
        method: 'post',
        data,
      })
      console.log('RESPONSE')
      console.log(response)
      if (response.status === 200) {
        this.successAlert('New profile for client has been created.')
      }
    } catch (err) {
      console.log('==ERROR==')
      this.errorAlert(err.response.data.message)
    }
  }

  removeModal = () => {
    this.setState({ createProfileModal: null })
  }

  /* SUCCESS AND ERROR ALERTS */
  successAlert(msg = '') {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{
            display: 'block',
            marginTop: '-100px',
          }}
          title="Done"
          onConfirm={() => this.hideAlert(true)}
          confirmBtnBsStyle="info">
          {msg}
        </SweetAlert>
      ),
    })
  }

  errorAlert(message = 'There was an error. Please try again.') {
    this.setState(
      {
        alert: (
          <SweetAlert
            danger
            style={{
              display: 'block',
              marginTop: '-100px',
            }}
            title="Error"
            onConfirm={() => this.hideAlert()}
            confirmBtnBsStyle="info">
            {message}
          </SweetAlert>
        ),
      },
      () => {
        this.removeModal()
      }
    )
  }

  hideAlert(reloadData = false) {
    this.setState(
      {
        alert: null,
      },
      () => {
        if (reloadData) {
          this.removeModal()
          this.onFetchData(this.state.tableState)
        }
      }
    )
  }

  render() {
    const { data, pages, loading } = this.state
    console.log('DATA')
    console.log(data)
    return (
      <div className="main-content">
        {this.state.alert}
        <Grid fluid>
          <Row style={{ marginBottom: 10 }}>
            <Col md={6}>
              <div className="text-left">
                <span className="title customTitle">Companies Management Console</span>
              </div>
            </Col>
            <Col md={6}>
              <div className="text-right">
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Addon>
                      <i className="fa fa-search" />
                    </InputGroup.Addon>
                    <FormControl
                      onChange={(event) => {
                        console.log('CHANGE: ', event.target.value)
                        this.setState({ searchParam: event.target.value }, () => {
                          this.onFetchData(this.state.tableState)
                        })
                      }}
                      type="text"
                      placeholder="Search..."
                    />
                  </InputGroup>
                </FormGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div>
                    <Row style={{ marginBottom: 10 }}>
                      <Col md={6}>
                        <div className="text-left">
                          <h4 className="title">Companies List</h4>
                        </div>
                      </Col>
                    </Row>
                    <ReactTable
                      data={data}
                      ref={(refReactTable) => {
                        this.refReactTable = refReactTable
                      }}
                      filterable
                      columns={[
                        {
                          Header: 'Company Name',
                          sortable: true,
                          filterable: false,
                          accessor: 'companyName',
                        },
                        {
                          Header: 'Email',
                          sortable: false,
                          filterable: false,
                          accessor: 'email',
                        },
                        {
                          Header: 'Phone Number',
                          sortable: false,
                          filterable: false,
                          accessor: 'phone',
                        },
                        {
                          Header: 'Authority User',
                          sortable: false,
                          filterable: false,
                          accessor: 'authorityUser',
                        },
                        {
                          Header: 'City',
                          sortable: false,
                          filterable: false,
                          accessor: 'city',
                        },
                        // {
                        //   Header: 'Actions',
                        //   accessor: 'actions',
                        //   sortable: false,
                        //   filterable: false,
                        // },
                      ]}
                      defaultPageSize={10}
                      //showPaginationTop
                      pages={pages}
                      manual
                      loading={loading}
                      showPagination={true}
                      showPaginationBottom={true}
                      onFetchData={this.onFetchData}
                      className="-striped -highlight"
                      onPageChange={this.onPageChange}
                    />
                  </div>
                }
              />
            </Col>
          </Row>
          {this.state.createProfileModal}
        </Grid>
      </div>
    )
  }
}

export default Firms
