import React, { useEffect, useState } from 'react'

import { Row, Col } from 'react-bootstrap'

/*Items per tabs*/
import UserPage from './UserPage';
import Subscription from './Subscription';
import CreditCards from './CreditCards';
import Transactions from './Transactions';
import CreateTheme from './CreateTheme';
import AllThemes from './AllThemes'

const focusStyle = {
    color: 'rgba(88, 164, 176, 1)',
    borderBottom: '5px solid #58A4B0',
    textAlign: 'center',
    marginLeft: '25px',
    marginRight: '25px',
    cursor: 'pointer'
}

const MyProfileRoot = () => {

    const [component, setComponent] = useState((<UserPage />))
    const [role, setRole] = useState(localStorage.getItem('role'))
    const [tabs, setTabs] = useState([])

    useEffect(() => {
        setTabsPerRole()
    }, [])

    const setTabsPerRole = () => {
        if(role === 'admin'){
            setTabs([
                {id: 1, name: 'My profile', component: (<UserPage />), active: true},
                {id: 2, name: 'Subscription', component: (<Subscription/>), active: false},
                {id: 3, name: 'Credit cards', component: (<CreditCards/>), active: false},
                {id: 4, name: 'Transactions', component: (<Transactions/>), active: false},
            ])
        }else if(role === 'superAdmin'){
            setComponent(<CreateTheme/>)
            setTabs([
                {id: 2, name: 'Create theme', component: (<CreateTheme/>), active: true},
                {id: 3, name: 'All themes', component: (<AllThemes/>), active: false},
            ])
         }
    }

    const setRenderComponent = (id, component) => {
        const tabsCopy = [...tabs]
        tabsCopy.forEach( elem => elem.active = false)
        tabsCopy.forEach( elem => {
            if(elem.id === id){
                elem.active = true
            }
        })
        setTabs([...tabsCopy])
        setComponent(component)
    }

    return (
        <div>
            <Row style={{
                borderBottom: '1px solid #58A4B0',
                color: 'rgba(197, 195, 198, 1)',
                paddingLeft: '40px',
                marginTop: '30px',
                color: '#8d7777',
                width: '100%'
            }}>
                {tabs.length > 0 && tabs.map((elem, index) => (
                    <Col 
                        key={index}
                        md={2} 
                        onClick={() => setRenderComponent(elem.id, elem.component)}
                        style={elem.active ? focusStyle : {textAlign: 'center', cursor: 'pointer'} }>{elem.name}</Col>
                ))}
            </Row>
            {component}
        </div>
    )

    
}

export default MyProfileRoot
