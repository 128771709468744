import React, {useState, useEffect, useCallback} from 'react';
import {Grid, Row, Col, Button } from 'react-bootstrap';


import FormInputs from 'components/FormInputs/FormInputs'

import UserCard from 'components/Card/UserCard';
import Avatar from 'assets/img/avatar-sil.png'
import Card from "components/Card/Card";
import {Redirect, useHistory} from 'react-router-dom';

import apiRequest from 'helpers/apiRequest';

const SingleWorker = (props) =>{

    const [worker, setWorker] = useState({})
    const [company, setCompany] = useState({})
    const [clients, setClients] = useState([])
    const [redirect, setRedirect] = useState(null)
    const [workerStatistics, setWorkerStatistics] = useState({})

    useEffect(() =>{
        const fetchData = async() =>{
            try{
                const user = await apiRequest({
                    method: 'get',
                    url: `users/${props.match.params.id}`
                })
                const company = await apiRequest({
                    method: 'get',
                    url: `firms/${user.data.firmId}`
                })
                const clients = await apiRequest({
                    method: 'get',
                    url: `clients?workerId=${user.data.userId}`
                })
                const workerStatistic = await apiRequest({
                    method: 'get',
                    url: `users/workerStats/${user.data.userId}`                  
                })
                
                if(user.status === 200){
                    setWorker(user.data)  
                    setCompany(company.data)
                    setClients(clients.data.pageOfItems)
                    setWorkerStatistics(workerStatistic.data)
                }
            }catch(error){
                console.log(error)
            }
            
        }
        if(localStorage.getItem('role') === 'worker'){
            setRedirect((<Redirect to='/admin/not-allowed' />))
        }else{
            fetchData()
        }
        
    }, [])

    const goToClient = (clientId) => setRedirect((<Redirect to={`/admin/clients/${clientId}`}/>));
    //admin/clients/127

    return(
        <div className='main-content'>
            {redirect}
            <Grid fluid>
                <Row>
                    <Col md={6}>
                        <UserCard 
                            hideHeaderImg
                            avatar={Avatar}
                            description={
                                <span>
                                    <br />
                                        <FormInputs
                                        ncols={['col-md-6', 'col-md-6']}
                                        proprieties={[
                                            {
                                            label: 'Name',
                                            type: 'text',
                                            bsClass: 'form-control',
                                            alignLeft: true,
                                            placeholder: 'Name',
                                            value: worker?.firstName === undefined || worker?.lastName === undefined ? 'Loading' :  worker?.firstName + ' ' + worker?.lastName,
                                            disabled: true,
                                            },
                                            {
                                            label: 'Cell phone',
                                            type: 'text',
                                            bsClass: 'form-control',
                                            alignLeft: true,
                                            placeholder: 'Phone',
                                            value:
                                                worker.phone, 
                                                // && this.formatPhoneNumber(clientData.cellPhone),
                                            disabled: true,
                                            },
                                        ]}
                                        />
                                        <FormInputs
                                        ncols={['col-md-6', 'col-md-6']}
                                        proprieties={[
                                            {
                                            label: 'Email address',
                                            type: 'email',
                                            alignLeft: true,
                                            placeholder: 'Email',
                                            bsClass: 'form-control',
                                            disabled: true,
                                            value: worker.email,
                                            },
                                            {
                                            label: 'Company name',
                                            type: 'text',
                                            bsClass: 'form-control',
                                            alignLeft: true,
                                            placeholder: 'Company',
                                            value: company.name,
                                            //&& this.formatSinNumber(clientData.sin)
                                            disabled: true,
                                            },  
                                        ]}
                                        />
                                        <FormInputs
                                        ncols={['col-md-4', 'col-md-4', 'col-md-4']}
                                        proprieties={[
                                            {
                                            label: 'Country',
                                            type: 'text',
                                            bsClass: 'form-control',
                                            alignLeft: true,
                                            disabled: true,
                                            value: worker?.country
                                                // ', ' +
                                                // clientData.city +
                                                // ', ' +
                                                // clientData.postalCode,
                                            },
                                            {
                                                label: 'Province',
                                                type: 'text',
                                                bsClass: 'form-control',
                                                alignLeft: true,
                                                disabled: true,
                                                value:
                                                    worker?.province?.label
                                                    // ', ' +
                                                    // clientData.city +
                                                    // ', ' +
                                                    // clientData.postalCode,
                                            },
                                            {
                                                label: 'Postal code',
                                                type: 'text',
                                                bsClass: 'form-control',
                                                alignLeft: true,
                                                disabled: true,
                                                value:
                                                    worker?.postalCode
                                                    // ', ' +
                                                    // clientData.city +
                                                    // ', ' +
                                                    // clientData.postalCode,
                                            }
                                        ]}
                                        />
                                </span>
                            }/>
                    </Col>
                    <Col md={6}>
                        <div className="clientStatusRow">
                            <div className="counterBoxes">
                            <div className="counterBoxesRow">
                                <div
                                className="countBox"
                                style={{
                                    backgroundColor: '#EB5757',
                                }}>
                                <span className="counterTxt">
                                    {workerStatistics.new}
                                </span>
                                <span className="statusTxt">New requests</span>
                                </div>
                                <div
                                className="countBox"
                                style={{
                                    backgroundColor: '#F2994A',
                                }}>
                                <span className="counterTxt">
                                    {workerStatistics.pending}
                                </span>
                                <span className="statusTxt">Pending requests</span>
                                </div>
                            </div>
                            <div className="counterBoxesRow">
                                <div
                                className="countBox"
                                style={{
                                    backgroundColor: '#27AE60',
                                }}>
                                <span className="counterTxt">
                                    {workerStatistics.completed}
                                </span>
                                <span className="statusTxt">Completed requests</span>
                                </div>
                                    {/* <div
                                    className="countBox"
                                    style={{
                                        backgroundColor: '#447DF7',
                                    }}>
                                    {/* <span className="counterTxt">{clientData.documentCount}</span> 
                                    <span className="statusTxt">Documents</span>
                                    </div> */}
                            </div>
                            </div>
                        </div>
                        </Col>
                    </Row>
                    <Row className="top30">
                        <Col md={12}>
                        <div className="text-left">
                            <h4>Assigned clients</h4>
                        </div>
                        </Col>
                    </Row>
                    {clients.length !== 0 ? 
                        <Row className="top30">
                            {clients.map((elem, index) => (
                                <Col lg="3" sm="6" key={elem.clientId}>
                                    <Card 
                                    title={elem.firstName && elem.lastName ? elem.firstName + " " + elem.lastName :  elem.email ? elem.email : elem.phone }
                                    textCenter={true}
                                    ctFullWidth={true}
                                    ctTextCenter={true}
                                    content={"#" + elem.clientId}
                                    button={true}
                                    buttonFunction={() => goToClient(elem.clientId)}/>
                                </Col>
                            ))}
                        </Row>
                : null}
                    
            </Grid>
        </div>
    )
}

export default SingleWorker;