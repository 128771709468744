import React, { useState } from 'react'
import './onboarding.css'
import StripeNotification from 'views/StripeNotification/StripeNotif'
import { connect } from 'react-redux'
import { setStripeNotifcation } from 'redux/actions/stripeNotificationActions'

/* Pages */
import PageOne from './Pages/PageOne'
import PageThree from './Pages/PageThree'
import Dashboard from './Pages/Dashboard'
import FirmManagement from './Pages/FirmManagement'
import ManagementConsole from './Pages/ManagementConsole'
import UserManagement from './Pages/UserManagement'
import TaxFiles from './Pages/TaxFiles'
import Messenger from './Pages/Messenger'
import BasicSettings from './Pages/BasicSettings'

const Onboarding = (props) => {
  let [pageCounter, setPageCounter] = useState(2)
  let [page, setPage] = useState(<PageOne />)
  let [showDone, setShowDone] = useState(false)
  const [secondStep, setsecondStep] = useState(false)
  let pages = [
    { id: 1, component: <PageOne />, path: '/' },
    // {id: 2, component: <PageTwo />, path: '/'},
    { id: 2, component: <Dashboard />, path: '/' },
    { id: 3, component: <FirmManagement />, path: '/admin/company-info' },
    { id: 4, component: <ManagementConsole />, path: '/admin/console' },
    { id: 5, component: <UserManagement />, path: '/admin/clients' },
    { id: 6, component: <TaxFiles />, path: '/admin/taxfiles' },
    { id: 7, component: <Messenger />, path: '/admin/messenger' },
    { id: 8, component: <BasicSettings />, path: '/admin/stripe-account' },
    { id: 9, component: <PageThree />, path: '/' },
  ]

  const setNextPage = () => {
    pages.map((elem) => {
      if (elem.id === pageCounter) {
        if (elem.id === 2) setsecondStep(true)
        else setsecondStep(false)
        setPageCounter(pageCounter + 1)
        setPage(elem.component)
        if (elem.id > 2 && elem.id !== 9) {
          props.history(elem.path)
        }
        if (elem.id === 9) {
          setShowDone(true)
        }
      }
      return 0
    })
  }

  const closeModal = () => {
    props.closeModal()
    props.setStripeNotifcation(<StripeNotification />)

  }

  return (
    <div className="blured-back">
      <div className={secondStep ? 'modal-card-second-step' : 'modal-card'}>
        <div className="pages">{page}</div>
        <div className={secondStep ? 'page-list-second-step' : 'page-list'}>
          <p className="page-counter">
            {pageCounter - 1}/{pages.length}
          </p>
          {showDone ? (
            <p className="next-page" onClick={() => closeModal()}>
              Done
            </p>
          ) : (
            <p className="next-page" onClick={setNextPage}>
              Next
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setStripeNotifcation: (state) => dispatch(setStripeNotifcation(state))
})

export default connect(null, mapDispatchToProps)(Onboarding)
