import React from 'react'
import { Modal } from 'react-bootstrap';
import Button from 'components/CustomButton/CustomButton';
import './company-card.css'

const ThemModal = (props) => {
    return (
        <Modal show={true} >
            <Modal.Header style={{backgroundColor: '#58A4B0', borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}>
            <span className="modal-title customTitle" style={{ color: '#FFF' }}>
                Selected theme
            </span>
            <div className="pull-right">
                <Button bsStyle="primary" fill onClick={() => props.closeModal()} className='theme-close-button'>
                <span className="btn-label">
                    <i className="fa fa-times" />
                    {'  '}
                </span>
                <span style={{ fontWeight: 'bold' }}>Close</span>
                </Button>
            </div>
            </Modal.Header>
            <Modal.Body className='themeModal'>
                <img src={props.theme.themeImage} className="theme-image"/>
                <p className='theme-name'>{props.theme.themeName}</p>
            </Modal.Body>
        </Modal>

    )
}

export default ThemModal

const style={
    themeImage: {
        height: '30px',
        width: '50%'
    }
}
