import React, { Component } from 'react'
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'

import Card from 'components/Card/Card.jsx'
import Button from 'components/CustomButton/CustomButton.jsx'
import SweetAlert from 'react-bootstrap-sweetalert'

import SetAccountInfoModal from 'views/Components/SetAccountInfoModal'
import Alert from 'components/Alert/Alert'

import { Redirect } from 'react-router-dom'
import { apiRequest } from 'helpers'

class WorkerFirstLogin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cardHidden: true,
      email: this.props.match.params.email,
      password: '',
      redirect: null,
      alert: null,
      user: null,
      accountInfoModal:null,
      token: null,
      notifAlert: null
    }
  }
  componentDidMount() {
    //eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpbnZpdGUiOiJpbnZpdGVUb2tlbiIsImlhdCI6MTU4OTQ1Njc1NX0.1QM3h57E6AnfFgyhMMrzCi4aF6ymROG0oerA9I_Us7w
    console.log('== PARAMS ==')
    console.log(this.props.match.params)
    const { token } = this.props.match.params
    if (!token) {
      // REDIRECT IF THERE IS NO TOKEN
      this.setState({ redirect: <Redirect to="/auth/login-page" /> })
      return
    }

    this.setState({ token })
    setTimeout(
      function () {
        this.setState({ cardHidden: false })
      }.bind(this),
      700
    )
    
  }

  submitLogin = async () => {
    //e.preventDefault()

    const { email, password } = this.state
    const data = {
      email,
      password,
    }

    try {
      const login = await apiRequest({
        url: 'users/login',
        method: 'post',
        data,
      })

      if (login.status === 200) {
        console.log('Logged in')
        console.log(login)

        localStorage.setItem('usertoken', login.data.token)

        const user = await apiRequest({
          method: 'post',
          url: 'users/getByToken',
          data: {
            token: login.data.token,
          },
        })

        if (user.status === 200) {
          const company = await apiRequest({
            method: 'get',
            url: `firms/${user.data.firmId}`,
          })
          switch(user.data.userTypeId){
            case 1: 
              localStorage.setItem('role', 'admin')
              break;
            case 2:
              localStorage.setItem('role', 'worker')
              break;
            default: localStorage.setItem('role', 'none')
          }
          
          localStorage.setItem('companyCode', company.data.inviteCode)
          localStorage.setItem('companyId', user.data.firmId)
          localStorage.setItem('userId', user.data.userId)
          localStorage.setItem('senderId', user.data.senderId)
          localStorage.setItem('username', user.data.firstName + ' ' + user.data.lastName)
          localStorage.setItem('firstLogin', true)
          //localStorage.setItem('companyCode', this.props.match.params.companyCode )
          localStorage.setItem('notification', true)
          this.setState({ redirect: <Redirect to="/admin/dashboard" /> })
          window.location.reload()
        }
      }
    } catch (err) {
      if (err.response.status === 404) {
        this.errorAlert("That account doesn't exist.")
      } else if (err.response.status === 401) {
        this.errorAlert('Your account is disabled.')
      } else {
        this.errorAlert('Invalid email or password. Please try again.')
      }
    }
  }

  checkAccount = async () => {
    //e.preventDefault()

    const { email, token } = this.state
    const data = {
      email,
      token,
    }

    try {
      const checkAccount = await apiRequest({
        url: 'users/setAccount',
        method: 'post',
        data,
      })
      if (checkAccount.status === 200) {
        console.log('Logged in')
        console.log(checkAccount)
        this.setState({ user: checkAccount.data })
      }
    } catch (err) {
        this.errorAlert(err.response.data.message)
    }
  }

  createProfile = async (firstName, lastName, dob) => {
    const { password, user } = this.state
    if (user) {
      const data = {
        userId: user.userId,
        // firstName,
        // lastName,
        // dob,
        password,
      }

      try {
        const response = await apiRequest({
          method: 'put',
          url: 'users/setAccountInfo',
          data,
        })

        console.log('== UPDATE RESPONSE ==')
        console.log(response)
        if (response.status === 200) {
          localStorage.clear()
          this.submitLogin()
        }
      } catch (err) {
        this.errorAlert(err.response.data.message)
      }
    }
  }

  errorAlert = (text) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{
            display: 'block',
            marginTop: '-100px',
          }}
          title="Error"
          onConfirm={() => this.hideAlert()}
          confirmBtnBsStyle="info">
          {text}
        </SweetAlert>
      ),
    })
  }

  hideAlert() {
    this.setState({
      alert: null,
    })
  }

  openUserDataModal = () => {
    const { password } = this.state
    if (password === '') {
      this.errorAlert('Password field cannot be empty.')
      return
    }

    if (password.length < 6) {
      this.errorAlert('Your password is below minimum length. Minimum password length is 6.')
      return
    }

    this.setState({
      accountInfoModal: (
        <SetAccountInfoModal
          createProfile={this.createProfile}
          removeModal={this.removeAccountModal}></SetAccountInfoModal>
      ),
    })
  }

  checkPassword = () => {
    const { password } = this.state
    var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
    if(password.length < 6){
      this.setState({notifAlert: (<Alert message='Password needs to be at least 6 characters long.'/>)})
      setTimeout(() => {
        this.setState({notifAlert: null})
      }, 5000)
    }else if(!mediumRegex.test(password)){
      this.setState({notifAlert: (<Alert message='Password needs to have al least one special character, one upper case and one number'/>)})
      setTimeout(() => {
        this.setState({notifAlert: null})
      }, 5000)
    }else{
      this.createProfile()
    }
  }

  removeAccountModal = () => {
    this.setState({ accountInfoModal: null })
  }


  render() {
    const { user, notifAlert, alert } = this.state
    return (
      <>
       {notifAlert}
      <Grid>
        {alert}
        {this.state.redirect}
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                title="Enter your email address"
                content={
                  user ? (
                    <div>
                      <FormGroup>
                        <ControlLabel>Email address</ControlLabel>
                        <FormControl
                          placeholder="Enter email"
                          type="email"
                          onChange={(e) => this.setState({ email: e.target.value })}
                        />
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel>Password</ControlLabel>
                        <FormControl
                          placeholder="Password"
                          type="password"
                          onChange={(e) => this.setState({ password: e.target.value })}
                          onKeyPress={event => {
                            if (event.key === 'Enter') {
                              this.checkPassword()
                            }
                          }}
                        />
                      </FormGroup>
                    </div>
                  ) : (
                    <div>
                      <FormGroup>
                        <ControlLabel>Email address</ControlLabel>
                        <FormControl
                          placeholder="Enter email"
                          type="email"
                          value={this.state.email}
                          onChange={(e) => this.setState({ email: e.target.value })}
                          onKeyPress={event => {
                            if (event.key === 'Enter') {
                              this.checkAccount()
                            }
                          }}
                        />
                      </FormGroup>
                    </div>
                  )
                }
                legend={
                  <Button
                    st
                    bsStyle="info"
                    fill
                    wd
                    className="loginButton"
                    onClick={() => (!user ? this.checkAccount() : this.checkPassword())}
                    style={{backgroundColor: '#58A4B0'}}>
                    {!user ? 'Start' : 'Proceed'}
                  </Button>
                }
                ftTextCenter
              />
            </form>
          </Col>
              </Row>
        {this.state.accountInfoModal} 
      </Grid>
      </>
    )
  }
}

export default WorkerFirstLogin
