import React, { useEffect, useState } from 'react'

import { Col, Row, FormControl, Button } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { ReactComponent as Loader } from 'assets/img/loader-preview.svg'

import trueImg from 'assets/img/true.png'
import caseImg from 'assets/img/case.png';

import { apiRequest } from 'helpers';
import UpgradeSubscriptionModal from '../Components/UpgradeSubscriptionModal';
import './subscriptions.css';
import TaxPaymentModal from 'views/Components/TaxPaymentModal';
import Tooltip from '@material-ui/core/Tooltip';

const Subscription = () => {

    const [subscriptions, setSubscriptions] = useState({})
    const [taxPaymentModal, setTaxPaymentModal] = useState(null)
    const [showPlus, setShowPlus] = useState(true)
    const [alert, setAlert] = useState(null)
    const [loading, setLoading] = useState(null)
    const [metadata, setMetadata] = useState(null)
    const [subscriptionModal, setSubscriptionModal] = useState()
    const [taxRequestAmount, setTaxRequestAmount] = useState("")
    const [taxPrice, setTaxPrice] = useState("")
    const [requestCount, setRequestCount] = useState({})
    const [requestQuantity, setRequestQuantity] = useState(0)
    const [showRestBoxes, setShowRestBoxes] = useState(true)
    const [boxAmounts, setBoxAmounts] = useState([
        {id:1, amount: 5, active: true},
        {id:2, amount: 10, active: false},
        {id:3, amount: 15, active: false},
    ])

    useEffect(() => {
        fetchSubscriptions()
        fetchRequestCount()
    }, [])

    const closeAlert = () => {
        setAlert(null)
    }
    const fetchRequestCount = async () => {
        try{
            const counts = await apiRequest({
                method: 'get',
                url: 'firms/getCurrentRequestCount'
            })
            if(counts.status === 200){
                setTaxRequestAmount(counts.data.price * 5)
                setTaxPrice(counts.data.price )
                setRequestCount(counts.data)
                setRequestQuantity(5)
            }
        }catch(err){
            setAlertMessage('danger', err.response.data.message)
        }
    }
    const setAlertMessage = (status, message) => {
        setAlert((
            <SweetAlert
                type={status}
                style={{
                display: 'block',
                marginTop: '-100px',
                }}
                title={message}
                onConfirm={() => closeAlert()}
                confirmBtnBsStyle="info">
            </SweetAlert>
        ))
    }
    const setLoadingAnimation = () => {
        setLoading( 
            <SweetAlert
                style={{
                display: 'block',
                marginTop: '-100px',
                }}
                title="Loading"
                showConfirm={false}>
                <div>
                    <Loader style={{ height: 75, width: 100 }} />
                </div>
            </SweetAlert>)
    }

    const fetchSubscriptions = async() => {
        try{
            const subscriptions = await apiRequest({
                method: 'get',
                url: 'subscriptions/info'
            })
            if(subscriptions.status === 200){
                setSubscriptions(subscriptions.data)
            }
        }catch(err){
            console.log('===== ERROR= ====')
            console.log(err)
        }
    }

    const buyTaxRequests = async () => {
        setTaxPaymentModal(null)
        setLoading( 
            <SweetAlert
                style={{
                display: 'block',
                marginTop: '-100px',
                }}
                title="Loading"
                showConfirm={false}>
                <div>
                    <Loader style={{ height: 75, width: 100 }} />
                </div>
            </SweetAlert>)
        const data = {amount: requestQuantity}
        try{
            const response = await apiRequest({
                method: 'post',
                url: 'firms/purchase/taxCredit',
                data
            })
            if(response.status === 200){
                setLoading(null)
                setTimeout(() => {setAlertMessage('success', 'Done')}, 200)
                fetchRequestCount() 
            }
        }catch(err){
            setLoading(null)
            setTimeout(() => {setAlertMessage('danger', err.response.data.message || err.response.data.msg )}, 200)
        }
    }
    
    const upgradePlan = async() => {
        setLoadingAnimation()
        try{
            const subsPlans = await apiRequest({
                method: 'get',
                url: 'subscriptions/upgradePlans'
            })
            if(subsPlans.status === 200){
                const data = {};
                subsPlans.data.forEach(elem => {
                    console.log(elem)
                   if(elem.name === 'Premium Plan'){
                        data['upgradeTo'] = elem.priceId
                   }
                })
                 const upgradePlan = await apiRequest({
                    method: 'post',
                    url: `subscriptions/upgrade`,
                    data
                })
                if(upgradePlan.status === 200){
                    setLoading(null)
                    setSubscriptionModal(null)
                    setTimeout(() => { setAlertMessage('success', 'Done!'); fetchSubscriptions();}, 100)
                }
            }
        }catch(err){
            setAlertMessage('danger', err.response.data.message || err.response.data.msg )
        }
    }

    const openUpgradeModal = () => {
        if(subscriptions.product.name === 'Premium Plan'){
            console.log(subscriptions.product.name)
            setAlertMessage('info', 'You already have premium plan')
        }else{
            setSubscriptionModal(
                <UpgradeSubscriptionModal 
                    upgrade={() => upgradePlan()}
                    closeModal={() => setSubscriptionModal(null)}/>)
        }
      
    }
    const setTaxAmount = (box) => {
        const boxes = [...boxAmounts]
        boxes.forEach(elem => elem.active = false)
        boxes.forEach(elem => {
            if(elem.id === box){
                elem.active = true
                setTaxRequestAmount(taxPrice * elem.amount)
                setRequestQuantity(elem.amount)
            }
        })
        setBoxAmounts([...boxes])
    }
    const getValue = (e) => {
        const boxes = [...boxAmounts]
        if(+e.target.value < 15){
            setAlertMessage('info', 'You can not set value lower than 15!')
        }else{
            setTaxRequestAmount(taxPrice * +e.target.value)
            setRequestQuantity(+e.target.value)
            setShowPlus(true)
            setShowRestBoxes(true)
            boxes.forEach(elem => elem.active = false)
            setBoxAmounts([...boxes])
        }
    }
    const confirmAndPay = () => {
        const data = {
            amount: taxRequestAmount,
            paymentDetails: requestCount,
            requestQuantity
        }
        setTaxPaymentModal(<TaxPaymentModal data={data} closeModal={() => setTaxPaymentModal(null)} buyTax={buyTaxRequests}/>)
    }
    const showBoxesAndPlus = () => {
        setShowPlus(false)
        setShowRestBoxes(false)
    }
    const renderContent = () => {
        if(Object.keys(subscriptions).length > 0){
            let metadata = {}
            
            if(subscriptions.product.name === 'Standard Plan'){
                metadata = {
                    banner: 'App default banner inside app',
                    color: 'App default color',
                    findFirm: 'Includes basic find my firm dot',
                    taxRefunds: 'Minimum 200 all upfront user purchase'
                }
            }else if(subscriptions.product.name === 'Premium Plan'){
                metadata = {
                    banner: 'Customized firm logo banner inside the app',
                    color: 'Select own colors from color wheel',
                    findFirm: 'Includes bold icon find my firm',
                    taxRefunds: 'Minimum 300 annual user purchase'
                }
            }

            return (
                <div>
                    <Row className='package-info-row'>
                        <Col lg={6} md={6}>
                            <div className='plan-info-container'>
                                <div>{subscriptions.product.name.split(' ')[0]}</div>
                                <div>{subscriptions.product.name.split(' ')[1]}</div>
                            </div>
                            <div className='plan-details-container'>
                                {Object.keys(metadata).map((elem, index) => (
                                    <div className='image-detail' key={index}>
                                        <img src={trueImg} alt='True'/>
                                        <div>{metadata[`${elem}`]}</div>
                                    </div>
                                ))}
                            </div>
                        </Col>
                        <Col lg={6} md={6} className='col-price'>
                            <div className='price-container'>
                                <div className='price'>
                                    <p>$</p>
                                    <p>{subscriptions?.plan?.amount}</p>
                                    <p>/year</p>
                                </div>
                                <div className='notication-plan'>
                                    <p>Next payment on {subscriptions?.nextPaymentDate}</p>
                                </div>
                                <div className='upgrade-button'>
                                    <Button onClick={() => openUpgradeModal()} className='upgrade-plan-button'>Upgrade plan <img src={caseImg} style={{marginLeft: '10px', height: '15px', width: '15px'}}/></Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                <Row 
                    className='taxes-row'>
                        <Col md={6} className='taxes-row-left'>
                            <p className='add-more-head'>Add more tax requests</p>
                            <p className='requests-counter'>{requestCount?.current}/{requestCount?.initial} Tax requests</p>
                        </Col>
                        <Col md={6}className='col-dvica'>
                            <p className='add-10-more'>Add {requestQuantity} more tax request for:</p>
                            <p className='counter-value'>${taxRequestAmount}</p>
                            <div className='boxes-holder'>
                                {/*If user is entering his value, hide other boxes */}
                                {showRestBoxes ? 
                                    boxAmounts.map(elem => (
                                        <div className={elem.active ? 'price-box box-active': 'price-box'} key={elem.id} onClick={() => setTaxAmount(elem.id)}>{elem.amount}</div>
                                )) : null}
                                {showPlus ? (
                                    <div className='plus-minus'>
                                        <Tooltip title='Add requests' arrow={true}>
                                            <div className='price-box' onClick={() => showBoxesAndPlus()}>+</div>
                                        </Tooltip>
                                        <Tooltip title='Reset' arrow={true}>
                                            <div className='price-box' onClick={() => fetchRequestCount()}><i class="fa fa-trash" aria-hidden="true"></i></div>
                                        </Tooltip>
                                    </div>
                            
                                
                                ) : (
                                    <Tooltip title="Add" arrow disableHoverListener={true} open={true} title='Hit enter to save'>
                                    <FormControl
                                        className="modalTitleBox"
                                        type="text"
                                        style={{
                                            width: '50px',
                                            height: '50px'
                                        }}
                                        onKeyPress={event => {
                                            if (event.key === 'Enter') {
                                            getValue(event)
                                            }
                                        }}
                                    />
                                    </Tooltip>
                                )}
                            </div>
                            <div className='confirm-button-cont'>
                                <Button className='confirm-button' onClick={confirmAndPay}>Confirm & pay</Button>
                            </div>
                        </Col>
                       
                </Row> 
                </div>
            )
        }else{
            return (
                <SweetAlert
                    style={{
                    display: 'block',
                    marginTop: '-100px',
                    }}
                    title="Loading"
                    showConfirm={false}>
                    <div>
                        <Loader style={{ height: 75, width: 100 }} />
                    </div>
              </SweetAlert>
            )
        }
       
    }

    return (
        <div style={{minHeight: '90vh'}}>
        {renderContent()}
        {subscriptionModal}
        {alert}
        {taxPaymentModal}
        {loading}
        </div>
    )
}

export default Subscription
