import React from 'react'

const PageOne = (props) => {
  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <p style={{ color: '#79adb7', fontSize: '20px', fontWeight: '400' }}>
          You are now ready to work with your clients
        </p>
        <p style={{ fontWeight: '300', marginTop: '25px' }}>
          Check your profile information. If everything's okay you can
          start tax returning. <br /> If you have any questions please contact our Customers
          service!
        </p>
      </div>
    </div>
  )
}

export default PageOne
