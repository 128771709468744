import React, {useState, useEffect} from 'react'
import './form-answers.css';

import moment from 'moment'
import {Row, Col} from 'react-bootstrap';
import { Redirect, Link } from 'react-router-dom';


import  AnswersData  from './AnswersData';
import apiRequest  from 'helpers/apiRequest';
import Checkbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomRadio from 'components/CustomRadio/CustomRadio';
import FormInputs from 'components/FormInputs/FormInputs';
import arrowBack from 'assets/img/icons/arrow_back.png'

const App=(props)=>{

  const [answers, setAnswers] = useState([])
  const [tabStyle, setTabStyle] = useState('hide')
  const [redirect, setRedirect] = useState(null)
  const [open, setOpen] = useState(false)
  const [iconClass, setIconClass] = useState(['fa', 'fa-angle-up', 'icon'])

  const formId = props.match.params.formId
  const requestId = props.match.params.requestId

  useEffect(() => {
    const role = localStorage.getItem('role')
    if(role === 'superAdmin' ){
      setRedirect(<Redirect to='/admin/not-allowed/' />) 
    }

    const getClientAnswers = async () => {
      const response = await apiRequest({
        method: 'get',
        url: `answers/getByFormIdRequestId/?requestId=${requestId}&formId=${formId}`,
      })
  
      if (response.status === 200) {

        const documents = await apiRequest({
          method: 'get',
          url: `requests/document?requestId=${requestId}&&documentName=T3 Dividend, Interest Income`,
        })
        console.log("%c DOCUMENT", 'color: red')
        console.log(response);
        setAnswers(response.data.answersJSON)
      }
    }

    getClientAnswers()
  }, [])

  const showTabs = (pageKey, tabKey) => {
    let isOpen = false;
    var tabElement = document.getElementsByClassName('tab-page')[pageKey].children[tabKey].children[1].classList
    if(!isOpen){
      setIconClass([...iconClass, 'open'])
    }else{
      setIconClass(['fa', 'fa-angle-up', 'icon'])
    }
    isOpen = !isOpen

    if(tabElement[1] === 'hide'){
      document.getElementsByClassName('tab-page')[pageKey].children[tabKey].children[1].classList.remove('hide')
      document.getElementsByClassName('tab-page')[pageKey].children[tabKey].children[1].classList.add('show')
    }else{
      document.getElementsByClassName('tab-page')[pageKey].children[tabKey].children[1].classList.remove('show')
      document.getElementsByClassName('tab-page')[pageKey].children[tabKey].children[1].classList.add('hide')
    }
  }



  const mapData =()=>(
    answers.map((page,pageKey)=>
      <div 
        key={pageKey} 
        className='tab-page'>
        {page.tabs.map((tab,tabKey)=>
          <div  
            key={tabKey} 
            className='tab-field' 
            onClick={ () => showTabs(pageKey,tabKey)}  >
              <h3
                className='field-label'>
                {tab.tabName}
                <i className={iconClass.join(' ')} 
                aria-hidden="true"></i>
              </h3>
              <div 
                className='tab-content hide' 
                id='menu'>
                  {tab.fields.map((field,fieldKey)=>
                    <div 
                    key={fieldKey}  
                    className='field'>
                      <p 
                        className={field.selected ? 'green-border field-label-checkbox' : 'field-label-checkbox'}>
                        {field.label}
                      </p>
                      <div className='checkboxes'>      
                        {field.type==='checkbox-group'?
                          <div>{renderCheckBox(field.selected)}</div>:
                            field.type==='radio-group'?
                          <div>{renderRadio(field.values,field.selectedValue,field.label)}</div>:
                            field.type==='text'?
                          <div>{renderText(field.value)}</div>:
                            field.type==='date'?
                          <div>{renderDate(field.value)}</div>:
                            field.type==='dependant-children'?
                          <div>{renderDependantChildren(field.value)}</div>:
                            <div>yes</div>
                        }
                      </div>
                </div>
          )}
          </div>

          </div>)}
        </div>
  ))
  const renderText=(value)=><input type='text' value={value} readOnly className='date-input'  />
  const renderDate=(value)=>{
    if(value === "" || value === null ){
      return 'No date set'
    }
    return<input type='text' value={moment.utc(value).format('L')} readOnly className='date-input'/>
  }
  //value !== "" || value !== null ? <input type='text' value={moment.utc(value).format('L')} readOnly className='date-input'/> : 'No change'
  const renderRadio=(radioGroup,value,name)=>(
    <div className='cont-radio-label'>
    {radioGroup.map((el,idx)=>
    <div key={idx} className='radio-label-cont'>
      <CustomRadio label={el.label} checked={value===el.value}/>
    </div>)}
    </div>
    )
  const renderDependantChildren=(childrenArr)=>(
  <div>
    {childrenArr || [].map((child,idx)=>
    <div key={idx}>
      <label>Name</label>
      <input type='text' value={child.name} readOnly/>
      <label>DOB</label>
      <input type='text' value={child.dob} readOnly className='dob-input' />
      <label>Sin</label>
      <input type='text' value={child.sin} readOnly/>
    </div>)}
  </div>)
  const renderCheckBox = (value) =>
    <Checkbox isChecked={value}/>
  return (
    <div  className='answers-page-container'>
      <div className='request-info' id='answers-info'>
              <p className='client-answ-back'><Link to={`/admin/requests/${requestId}`}><img src={arrowBack} className="arrow-img-back" alt='back'/></Link>Client answers</p>
            </div>
      {redirect}
      {mapData()}
    </div>
  )
}
export default App
//<input type='checkbox' checked={value} readOnly/>