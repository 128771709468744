import React from 'react'
import '../onboarding.css';

const PageOne = (props) => {
    return (
        <div style={{textAlign: 'center'}}>
            <p style={{color: '#79adb7', fontSize: '20px', fontWeight: '400'}}>Welcome to MyMobileTax Dashboard</p>
            <p style={{fontWeight: '300', marginTop: '15px'}}>Hello, <span style={{fontWeight: '400'}}>{localStorage.getItem('username')}</span></p>
            <p style={{fontWeight: '300'}}>We are glad to have you on board. You can now invite your clients. Tell your clients to use your <span style={{color: '#79adb7'}}>Company Code</span>, you can see it in top right corner.</p>
        </div>
    )
}

export default PageOne
