/*eslint-disable*/
import React, { Component } from 'react'
// react component for creating dynamic tables
import ReactTable from 'react-table'
import { Grid, Row, Col, FormControl, FormGroup, InputGroup } from 'react-bootstrap'

import Card from 'components/Card/Card.jsx'
import Button from 'components/CustomButton/CustomButton.jsx'
import AssignClientToWorker from 'views/Components/AssignClientToWorker';

import CreateClientProfileModal from 'views/Components/CreateClientProfileModal'
import SweetAlert from 'react-bootstrap-sweetalert'

import { withRouter, Link, Redirect } from 'react-router-dom'
import { ReactComponent as Loader } from 'assets/img/loader-preview.svg'

import { apiRequest } from 'helpers'

class Clients extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: this.props.data,
      loading: false,
      pages: 0,
      searchParam: '',
      data: [],
      tableState: null,
      createProfileModal: null,
      alert: null,
      redirect: null,
      assignClientToWorker: null,
      workers: [],
      loding: null
    }
  }

  componentDidMount(){
    if(localStorage.getItem('role') === 'superAdmin'){
        this.setState({
          redirect : <Redirect to='/admin/not-allowed' />
        })
    }
  }

  getClients = async (page = 0, pageSize = 10, sorted, filtered) => {
    console.log('PAGE KLIJENATA')
    console.log(page)
    console.log(pageSize)

    let orderId = null
    let orderType = null
    let searchParam = this.state.searchParam

    if (sorted && sorted.length > 0) {
      orderId = sorted[0].id
      orderType = sorted[0].desc ? 'DESC' : 'ASC'
    }

    if (orderId === null && orderType === null) {
      orderId = 'firstName'
      orderType = 'ASC'
    }

    const response = await apiRequest({
      method: 'get',
      url: `clients?page=${
        page + 1
      }&pageSize=${pageSize}&order=${orderId}&orderType=${orderType}&searchQuery=${searchParam}`,
    })

    if (response.status === 200) {
      console.log('Response')
      console.log(response)
      /* if (searchParam !== '') {
        /* this.setState({
          data: response.data.pageOfItems,
          pages: response.data.pager.totalPages,
        })
        this.refReactTable.fireFetchData()
      }*/
      return response
    }
  }
  onFetchData = async (state, instance = null) => {
    this.setState({ loading: true })
    console.log('STATE')
    console.log(state)
    const res = await this.getClients(state.page, state.pageSize, state.sorted, state.filtered)

    if (res) {
      let data = res.data.pageOfItems.map((item, key) => {
        console.log('============== CLIENTS ===========')
        console.log(item)
        return {
          id: item.clientId,
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.email,
          phone: item.cellPhone,
          sin: item.sin,
          actions: (
            // we've added some custom button actions
            <div >
              {/* use this button to add a like kind of action */}
              <Link to={`/admin/clients/${item.clientId}`}>
                <Button bsStyle="primary">Open</Button>
              </Link>{' '}
            </div>
          ),
          assign: (
            localStorage.getItem('role') === 'admin' ?
              item.workerId ? 
                <div className="actions-right">
                    <Button 
                    className='btn-fill btn-primary' 
                    onClick={() => this.setAssignAlert(item.clientId)}>Assigned</Button>
                </div>
              : 
                <div className="actions-right">
                  <Button bsStyle="primary" onClick={() => this.setAssignAlert(item.clientId)}>Assign</Button>
                </div>
            : null
          ),
          assigned: item.worker !== null ? item.worker.firstName + ' ' + item.worker.lastName : 'Not assigned'
        }
      })

      this.setState({
        data,
        pages: res.data.pager.totalPages,
        loading: false,
        tableState: state,
      })
    }
  }

  closeModal = () => {
    this.setState({assignClientToWorker: null})
  }
  setLoading = () => {
      this.setState({
        loding: (  
          <SweetAlert
              style={{
              display: 'block',
              marginTop: '-100px',
              }}
              title="Loading"
              showConfirm={false}>
              <div>
                  <Loader style={{ height: 75, width: 100 }} />
              </div>
        </SweetAlert>)
      })
  }

  openAssignClientModal = (clientId) => {
    this.setState({
      alert: null,
      assignClientToWorker: (<AssignClientToWorker clientId={clientId} closeModal={this.closeModal} sucessAlert={() => this.addSuccessAllert()}/>)})

  }

  setAssignAlert = (clientId) => {
    this.setState({
      alert: (
        <SweetAlert
        warning
        style={{ display: 'block', marginTop: '-100px' }}
        title="Are you sure you want to reassign client?"
        onConfirm={() => this.openAssignClientModal(clientId)}
        onCancel={() => this.setState({alert: null})}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes"
        cancelBtnText="Cancel"
        showCancel></SweetAlert>
      )
    })
  }

  addSuccessAllert = () =>{
     this.setState({
       alert: (
        <SweetAlert
          success
          style={{
            display: 'block',
            marginTop: '-100px',
          }}
          title="Assigned"
          onConfirm={() => this.hideAlert(true)}
          confirmBtnBsStyle="info">
      </SweetAlert>
       )
     })
  }


  createProfile = async (firstName, lastName, email, phone ) => {
    const data = {
      email,
      firstName, 
      lastName,
      phone,
      code : localStorage.getItem('companyCode')
    }
    try {
      const response = await apiRequest({
        url: 'clients/createClientByUser',
        method: 'post',
        data,
      })
      if (response.status === 200) {
        this.successAlert('New profile for client has been created.')
        console.log(response)
      }
    } catch (err) {
      console.log('==ERROR==')
      this.errorAlert(err.response.data.message)
      console.log(err)
   }
  }

  removeModal = () => {
    this.setState({ createProfileModal: null })
  }
  

  /* SUCCESS AND ERROR ALERTS */
  successAlert(msg = '') {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{
            display: 'block',
            marginTop: '-100px',
          }}
          title="Done"
          onConfirm={() => this.hideAlert(true)}
          confirmBtnBsStyle="info">
          {msg}
        </SweetAlert>
      ),
    })
  }

  errorAlert(message = 'There was an error. Please try again.') {
    this.setState(
      {
        alert: (
          <SweetAlert
            danger
            style={{
              display: 'block',
              marginTop: '-100px',
            }}
            title="Error"
            onConfirm={() => this.hideAlert()}
            confirmBtnBsStyle="info">
            {message}
          </SweetAlert>
        ),
      },
      () => {
        this.removeModal()
      }
    )
  }

  hideAlert(reloadData = false) {
    this.setState(
      {
        alert: null,
      },
      () => {
        if (reloadData) {
          this.removeModal()
          this.onFetchData(this.state.tableState)
        }
      }
    )
  }

  render() {
    const { data, pages, loading, redirect } = this.state
    console.log('DATA')
    console.log(data)
    return (
      <div className="main-content">
        {this.state.alert}
        {redirect}
        <Grid fluid>
          <Row style={{ marginBottom: 10 }}>
            <Col md={6}>
              <div className="text-left">
                <span className="title customTitle">Clients Management Console</span>
              </div>
            </Col>
            <Col md={6}>
              <div className="text-right">
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Addon>
                      <i className="fa fa-search" />
                    </InputGroup.Addon>
                    <FormControl
                      onChange={(event) => {
                        console.log('CHANGE: ', event.target.value)
                        this.setState({ searchParam: event.target.value }, () => {
                          this.onFetchData(this.state.tableState)
                        })
                      }}
                      type="text"
                      placeholder="Search..."
                    />
                  </InputGroup>
                </FormGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div>
                    <Row style={{ marginBottom: 10 }}>
                      <Col md={6}>
                        <div className="text-left">
                          <h4 className="title">Clients List</h4>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="text-right">
                          <Button
                            bsStyle="primary"
                            fill
                            onClick={() => {
                              this.setState({
                                createProfileModal: (
                                  <CreateClientProfileModal
                                    removeModal={this.removeModal}
                                    createProfile={
                                      this.createProfile
                                    }></CreateClientProfileModal>
                                ),
                              })
                            }}>
                            <span className="btn-label">
                              <i className="fa fa-plus-square" />{' '}
                            </span>
                            Create new profile
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <ReactTable
                      data={data}
                      ref={(refReactTable) => {
                        this.refReactTable = refReactTable
                      }}
                      filterable
                      columns={[
                        {
                          Header: 'First Name',
                          sortable: true,
                          filterable: false,
                          accessor: 'firstName',
                        },
                        {
                          Header: 'Last Name',
                          sortable: true,
                          filterable: false,
                          accessor: 'lastName',
                        },
                        {
                          Header: 'Email',
                          sortable: false,
                          filterable: false,
                          accessor: 'email',
                        },
                        {
                          Header: 'Cell Phone',
                          sortable: false,
                          filterable: false,
                          accessor: 'phone',
                        },
                        {
                          Header: 'SIN',
                          sortable: false,
                          filterable: false,
                          accessor: 'sin',
                        },
                        {
                          Header: 'Actions',
                          accessor: 'actions',
                          sortable: false,
                          filterable: false,
                        },
                        {
                          Header: 'Assigned to',
                          accessor: 'assigned',
                          sortable: false,
                          filterable: false,
                        },
                        {
                          Header: 'Assign client',
                          accessor: 'assign',
                          sortable: false,
                          filterable: false,
                        },
                      ]}
                      defaultPageSize={10}
                      //showPaginationTop
                      pages={pages}
                      manual
                      showPaginationBottom={true}
                      loading={loading}
                      showPagination={true}
                      onFetchData={this.onFetchData}
                      className="-striped -highlight"
                    />
                  </div>
                }
              />
            </Col>
          </Row>
          {this.state.createProfileModal}
          {this.state.assignClientToWorker}
          {this.state.loding}
        </Grid>
      </div>
    )
  }
}

export default Clients
