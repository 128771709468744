import axios from 'axios'
import Config from 'config'
import getToken from './getToken'

export default async (request) => {
  console.log('API URL::: ')
  console.log(Config.APP_API_URL)
  request.method = request.method || 'get'
  request.url = `${Config.APP_API_URL}/${request.url}`
  request.data = request.data || {}
  console.log('URL: ', request.url)
  if (request.data && request.method === 'get') {
    // If data is set the get request won't be made
    request.data = null
  }

  //const accessToken = await Storage.getItem('access_token')
  const accessToken = await getToken()
  console.log('=== ACCESS TOKEN ==')
  console.log(accessToken)
  if (accessToken) {
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${accessToken}`,
    }
  }
  console.log('Ovo je request')
  console.log(request)
  return axios(request)
}
