import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import Button from 'components/CustomButton/CustomButton';
import AsyncSelect from 'react-select'

import apiRequest from 'helpers/apiRequest';

const SearchCompanyModal = (props) => {
    const [companies, setCompanies] = useState([])

    const fetchCompanies = (val) => {
        const fetchData = async () => {
            try{
                const companies = await apiRequest({
                    method: 'get',
                    url: `firms?approved=true&searchQuery=${val}`
                })
                if(companies.status === 200){
                    const options = []
                    options.push({value: null, label: 'All companies'})
                    companies.data.pageOfItems.forEach(elem => {
                        options.push({value:elem.id, label: elem.name})
                    })
                    setCompanies([...options])
                }
            }catch(err){
                console.log(err)
            }
        }
        fetchData()  
    }
    useEffect(() => {
        const options = []
        options.push({value: null, label: 'All companies'})
        setCompanies([...options])
    }, [])

    return (
        <Modal show={true} onHide={props.closeModal}>
              <Modal.Body>
                 <Modal.Title>Choose company</Modal.Title>
                 <AsyncSelect 
                    options={companies}
                    onInputChange={(val) => fetchCompanies(val)}
                    onChange={(val) => props.getSelectedCompany(val)}
                 ></AsyncSelect>
              </Modal.Body>
              <Modal.Footer>
                <Button className='btn-fill' onClick={props.closeModal}>Cancel</Button>
              </Modal.Footer>
            </Modal>
    )
}

export default SearchCompanyModal
