import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
// react component used to create alerts

import Card from 'components/Card/Card.jsx'

import Tasks from 'components/Tasks/Tasks.jsx'
import moment from 'moment'

class NotificationsActivity extends Component {
  constructor(props) {
    super(props)
    this.state = {
      alert: null,
      lastUpdateTime: moment(),
    }
  }

  lastUpdateTime = (time) => {
    this.setState({ lastUpdateTime: time })
  }

  render() {
    const { lastUpdateTime } = this.state
    return (
      <div className="main-content">
        {this.state.alert}
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="All notifications"
                category="This list is refreshed on every new update"
                content={
                  <table className="table">
                    <Tasks lastUpdateTime={this.lastUpdateTime} showAllNotifications={true} />
                  </table>
                }
                stats={
                  <div>
                    <i className="fa fa-history" /> Updated{' '}
                    {moment(lastUpdateTime).utc().fromNow()}
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

export default NotificationsActivity
