import React from 'react'

const UserManagement = () => {
    return (
        <div>
            <div style={{textAlign: 'center'}}>
                <p style={{color: '#79adb7', fontSize: '20px', fontWeight: '400'}}>User management!</p>
                <p style={{fontWeight: '300', marginTop: '25px'}}>Here you can manage and see all clients. You have option to invite them to your company!</p>
            </div>
        </div>
    )
}

export default UserManagement
