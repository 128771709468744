import React from 'react'
import Button from 'components/CustomButton/CustomButton'
import { withRouter } from 'react-router-dom'

const StripeNotif = (props) => {
    return (
        <div 
        className="stripe-notif"
        style={{
           
        }}>
            Please setup your stripe account first so you can use application!
            <Button className='settings-button' onClick={() => props.history.push('/admin/stripe-account')}>Go to settings</Button>
        </div>
    )
}

export default withRouter(StripeNotif)
