import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { apiRequest } from 'helpers'
import Card from 'components/Card/Card.jsx'

import ReactTable from 'react-table'
import SweetAlert from 'react-bootstrap-sweetalert'
import Loader from 'components/Loader/Loader'
import Button from 'components/CustomButton/CustomButton.jsx'

import { connect } from 'react-redux'
import { setStripeNotifcation } from 'redux/actions/stripeNotificationActions'

/*Notifications*/
import AdditionalWerification from './StripeNotifications/AdditionalWerification'
import AllSetUp from './StripeNotifications/AllSetUp';

import avatar from 'assets/img/avatarStripe.png'
const StripeAccount = ({ match, setStripeNotifcation }) => {
  const [isSetUp, setisSetUp] = useState(false)
  const [link, setlink] = useState(null)
  const [displayMessage, setdisplayMessage] = useState(false)
  const [stripeAccountName, setstripeAccountName] = useState('')
  const [notCompleted, setnotCompleted] = useState(false)
  const [stripeAccountLink, setstripeAccountLink] = useState('')
  const [requirements, setrequirements] = useState(false)
  const [stripeDate, setstripeDate] = useState('')
  const [currentBalance, setcurrentBalance] = useState('')
  const [curr, setcurr] = useState('')
  const [transfers, settransfers] = useState([])
  const [loadingTransfers, setloadingTransfers] = useState(false)
  const [isLoadingPage, setisLoadingPage] = useState(true)
  const [error, setError] = useState(null)
  const query = new URLSearchParams(useLocation().search)

  // useEffect(() => {
  //   if(requirements){
  //     setNotification(<AdditionalWerification stripeAccountLink={stripeAccountLink} />)
  //   }
  // }, [])

  /* kad je requirements false, ne treba notifikacija */
  async function fetchStripeAccount() {
    setisLoadingPage(true)
    const response = await apiRequest({
      method: 'get',
      url: 'stripe_account/stripeAccount',
    })
    if (response.status === 200) {
      const dateStripe = moment.unix(response.data.created).format('LL')
      setstripeAccountName(response.data.business_profile.name)
      setstripeDate(dateStripe)
      if (response.data.details_submitted) {
        setisSetUp(true)
      }
      if (!response.data.payouts_enabled) {
        setrequirements(true)
      }
      if (
        response.data.requirements.currently_due.length > 0 ||
        response.data.requirements.eventually_due.length > 0
      ) {
        setrequirements(true)
      }
      if (!response.data.charges_enabled) {
        setnotCompleted(true)
        getLink()
      }
    }

    setisLoadingPage(false)
  }
  const setErrorMessage = (msg) => {
    setError(
      <SweetAlert
          danger
          style={{
            display: 'block',
            marginTop: '-100px',
          }}
          title={msg}
          onConfirm={() => setError(null)}
          confirmBtnBsStyle="info">
      </SweetAlert>
    )
  }

  async function fetchStripeProfileLink() {
    setisLoadingPage(true)
    const response = await apiRequest({
      method: 'get',
      url: 'stripe_account/stripeLoginLink',
    })
    if (response.status === 200) {
      setstripeAccountLink(response.data.url)
    }

    setisLoadingPage(false)
  }

  async function fetchBalanceHistory() {
    setloadingTransfers(true)
    setisLoadingPage(true)
    const response = await apiRequest({
      method: 'get',
      url: 'stripe_account/balanceHistory',
    })
    if (response.status === 200) {
      fetchStripeAccount()
      fetchStripeProfileLink()
      // setisSetUp(false)
      const curren = response.data.currency
      setcurrentBalance(response.data.currentBalance / 10)
      setcurr(curren)

      settransfers(
        response.data.transfers.map((x) => {
          const amountX = x.amount / 10
          const amountString = amountX.toString().concat(' ')
          return {
            id: x.id,
            name: 'John Doe',
            amount: amountString.concat(curren),
            time: moment.unix(x.created).format('LL'),
            type: x.source_type,
          }
        })
      )
    }
    setloadingTransfers(false)
  }

  useEffect(() => {
    setTimeout(() => {
      setdisplayMessage(false)
    }, 5000)
    console.log('pok')
    getLink()
    fetchBalanceHistory()
    if (query.get('bank_account') && query.get('first') === 'true') {
      setisSetUp(query.get('bank_account'))
      //uslov za obrisati poruku
      setdisplayMessage(true)
    }
    if (query.get('refresh_url' === 'true')) {
      getLink()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getLink = async () => {
    try {
      const response = await apiRequest({
        method: 'GET',
        url: 'stripe_account/generateOnboardingLink',
      })
      if (response.status === 200) {
        setlink(response.data.url)
      }
    } catch (err) {
      setisLoadingPage(false)
      setErrorMessage(err.response.data.message || err.response.data.msg)
    }
  }
  const setNotification = (val) => {
    // React.useState()[1].bind(null, {})
    // setStripeNotif()
    // setShow(false)
    setStripeNotifcation(val)
    console.log('%c STRIPE NOTIF', 'color:green')
  }

  return (
    <div className="main-content" style={{minHeight: '90vh'}}>
      <Row>
        <Col xxs="12" className="mb-4">
          {isLoadingPage ? (
            <SweetAlert
              style={{
                display: 'block',
                marginTop: '-100px',
              }}
              showConfirm={false}>
              <div>
                <Loader style={{ height: 75, width: 100 }} />
              </div>
            </SweetAlert>
          ) : isSetUp && !loadingTransfers ? (
            <div>
              {displayMessage ? (
                <div>
                  {/* {setNotification(<AllSetUp />)} */}
                  <div
                    style={{
                      margin: '0 auto',
                    }}>
                    <strong>Congratulations, your account is set up. </strong>
                    <span>Take your first payment.</span>
                  </div>
                </div>
              ) : (
                <div />
              )}
              {requirements ? console.log('sta ima') : <div />}
              <Card
                style={{
                  marginTop: '17%',
                  width: '80%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  padding: '10px 0',
                }}
                content={
                  <>
                    <div
                      style={{
                        display: 'flex',
                        maxWidth: '800px',
                        padding: '40px 0',
                        width: '100%',
                      }}>
                      <div
                        style={{
                          marginRight: '32%',
                          paddingLeft: '65px',
                          position: 'relative',
                          marginLeft: '20px',
                        }}>
                        <img
                          style={{
                            position: 'absolute',
                            width: '50px',
                            height: '50px',
                            top: '36%',
                            left: '0',
                            marginTop: '-25px',
                            borderRadius: '50%',
                          }}
                          src={avatar}
                          alt="User"
                        />

                        <p classnames="text-muted">Created at {stripeDate}</p>
                        <h2>{stripeAccountName}</h2>
                        <h6>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={stripeAccountLink}
                            style={{ color: '#219f72', fontSize: '1rem' }}>
                            View Stripe account
                          </a>
                        </h6>
                      </div>

                      <div style={{ position: 'relative', marginLeft: '40px' }}>
                        <p className="text-muted">Your balance </p>
                        <h2>
                          {currentBalance} {curr}
                        </h2>
                        <p className="text-muted">
                          {currentBalance} {curr} available
                        </p>
                      </div>
                      <div style={{ position: 'relative', marginLeft: '40px' }}>
                        {/* <p className='text-muted'>This week </p>
                        <h1>$41.09</h1>
                        <p className='text-muted'>5 orders</p> */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: '90%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}>
                      <h3>Recent payouts</h3>
                      <ReactTable
                        data={transfers}
                        filterable
                        columns={[
                          {
                            Header: 'Name',
                            sortable: true,
                            filterable: false,
                            accessor: 'name',
                          },
                          {
                            Header: 'Amount',
                            sortable: true,
                            filterable: false,
                            accessor: 'amount',
                          },
                          {
                            Header: 'Time',
                            sortable: false,
                            filterable: false,
                            accessor: 'time',
                          },
                          {
                            Header: 'Type',
                            sortable: false,
                            filterable: false,
                            accessor: 'type',
                          },
                        ]}
                        defaultPageSize={5}
                        //showPaginationTop
                        manual
                        showPaginationBottom={false}
                        showPagination={false}
                        className="-striped -highlight"
                      />
                    </div>
                  </>
                }
              />
            </div>
          ) : notCompleted ? (
            <div
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '80%',
                height: '400px',
                textAlign: 'center',
                padding: '90px 0',
              }}>
              <div
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '100%',
                  height: '400px',
                  marginBottom: '80px',
                  textAlign: 'center',
                  padding: '90px 0',
                }}
                stripe-pane="stripe">
                <h2>Connect your account to Stripe</h2>
                <br />
                <p className="text-muted">
                  We use Stripe to make sure you get paid on time and keep
                  <br /> your personal and bank details secure.
                </p>
                <Button
                  style={{
                    marginLeft: '20px',
                    width: '35%',
                    backgroundColor: '#15B67C',
                    border: '1px transparent',
                    borderRadius: '0px',
                    marginTop: '20px',
                    fontWeight: 'bold',
                    color: 'white',
                    height: '50px',
                    fontSize: '18px',
                  }}
                  onClick={() => {
                    console.log('=== LINK ===')
                    console.log(link)
                    if (link) {
                      console.log('URL', link)
                      window.location.replace(link)
                    }
                  }}>
                  CONTINUE
                </Button>
              </div>
              <Card
                content={
                  <p className="text-muted">
                    This platform uses <strong>Stripe Connect Express</strong> accounts to pay
                    out. We’ll pass some of the user information we have collected to{' '}
                    <strong>Stripe Express</strong> in order to make onboarding as simple as
                    possible.
                  </p>
                }
              />
            </div>
          ) : (
            <div
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '80%',
                height: '400px',
                textAlign: 'center',
                padding: '90px 0',
              }}>
              <div
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '100%',
                  height: '400px',
                  marginBottom: '80px',
                  textAlign: 'center',
                  padding: '90px 0',
                }}
                stripe-pane="stripe">
                <h2>Connect your account to Stripe</h2>
                <br />
                <p className="text-muted">
                  We use Stripe to make sure you get paid on time and keep
                  <br /> your personal and bank details secure.
                </p>
                <Button
                  style={{
                    marginLeft: '20px',
                    width: '50%',
                    backgroundColor: '#15B67C',
                    border: '1px transparent',
                    borderRadius: '0px',
                    marginTop: '20px',
                    fontWeight: 'bold',
                    color: 'white',
                    height: '50px',
                    fontSize: '18px',
                  }}
                  onClick={() => {
                    setNotification(null)
                    localStorage.removeItem('notification')
                    if (link) {
                      console.log('URL', link)
                      window.location.replace(link)
                    }
                  }}>
                  SET UP PAYMENTS
                </Button>
              </div>
              <Card
                content={
                  <p className="text-muted">
                    This platform uses <strong>Stripe Connect Express</strong> accounts to pay
                    out. We’ll pass some of the user information we have collected to{' '}
                    <strong>Stripe Express</strong> in order to make onboarding as simple as
                    possible.
                  </p>
                }
              />
            </div>
          )}
        </Col>
      </Row>
      {error}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setStripeNotifcation: (state) => dispatch(setStripeNotifcation(state)),
})

export default connect(null, mapDispatchToProps)(StripeAccount)
