import React, { useEffect, useState } from 'react';

import { Modal } from 'react-bootstrap';
import AsyncSelect from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert'

import apiRequest from 'helpers/apiRequest';

import Button from 'components/CustomButton/CustomButton'
import { ReactComponent as Loader } from 'assets/img/loader-preview.svg'

const AssignClientToWorker = (props) => {

    const [workers, setWorkers] = useState([])
    const [showModal, setModal] = useState(true)
    const [loading, setLoading] = useState(null)
    const [assignedWorker, setAssignedWorker] = useState()
    const [contentToRender, setContentToRender] = useState(null)

    useEffect(() => {
        const fetchData = async() => {
            try{
                const response = await apiRequest({
                    method: 'get',
                    url: 'users'
                })

                if(response.status === 200){
                    const worker = [...workers]
                    response.data.pageOfItems.map(elem => (
                        worker.push({label: elem.firstName + ' ' + elem.lastName, value: elem.userId})
                    ))
                    setWorkers([...worker])
                }
            }catch(err){
                console.log(err)
            }
        }
        fetchData();
    }, [])


    const setValue = (val) => {
        setAssignedWorker(val.value)
    }

    const assignClient = async() => {
        props.closeModal()
        const data = {
            workerId: assignedWorker,
            clientId: props.clientId
        }
        try{
            const response = await apiRequest({
                method: 'POST',
                url: 'firms/assign-worker-client',
                data
            })
            if(response.status === 200){
                setModal(false)
                setTimeout(() => {props.sucessAlert()}, 1000) 
            }
        }catch(err){
            console.log(err)
        }
        
    }

    

    return(
        <>
        <Modal show={true} style={{overflow: 'hidden'}}>
            <Modal.Header style={{backgroundColor: '#2C6CF6', color: '#FFFFFF', fontSize: '18px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}>Assign client to worker</Modal.Header>
            <Modal.Body>
                <AsyncSelect 
                    placeholder='Select worker'
                    options={workers}
                    onChange={(value) => setValue(value)}>
                </AsyncSelect>
            </Modal.Body>
            <Modal.Footer>
                <Button className='btn-fill btn-twitter' onClick={() => assignClient()}>Assign</Button>
                <Button className='btn-fill' onClick={() => props.closeModal()}>Cancel</Button>
            </Modal.Footer>
        </Modal>
        </>
    )
}

export default AssignClientToWorker;