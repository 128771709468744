import React from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import moment from 'moment'
import Button from 'components/CustomButton/CustomButton'

const PaymentDetailsModal = ({client, paymentDetails, closeModal}) => {
    return (
        <>
        {}
        {paymentDetails !== undefined ? console.log(paymentDetails) : null}
            <Modal show={true}>
                <Modal.Header style={{backgroundColor: '#58A4B0', borderTopRightRadius: '10px', borderTopLeftRadius: '10px'}}>
                <span className="modal-title customTitle" style={{ color: '#FFF' }}>
                    Update the request
                </span>
                <div className="pull-right">
                    <Button bsStyle="primary" fill onClick={() => closeModal()}>
                    <span className="btn-label">
                        <i className="fa fa-times" />
                        {'  '}
                    </span>
                    <span style={{ fontWeight: 'bold' }}>Close Modal</span>
                    </Button>
                </div>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{textAlign: 'center'}}>
                        <Col md={6} style={style.headStyle}>Payment ID: </Col>
                        <Col md={6} style={style.infoStyle}>{paymentDetails?.paymentId}</Col>
                    </Row>
                    <Row style={{textAlign: 'center'}}>
                        <Col md={6} style={style.headStyle}>Created at:  </Col>
                        <Col md={6} style={style.infoStyle}>{moment(paymentDetails?.createdAt).format('L')}</Col>
                    </Row>
                    <Row style={{textAlign: 'center'}}>
                        <Col md={6} style={style.headStyle}>Client: </Col>
                        <Col md={6} style={style.infoStyle}>{client?.name} {client?.lastName}</Col>
                    </Row>
                    <Row>
                        <Col md={12} style={style.amount}>Total amount: $ {paymentDetails?.amount}</Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PaymentDetailsModal
const style ={
    headStyle: {
        fontWeight: 'bold',
        fontSize: '14px',
        padding: '5px'

    },
    infoStyle : {
        fontWeight: '400',
        fontSize: '13px',
        padding: '5px'
    },
    amount : {
        fontWeight: 'bold',
        fontSize: '15px',
        textAlign: 'center',
        marginTop: '10px'
    }
}