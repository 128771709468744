import React from 'react';
import {useSpring, animated} from 'react-spring'

const Alert = ({message}) => {
    const props = useSpring({
        to: [{width: 200, height: 100}],
        from: {width: 0, height: 100},
      })

    return(
        <animated.div style={props} className="alert-notification"> <p>{message}</p></animated.div>
    )
}

export default Alert