import React from 'react'

const Dashboard = () => {
    return (
        <div>
        <div style={{textAlign: 'center'}}>
            <p style={{color: '#79adb7', fontSize: '20px', fontWeight: '400'}}>Dashboard</p>
            <p style={{fontWeight: '300', marginTop: '25px'}}>This is dashboard. <br/>Here you can see statistics, notifications and such!</p>
        </div>
    </div>
    )
}

export default Dashboard
