import React, { Component } from 'react'
import {Grid, Row, Col} from 'react-bootstrap'
import { apiRequest } from 'helpers'
import Config from 'config'
import moment from 'moment'
import { Link, Redirect } from 'react-router-dom'
import SweetAlert from 'react-bootstrap-sweetalert'

import DocumentCard from './documentCard/DocumentCard';
import UploadCard from './upload-card/UploadCard';
import MessageIcon from 'assets/img/bubble.svg';

import Button from 'components/CustomButton/CustomButton.jsx'

import PDFModal from 'views/Components/PDFModal.jsx'
import NewDiscussionModal from 'views/Components/NewDiscussionModal.jsx'
import UpdateRequestModal from 'views/Components/UpdateRequestModal.jsx'
import PaymentRequestModal from 'views/Components/PaymentRequestModal'
import ConfirmationModal from 'views/Components/ConfirmationModal'
import UploadT183Modal from 'views/Components/UploadT183Modal'
import RequestStatusInfo from './RequestStatusInfo';
import PaymentDetailsModal from 'views/Components/PaymentDetailsModal'
import SuccessPaymentDetails from 'views/Components/SuccessPaymentDetailsModal'

import { ReactComponent as Processing } from 'assets/img/loader-preview.svg'
import './singleRequest.css';

import arrowBack from 'assets/img/icons/arrow_back.png'
import TotalPaymentModal from 'views/Components/TotalPaymentModal'
import checkImg from 'assets/img/chcek.svg'

class SingleRequest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      alert: null,
      isLoading: true,
      requestId: null,
      request: {},
      client: null,
      pdfModal: null,
      newDiscussionModal: null,
      updateRequestModal: null,
      paymentRequestModal: null,
      notAllowedRedirect: null,
      totalAmountModal: null,
      failedStatusMessage: null,
      confirmationModal: null,
      uploadT183: null,
      showConfirmationModal: null,
      paymentDetailsModal: null,
      showSuccessPaymentModal: null,
    }

  }


  componentDidMount() {
    const {
      match: { params },
    } = this.props
    let redirect;
    //CHECK IF USER IS ALLOWED TO SEE THIS PAGE
    const userRole = localStorage.getItem('role');
    if(userRole === 'admin' || userRole === 'worker'){
      redirect = null 
    }else if(userRole === 'superAdmin'){
      redirect = <Redirect to='/admin/not-allowed' />
    }

    this.setState({ requestId: params.id, notAllowedRedirect: redirect })
    this.getRequestData(params.id)
    

  }

  getRequestData = async (requestId) => {
    this.loadingAlert(true)
    const response = await apiRequest({
      method: 'get',
      url: 'requests/' + requestId,
    })

    if (response.status === 200) {
      console.log(response)
      this.setState({ request: response.data })
      this.getClient(response.data.clientId);
    }
    this.setState({ isLoading: false }, () => this.hideAlert())
  }

  openDiscussion = async (message, checkedType, documentId) => {
    console.log('OTVORI DISKUSIJU API')
    console.log(message)
    const { request } = this.state
    const clientId = request.clientId

    const data = {
      text: message,
      senderId: localStorage.getItem('senderId'),
      clientId,
    }

    const messageRequest = await apiRequest({
      method: 'post',
      url: 'chat/openDiscusion',
      data,
    })

    // If we need to change document status
    console.log('== CHECKED TYPE ==')
    console.log(checkedType)
    if (checkedType) {
      const dataDoc = {
        requestId: request.requestId,
        documentId,
      }

      const changeDocumentStatus = await apiRequest({
        method: 'post',
        url: 'requestdocument/scanAgain',
        data: dataDoc,
      })

      console.log('== STATUS CHANGED ==')
      console.log(changeDocumentStatus)
    }

    if (messageRequest.status === 200) {
      this.successAlert('Message has been sent!')
    } else {
      this.errorAlert()
    }

    this.removeDiscussionModal()
  }

  colorForStatus = (status) => {
    switch (status) {
      case 'pending':
        return '#EC5758'
      case 'completed':
        return '#29AE60'
      case 'scanned':
        return '#29AE60'
      case 'signed':
        return '#29AE60'
      default:
        return '#F2994B'
    }
  }

  getPaymentInfo = async() => {
    let payment = true
    try{
      const response = await apiRequest({
        method: 'get',
        url: `payments/webByRequestId/${this.state.requestId}`
      })
      if(response.status === 200){
        console.log("%c RESPONSE", 'color: red')
        console.log(response.data)
        this.setState({})
        // this.setState({
        //   paymentDetailsModal: (<PaymentDetailsModal client={this.state.client} paymentDetails={response.data} closeModal={() => this.setState({paymentDetailsModal: null})}/>)
        // })
      }
    }catch(err){
      this.errorAlert(err.response.data.message || err.response.data.msg)
    }
  
  }
  getSuccessPaymentInfo = async() => {
    try{
      const response = await apiRequest({
        method: 'get',
        url: `payments/webByRequestId/${this.state.requestId}`
      })
      if(response.status === 200){
        console.log("%c RESPONSE", 'color: red')
        console.log(response.data)
        this.setState({
          showSuccessPaymentModal: <SuccessPaymentDetails closeModal={() => this.setState({showSuccessPaymentModal: null})} paymentData={response.data}/>
        })
      }
    }catch(err){
      this.errorAlert(err.response.data.message || err.response.data.msg)
    }
  }


  sendT183 = (doc) => {
    console.log('document to send')
    if(doc.length > 1){
      this.hideUploadModal()
      this.errorAlert("You can't add more than one document!")
    }else{
    var formData = new FormData()
        formData.append('requestId', this.state.requestId)
        formData.append('file', doc[0])
        formData.append('T183Array', JSON.stringify(["true"]))

        const jwtoken = localStorage.getItem('usertoken')
        var req = new XMLHttpRequest()

        const url = `${Config.APP_API_URL}/requests/updateRequest`

        req.open('POST', url)

        if (jwtoken) {
          req.setRequestHeader('Authorization', 'Bearer ' + jwtoken)
        }

        req.send(formData)

        req.onload = () => {
          console.log('STATUS')
          console.log(req.status)
          if (req.status === 200) {
            console.log('REQQQ')
            const response = JSON.parse(req.response)
            console.log('======== RESPONSE gfgfdhdf ==========')
            console.log(response)
            this.hideUploadModal()
            setTimeout(() => {this.successAlert('Document uploaded successfully', true)}, 1000)
            this.getRequestData()
          } else {
            console.log("============= ELSE =============")
            if(req.response === "A field already exists with the specified name: \"sigImage\""){
              console.log('IMA IMA IMA')
            }
            this.errorAlert(JSON.parse(req.response).message || req.response.message)
            this.hideUploadModal()
          }
      }
    }
    
  
  }

  sendRequest = async (documents, documentsForUpload, forms) => {
    console.log('DOCUMENT FOR UPLOAD')
    console.log(documentsForUpload)
    const { requestId } = this.state
    let docsArr = []
    console.log('==== DOCUMENTS===')
    console.log(documentsForUpload)
    this.loadingAlert()

    if (documents.length > 0) {
      documents.forEach((doc) => {
        docsArr.push({ value: doc.value, isDefault: doc.isDefault })
      })
    }

    let formsArr = []

    if (forms.length > 0) {
      forms.forEach((form) => {
        formsArr.push(form.value)
      })
    }

    if (requestId !== null) {
      const data = {
        requestId,
        documents: docsArr,
        forms: formsArr,
        file: documentsForUpload,
      }

      console.log('DATA')
      console.log(data)

      const formData = new FormData()
      formData.append('requestId', this.state.requestId)
      formData.append('documents', JSON.stringify(data.documents))
      formData.append('forms', JSON.stringify(data.forms))
      formData.append('T183Array', JSON.stringify(["false"]))

      documentsForUpload.forEach((docUpload) => {
        formData.append('file', docUpload, docUpload.name)
      })

      console.log('=== FORM DATA ==')
      console.log(formData)

      const jwtoken = localStorage.getItem('usertoken')
      var req = new XMLHttpRequest()

      const url = `${Config.APP_API_URL}/requests/updateRequest`

      req.open('POST', url)

      if (jwtoken) {
        req.setRequestHeader('Authorization', 'Bearer ' + jwtoken)
      }

      req.send(formData)

      req.onload = () => {
        console.log('STATUS')
        console.log(req.status)
        if (req.status === 200) {
          this.getRequestData()
          console.log('REQQQ')
          const response = JSON.parse(req.response)
          console.log(response)
          this.successAlert('The request has been sent.', true)
        } else {
          this.errorAlert(JSON.parse(req.response).message || req.response.message)
        }
      }
    }
  }

  sendDocumentToScan = async (documentName, isMandatory) => {
    const { requestId } = this.state
    const data = {
      requestId,
      documentName,
      isMandatory,
    }
    try {
      const response = await apiRequest({
        method: 'post',
        url: 'requests/addDocumentToScan',
        data,
      })
      if (response.status === 200) {
        this.successAlert('Document added successfully.', true)
      }
    } catch (err) {
      this.errorAlert()
    }
  }

  // taxSummarie = async(summaries) => {
  //   const { requestId } = this.state
  //   const data = {
  //     requestId,
  //     summaries,
  //   }
  //   try {
  //     const response = await apiRequest({
  //       method: 'post',
  //       url: 'taxSummaries',
  //       data,
  //     })
    
  //     if (response.status === 201) {
  //       //this.normalSuccessAlert('Payment request has been sent.', true)
  //       this.removePaymentRequestModal()
  //       this.openTotalAmountModal()
  //     }
  //   } catch (err) {
  //     this.errorAlert(err.response.data)
  //   }
  // }

 
  approveRequest = async () => {
    const { requestId } = this.state
    const response = await apiRequest({
      method: 'put',
      url: `requests/requestToComplete/${requestId}`,
    })
    if (response.status === 200) {
      this.normalSuccessAlert('The request has been approved.', true)
    } else {
      this.errorAlert()
    }
  }

  // openPaymentRequestModal = () => {
  //   console.log("OTVORIO MODAL")
  //   this.setState({
  //     paymentRequestModal: (
  //       <PaymentRequestModal
  //         removeModal={this.removePaymentRequestModal}
  //         sendRequest={this.taxSummarie}
  //       />
  //     ),
  //   })
  // }

  openTotalAmountModal = () => {
    this.setState({
      totalAmountModal: (
        <TotalPaymentModal 
          removeModal={this.removeTotalAmountModal}
          requestId={this.state.requestId}
          successMessage={() => this.successAlert('Payment request successfully sent!')}
          errorMessage={(msg) => this.errorAlert(msg)}/>
      )
    })
  }

  removeModal = () => {
    this.setState({ pdfModal: null })
  }

  removeDiscussionModal = () => {
    this.setState({ newDiscussionModal: null })
  }

  removeUpdateRequestModal = () => {
    this.setState({ updateRequestModal: null })
  }

  removePaymentRequestModal = () => {
    this.setState({ paymentRequestModal: null })
  }

  removeTotalAmountModal = () => {
    this.setState({ totalAmountModal: null})
  }
  /* SUCCESS */

  successAlert(msg = 'This request has been updated!', removeModal = false) {
    this.setState(
      {
        alert: (
          <SweetAlert
            success
            style={{
              display: 'block',
              marginTop: '-100px',
            }}
            title="Done"
            onConfirm={() => this.hideAlert()}
            confirmBtnBsStyle="info">
            {msg}
          </SweetAlert>
        ),
      },
      () => {
        const { requestId } = this.state
        if (removeModal) {
          this.removeUpdateRequestModal()
        }
        this.getRequestData(requestId)
      }
    )
  }

  errorAlert(message = 'There was an error. Please try again.') {
    this.setState(
      {
        alert: (
          <SweetAlert
            danger
            style={{
              display: 'block',
              marginTop: '-100px',
            }}
            title="Error"
            onConfirm={() => this.hideAlert()}
            confirmBtnBsStyle="info">
            {message}
          </SweetAlert>
        ),
      },
      () => {
        this.removeUpdateRequestModal()
      }
    )
  }

  loadingAlert(noText = false) {
    this.setState({
      alert: (
        <SweetAlert
          style={{
            display: 'block',
            marginTop: '-100px'
          }}
          title='Processing'
          showConfirm={false}>
          {/* <div>
            <Processing style={{ height: 75, width: 100 }} />
          </div> */}
        </SweetAlert>
      ),
    })
  }

  warningWithConfirmMessage = () => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title="Are you sure you want to approve this request?"
          onConfirm={() => this.approveRequest()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, approve it!"
          cancelBtnText="Cancel"
          showCancel>
          We will notify client that a request is approved.
        </SweetAlert>
      ),
    })
  }

  normalSuccessAlert(msg = 'The client has been notified.', reload = false) {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{
            display: 'block',
          }}
          title="Done"
          onConfirm={() => {
            this.hideAlert(reload)
          }}
          confirmBtnBsStyle="info">
          {msg}
        </SweetAlert>
      ),
    })
  }

  hideAlert(refreshData = false) {
    this.setState(
      {
        alert: null,
      },
      () => {
        if (refreshData) {
          const { requestId } = this.state
          this.getRequestData(requestId)
        }
      }
    )
  }

  notifyClient = async () => {
    const { requestId } = this.state

    const data = {
      id: requestId,
    }

    const response = await apiRequest({
      method: 'post',
      url: 'requests/notifyClientToFinishRequest',
      data,
    })

    if (response.status === 200) {
      this.normalSuccessAlert('The client has been notified.')
    } else {
      this.errorAlert()
    }
  }

  toggleDocumentMandatory = async (docId) => {
    const { requestId } = this.state
    const data = {
      requestId,
      documentId: docId,
    }
    const response = await apiRequest({
      method: 'post',
      url: `requestdocument/setRemoveMandatory`,
      data,
    })

    console.log('== DOC RESPONSE ==')
    console.log(response)
    if (response.status === 200) {
      this.getRequestData(requestId)
    }
  }

  getClient = async(clientId) =>{
    //const { request } = this.state

    const response = await apiRequest({
      method: 'get',
      url: `clients/${clientId}`
    })

    if(response.status === 200){
      this.setState({client: {name: response.data.firstName, lastName:response.data.lastName }})
    }

    console.log("=== CLIENT ===")
    console.log(this.state.client)
  }

  updateRequest = (documents) => {
    this.setState({
      confirmationModal: null,
      updateRequestModal: (
        <UpdateRequestModal
          type={'documents'}
          removeModal={this.removeUpdateRequestModal}
          attachedDocuments={documents}
          sendDocumentToScan={this.sendDocumentToScan}
          sendRequest={this.sendRequest}></UpdateRequestModal>
      ),
    })
  }
  hideUploadModal = () => {
    this.setState({
      uploadT183: null
    })
  }

  addT183 = () => {
    this.setState({
      confirmationModal: null,
      uploadT183: (
        <UploadT183Modal sendRequest={this.sendT183} removeModal={() => this.hideUploadModal()}/>
      )

    })
  }
  closeModals = (reqId) => {
    this.setState({
      showConfirmationModal: null
    }, () => this.getRequestData(reqId))
    
  }
  deleteDocument = async (docId, reqId) => {
    this.setState({showConfirmationModal: null})
    let data = {
      requestId: reqId,
      documentId: docId
    }
    console.log('data');
    console.log(data);
    try{
      const response = await apiRequest({
        method: 'post',
        url: 'requestdocument/removeDocumentFromRequest',
        data
      })
      if(response.status === 200){
        this.setState({
          showConfirmationModal: (
            <SweetAlert
            success
            style={{
              display: 'block',
              marginTop: '-100px',
            }}
            title="Document removed successfully!"
            onConfirm={() => this.closeModals(reqId)}
            confirmBtnBsStyle="info">
          </SweetAlert>
          )
        })
      }
    }catch(err){
      this.errorAlert(err.response.data.message || err.response.data.msg)
      this.getRequestData()
    }
  }
  deleteConfirmation = (docId, reqId) => {
    console.log(docId, reqId);
    this.setState({
      showConfirmationModal: (
        <SweetAlert
          warning
          showCancel
          style={{
            display: 'block',
          }}
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          title="You will not be able to recover this file!"
          onConfirm={() => this.deleteDocument(docId, reqId)}
          onCancel={() => this.setState({showConfirmationModal: null})}
          confirmBtnBsStyle="warning">
        </SweetAlert>
      )
    })
  }
  openDetailsModal = () => {
   this.getPaymentInfo()
  }
  checkPaidDetails = () => {
    this.getPaymentInfo()
  }
  checkIfAdmin = () => {
    this.setState({
      showConfirmationModal: (
        <SweetAlert
          info
          style={{
            display: 'block',
          }}
          title="You dont have a permission to see answers, please contact your supervisor!"
          onConfirm={() => this.setState({showConfirmationModal: null})}>
        </SweetAlert>
      )
    })
  }

  render() {
    const { request, notAllowedRedirect, failedStatusMessage } = this.state
    console.log('== REQUEST == ')
    console.log(request)
    console.log('IS LOADING: ', this.state.isLoading)
    return (
      <div className="main-content">
        {notAllowedRedirect}
        {failedStatusMessage}
        {this.state.alert}
        {!this.state.isLoading && (
          
          <Grid fluid>
            <div className='request-info'>
              <p className='tax-return'><Link to='/admin/requests'><img src={arrowBack} className="arrow-img" alt='back'/></Link>Single request</p>
              <p className='request-id'>ID: {request.requestId}</p>
            </div>
            <RequestStatusInfo 
              request={request}
              notifyClient={() => this.notifyClient()}
              sendPaymentRequest={() => this.openTotalAmountModal()}
              completeRequest={() => this.warningWithConfirmMessage()}
              checkDetails={() => this.openDetailsModal()}
              chechPaid={() => this.getSuccessPaymentInfo()} />
              
            <Row className="top70">
              <Col md={6}>
                <div className="text-left documents-headline">
                  <h4 style={{ margin: 0, color: "rgba(119, 119, 119, 1)" }}>Documents</h4>
                </div>
              </Col>
            </Row>
            <Row className="top20">
            {console.log("==== FOOOOOOOORM =====")}
                {console.log(request.documents)}
                {(request.documents || []).map((elem, index) => (
                   <DocumentCard
                      key={index} 
                      status={elem.documentstatus.statusKey}
                      reqStatus={elem.documentstatus.status}
                      requestStatus={request.requeststatus.status}
                      docType={elem.document.documentType}
                      docName={elem.document.documentName}
                      docId={elem.document.documentId}
                      reqId={request.requestId}
                      checkIfAdmin={() => this.checkIfAdmin()}
                      deleteDocument={this.deleteConfirmation}
                      documentURL={elem.document.documentUrl}
                      openDocument={() => this.setState({
                        pdfModal: (
                          <PDFModal
                            removeModal={this.removeModal}
                            documentName={elem.document.documentName}
                            documentKey={elem.document.key}></PDFModal>
                        ),
                      })}
                      openDiscussion={() => this.setState({
                        newDiscussionModal: (
                          <NewDiscussionModal
                            removeModal={this.removeDiscussionModal}
                            objName={elem.document.documentName}
                            objKey={elem.document.key}
                            objId={elem.document.documentId}
                            objType="document"
                            documentStatus={elem.documentstatus.statusKey}
                            openDiscussion={
                              this.openDiscussion
                            }></NewDiscussionModal>
                        ),
                      })}
                  />
                ))}
                {request.requeststatus.status !== 'Completed' ? 
                <UploadCard
                requestStatus = {request}
                 addT183={() => this.addT183()}
                 addNormalDoc={() => this.updateRequest(this.state.request.documents)}
                  openConfirmationModal={() => 
                    this.checkWhatToUpload()
                    // this.setState({
                    //   confirmationModal: (
                    //     <ConfirmationModal 
                    //       addNormalDoc={() => this.updateRequest(request.documents)}
                    //       addT183={() => this.addT183()}
                    //       closeConfirmation={() => this.setState({confirmationModal: null})}/>
                    //     )
                    // })
                  }
                /> : null}
              {this.state.pdfModal}
            </Row>
            <Row className="top70">
              <Col md={6}>
                <div className="text-left documents-headline">
                  <h4 style={{ margin: 0, color: "rgba(119, 119, 119, 1)"  }}>Forms</h4>
                </div>
              </Col>
            </Row>
            <Row className="top20">
              <div className="documents-forms-container">
               {request.forms.map((elem, index) =>(
                 <DocumentCard
                    key={index}  
                    status={request.requeststatus.statusKey}
                    requestStatus={request.requeststatus.status}
                    docName={elem.formName}
                    docType={"application/pdf"}
                    reqStatus={request.requeststatus.status}
                    formId={elem.formId}
                    formDiscuss={true}
                    requestId={request.requestId}
                    openDiscussion={() =>
                      this.setState({
                        newDiscussionModal: (
                          <NewDiscussionModal
                            removeModal={this.removeDiscussionModal}
                            objName={elem.formName}
                            objKey={elem.formId}
                            objType="form"
                            openDiscussion={
                              this.openDiscussion
                            }></NewDiscussionModal>
                        ),
                      })
                    }
                    />
               ))}
              </div>
            </Row>
            {this.state.newDiscussionModal}
            {this.state.updateRequestModal}
            {this.state.paymentRequestModal}
            {this.state.totalAmountModal}
            {this.state.confirmationModal}
            {this.state.uploadT183}
            {this.state.showConfirmationModal}
            {this.state.paymentDetailsModal}
            {this.state.showSuccessPaymentModal}
          </Grid>
        )}
      </div>
    )
  }
}

export default SingleRequest
