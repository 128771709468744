import React from 'react'

const TaxFiles = () => {
    return (
        <div>
            <div style={{textAlign: 'center'}}>
                <p style={{color: '#79adb7', fontSize: '20px', fontWeight: '400'}}>Tax files</p>
                <p style={{fontWeight: '300', marginTop: '25px'}}>This page provide you information on tax files. You can manage tax files by clicking <b>Open</b> button.</p>
            </div>
        </div>
    )
}

export default TaxFiles
