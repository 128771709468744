import { apiRequest } from 'helpers'

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS'
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE'
export const SET_SEEN_NOTIFICATION = 'SET_SEEN_NOTIFICATION'

export const getNotifications = () => ({ type: GET_NOTIFICATIONS })
export const refetchNotifications = (notification) => ({
  type: UPDATE_NOTIFICATIONS,
  payload: notification,
})

export const getNotificationsSuccess = (notifications) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: notifications,
})
export const getNotificationsFailure = () => ({ type: GET_NOTIFICATIONS_FAILURE })
export const changeNotification = (notificationId, isMessage) => ({
  type: SET_SEEN_NOTIFICATION,
  payload: { notificationId, isMessage },
})

export function fetchNotifications(total) {
  return async (dispatch) => {
    dispatch(getNotifications())
    console.log('==FETCH==')
    console.log(total)
    try {
      const response = await apiRequest({
        method: 'get',
        url: 'activities/getTop/' + total,
      })

      if (response.status === 200) {
        dispatch(getNotificationsSuccess(response.data))
      }
    } catch (error) {
      dispatch(getNotificationsFailure())
    }
  }
}

export function updateNotifications(notification) {
  return async (dispatch) => {
    console.log('== UPDATE NOTIFICATION REDUX ==')
    console.log(notification)
    dispatch(refetchNotifications(notification))
  }
}

export function setSeenNotification(notificationId, isMessage = false) {
  console.log('== SEEN NOTIFY ==')
  console.log(isMessage)
  return async (dispatch) => {
    dispatch(changeNotification(notificationId, isMessage))
  }
}
