import React from 'react'
import './singleRequest.css'
import Button from 'components/CustomButton/CustomButton'

import bellIcon from 'assets/img/icons/bellIcon.svg'
import bellIconWhite from 'assets/img/icons/bellWhite.svg'
import messageIcon from 'assets/img/icons/messageIcon.svg'
import walletWhite from 'assets/img/icons/walletWhite.svg'
import walletGreen from 'assets/img/icons/walletGreen.svg'
import trueImg from 'assets/img/icons/true.svg'

import { Grid, Col, Row } from 'react-bootstrap';
import moment from 'moment'
import { Link } from 'react-router-dom'



const RequestStatusInfo = (props) => {

    const mapButtonForStatus = (request) => {
        console.log('STATUS KEY');
        console.log(request.requeststatus.statusKey)
        switch(request.requeststatus.statusKey){
            case 'pending': {
                return (
                    <>
                        <p style={style.infoStyle}>This request is not done yet. Please wait for the client to finish all documents.</p>
                        <Link to={'/admin/messenger'}>
                            <Button
                                className='status-button'>
                                    <img src={messageIcon} alt='Message img' style={style.buttonIcon}/>
                            Send message</Button>
                        </Link>
                        <span style={{margin: '0 20px', fontWeight: 'bold'}}>or</span>
                        <Button
                            className='status-button' 
                            onClick={props.notifyClient}>
                                <img src={bellIcon} alt='Bell img' style={style.buttonIcon} 
                            />Notify client to finish</Button>
                    </>
                )
            }
            case 'paymentSubmitted': {
                return (
                    <>
                        <p style={style.infoStyle}>Payment has successfully submitted. <br /> Please check it and proceed with approval process</p>
                        <Button
                            className='status-button status-button-fill' 
                            onClick={props.completeRequest}>
                                <img src={trueImg} alt='Bell img' style={style.buttonIcon} 
                            />Complete request</Button>
                            <span style={{margin: '0 20px', fontWeight: 'bold'}}>or</span>
                        <Button
                            className='status-button' 
                            onClick={props.chechPaid}>
                                <img src={walletGreen} alt='Bell img' style={style.buttonIcon} 
                        />Check payment details</Button>
                    </>
                )
            }
            case 'waitingForPayment':{
                return (
                    <>
                        <p style={style.infoStyle}>This request is waiting for payment. Please wait for client to pay</p>
                        <Button
                            className='status-button status-button-fill' 
                            onClick={props.notifyClient}>
                                <img src={bellIconWhite} alt='Bell img' style={style.buttonIcon} 
                            />Notify client to pay</Button>
                    </>
                )
            }
            case 'paymentFailed': {
                return(
                    <>
                        <p style={style.infoStyle}>The payment proccess failed. You can check the details below.</p>
                        <Button
                            onClick={props.sendPaymentRequest}
                            className='status-button'   >
                                <img src={walletGreen} alt='Message img' style={style.buttonIcon}/>
                            Resend payment request</Button>
                            <span style={{margin: '0 20px', fontWeight: 'bold'}}>or</span>
                        <Button
                            className='status-button status-button-fill' 
                            onClick={props.chechPaid}>
                                <img src={walletWhite} alt='Bell img' style={style.buttonIcon} 
                            />Check payment details</Button>
                    </>
                )
            }
            case "completed": {
                return(
                    <>
                        <p style={style.infoStyle}>The request has been completed successfully</p>
                        <div className="signatureBox top20">
                            <a
                            className="signatureBoxClick"
                            href={request.signature.image}
                            download="signature.jpg">
                            <img
                                alt="Signature"
                                style={{ maxWidth: "100%", maxHeight: "100%", backgroundSize: 'contain' }}
                                src={`data:image/png;base64,${request.signature.image}`}></img>
                            </a>
                            <span
                            style={{
                                position: 'absolute',
                                right: 25,
                                bottom: 7,
                                zIndex: 9999,
                            }}>
                            <i
                                className="fa fa-download"
                                style={{ fontSize: 30, color: '#447DF7' }}></i>
                            </span>
                        </div>
                    </>
                )
            }
            case 'submittedForApproval': {
                return(
                    <>
                        <p style={style.infoStyle}>The request is submited for approval. Please check all documents and continue with return.</p>
                        <Button
                            onClick={props.completeRequest}
                            className='status-button status-button-fill'>
                                <img src={trueImg} alt='Message img' style={style.buttonIcon}/>
                            Approve request</Button>
                            <span style={{margin: '0 20px', fontWeight: 'bold'}}>or</span>
                        <Button
                            className='status-button' 
                            onClick={props.sendPaymentRequest}>
                                <img src={walletGreen} alt='Bell img' style={style.buttonIcon} 
                            />Request payments</Button>
                    </>
                )
            }
            default:
                return <p>Status not found</p>
            }  
    }
    const renderContent = (request) => {  
        return(
            <div>
                <div style={style.gridStyle}>
                    <Grid 
                    style={{
                        backgroundColor: 
                            request.requeststatus.statusKey === 'pending' ? '#C5C3C6' : 
                            request.requeststatus.statusKey === 'submitedForApproval' || request.requeststatus.statusKey === 'submitedForApproval' || request.requeststatus.statusKey === 'waitingForPayment' || request.requeststatus.statusKey === 'paymentSubmitted' ? '#58A4B0' : 
                            request.requeststatus.statusKey === 'paymentFailed' ? '#F45B69' : 
                            request.requeststatus.statusKey === 'completed' ? '#3AB795' : '#58A4B0',
                            height: '100%', width: '100%'
                        }}>
                        <Row>
                            <Col md={6} lg={6} sm={12} style={style.colsStyle}>
                                <p className='request-status-label request-status-label-2'>Request status</p>
                                <h4 className='request-status'>{request.requeststatus.status.toUpperCase()}</h4>
                            </Col>
                            <Col md={6} lg={6} sm={12} className='text-right' style={style.colsStyle}>
                                <p className='request-status-label request-status-label-2'>Date created</p>
                                <p style={{fontSize: '17px', marginTop: '5%'}}>{moment.utc(request.dateCreated).format('L')}</p>
                            </Col>
                        </Row>
                    </Grid>
                </div>
                <div style={{textAlign: 'center'}}>
                    {mapButtonForStatus(request)}
                </div>
            </div>
        )
    }


    return (
        <>
           {renderContent(props.request)}
        </>
    )
}

const style = {
    gridStyle : {
        borderRadius: '15px',
        overflow: 'hidden', 
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    },
    colsStyle: {
        padding: "15px",
        color: '#FFFFFF'
    },
    infoStyle: {
        padding: '30px'
    },
    buttonIcon:{
        height: '13%',
        width: '13%',
        marginRight: '5px'
    }
}

export default RequestStatusInfo
