import React, { Component } from 'react'
import { ReactComponent as LoadingImg } from 'assets/img/loader-preview.svg'

class Loader extends Component {
  render() {
    const { size } = this.props
    return (
      <LoadingImg
        style={{
          height: size ? size : 30,
          // opacity: uploadProgress && uploadProgress.state === 'done' ? 0.5 : 0,
        }}
      />
    )
  }
}
export default Loader
