import React from 'react';

import Lottie from 'react-lottie';
import animationData from 'lotties/403.error.page.json';
import {Link} from 'react-router-dom';

import Button from 'components/CustomButton/CustomButton';

const NotAllowed = () =>{

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

    return(
        <div className='not-allowed-main-content' style={{height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div>
                <Lottie 
                    options={defaultOptions}
                    height={400}
                    width={400}
                />
                <br/>
            </div>
            <h3 st>Oops.. You are not allowed to access this page!</h3>
            <Link to='/dashboard'>
                <Button className='btn-fill btn-facebook'>Go back to dashboard</Button>
            </Link>
        </div>
    )

}

export default NotAllowed;