const docs = [
  'T3 Dividend, Interest Income',
  'T4 Employment Income',
  'T4A Other Income Pension/Scholarships',
  'T4RSP RRSP Withdrawals',
  'T5 Dividend, Interest Income',
  'T5013 Statement of Partnership Income',
  'T4RIF RRSP Annuity',
  'RRSP Purchase',
  'Tuition Fees (T2202)',
  'Charitable & Political Donation Receipts',
  'Treaty (scan treaty card)',
]

module.exports = {
  docs,
}
