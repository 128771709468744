const inputSets = [
  {
    label: 'Personal information',
    name: 'personal-info',
    icon: '👤',
    showHeader: false,
    fields: [
      {
        type: 'text',
        label: 'First Name',
        className: 'form-control',
        required: true,
      },
      {
        type: 'text',
        label: 'Last Name',
        className: 'form-control',
        required: true,
      },
      {
        type: 'text',
        label: 'Cell Phone',
        className: 'form-control masked cellMask',
        required: false,
      },
      {
        type: 'text',
        label: 'Home Phone',
        className: 'form-control masked phoneMask',
        required: false,
      },
      {
        type: 'text',
        label: 'Email',
        className: 'form-control',
        required: true,
      },
      {
        type: 'text',
        label: 'Mailing Address',
        className: 'form-control',
        required: true,
      },
      {
        type: 'text',
        label: 'City/Town',
        className: 'form-control',
        required: true,
      },
      {
        type: 'text',
        label: 'Postal Code',
        className: 'form-control masked postalMask',
        required: true,
      },
      {
        type: 'select',
        label: 'Select Province/Territory',
        className: 'form-control',
        required: true,
        values: [
          {
            label: 'Alberta',
            value: 'AL',
            selected: false,
          },
          {
            label: 'British Columbia',
            value: 'BC',
            selected: false,
          },
          {
            label: 'Manitoba',
            value: 'MB',
            selected: false,
          },
          {
            label: 'New Brunswick',
            value: 'NB',
            selected: false,
          },
          {
            label: 'Newfoundland and Labrador',
            value: 'NL',
            selected: false,
          },
          {
            label: 'Northwest Territories',
            value: 'NT',
            selected: false,
          },
          {
            label: 'Nova Scotia',
            value: 'NS',
            selected: false,
          },
          {
            label: 'Nunavut',
            value: 'NU',
            selected: false,
          },
          {
            label: 'Ontario',
            value: 'ON',
            selected: false,
          },
          {
            label: 'Prince Edward Island',
            value: 'PE',
            selected: false,
          },
          {
            label: 'Quebec',
            value: 'QC',
            selected: false,
          },
          {
            label: 'Saskatchewan',
            value: 'SK',
            selected: false,
          },
          {
            label: 'Yukon',
            value: 'YT',
            selected: false,
          },
        ],
      },
      {
        type: 'date',
        label: 'DOB',
        // inline: true,
        className: 'form-control',
        required: true,
      },
      {
        type: 'text',
        label: 'SIN',
        className: 'form-control masked sinMask',
        required: true,
      },
      {
        type: 'radio-group',
        required: true,
        label: 'Preferred Language of Correspondence',
        inline: true,
        values: [
          {
            label: 'English',
            value: 'english',
            selected: true,
          },
          {
            label: 'French',
            value: 'french',
            selected: false,
          },
        ],
      },
    ],
  },
  {
    label: 'Income',
    name: 'income-info',
    icon: '💰',
    showHeader: false,
    fields: [
      {
        type: 'header',
        label: 'Please check off all that apply & scan related documents',
        subtype: 'h5',
      },
      {
        type: 'text',
        label: 'Tax Return Year',
        className: 'form-control numeric validateYear',
        required: true,
      },
      {
        type: 'checkbox-group',
        label: 'Income',
        inline: false,
        className: 'form-control',
        required: true,
        values: [
          {
            label: 'T3 Dividend, Interest Income',
          },
          {
            label: 'T4 Employment Income',
          },
          {
            label: 'T4A Other Income Pension/Scholarships',
          },
          {
            label: 'T4A(P) Canada Pension Income',
          },
          {
            label: 'T4A (OAS) Old Age Security Income',
          },
          {
            label: 'T4E Employment Insurance',
          },
          {
            label: 'T4RIF RRSP Annuity',
          },
          {
            label: 'T4RSP RRSP Withdrawals',
          },
          {
            label: 'T5 Dividend, Interest Income',
          },
          {
            label: 'T5007 Seniors Supports/WCB/Social Assistance',
          },
          {
            label: 'T5013 Statement of Partnership Income',
          },
          {
            label: 'Self Employment Income - Business',
          },
          {
            label: 'My business is an online business',
          },
          {
            label: 'Self Employment Income - Rental Income',
          },
          {
            label: 'Self Employment Income - Farm Income',
          },
          {
            label: 'Sale of Any Property (home, land, buildings, stock, etc)',
          },
          {
            label: 'Alimony',
          },
          {
            label: 'Foreign Income',
          },
          {
            label: 'Other',
          },
        ],
      },
    ],
  },
  {
    label: 'Deductions/Expenses',
    name: 'deductions-expenses-info',
    icon: '🎫',
    showHeader: false,
    fields: [
      {
        type: 'header',
        label: 'Please check off all that apply & scan related documents',
        subtype: 'h5',
      },
      {
        type: 'checkbox-group',
        label: 'Deductions/Expenses',
        inline: false,
        className: 'form-control',
        required: true,
        values: [
          {
            label: 'RRSP Purchase',
          },
          {
            label: 'Treaty (scan treaty card)',
          },
          {
            label: 'Union or Professional Dues',
          },
          {
            label: 'Childcare Expenses',
          },
          {
            label: 'Moved 40 km or more for education or work (dates, km)',
          },
          {
            label: 'Home Buyers Plan/Lifelong Learning Plan',
          },
          {
            label: 'Investment Fees to a Financial Advisor',
          },
          {
            label: 'Trade / Apprentice Tools Receipts',
          },
          {
            label: 'Employment Expense (Form T2200 signed by employer)',
          },
          {
            label: 'Meals for Transport Employees (Schedule Required)',
          },
          {
            label: 'Alimony Paid',
          },
          {
            label: 'Northern Residence Claim',
          },
          {
            label: 'Teacher Supply Credit',
          },
          {
            label: 'Volunteer Firefighter/Seach & Rescue Volunteer',
          },
          {
            label: 'New Home Buyer',
          },
          {
            label: 'Caring for an aging parent or family member',
          },
          {
            label: 'Expenses for caring for a disabled child/parent/family member',
          },
          {
            label: 'Home Accessibility Costs Incurred',
          },
          {
            label: 'Disability Tax Credit',
          },
          {
            label: 'Interest on Student Loans',
          },
          {
            label: 'Tuition Fees (T2202)',
          },
          {
            label: 'Medical Expenses',
          },
          {
            label: 'Medical Health Plan Premiums',
          },
          {
            label: 'Charitable & Political Donation Receipts',
          },
          {
            label: 'Tax Installments Paid',
          },
          {
            label: 'Other',
          },
        ],
      },
    ],
  },
  {
    label: 'Family information',
    name: 'family-info',
    icon: '👫',
    showHeader: false,
    fields: [
      {
        type: 'radio-group',
        required: true,
        label: 'My marital status as of {date}:',
        inline: true,
        className: 'form-control marital-parent',
        values: [
          {
            label: 'Single',
            value: 'single',
            selected: false,
          },
          {
            label: 'Common-Law',
            value: 'common-law',
            selected: false,
          },
          {
            label: 'Married',
            value: 'married',
            selected: false,
          },
          {
            label: 'Divorced',
            value: 'divorced',
            selected: false,
          },
          {
            label: 'Widowed',
            value: 'widowed',
            selected: false,
          },
        ],
      },
      {
        type: 'date',
        className: 'form-control marital-child widowed',
        label: 'Widowed date',
      },
      {
        type: 'date',
        label: 'If changed in {year}, provide date of change:',
        className: 'form-control',
        required: false,
      },
      {
        type: 'text',
        label: 'First name of Spouse:',
        className: 'form-control marital-child married common-law',
        required: true,
      },
      {
        type: 'text',
        label: 'Last name of Spouse:',
        className: 'form-control marital-child married common-law',
        required: true,
      },
      {
        type: 'text',
        label: 'SIN',
        className: 'form-control masked marital-child married common-law sinMask',
        required: true,
      },
      {
        type: 'date',
        label: 'DOB',
        className: 'form-control marital-child married common-law',
        required: true,
      },
      {
        type: 'radio-group',
        required: true,
        label: "My spouse's income is zero for the tax year",
        inline: true,
        className: 'form-control marital-child married common-law',
        values: [
          {
            label: 'Yes',
            value: 'yes',
            selected: false,
          },
          {
            label: 'No',
            value: 'no',
            selected: true,
          },
        ],
      },
      {
        type: 'radio-group',
        required: true,
        label: 'I have dependend children under 18 years of age in the year',
        inline: true,
        className: 'form-control childrens-parent',
        values: [
          {
            label: 'Yes',
            value: 'yes',
            selected: false,
          },
          {
            label: 'No',
            value: 'no',
            selected: true,
          },
        ],
      },
      {
        type: 'inputsGroupName',
        subtype: 'h5',
        className: 'group1-master childrens-child yes',
        label:
          'My children (under the age of 18), dependants, as of the end of the calendar year are:',
      },
      {
        type: 'text',
        label: 'First and Last Name',
        className: 'form-control group1',
        required: false,
      },
      {
        type: 'date',
        label: 'DOB',
        className: 'form-control group1',
        required: false,
      },
      {
        type: 'text',
        label: 'SIN',
        className: 'form-control group1 masked sinMask',
        required: false,
      },
    ],
  },
  {
    label: 'Citizenship information',
    name: 'citizenship-info',
    icon: '🏠',
    showHeader: false,
    fields: [
      /*{
        type: 'newPage',
        label: 'Citizenship information',
      },*/
      {
        type: 'radio-group',
        required: true,
        label: 'I am a Canadian Citizen',
        inline: true,
        values: [
          {
            label: 'Yes',
            value: 'yes',
            selected: true,
          },
          {
            label: 'No',
            value: 'no',
            selected: false,
          },
        ],
      },
      {
        type: 'radio-group',
        required: true,
        label: 'I became or ceased to be a Canadian Resident during the year',
        className: 'date-group',
        inline: true,
        values: [
          {
            label: 'Yes',
            value: 'yes',
            selected: false,
          },
          {
            label: 'No',
            value: 'no',
            selected: true,
          },
        ],
      },
      {
        type: 'date',
        className: 'form-control date-group-single',
        label: 'Entry Date',
        required: true,
      },
      {
        type: 'date',
        className: 'form-control date-group-single',
        label: 'Exit Date',
        required: true,
      },
      {
        type: 'select',
        label: 'On December 31st, I lived in this Province/Territory',
        className: 'form-control',
        required: true,
        values: [
          {
            label: 'Alberta',
            value: 'AL',
            selected: false,
          },
          {
            label: 'British Columbia',
            value: 'BC',
            selected: false,
          },
          {
            label: 'Manitoba',
            value: 'MB',
            selected: false,
          },
          {
            label: 'New Brunswick',
            value: 'NB',
            selected: false,
          },
          {
            label: 'Newfoundland and Labrador',
            value: 'NL',
            selected: false,
          },
          {
            label: 'Northwest Territories',
            value: 'NT',
            selected: false,
          },
          {
            label: 'Nova Scotia',
            value: 'NS',
            selected: false,
          },
          {
            label: 'Nunavut',
            value: 'NU',
            selected: false,
          },
          {
            label: 'Ontario',
            value: 'ON',
            selected: false,
          },
          {
            label: 'Prince Edward Island',
            value: 'PE',
            selected: false,
          },
          {
            label: 'Quebec',
            value: 'QC',
            selected: false,
          },
          {
            label: 'Saskatchewan',
            value: 'SK',
            selected: false,
          },
          {
            label: 'Yukon',
            value: 'YT',
            selected: false,
          },
        ],
      },
      {
        type: 'radio-group',
        required: true,
        label: 'This is my first year filing a tax return',
        inline: true,
        values: [
          {
            label: 'Yes',
            value: 'yes',
            selected: false,
          },
          {
            label: 'No',
            value: 'no',
            selected: true,
          },
        ],
      },
      {
        type: 'radio-group',
        required: true,
        label: 'My income for the year is zero',
        inline: true,
        values: [
          {
            label: 'Yes',
            value: 'yes',
            selected: false,
          },
          {
            label: 'No',
            value: 'no',
            selected: true,
          },
        ],
      },
      {
        type: 'radio-group',
        required: true,
        label: 'I am filing a tax return for a deceased taxpayer',
        inline: true,
        values: [
          {
            label: 'Yes',
            value: 'yes',
            selected: false,
          },
          {
            label: 'No',
            value: 'no',
            selected: true,
          },
        ],
      },
      {
        type: 'radio-group',
        required: true,
        label: 'I was considered Bankrupt during the year',
        inline: true,
        className: 'bankrupt-parent',
        values: [
          {
            label: 'Yes',
            value: 'yes',
            selected: false,
          },
          {
            label: 'No',
            value: 'no',
            selected: true,
          },
        ],
      },
      {
        type: 'date',
        className: 'form-control bankrupt-child yes',
        label: 'Date of bankruptcy',
      },
      {
        type: 'radio-group',
        required: true,
        label: 'I was confined to a prison or similar institution in the year',
        inline: true,
        values: [
          {
            label: 'Yes',
            value: 'yes',
            selected: false,
          },
          {
            label: 'No',
            value: 'no',
            selected: true,
          },
        ],
      },
      {
        type: 'radio-group',
        required: true,
        label: 'I consent to release information to Elections Canada',
        inline: true,
        values: [
          {
            label: 'Yes',
            value: 'yes',
            selected: true,
          },
          {
            label: 'No',
            value: 'no',
            selected: false,
          },
        ],
      },
      {
        type: 'radio-group',
        required: true,
        label:
          'I held foreign property at any time during 2019  (eg. Rental property, investments, stocks, etc) totaling more than $100,000 CND',
        inline: true,
        values: [
          {
            label: 'Yes',
            value: 'yes',
            selected: false,
          },
          {
            label: 'No',
            value: 'no',
            selected: true,
          },
        ],
      },
      {
        type: 'radio-group',
        required: true,
        label:
          'I want to sign up for MyAccount with CRA and I would like to sign up to receive email notifications. (Note:  you will no longer receive mail through Canada Post with this option)',
        inline: true,
        values: [
          {
            label: 'Yes',
            value: 'yes',
            selected: false,
          },
          {
            label: 'No',
            value: 'no',
            selected: true,
          },
        ],
      },
      {
        type: 'radio-group',
        required: true,
        label: 'I want my refunds, (GST, Family Benefits, etc) direct deposited?',
        inline: true,
        values: [
          {
            label: 'Yes',
            value: 'yes',
            selected: true,
          },
          {
            label: 'No',
            value: 'no',
            selected: false,
          },
        ],
      },
    ],
  },
  {
    label: 'Other information',
    name: 'extra-info',
    icon: '?',
    showHeader: false,
    fields: [
      /*{
        type: 'newPage',
        label: 'Other information',
      },*/
      {
        type: 'radio-group',
        required: true,
        label: 'I am eligible for the disability tax credit?',
        inline: true,
        values: [
          {
            label: 'Yes',
            value: 'yes',
            selected: false,
          },
          {
            label: 'No',
            value: 'no',
            selected: true,
          },
        ],
      },
      {
        type: 'radio-group',
        required: true,
        label: 'I wish to split eligible pension income',
        inline: true,
        values: [
          {
            label: 'Yes',
            value: 'yes',
            selected: false,
          },
          {
            label: 'No',
            value: 'no',
            selected: false,
          },
          {
            label: 'n/a',
            value: 'n/a',
            selected: true,
          },
        ],
      },
      {
        type: 'radio-group',
        required: true,
        label: 'I am caring for a disabled parent/child/sibling?',
        inline: true,
        values: [
          {
            label: 'Yes',
            value: 'yes',
            selected: false,
          },
          {
            label: 'No',
            value: 'no',
            selected: true,
          },
        ],
      },
      {
        type: 'radio-group',
        required: true,
        label: 'I sold my home during the year ',
        inline: true,
        values: [
          {
            label: 'Yes',
            value: 'yes',
            selected: false,
          },
          {
            label: 'No',
            value: 'no',
            selected: true,
          },
        ],
      },
      {
        type: 'radio-group',
        required: true,
        label: 'I changed the use of my residence to a rental property',
        inline: true,
        values: [
          {
            label: 'Yes',
            value: 'yes',
            selected: false,
          },
          {
            label: 'No',
            value: 'no',
            selected: true,
          },
        ],
      },
      {
        type: 'radio-group',
        required: true,
        label: 'I changed my rental property to my residence',
        inline: true,
        values: [
          {
            label: 'Yes',
            value: 'yes',
            selected: false,
          },
          {
            label: 'No',
            value: 'no',
            selected: true,
          },
        ],
      },
    ],
  },
]

const defaultFields = [
  {
    className: 'form-control',
    label: 'Fill page name',
    type: 'newPage',
  },
]

const customFields = [
  {
    label: 'New Page',
    attrs: {
      type: 'newPage',
    },
    icon: '📋',
  },
  {
    label: 'Inputs Group Name',
    attrs: {
      type: 'inputsGroupName',
    },
    icon: '★',
  },
]

const controlOrder = [
  'text',
  'checkbox-group',
  'autocomplete',
  'radio-group',
  'select',
  'date',
]

const disabledAttrs = [
  'value',
  'placeholder',
  'access',
  'name',
  'toggle',
  'className',
  //'maxlength',
  'other',
  //'inline',
]

const disabledFields = ['paragraph', 'hidden', 'button']

export default {
  inputSets,
  defaultFields,
  customFields,
  controlOrder,
  disabledAttrs,
  disabledFields,
}
