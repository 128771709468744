
import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import Select from 'react-select';
import Button from 'components/CustomButton/CustomButton.jsx';
import apiRequest from '../../helpers/apiRequest';
class TaxFilesModal extends Component {
    state = {
        workers: [],
        selectedWorker: null,
        showSubmit: false
    }


    getWorkers = async (inputValue) => {
        let page = 0;
        let workerValue = " " || inputValue;
        const response = await apiRequest({
          method: 'get',
          url: `users?page=${
            page + 1
          }&pageSize=${10}&order=${"firstName"}&orderType=${"ASC"}&searchQuery=${workerValue}`,
        })
    
        if (response.status === 200) {
          let arrClients = []
          response.data.pageOfItems.forEach((client) => {
            arrClients.push({
              value: client.userId,
              label: client.firstName + " " + client.lastName,
            })
          })
          this.setState({ workers: arrClients })
        }
          return response
        
      }

    handleInputChange = (inputChange) =>{
            this.getWorkers(inputChange)
    }
    checkAssignedRequests = () =>{
        const assignedUsers = [];
        this.props.taxFiles.forEach(elem =>{
          if(elem.userId === this.state.selectedWorker.value){
            assignedUsers.push(elem)
          }  
        })
        return (
          assignedUsers.map(elem =>{
            return(
              <div 
              key={elem.requestId}
              style={{fontSize: "14px", marginLeft: "10px", borderRadius: "10px",  color: "#ffffff",}}
              className="alert alert-danger"
              >Request: <b>"{elem.requestName}"</b>, has already been assinged to <b>{elem.user.firstName + " " + elem.user.lastName}</b></div>
            )
          })
          );
          
  
      }

    checkIfAssigned = () =>{
        const style = {
            marginTop: "10px",
    
          }
        if(this.props.taxFilesLength === 0){
            return (
                <Modal.Body>
                    Please assign requests
                </Modal.Body>
            )
        }else{
            return( 
            <Modal.Body>
                <Select
                        onInputChange={this.handleInputChange}
                        isClearable={true}
                        className="basic-multi-select"
                        cursor="pointer"
                        options={this.state.workers}
                        value={this.state.selectedWorker}
                        onChange={(value) => this.setState({selectedWorker: value, showSubmit: true})}
                        
                      />
                    <div style={style}>
                    {this.state.selectedWorker ? this.checkAssignedRequests() : null}  
                    </div>
            </Modal.Body>)
           
        }
    }
    assignTextFile = async () =>{
        const data = {
            userId: this.state.selectedWorker.value,
            requests: []
          }
          this.props.taxFiles.forEach(elem =>{data.requests.push({requestId: elem.requestId, requestTypeId: elem.requestTypeId})})
          
          try{
            const sendReq = await apiRequest({
              url: 'taxFiles/user',
              method: 'post',
              data,
            })
      
            if (sendReq.status === 200) {
              console.log('Request sent')
              console.log(sendReq)
              this.props.closeModal();
              window.location.reload();
            }
          }catch (err){
            if (err.response.status === 404) {
              //this.props.errorAlert();
            }
        }
            
    }
    componentDidMount = () => {
      this.getWorkers()
    }
    
    render() {
        return(
            <Modal show={true}>
                <Modal.Header className="blueModal">
                  <div style={{display: "flex", flexDirection:"row", alignItems: "center", justifyContent: "space-between"}}>
                    <div>
                      <span className="modal-title customTitle" style={{ color: '#FFF'}}>
                        Selected {this.props.taxFilesLength} tax {this.props.taxFilesLength === 0 ? "requests." : "request."}
                      </span>
                    </div>

                    <div>
                    <Button 
                      bsStyle="primary" 
                      fill
                      onClick={
                        () => this.props.closeModal()}>
                        <span className="btn-label">
                          <i className="fa fa-times" />
                          {'  '}          
                        </span>
                        <span style={{ fontWeight: 'bold'}}>Cancel</span>
                      </Button> 
                    </div>
                  </div>
                </Modal.Header>

                {this.checkIfAssigned()}
                {this.state.selectedWorker ? 
                    <Modal.Footer>
                      <p style={{float: "left"}}>Are you sure you want to proceed?</p>
                      {this.state.showSubmit ?  <Button bsStyle="primary" onClick={this.assignTextFile}>Submit</Button> : null}
                    </Modal.Footer> : null}
            </Modal>
        )
    }



}


export default TaxFilesModal;