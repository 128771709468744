import React, { Component } from 'react'
// react component for creating dynamic tables
import ReactTable from 'react-table'
import AsyncSelect from 'react-select';
import { Grid, Row, Col, FormControl, FormGroup, InputGroup} from 'react-bootstrap';
import AssignModal from '../Components/AssignRequestModal';


import Card from 'components/Card/Card.jsx'
import Button from 'components/CustomButton/CustomButton.jsx'


import SweetAlert from 'react-bootstrap-sweetalert'

import { Link, Redirect } from 'react-router-dom'

import { apiRequest } from 'helpers'
import moment from 'moment'

class Requests extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      loading: false,
      pages: 0,
      searchParam: '',
      tableState: null,
      createProfileModal: null,
      alert: null,
      statusFilter: "all",
      checked: false,
      requests: [],
      requestLength: 0,
      showAssignModal: false,
      selectOptions: [
        {value: "all", label: "ALL"},
        {value: 1, label: "PENDING"},
        {value: 2, label: "WAITING FOR PAYMENT"},
        {value: 3, label: "PAYMENT SUBMITTED"},
        {value: 5, label: "COMPLETED"},
        {value: 6, label: "SUBMITTED FOR APPROVAL"},
      ],
      selectedLabel: null,
      notAllowedRedirect: null
    }
  }

  componentDidMount(){
    const userRole = localStorage.getItem('role');
    if(userRole === 'superAdmin'){
      this.setState({notAllowedRedirect: <Redirect to='/admin/not-allowed' />})
    }
  }

  getRequests = async (page = 0, pageSize = 10, sorted, filtered) => {
    let orderId = null
    let orderType = null
    const {statusFilter } = this.state
    const userId = localStorage.getItem('userId')
    const requests = [];

    if (sorted && sorted.length > 0) {
      orderId = sorted[0].id
      orderType = sorted[0].desc ? 'DESC' : 'ASC'
    }

    if (orderId === null && orderType === null) {
      orderId = 'dateCreated'
      orderType = 'ASC'
    }

    const response = await apiRequest({
      method: 'get',
      url: `requests/requestPagination?page=${
        page + 1
      }&pageSize=${pageSize}&order=${orderId}&orderType=${orderType}&searchQuery=${""}&statusFilter=${statusFilter}`,
      //url:  `requests?sort=status&order=asc`
    })

    if (response.status === 200) {
        console.log(response.data)
        response.data.requests.forEach((elem)=>{
            requests.push(elem)
          })

      return response
    }
  }
  changeCheckbox = (item, event) =>{
    if(event.target.checked){
      let localRequests = [...this.state.requests];
      let newList = localRequests.concat(item) 
        this.setState({
          requests: newList,
          requestLength: newList.length,

        })
    }else{
      let localRequests = [...this.state.requests];
      localRequests.forEach(elem =>{
        if ( elem.requestId === item.requestId) { 
          localRequests.splice(elem, 1); 
          this.setState({
            requests: localRequests,
            requestLength: localRequests.length
  
          })
      }
      })
    }
  }

  onFetchData = async (state, instance = null) => {
    this.setState({ loading: true })
    console.log('STATE')
    console.log(state)
    const res = await this.getRequests(
      state.page,
      state.pageSize,
      state.sorted,
      state.filtered
    )


    if (res) {
      console.log(res)
      let data = res.data.requests.map((item, key) => {
        return {
          id: item.requestId,
          requestName: item.requestName,
          dateCreated: moment.utc(item.dateCreated).format('L'),
          dateFinished: item.dateFinish
             ? moment.utc(item.dateFinish).format('L')
             : 'Not finished',
          client: (item['client.firstName'] + ' ' + item['client.lastName']),
          // checkBox:(
          //   <div style={{textAlign: "right"}}>
          //     <input type="checkbox" id="chechbox" className="check" name="check" onChange={(event) => this.changeCheckbox(item, event)}/>
          //   </div>
          // ),
          status: (
            <span
              style={{
                fontWeight: '600',
                color: this.colorForStatus(item['requeststatus.statusKey']),
              }}>
              {item['requeststatus.status']}
            </span>
          ),
          actions: (
            // we've added some custom button actions
            <div className="actions-right" >
              {/* use this button to add a like kind of action */}
              <Link to={`/admin/requests/${item.requestId}`}>
                <Button bsStyle="primary" leftMargin={false}>Open</Button>
              </Link>{' '}
            </div>
          ),
          selectAction: (
            console.log("clicked")
          )
        }
        
      })
      this.setState({
        data,
        pages: 2,
        loading: false,
        tableState: state,
      })
    }
  }
  

  createProfile = async (email) => {
    console.log('== CALLED ==')
    const data = {
      email,
    }

    try {
      const response = await apiRequest({
        url: 'users/addWorker',
        method: 'post',
        data,
      })
      console.log('RESPONSE')
      console.log(response)
      if (response.status === 200) {
        this.successAlert('New profile for worker has been created.', true)
      }
    } catch (err) {
      console.log('==ERROR==')
      this.errorAlert(err.response.data.message)
    }
  }

  toggleActive = async (userId) => {
    console.log('== POZVANO ==')
    try {
      const response = await apiRequest({
        method: 'PUT',
        url: 'users/makeUserInactiveActive/' + userId,
      })
      console.log('== RESP ==')
      console.log(response)
      if (response.status === 203 || response.status === 200) {
        this.successAlert('The profile has been changed.', true)
      }
    } catch (err) {
      this.errorAlert()
    }
  }

  warningWithConfirmMessage = (status, userId) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title={`Are you sure you want to make this profile ${
            status ? ' inactive' : 'active'
          } ?`}
          onConfirm={() => this.toggleActive(userId)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes"
          cancelBtnText="Cancel"
          showCancel>
          This profile will be {status ? 'disabled' : 'enabled'}.
        </SweetAlert>
      ),
    })
  }

  removeModal = () => {
    this.setState({ createProfileModal: null })
  }

  colorForStatus = (status) => {
    console.log('====== statuses =======')
    console.log(status)
    switch (status) {
      case 'pending':
        return '#EC5758'
      case 'completed':
        return '#29AE60'
      case 'scanned':
        return '#29AE60'
      case 'signed':
        return '#29AE60'
      case 'paymentFailed':
        return '#F45B69'
      default:
        return '#F2994B'
    }
  }

  /* SUCCESS AND ERROR ALERTS */
  successAlert(msg = '', refresh = false) {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{
            display: 'block',
            marginTop: '-100px',
          }}
          title="Done"
          onConfirm={() => this.hideAlert(refresh)}
          confirmBtnBsStyle="info">
          {msg}
        </SweetAlert>
      ),
    })
  }

  errorAlert(message = 'There was an error. Please try again.') {
    this.setState(
      {
        alert: (
          <SweetAlert
            danger
            style={{
              display: 'block',
              marginTop: '-100px',
            }}
            title="Error"
            onConfirm={() => this.hideAlert()}
            confirmBtnBsStyle="info">
            {message}
          </SweetAlert>
        ),
      },
      () => {
        this.removeModal()
      }
    )
  }

  hideAlert(reloadData = false) {
    this.setState(
      {
        alert: null,
      },
      () => {
        if (reloadData) {
          this.removeModal()
          this.onFetchData(this.state.tableState)
        }
      }
    )
  }
  closeModal = () =>{
    this.setState({
      showAssignModal: false
    })
  }
  selectChanged = (e) =>{
    console.log(this.state.statusFilter)
    this.setState({ statusFilter: e.target.value });
  }


  renderFilterButtons = () => {
    const { statusFilter } = this.state
    return (
        <div style={{width: "100%"}}>
          {/* {this.state.requestLength > 0 ?
            <Button
            bsStyle="primary"
            style={{ marginLeft: 5, marginBottom: 5 }}
            fill={statusFilter === 1}
            onClick={() => {
              this.setState(
                {
                  showAssignModal: true
                }
              )
            }}>
            ASSIGN REQUEST
          </Button>   
        : null} */}
        
        <div style={{float: "right", textAlign: "left", width: "30%", marginLeft: "20px"}}>
          <AsyncSelect
            placeholder={this.state.selectedLabel !== null ? this.state.selectedLabel: "ALL"}
            name="sdasdass"
            options={this.state.selectOptions}
            onChange={(value) =>{
              this.setState({statusFilter : value.value, selectedLabel: value.label}, () => this.onFetchData(this.state.tableState))}}
          ></AsyncSelect>
        </div>
        
          
        
      </div>
    )
  }
  render() {
    const { data, pages, loading, notAllowedRedirect } = this.state
    return (
      <div className="main-content">
        {notAllowedRedirect}
        {this.state.showAssignModal ? 
        <AssignModal 
          showModal={this.state.showAssignModal} 
          closeModal={this.closeModal} 
          requests={this.state.requests} 
          requestLength={this.state.requestLength}
          /> : null}
          
        {this.state.alert}
        <Grid fluid>
          <Row style={{ marginBottom: 10 }}>
            <Col md={6}>
              <div className="text-left">
                <span className="title customTitle">Requests Management Console</span>
              </div>
            </Col>
            <Col md={6}>
              <div className="text-right">
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Addon>
                      <i className="fa fa-search" />
                    </InputGroup.Addon>
                    <FormControl
                      onChange={(event) => {
                        this.setState({ searchParam: event.target.value }, () => {
                          this.onFetchData(this.state.tableState)
                        })
                      }}
                      type="text"
                      placeholder="Search..."
                    />
                  </InputGroup>
                </FormGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div>
                    <Row style={{ marginBottom: 10 }}>
                      <Col md={6}>
                        <div className="text-left">
                          <h4 className="title">Requests List</h4>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="text-right">{this.renderFilterButtons()}</div>
                        
                      </Col>
                    </Row>
                    <ReactTable
                      data={data}
                      ref={(refReactTable) => {
                        this.refReactTable = refReactTable
                      }}
                      filterable
                      columns={[
                        {
                          Header: 'Request Name',
                          sortable: true,
                          filterable: false,
                          accessor: 'requestName',
                        },
                        {
                          Header: 'Created Date',
                          sortable: true,
                          filterable: false,
                          accessor: 'dateCreated',
                        },
                        {
                          Header: 'Finished Date',
                          sortable: false,
                          filterable: false,
                          accessor: 'dateFinished',
                        },
                        {
                          Header: 'Client',
                          sortable: false,
                          filterable: false,
                          accessor: 'client',
                        },
                        {
                          Header: 'Request Status',
                          sortable: false,
                          filterable: false,
                          accessor: 'status',
                        },
                        {
                          Header: 'Actions',
                          accessor: 'actions',
                          sortable: false,
                          filterable: false,
                        },
                        //  {
                        //   Header: 'Select',
                        //   accessor: 'checkBox',
                        //   sortable: false,
                        //   filterable: false,
                          
                        // },
                      ]}
                      defaultPageSize={10}
                      //showPaginationTop
                      pages={pages}
                      manual
                      showPaginationBottom={true}
                      loading={loading}
                      showPagination={true}
                      onFetchData={this.onFetchData}
                      className="-striped -highlight"
                    />
                  </div>
                }
              />
            </Col>
          </Row>
          {this.state.createProfileModal}
        </Grid>

       
      </div>
    )
  }
}

export default Requests
