import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Footer from 'components/Footer/Footer.jsx'
import AuthNavbar from 'components/Navbars/AuthNavbar.jsx'

// dinamically create pages routes
import routes from 'routes.js'

import bgImage from 'assets/img/login-image.png'

class Pages extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: null,
    }
  }
  componentDidMount() {
    const userToken = localStorage.getItem('usertoken')
    console.log('USER TOKEN')
    console.log(userToken)

    if (userToken) {
      this.setState({ redirect: <Redirect to="/admin/dashboard" /> })
    }
  }

  componentWillMount() {
    if (document.documentElement.className.indexOf('nav-open') !== -1) {
      document.documentElement.classList.toggle('nav-open')
    }
  }
  getPageClass() {
    var pageClass = ''
    switch (this.props.location.pathname) {
      case '/auth/login-page':
        console.log('== PATH ==')
        console.log(this.props.location.pathname)
        pageClass = ' login-page'
        break
      case '/auth/set-account':
        pageClass = ' login-page'
        break
      case '/auth/register-page':
        pageClass = ' register-page'
        break
      case '/auth/privacy-policy':
        pageClass = ' privacy-page'
        break
      case '/auth/lock-screen-page':
        pageClass = ' lock-page'
        break
      default:
        if (this.props.location.pathname.includes('set-account')) {
          pageClass = ' login-page'
        } else {
          pageClass = ''
        }
        break
    }
    return pageClass
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views)
      }
      if (prop.layout === '/auth') {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />
      } else {
        return null
      }
    })
  }
  render() {
    return (
      <div>
        {this.state.redirect}
        <AuthNavbar />
        <div className="wrapper wrapper-full-page">
          <div
            className={'full-page' + this.getPageClass()}
            data-color="black"
            data-image={bgImage}>
            <div className="content">
              <Switch>{this.getRoutes(routes)}</Switch>
            </div>
            <Footer transparent />
            <div
              className="full-page-background"
              style={{ backgroundImage: 'url(' + bgImage + ')', backgroundColor: 'linear-gradient(180deg, rgba(0, 153, 131, 0.7) 0%, rgba(102, 194, 181, 0.7) 100%);' }}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default Pages
