import React, { Component } from 'react'
import { Modal, FormControl, FormGroup } from 'react-bootstrap'
import Button from 'components/CustomButton/CustomButton.jsx'
import Checkbox from 'components/CustomCheckbox/CustomCheckbox.jsx'
import Select from 'react-select'
import Upload from 'components/Upload/Upload.jsx'
import Dropzone from 'components/Dropzone/Dropzone'
import { apiRequest } from 'helpers'

class UpdateRequestModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: true,
      errorMessage: null,
      updateType: 'newDocument',
    }
  }

  closeModal = () => {
    //this.setState({ showModal: false })
    this.props.closeModal()
  }
  addNewDocuments = (documents) => {
    this.setState({ newDocuments: documents })
  }

  sendRequest = () => {
    const { newDocuments } = this.state
    //   if ( newDocuments.length <= 0 ) {
    //     this.setState({
    //       errorMessage: (
    //         <small className="text-danger">
    //           {type === 'documents'
    //             ? 'Please select or upload some documents.'
    //             : 'Please choose at least one form.'}
    //         </small>
    //       ),
    //     })
    //     return
    //   }
    this.props.sendRequest(newDocuments)
  }

  render() {
    return (
      <Modal show={this.state.showModal} onHide={() => this.props.closeModal()}>
        <Modal.Header className="blueModal">
          <span className="modal-title customTitle" style={{ color: '#FFF' }}>
            Update the request
          </span>
          <div className="pull-right">
            <Button bsStyle={'primary'} fill onClick={() => this.sendRequest()}>
              <span className="btn-label">
                <i className="fa fa-paper-plane" />
                {'  '}
              </span>
              <span style={{ fontWeight: 'bold' }}>Send</span>
            </Button>
            <Button bsStyle="primary" fill onClick={() => this.props.closeModal()} >
              <span className="btn-label">
                <i className="fa fa-times" />
                {'  '}
              </span>
              <span style={{ fontWeight: 'bold' }}>Cancel</span>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage}
            <div>
              <Upload noUpload={true} addNewDocuments={this.addNewDocuments} />
              {/* <Dropzone /> */}
            </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default UpdateRequestModal
