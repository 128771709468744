import { combineReducers } from 'redux'


import notificationsReducer from './notificationsReducer'
import applicationReducer from './applicationReducer'
import stripeNotficationReducer from './stripeNotficationReducer'

const rootReducer = combineReducers({
  notifications: notificationsReducer,
  apps: applicationReducer,
  stripeNotification: stripeNotficationReducer
})

export default rootReducer

