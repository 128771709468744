import React, { Component } from 'react'
import { Modal, FormControl, Row, Col } from 'react-bootstrap'
import Button from 'components/CustomButton/CustomButton.jsx'
import { validateEmail } from 'helpers'

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";


class CreateClientProfileModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: true,
      firstName: '',
      lastName: '',
      userEmail: '',
      errorMessage: null,
      value: '',
      phoneNumber: null
    }
  }

  closeModal = () => {
    this.setState({ showModal: false })
    this.props.removeModal()
  }

  createProfile = () => {
    const { userEmail, phoneNumber, firstName, lastName } = this.state
    console.log(phoneNumber)

    if (userEmail === '') {
      this.setState({
        errorMessage: <small className="text-danger">Email address cannot be empty.</small>,
      })
      return
    }
    if (phoneNumber === null) {
      this.setState({
        errorMessage: <small className="text-danger">Phone number cannot be empty.</small>,
      })
      return
    }

    if (!validateEmail(userEmail)) {
      this.setState({
        errorMessage: <small className="text-danger">Email address needs to be valid.</small>,
      })
      return
    }

    this.props.createProfile(firstName, lastName, userEmail, phoneNumber)
    this.setState({ showModal: false })
  }

  render() {
    const { userEmail, firstName, lastName } = this.state
    return (
      <Modal show={this.state.showModal} onHide={() => this.closeModal()} className=''>
        <Modal.Header className="blueModal">
          <span className="modal-title customTitle" style={{ color: '#FFF' }}>
            Create a new profile for client
          </span>
          <div className="pull-right">
            <Button bsStyle="primary" fill onClick={() => this.createProfile()}>
              <span className="btn-label">
                <i className="fa fa-paper-plane" />
                {'  '}
              </span>
              <span style={{ fontWeight: 'bold' }}>Send</span>
            </Button>
            <Button bsStyle="primary" fill onClick={() => this.closeModal()}>
              <span className="btn-label">
                <i className="fa fa-times" />
                {'  '}
              </span>
              <span style={{ fontWeight: 'bold' }}>Cancel</span>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage}
          <div>
            <h3 className="customSubtitle top10">Enter email address: </h3>
            <small>We'll send an email invitation to the client's email address</small>
            <Row>
              <Col md={12}>
                <FormControl
                className="modalTitleBox"
                placeholder="E-mail"
                value={userEmail}
                type="email"
                style={{marginBottom: '20px'}}
                onChange={(event) => {
                  this.setState({ userEmail: event.target.value })
                }}
              />
              </Col>
              <Col md={12}>
              <PhoneInput
                  name="phoneNumber"
                  type="text"
                  country={'ca'}
                  enableAreaCodes={true}
                  autoFormat
                  style={{width: '80%'}}
                  value={this.state.phoneNumber}
                  onChange={(value) => this.setState({phoneNumber: value})}
                  containerClass="contClass"
                  inputClass="inpClass"
                  required
                  placeholder="Phone number*"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormControl
                  className="modalTitleBox"
                  placeholder="First name (optional)"
                  value={firstName}
                  type="text"
                  style={{marginBottom: '20px'}}
                  onChange={(event) => {
                    this.setState({ firstName: event.target.value })
                }}
              />
              </Col>
              <Col md={6}>
                <FormControl
                  className="modalTitleBox"
                  placeholder="Last name (optional)"
                  value={lastName}
                  type="text"
                  style={{marginBottom: '20px'}}
                  onChange={(event) => {
                    this.setState({ lastName: event.target.value })
                }}
              />
              </Col>
            </Row>
             
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default CreateClientProfileModal
