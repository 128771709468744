import React, { useState, useEffect } from 'react'
import { Modal, Row, Col } from 'react-bootstrap';
import Button from 'components/CustomButton/CustomButton';
import moment from 'moment';
import '../UserInformations/subscriptions.css'

const TaxPaymentModal = (props) => {
    const [totalAmount, setTotalAmount] = useState('')

    useEffect(() => {
        let amount = props.data.amount
        props.data.paymentDetails.taxRates.forEach(elem => {
            amount *= (1 + parseFloat(elem.percentage)/100)
        })
        setTotalAmount(amount.toFixed(2))
    }, [totalAmount])


    return (
        <Modal show={true}>
              <Modal.Header style={{backgroundColor: '#58A4B0', borderTopRightRadius: '10px', borderTopLeftRadius: '10px'}}>
                <span className="modal-title customTitle" style={{ color: '#FFF' }}>
                    Payment confirmation
                </span>
                <div className="pull-right">
                    <Button bsStyle="primary" style={{border: '1px solid white', color: 'white'}} onClick={props.closeModal}>
                    <span className="btn-label">
                        <i className="fa fa-times" />
                        {'  '}
                    </span>
                    <span style={{ fontWeight: 'bold' }}>Close Modal</span>
                    </Button>
                </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='modal-row-first'>
                        <Row className='modal-row'>
                            <Col md={6} className='modal-col'>
                                <p className='payment-head'>Billed to</p>
                                <p className='payment-info'>{localStorage.getItem('username')}</p>
                            </Col>
                            <Col md={6} className='modal-col'>
                                <p className='payment-head'>Payment date</p>
                                <p className='payment-info'>{moment().format("MMM Do YY")}</p>
                            </Col>
                        </Row>
                    </div>
                    <div className='payment-info-row'>
                        <div className='payment-info-content'>
                            <p className='service-head'>Service</p>
                            <p className='service-head'>Price</p>
                        </div>
                        <div className='payment-info-content'>
                            <p className='service'>Number of tax requests:</p>
                            <p className='service'>{props?.data?.requestQuantity}</p>
                        </div>
                        <div className='payment-info-content'>
                            <p className='service'>Price of tax requests</p>
                            <p className='service'>${props?.data?.amount} CAD</p>
                        </div>
                        {props.data.paymentDetails.taxRates.length > 0 && props.data.paymentDetails.taxRates.map((item, index) => (
                            <div className='payment-info-content' key={index}>
                                <p className='service'>{item.display_name}</p>
                                <p className='service'>{item.percentage}%</p>
                            </div>
                        ))}
                        <hr className='payment-hr'/>
                        <div className='total-amount-container'>
                            <p className='total'>Total amount</p>
                            <p className='total'>${totalAmount} CAD</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="text-center">
                        <Button bsStyle="primary" style={{border: '1px solid white', color: 'white', backgroundColor: '#58a4b0'}} onClick={() => props.buyTax()}>
                        <span className="btn-label">
                            <i className="fa fa-shopping-cart" />
                            {'  '}
                        </span>
                        <span style={{ fontWeight: 'bold' }}>Buy</span>
                        </Button>
                    </div>
                </Modal.Footer>
        </Modal>
    )
}

export default TaxPaymentModal
