import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import './company-info.css';

/*Components*/
import SetTOS from './SetTOS';
import EditFirmProfile from './EditFirmProfile';

const CompanyInfoRoot = () => {

    const [page, setPage] = useState((<EditFirmProfile />))
    const [allPages, setAllPages] = useState([
        {id: 1, pageName: 'Edit firm profile', component: (<EditFirmProfile />), active: true},
        {id: 2, pageName: 'Terms of Service', component: (<SetTOS />), active: false},
    ])

    const renderComponent = (id) => {
        const pages = [...allPages];
        pages.forEach(elem => elem.active = false)
        pages.forEach(elem => {
            if(elem.id === id){
                elem.active = true
                setPage(elem.component)
            }
        })
        setAllPages([...pages])
    }

    return (
        <div style={{minHeight: '90vh'}}>
            <Row className='firm-tabs-container'>
                {allPages.length > 0 && allPages.map(elem => (
                    <Col md={2} key={elem.id} className={elem.active ? 'firm-tab tab-active' : 'firm-tab'} onClick={() => renderComponent(elem.id)}>
                        {elem.pageName}
                    </Col>
                ))}
            </Row>
            {page}
        </div>
    )
}

export default CompanyInfoRoot
