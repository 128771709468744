/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from 'react'
import AccountantBg from 'assets/img/accountant-bg.jpg'

export class UserCard extends Component {
  render() {
    const { hideHeaderImg } = this.props
    return (
      <div className="card card-user">
        {!hideHeaderImg && (
          <div className="image">
            <img src={AccountantBg} alt="..." />
          </div>
        )}
        <div className="content">
          <div className={`author ${hideHeaderImg ? 'smallTopMargin' : ''}`}>
            <a href="#pablo">
              <img className="avatar border-gray" src={this.props.avatar} alt="..." />
              <h4 className="title">
                {this.props.name}
                <br />
                <small>{this.props.userName}</small>
              </h4>
            </a>
          </div>
          <p className="description text-center">{this.props.description}</p>
        </div>
        <hr />
        <div className="text-center">{this.props.socials}</div>
      </div>
    )
  }
}

export default UserCard
