import React, { Component } from 'react'
import { Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap'

import Button from 'components/CustomButton/CustomButton.jsx'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'

class HeaderLinks extends Component {

  state = { 
    companyCode: localStorage.getItem('companyCode'),
    styles: {display: 'none'}
  }


  logout = () => {
    localStorage.removeItem('usertoken')
    localStorage.removeItem('userId')
    localStorage.removeItem('senderId')
    localStorage.removeItem('username')
    localStorage.removeItem('role')
    localStorage.removeItem('companyCode')
    //localStorage.removeItem('notification')
    window.location.reload()
  }

  hasNewNotifications = () => {
    const { notifications } = this.props

    let totalNotSeen = 0
    if (notifications) {
      notifications.forEach((notify) => {
        if (!notify.seen) {
          totalNotSeen++
        }
      })
    }

    if (totalNotSeen > 0) {
      return (
        <div
          style={{
            position: 'absolute',
            top: -3,
            right: -7,
            width: 10,
            height: 10,
            borderRadius: 14,
            backgroundColor: 'red',
          }}></div>
      )
    } else {
      return null
    }
  }
  copyToClipboard = () =>{
    navigator.clipboard.writeText(this.state.companyCode)
    this.setState({styles: {display: 'block', border: '1px solid black', 
    position: 'absolute', top: '80%', right: '20%', backgroundColor: '#414142', color: '#FFFFFF', padding: '10px', borderRadius: '10px'}})
    setTimeout(() => {
      this.setState({styles: {display: 'none'}})
    }, 500)
  }



  render() {
    return (
      <div>
        {/*<Navbar.Form pullLeft className="navbar-search-form">
          <FormGroup>
            <InputGroup>
              <InputGroup.Addon>
                <i className="fa fa-search" />
              </InputGroup.Addon>
              <FormControl type="text" placeholder="Search..." />
            </InputGroup>
          </FormGroup>
        </Navbar.Form>*/}
        <Nav pullRight>
        <NavItem eventKey={3} href="#" style={{position: 'relative'}}>
            {localStorage.getItem('companyCode') !== null ? 
            <p onClick={() => this.copyToClipboard()}>Company Code: <b id='companyCode'>
              {localStorage.getItem('companyCode')}</b>
            </p> : null}
            <br />
            <span style={this.state.styles}>Copied!</span> 
          </NavItem>
          {/* <NavItem eventKey={3} href="#">
            <i className="fa fa-line-chart" />
            <p>Stats</p>
          </NavItem> */}
          
          {/*<NavDropdown
            eventKey={2}
            title={
              <div>
                <i className="fa fa-gavel" />
                <p className="hidden-md hidden-lg">
                  Actions
                  <b className="caret" />
                </p>
              </div>
            }
            noCaret
            id="basic-nav-dropdown-1">
            <MenuItem eventKey={2.1}>Create New Post</MenuItem>
            <MenuItem eventKey={2.2}>Manage Something</MenuItem>
            <MenuItem eventKey={2.3}>Do Nothing</MenuItem>
            <MenuItem eventKey={2.4}>Submit to live</MenuItem>
            <MenuItem divider />
            <MenuItem eventKey={2.5}>Another action</MenuItem>
          </NavDropdown> */}
          <Button
            onClick={() => this.props.history.push('/admin/notifications-activity')}
            style={{ border: 0 }}>
            <div style={{ position: 'relative' }}>
              <i className="fa fa-bell-o" style={{ fontSize: 20 }} />
              {this.hasNewNotifications()}
            </div>
          </Button>
          <NavDropdown
            eventKey={4}
            title={
              <div>
                <i className="fa fa-list" />
                <p className="hidden-md hidden-lg">
                  More
                  <b className="caret" />
                </p>
              </div>
            }
            noCaret
            id="basic-nav-dropdown-3"
            bsClass="dropdown-with-icons dropdown">
            <MenuItem
              eventKey={4.1}
              onClick={() => this.props.history.push('/admin/messenger')}>
              <i className="pe-7s-mail" /> Messages
            </MenuItem>
            {/* <MenuItem eventKey={4.2}>
              <i className="pe-7s-help1" /> Help Center
            </MenuItem>
            <MenuItem eventKey={4.3}>
              <i className="pe-7s-tools" /> Settings
            </MenuItem>
            <MenuItem divider /> */}
            {/* <MenuItem eventKey={4.4}>
              <i className="pe-7s-lock" /> Lock Screen
            </MenuItem> */}
            <MenuItem eventKey={4.5} onClick={() => this.logout()}>
              <div className="text-danger">
                <i className="pe-7s-close-circle" /> Log out
              </div>
            </MenuItem>
          </NavDropdown>
        </Nav>
      </div>
    )
  }
}

const mapStateToProps = (state) => (
  {
    loading: state.notifications.loading,
    notifications: state.notifications.notifications,
    hasErrors: state.notifications.hasErrors
  })

export default withRouter(connect(mapStateToProps)(HeaderLinks))
