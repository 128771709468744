import React, { Component } from 'react'
import { Grid } from 'react-bootstrap'

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props)
    this.state = {
      alert: null,
    }
  }

  render() {
    return (
      <div className="main-content">
        {this.state.alert}
        <Grid fluid style={{ color: '#fff', paddingTop: 120 }}>
          <h2>
            Privacy Policy of <span class="website_url">My Mobile Tax</span>
          </h2>

          <p>
            Gail J Broadbent Professional Corporation understands that you value your privacy
            and that you wish to have your personal information kept secure. For these reasons
            we place a high priority on the security of information we hold. We have developed
            this policy to inform you about how we manage your personal information and
            maintain its integrity and security in accordance with the Canadian Personal
            Information Protection and Electronic Documents Act (PIPEDA). We will safeguard and
            keep confidential any information collected relating to clients that is required to
            be kept confidential and safeguarded in accordance with governing laws, regulatory
            authorities, Rules of Professional Conduct/Code of Ethics, Firm policy and specific
            client instructions or agreements.
          </p>

          <p>
            We may review and update this Privacy Policy from time to time to take account of
            new laws and technology. All personal information held by us will be governed by
            the most up-to-date version of this Privacy Policy posted on our website.
          </p>

          <h3>Types of Information Collected and Held</h3>

          <p>Confidential information includes, but is not limited to:</p>
          <ul>
            <li>Name or other unique identifiers</li>
            <li>Date of Birth</li>
            <li>Addresses, phone, fax, e-mail</li>
            <li>Age, sex, marital status, personal relationships</li>
            <li>Family and close relationships</li>
            <li>Occupation and employment details</li>
            <li>Income amounts and sources</li>
            <li>Expenses incurred and sources</li>
            <li>Lifestyle information including medical</li>
            <li>Buying and consumption tendencies</li>
            <li>Financial or business information of any nature</li>
            <li>
              Proprietary trade information, secrets, processes, products or market knowledge
            </li>
            <li>
              Government or other regulatory information identification numbers or similar
              identities
            </li>
            <li>Electronic documents, data and communications</li>
            <li>
              Banking information including but not limited to bank statements, loans, credit
              card statements, investment statements
            </li>
            <li>Insurance documents</li>
          </ul>

          <p>We may collect personal information directly from you when you:</p>
          <ul>
            <li>Complete a form, manually or electronically</li>
            <li>Register in a mobile app</li>
            <li>
              Over the phone verbally, text, fax, face book, email, electronic chats, mobile
              applications
            </li>
            <li>Provide manual and/or scanned copies of documents</li>
            <li>Provide feedback</li>
            <li>Authorize our firm with Canada Revenue Agency or any other body</li>
            <li>Where we are required or authorized by law to do so</li>
          </ul>

          <h3>Collection and Retention of Information</h3>

          <p>
            We collect and retain personal information primarily to record appropriate details
            for the engagement, service or order you have placed with us and also that we may:
          </p>

          <ul>
            <li>Administer our client relationship</li>
            <li>
              Provide you with information, products or services you have requested or we feel
              may be of interest to you
            </li>
            <li>Acquire goods from us or use our services</li>
            <li>Request information about us, our products and services</li>
            <li>Provide feedback</li>
            <li>Fill in a form</li>
            <li>Where we are required or authorized by law to do so</li>
          </ul>

          <p>
            You consent to us using your personal information in those ways and for sending you
            information about our products, services and special offers. Personal and client
            information shall be retained only as long as necessary for the fulfilment of
            professional, regulatory and legal requirements.
          </p>

          <h3>Access and Use of Information</h3>

          <p>
            Client information and any personal information obtained shall be used or disclosed
            only for the purpose collected, except with the consent of the individual or entity
            or as required by law. You have the right to request access to you personal
            information and seek correction of same. You may do so by contacting us.
          </p>

          <h3>Security of Your Personal Information</h3>

          <p>
            We are committed to maintaining the security and confidentiality o f the data and
            information you provide us and will take all reasonable precautions to protect your
            personal information with the aid of our stringent security procedures and
            technology. Any personal information you provide to us will be collected and held
            by us or on our behalf. Access to and use of personal information within Gail J
            Broadbent Professional Corporation is appropriately limited to prevent misuse or
            unlawful disclosure of the information. Where the personal information is no longer
            required by us or by law, we delete the information. We will not otherwise disclose
            your personal information unless the disclosure:
          </p>

          <ul>
            <li>
              Is in accordance with this Privacy and Confidentialy Policy or any agreement you
              enter with us
            </li>
            <li>Is required by law</li>
            <li>Is authorized by law</li>
            <li>Is made with your consent</li>
          </ul>

          <h3>Quality of Your Personal Information</h3>

          <p>
            We aim to ensure that your personal information is accurate, complete, and up to
            date. To assist us in this, the responsibility remains with you to provide true,
            accurate, current and complete information about yourself as request and properly
            update the information to us to keep it true, accurate, current, and complete.
          </p>

          <p>
            Please contact us if you believe the information is inaccurate or incomplete, and
            we will use all reasonable efforts to correct the information.
          </p>

          <h3>Contact Us About Privacy</h3>

          <p>
            If you have any questions or would like further information about our privacy and
            information handling practices, please contact us:
          </p>

          <li>
            Email: <a href="mailto:admin@gailbroadbentcpa.com">admin@gailbroadbentcpa.com</a>
          </li>
          <li>Phone: 780-623-3735</li>
          <li>Fax: 780-623-3745</li>
          <li>Mail: Box 1992, Lac La Biche, AB T0A 2C0</li>

          <h3>Complaints Handling</h3>

          <p>
            If you are concerned in the manner we have dealt with your personal information
            and/or if you believe there is potential breach of your privacy and/or
            confidentiality, you may contact us using the contact information above and provide
            the details in writing of your complaint. We will investigate any complaint
            promptly and will contact you for further information if required. You will be
            contacted with our resolution on the matter.
          </p>
        </Grid>
      </div>
    )
  }
}

export default PrivacyPolicy
