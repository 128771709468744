import * as actions from '../actions/stripeNotificationActions'
import StripeNotification from 'views/StripeNotification/StripeNotif'

const INITAL_STATE = {
    isVisible: null
}

export default function stripeNotficationReducer (state=INITAL_STATE, action) {
    switch(action.type){
        case actions.SET_STRIPE_NOTIFICATION:
            return{...state, isVisible: action.val}
        default: {
            return state 
        }
    }
}