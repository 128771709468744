import React, { Component } from 'react'
import { Grid} from 'react-bootstrap'
// react component used to create alerts

class Redirection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      alert: null,
    }
  }

  componentDidMount() {
    this.openApp()
    setTimeout(() => {
      this.actualRedirect()
    }, 250)
  }

  openApp = () => {
    window.location.replace('mobilecpa://')
  }

  actualRedirect = () => {
    const { url } = this.props.match.params
    console.log('=== REDIRECTION MOUNT ===')
    if (url) {
      const decodedUrl = decodeURIComponent(url)
      window.location.replace(decodedUrl)
    }
  }

  render() {
    return (
      <div className="main-content">
        {this.state.alert}
        <Grid fluid></Grid>
      </div>
    )
  }
}

export default Redirection
