import React, { Component } from 'react'
import { Modal, FormControl, FormGroup } from 'react-bootstrap'
import Button from 'components/CustomButton/CustomButton.jsx'
import Checkbox from 'components/CustomCheckbox/CustomCheckbox.jsx'
import Select from 'react-select'
import Upload from 'components/Upload/Upload.jsx'
import { apiRequest } from 'helpers'

class UpdateRequestModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: true,
      publicDocuments: [],
      selectedPublicDocuments: [],
      newDocuments: [],
      forms: [],
      selectedForms: [],
      errorMessage: null,
      updateType: 'newDocument',
      scanDocumentName: '',
      isScanMandatory: false,
    }
  }

  componentDidMount() {
    this.getAllPublicDocuments()
    this.getAllForms()
  }

  closeModal = () => {
    this.setState({ showModal: false })
    this.props.removeModal()
  }

  sendRequest = () => {
    const {
      selectedPublicDocuments,
      selectedForms,
      newDocuments,
      scanDocumentName,
      updateType,
      isScanMandatory,
    } = this.state
    const { type } = this.props

    if ((type === 'documents' && updateType === 'newDocument') || type === 'forms') {
      if (
        selectedPublicDocuments.length <= 0 &&
        newDocuments.length <= 0 &&
        selectedForms.length <= 0
      ) {
        this.setState({
          errorMessage: (
            <small className="text-danger">
              {type === 'documents'
                ? 'Please select or upload some documents.'
                : 'Please choose at least one form.'}
            </small>
          ),
        })
        return
      }
    } else if (type === 'documents' && updateType === 'scanDocument') {
      if (scanDocumentName.length <= 0) {
        this.setState({
          errorMessage: <small className="text-danger">Document name cannot be empty.</small>,
        })
        return
      }
    }

    if (updateType === 'newDocument') {
      this.props.sendRequest(selectedPublicDocuments, newDocuments, selectedForms)
    } else {
      this.props.sendDocumentToScan(scanDocumentName, isScanMandatory)
    }
    this.setState({ showModal: false })
  }

  getAllPublicDocuments = async () => {
    try{
      const response = await apiRequest({
        method: 'get',
        url: 'firms/getFirmDocuments',
      })
  
      const { attachedDocuments } = this.props
  
      if (response.status === 200) {
        let arrDocuments = []
        response.data.forEach((doc) => {
          arrDocuments.push({
            value: doc.documentId,
            label: doc.documentName,
            isDefault: doc.isDefault,
            isDisabled: attachedDocuments.find((adoc) => adoc.documentId === doc.documentId)
              ? true
              : false,
          })
        })
  
        this.setState({ publicDocuments: arrDocuments })
      }
    }catch(err){
      
    }
   
  }

  getAllForms = async () => {
    const response = await apiRequest({
      method: 'get',
      url: 'forms?withoutJSON=true',
    })

    if (response.status === 200) {
      let arrForms = []
      response.data.forEach((form) => {
        arrForms.push({
          value: form.formId,
          label: form.formName,
        })
      })
      this.setState({ forms: arrForms })
    }
  }

  addNewDocuments = (documents) => {
    this.setState({ newDocuments: documents })
  }

  render() {
    const { publicDocuments, forms, updateType, scanDocumentName } = this.state
    const { type } = this.props
    let options = [
      {
        value: '',
        label: ' You can choose multiple options',
        isDisabled: true,
      },
    ]
    let optionsDocuments = [...options, ...publicDocuments]
    let optionsForms = [...options, ...forms]
    return (
      <Modal show={this.state.showModal} onHide={() => this.closeModal()}>
        <Modal.Header className="blueModal">
          <span className="modal-title customTitle" style={{ color: '#FFF' }}>
            Update request
          </span>
          <div className="pull-right">
            <Button bsStyle={'primary'} fill onClick={() => this.sendRequest()}>
              <span className="btn-label">
                <i className="fa fa-paper-plane" />
                {'  '}
              </span>
              <span style={{ fontWeight: 'bold' }}>Send</span>
            </Button>
            <Button bsStyle="primary" fill onClick={() => this.closeModal()}>
              <span className="btn-label">
                <i className="fa fa-times" />
                {'  '}
              </span>
              <span style={{ fontWeight: 'bold' }}>Cancel</span>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              bsStyle="primary"
              fill={updateType === 'newDocument' ? true : false}
              onClick={() => this.setState({ updateType: 'newDocument' })}>
              <span style={{ fontWeight: 'bold' }}>NEW DOCUMENT</span>
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              bsStyle="primary"
              fill={updateType === 'scanDocument' ? true : false}
              onClick={() => this.setState({ updateType: 'scanDocument' })}>
              <span style={{ fontWeight: 'bold' }}>DOCUMENT FOR SCAN</span>
            </Button>
          </div>
          {type === 'documents' && updateType === 'newDocument' && (
            <div>
               <h3 className="customSubtitle top10">Add documents to request</h3> 
               <Select
                className="react-select info"
                classNamePrefix="react-select"
                placeholder="Choose Document"
                name="multipleSelect"
                closeMenuOnSelect={false}
                isMulti
                value={this.state.selectedPublicDocuments}
                onChange={(value) => {
                  this.setState({ selectedPublicDocuments: value })
                }}
                options={optionsDocuments}
              /> 
              <div className="top20"></div>
              <Upload noUpload={true} addNewDocuments={this.addNewDocuments} />
            </div>
          )}

          {type === 'documents' && updateType === 'scanDocument' && (
            <div>
              <FormGroup>
                <h3 className="customSubtitle top10">Document name: </h3>

                <FormControl
                  className="modalTitleBox"
                  placeholder="Document name"
                  value={scanDocumentName}
                  type="text"
                  onChange={(event) => {
                    this.setState({ scanDocumentName: event.target.value })
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Checkbox
                  onClick={() =>
                    this.setState({
                      isScanMandatory: !this.state.isScanMandatory,
                    })
                  }
                  isChecked={this.state.isScanMandatory}
                  number="1"
                  label="Make this document mandatory"
                />
              </FormGroup>
            </div>
          )}

          {type === 'forms' && (
            <div>
              <h3 className="customSubtitle top10">Add forms to request</h3>
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                placeholder="Choose Form"
                name="multipleSelect"
                closeMenuOnSelect={false}
                isMulti
                value={this.state.selectedForms}
                onChange={(value) => {
                  this.setState({ selectedForms: value })
                }}
                options={optionsForms}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
    )
  }
}

export default UpdateRequestModal
