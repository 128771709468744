import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { Document, Page } from 'react-pdf/dist/entry.webpack'
import { apiRequest } from 'helpers'
import Button from 'components/CustomButton/CustomButton.jsx'
import Loader from 'components/Loader/Loader.jsx'

class PDFModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      numPages: null,
      pageNumber: 1,
      showModal: true,
      pdfContent: '',
    }
  }

  componentDidMount() {
    // pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
    const documentKey = this.props.documentKey
    this.getDocumentContent(documentKey)
  }

  getDocumentContent = async (documentKey) => {
    const response = await apiRequest({
      method: 'get',
      url: 'files/download/' + documentKey,
    })
  
    if (response.status === 200) {
      this.setState({
        pdfContent: 'data:application/pdf;base64,' + response.data,
      })
    }
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages })
  }

  closeModal = () => {
    this.setState({ showModal: false })
    this.props.removeModal()
  }

  downloadPDF = (pdf) => {
    const docName = this.props.documentName
    const linkSource = `${pdf}`
    const downloadLink = document.createElement('a')
    const fileName = docName ? docName : 'Document.pdf'

    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }

  render() {
    const { pageNumber, numPages, pdfContent } = this.state
    return (
      <Modal
        dialogClassName="modal-flex"
        show={this.state.showModal}
        onHide={() => this.closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>
            Document preview
            {pdfContent !== '' && (
              <div className="top10">
                <Button
                  bsStyle="primary"
                  fill
                  onClick={() => this.downloadPDF(pdfContent)}>
                  <span className="btn-label">
                    <i className="fa fa-download" />
                    {'  '}
                  </span>
                  Download
                </Button>
              </div>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {this.state.pdfContent !== '' ? (
              <div>
                <Document
                  file={pdfContent}
                  onLoadError={(err) => console.log('ERROR: ', err)}
                  onLoadSuccess={this.onDocumentLoadSuccess}>
                  <Page pageNumber={pageNumber} />
                </Document>
                <p>
                  Page {pageNumber} of {numPages}
                </p>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default PDFModal
