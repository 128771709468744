import React, { Component, useState } from 'react'
import { apiRequest } from 'helpers'

import { Grid, Col, Row } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';

import PaymentCard from 'assets/img/icons/payment-card.png';
import boxImg from 'assets/img/icons/Group 105.png';
import refundIcon from 'assets/img/icons/refund.png'

import { ReactComponent as Loader } from 'assets/img/loader-preview.svg'

class Transactions extends Component {
    constructor(props) {
      super(props)
      this.state = {
        isLoading: true,
        data : [],
        subscription: "",
        taxOfStates: []
        }
      this.getAllUserTransactions = this.getAllUserTransactions.bind(this)
    }


    componentDidMount(){
        this.getAllUserTransactions()
    }
    getAllUserTransactions = async () => {
        try {
          const response = await apiRequest({
            method: 'get',
            url: 'payments/getUserTransactions',
          })
          if (response.status === 200) {
            this.setState({ data: response.data.listOfPayments, isLoading: false, taxOfStates: response.data.taxOfState })
          }
        } catch (error) {
          this.setState({ isLoading: false, errorMessage: 'There was an error.' })
        }
      }
      

    render(){
        
        return(
            <div style={{minHeight: '90vh'}}>
            {!this.state.isLoading ? (
            <Grid fluid style={{
                width: '95%',
                backgroundColor: '#FFFFFF',
                margin: 'auto',
                minHeight: '300px',
                borderRadius: '10px',
                marginTop: '20px',
                marginBottom: '50px',
                boxShadow: "0 1px 2px rgb(0 0 0 / 5%), 0 0 0 1px rgb(63 63 68 / 10%)"
            }}>
                <p style={{
                    fontWeight: '600',
                    fontSize: '16px',
                    marginBottom: '30px',
                    marginTop: '20px',
                    marginLeft: '30px'
                }}>Last transactions</p>
                {this.state.data.length > 0 && this.state.data.map((trans) => (
                <Row style={{marginLeft: '30px'}} className='trans-row'>
                <p style={{
                        color: 'rgba(0, 0, 0, 0.5)',
                        fontWeight: '600',
                        fontSize: '13px',
                        marginLeft: '15px'
                        }}>{moment(trans.createdAt * 1000).format("MMM Do YY")}
                        </p>
                    <Col lg={3} md={5} sm={12}>
                        
                        <div className='transaction-detail'>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}>
                                <img src={PaymentCard} alt='Card'/>
                                <p style={{
                                    marginTop: '10px',
                                    fontWeight: 'bold',
                                    fontSize: '15px'
                                }}>{trans?.productName ? 'Subscription' : `${trans?.requestAmount} tax requests`}</p>
                            </div>
                            {trans?.type !== 'subscription_update' &&  this.state.taxOfStates.length > 0 && this.state.taxOfStates.map(elem => (
                                <div className='tax-infos'>
                                    <p>{elem.display_name} {elem.percentage}%</p>
                                </div>
                            ))}
                        </div>
                    </Col>

                    <Col lg={3} md={5} sm={12}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '10px',
                            fontSize: '15px',
                            color: '#58A4B0'
                        }}>
                            <p>{trans?.productName ? trans?.productName : 'Request purchase'}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={2} sm={12}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#58A4B0'
                        }}>
                            <img src={boxImg} alt='Box' style={{height: '30px', width: '30px'}}/>
                            <p style={{marginTop: '10px', marginLeft: '10px'}}>Credit Card **** **** **** {trans.last4}</p>
                        </div>
                    </Col>

                    <Col lg={3} md={2} style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            {/* //{((trans?.paidAmount) * (1 + (trans?.taxAmount/10 ))).toFixed(2)} */}
                        <p style={{fontWeight: 'bold', fontSize: '15px', marginTop: '10px'}}>${parseFloat(trans?.paidAmount + trans?.taxAmount)} CAD</p>
                    </Col>
                </Row>
                  ))}
            </Grid> 
            ) : (
            <SweetAlert
                style={{
                display: 'block',
                marginTop: '-100px',
                }}
                title="Loading"
                showConfirm={false}>
                <div>
                    <Loader style={{ height: 75, width: 100 }} />
                </div>
          </SweetAlert>
            )}
            </div>
        )
    }
}

export default Transactions
