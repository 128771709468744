import React, { Component } from 'react'
import { Grid, FormGroup, FormControl, InputGroup } from 'react-bootstrap'

import { GiftedChat } from 'react-web-gifted-chat'

import Button from 'components/CustomButton/CustomButton.jsx'
import NewMessageModal from 'views/Components/NewMessageModal'
import Avatar from 'components/Avatar/Avatar'

import moment from 'moment'

import { apiRequest } from 'helpers'

import Config from 'config'
import socketIOClient from 'socket.io-client'

import UIfx from 'uifx'

import newMessageAppear from 'assets/sounds/appear.mp3'

const newMessageSound = new UIfx(newMessageAppear, {
  volume: 1, // value must be between 0.0 ⇔ 1.0
})

moment.locale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'seconds',
    ss: '%ss',
    m: 'a minute',
    mm: '%dm',
    h: 'an hour',
    hh: '%dh',
    d: 'a day',
    dd: '%dd',
    M: 'a month',
    MM: '%dM',
    y: 'a year',
    yy: '%dY',
  },
})

class Messenger extends Component {
  constructor(props) {
    super(props)
    this.state = {
      chatOpened: false,
      chatOpenedName: '',
      newMessageModal: null,
      alert: null,
      messages: [],
      user: {},
      userOnSocket: {},
      myConversationId: null,
      sendToId: null,
      conversations: [],
      backupConversations: [],
      openedConversationId: 0,
      chatLoadedPage: 0,
    }
  }

  componentDidMount() {
    // SOCKET INITIALIZE
    this.socket = socketIOClient(Config.APP_SOCKET_IP)

    // If messenger is opened through user we will show send new message modal
    const { openNewMessage } = this.props.location.state ? this.props.location.state : {}
    if (openNewMessage) {
      this.setState({
        newMessageModal: (
          <NewMessageModal
            sendMessage={this.sendMessage}
            removeModal={this.removeModal}></NewMessageModal>
        ),
      })
    }

    // GET USER TOKEN
    const usertoken = localStorage.getItem('usertoken')

    // CONNECT OUR USER TO CHAT SOCKET
    this.socket.emit('newUser', usertoken)

    // LISTENER FOR INCOMING MESSAGE
    this.socket.on('incomingMessage', (message) => {
      const { openedConversationId } = this.state
      const mySenderId = parseInt(localStorage.getItem('senderId'))
      console.log('MY SENDER ID: ', mySenderId)
      let conversations = this.state.conversations
      console.log('=== INCOMING MESSAGE ===')
      message.user.id = message.user._id
      message.id = message._id
      console.log(message)
      //PLAY NOTIFICATION ONLY IF USER IS NOT THE SENDER
      if (mySenderId !== message.user._id) this.toggleSoundNotification()

      console.log(message)
      console.log('--- conv')
      console.log(this.state.conversations)
      let targetConversationIndex = conversations.findIndex(
        (conv) => conv.conversationId === message.conversationId
      )

      console.log('TARGET CONV INDEX')
      console.log(targetConversationIndex)

      // If new message was sent through API call
      if (targetConversationIndex === -1) {
        this.getConversations(false)
        return
      }

      let targetConversation = conversations[targetConversationIndex]
      console.log('TARGET CONV')
      console.log(targetConversation)
      let senderUser = targetConversation.senderUser

      message.senderUser = senderUser
      targetConversation.messages[0] = message

      console.log('===MSG===')
      console.log(message)
      if (!message.seenByUser && openedConversationId !== message.conversationId) {
        targetConversation.newMessage = true
      } else {
        targetConversation.newMessage = false
        targetConversation.messages[0].seenByUser = true
      }

      conversations[targetConversationIndex] = targetConversation

      console.log('-- conv after change --')
      console.log(conversations)

      this.setState({
        conversations,
      })

      // if opened conversation is same as receiving message conversation (show message only if user is in the targeted conversation)
      if (openedConversationId === message.conversationId) {
        this.socket.emit('openChat', 'user', openedConversationId)

        this.setState((previousState) => ({
          messages: GiftedChat.append(previousState.messages, message),
        }))
      }
    })

    // GET ALL PREVIOUS CONVERSATIONS
    this.getConversations()
  }

  componentWillUnmount() {
    this.socket.disconnect()
  }

  toggleSoundNotification = () => {
    newMessageSound.play()
  }

  getConversations = async (firstCall = true) => {
    if (firstCall) {
      this.socket.on('newUser', async (user) => {
        console.log('new user uhvacen')
        console.log(user)
        this.setState({ userOnSocket: user })

        const data = {
          id: user.referenceId,
          type: 'user',
        }

        const response = await apiRequest({
          method: 'post',
          url: 'chat/getConversations',
          data,
        })

        if (response.status === 200) {
          console.log('RESPONSE CONVERSATIONS')
          console.log(response)
          this.setState({
            conversations: response.data.conversations,
            backupConversations: response.data.conversations,
            myConversationId: response.data.yourId,
          })
        }
      })
    } else {
      const { userOnSocket } = this.state

      const data = {
        id: userOnSocket.referenceId,
        type: 'user',
      }

      const response = await apiRequest({
        method: 'post',
        url: 'chat/getConversations',
        data,
      })

      if (response.status === 200) {
        console.log('RESPONSE CONVERSATIONS')
        console.log(response)
        this.setState({
          conversations: response.data.conversations,
          backupConversations: response.data.conversations,
          myConversationId: response.data.yourId,
        })
      }
    }
  }

  loadMessagesStack = () => {
    const { chatLoadedPage, openedConversationId } = this.state
    this.socket.emit('loadNextMessages', { chatLoadedPage, openedConversationId })
    this.setState({ chatLoadedPage: chatLoadedPage + 1 })
  }

  renderChat() {
    const { myConversationId, chatOpened, chatOpenedName } = this.state
    return chatOpened ? (
      <GiftedChat
        loadEarlier={true}
        onLoadEarlier={() => this.loadMessagesStack()}
        messages={this.state.messages}
        onSend={(messages) => this.onSend(messages)}
        user={{
          id: myConversationId,
        }}
        renderAvatar={(avatar) => (
          <div className="avatarPhoto small">
            <Avatar name={chatOpenedName} />
          </div>
        )}
      />
    ) : (
      <h4>Open conversation to start chatting!</h4>
    )
  }

  renderConversations = () => {
    const { conversations, myConversationId, openedConversationId } = this.state
    const sortedConversations = conversations.sort(
      (a, b) => new Date(b.messages[0].createdAt) - new Date(a.messages[0].createdAt)
    )
    return (sortedConversations || []).map((conversation) => {
      // If message is not seen by user
      if (!conversation.messages[0].seenByUser) {
        conversation.newMessage = true
      }

      let sendToId = null
      if (myConversationId === conversation.sender) {
        sendToId = conversation.receiver
      } else {
        sendToId = conversation.sender
      }
      console.log('SEND TO ID: ', sendToId)
      const senderName = conversation.senderUser
      console.log('SENDER NAME')
      console.log(senderName)
      return (
        <div
          key={conversation.conversationId}
          className="chatSingleButton"
          onClick={() => {
            this.openChat(myConversationId, sendToId, conversation.conversationId)
          }}
          style={{
            ...styles.conversationContainer,
            ...(openedConversationId === conversation.conversationId
              ? styles.selectedConversation
              : ''),
          }}>
          <div style={styles.conversationItem}>
            <div className="avatarPhoto">
              <Avatar name={conversation.senderUser} />
            </div>
            <div style={styles.conversationInfo}>
              <span
                style={{
                  ...styles.conversationUser,
                  ...(conversation.newMessage ? styles.boldText : ''),
                }}>
                {conversation.senderUser}
              </span>
              <span
                style={{
                  ...styles.conversationMessage,
                  ...(conversation.newMessage ? styles.boldText : ''),
                }}>
                {conversation.messages[0].text.length > 22
                  ? conversation.messages[0].text.substring(0, 22) + '...'
                  : conversation.messages[0].text}
              </span>
            </div>
            {conversation.newMessage && <div style={styles.msgNewDot}></div>}
            <div style={styles.msgTime}>
              {moment(conversation.messages[0].updatedAt).utc().fromNow()}
            </div>
          </div>
        </div>
      )
    })
  }

  onSend(messages = []) {
    const { sendToId, userOnSocket } = this.state
    const newMsg = messages[0]
    console.log('NEW MSG')
    console.log(newMsg)
    const sender = {
      id: newMsg.user.id,
      name: userOnSocket.name,
    }

    const receiver = {
      id: sendToId,
    }

    this.socket.emit('message', { text: newMsg.text, sender, receiver, userType: 'user' })

    /*  this.setState((previousState) => ({
      messages: GiftedChat.append(previousState.messages, newMsg),
    }))*/
  }

  sendMessage = async (clients, message) => {
    const { myConversationId } = this.state

    let receivers = []
    clients.forEach((client) => {
      receivers.push(client.value)
    })

    const data = {
      text: message,
      senderId: myConversationId,
      receivers,
    }

    const response = await apiRequest({
      method: 'post',
      url: 'chat',
      data,
    })

    if (response.status === 200) {
      console.log('Sent!')
      console.log(response)

      this.getConversations(false)
    }

    this.setState({ newMessageModal: null })
  }

  removeModal = () => {
    this.setState({ newMessageModal: null })
  }

  openChat = (myId, sendToId, conversationId) => {
    console.log('EMIT OPEN CHAT ', myId + ' ' + sendToId)
    let conversations = this.state.conversations
    let conversationIndex = conversations.findIndex(
      (conv) => conv.conversationId === conversationId
    )

    conversations[conversationIndex].newMessage = false
    conversations[conversationIndex].messages[0].seenByUser = true

    let senderName = conversations[conversationIndex].senderUser

    this.setState({
      sendToId,
      conversations,
      chatOpened: true,
      chatOpenedName: senderName,
      openedConversationId: conversationId,
    })

    this.socket.emit('openChat', 'user', conversationId)
    this.socket.emit('chat', myId, sendToId, 'web')
    this.getSingleChat()
  }

  getSingleChat = () => {
    this.socket.on('priorMessages', (messages) => {
      console.log('prior messages dobio')
      console.log(messages)
      //store.dispatch(gotMessages(messages))
      messages.forEach((msg) => {
        msg.user.id = msg.user._id
        msg.id = msg._id
      })
      console.log('OBRADJENE PORUKE')
      console.log(messages)
      this.setState({ messages })
    })
  }

  handleSearch = (e) => {
    const { conversations, backupConversations } = this.state
    const searchParam = e.target.value
    if (searchParam.length > 0) {
      let searchedConversations = conversations.filter((conv) => {
        return conv.senderUser.toLowerCase().includes(searchParam.toLowerCase())
      })

      this.setState({
        conversations: searchedConversations,
      })
    } else {
      this.setState({ conversations: backupConversations })
    }
  }

  render() {
    const { chatOpened, chatOpenedName } = this.state
    const userName = localStorage.getItem('username')
    return (
      <div className="main-content">
        {this.state.alert}
        <Grid fluid>
          <div style={styles.container}>
            <div style={styles.channelList}>
              <div style={styles.infoHeader} className="chatInfoHeader">
                <div style={styles.infoHeaderLeft}>
                  <div className="photo">
                    <div className="avatarPhoto">
                      <Avatar name={userName} />
                    </div>
                  </div>
                  <span className="customTitle" style={styles.infoHeaderTitle}>
                    Messages
                  </span>
                </div>

                <Button
                  onClick={() =>
                    this.setState({
                      newMessageModal: (
                        <NewMessageModal
                          sendMessage={this.sendMessage}
                          removeModal={this.removeModal}></NewMessageModal>
                      ),
                    })
                  }
                  bsStyle="primary">
                  New message
                </Button>
              </div>
              <div className="conversationList" style={styles.conversationList}>
                <FormGroup style={{ marginLeft: 10, marginRight: 10 }}>
                  <InputGroup>
                    <InputGroup.Addon
                      style={{ ...(this.state.searchFocused && { borderColor: '#aaaaaa' }) }}>
                      <i className="fa fa-search" />
                    </InputGroup.Addon>
                    <FormControl
                      onFocus={(e) => this.setState({ searchFocused: true })}
                      onBlur={(e) => this.setState({ searchFocused: false })}
                      onChange={this.handleSearch}
                      type="text"
                      placeholder="Search..."
                    />
                  </InputGroup>
                </FormGroup>
                {this.renderConversations()}
              </div>
            </div>
            <div className="chatContainer" style={styles.chatContainer}>
              <div style={styles.chatHeader} className="chatHeader">
                <div style={styles.chatHeaderLeft}>
                  {chatOpenedName !== '' && (
                    <div className="avatarPhoto">
                      <Avatar name={chatOpenedName} />
                    </div>
                  )}
                  <span className="customTitle" style={{ marginLeft: 10 }}>
                    {chatOpenedName}
                  </span>
                </div>
                <div style={styles.chatHeaderRight}>
                  <i className="fa fa-ellipsis-v" style={{ fontSize: 30 }}></i>
                </div>
              </div>
              <div
                className="chatbg"
                style={{ ...styles.chat, ...(!chatOpened ? styles.centerChat : '') }}>
                {this.renderChat()}
              </div>
            </div>
          </div>
          {this.state.newMessageModal}
        </Grid>
      </div>
    )
  }
}

const styles = {
  boldText: {
    fontWeight: 'bold',
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    minHeight: 650,
  },
  chatContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
  },
  channelList: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#fff',
  },
  chat: {
    display: 'flex',
    flex: 3,
    flexDirection: 'column',
    borderWidth: '2px',
    borderColor: '#ccc',
    borderRightStyle: 'solid',
    borderLeftStyle: 'solid',
    maxHeight: 600,
  },
  centerChat: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  settings: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  infoHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 5,
    paddingLeft: 5,
    height: 80,
  },
  infoHeaderTitle: {
    marginLeft: 10,
  },
  infoHeaderLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatarImg: {
    borderRadius: '100%',
    height: 50,
    width: 50,
  },
  conversationList: {
    paddingTop: 15,
    maxHeight: 650,
    overflow: 'scroll',
  },
  conversationContainer: {
    borderWidth: '1px',
    borderColor: '#ccc',
    borderBottomStyle: 'solid',
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 5,
    paddingLeft: 5,
    position: 'relative',
    overflow: 'hidden',
  },
  msgTime: {
    position: 'absolute',
    top: 5,
    right: 5,
    maxWidth: 90,
    textAlign: 'right',
  },
  msgNewDot: {
    position: 'absolute',
    top: 32,
    right: 5,
    width: 14,
    height: 14,
    borderRadius: 14,
    backgroundColor: '#447DF7',
  },
  conversationItem: {
    height: 70,
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 10,
    paddingTop: 10,
  },
  selectedConversation: {
    backgroundColor: 'rgba(68, 125, 247, 0.2)',
  },
  conversationDate: {
    position: 'absolute',
    top: 5,
    right: 10,
  },
  conversationInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 12,
  },
  conversationUser: {
    fontSize: 16,
    //fontWeight: '600',
    paddingBottom: 8,
  },
  chatHeader: {
    height: 80,
    backgroundColor: '#fff',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: '2px',
    borderColor: '#ccc',
    borderLeftStyle: 'solid',
  },
  chatHeaderLeft: {
    width: 250,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  chatHeaderRight: {
    width: 250,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}

export default Messenger
