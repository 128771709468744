import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'

import Card from 'components/Card/Card.jsx'
import FormInputs from 'components/FormInputs/FormInputs.jsx'
import UserCard from 'components/Card/UserCard.jsx'
import Button from 'components/CustomButton/CustomButton.jsx'
import { withRouter } from 'react-router-dom';

import avatar from 'assets/img/avatar-sil.png'
import { apiRequest } from 'helpers'

class UserPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      user: null,
      errorMessage: '',
      userCompany: ''
    }
  }

  componentDidMount() {
    const usertoken = localStorage.getItem('usertoken')
    this.getProfileData(usertoken)
  }

  getProfileData = async (usertoken) => {
    this.setState({ isLoading: true })


    try {
      const response = await apiRequest({
        method: 'get',
        url: `users/${localStorage.getItem('userId')}`
      })

      const firm = await apiRequest({
            method: 'get',
            url: `firms/${response.data.firmId}` 
      })

      if (response.status === 200) {
        console.log('USER DATA')
        console.log(response)
        this.setState({ user: response.data, isLoading: false, userCompany: firm.data.name })
      }
    } catch (err) {
      this.setState({
        isLoading: false,
        errorMessage: 'There was an error. Please try again.',
      })
    }
  }

  updateProfile = async (user) => {
    console.log('USER NEW')
    console.log(user)

    const usertoken = localStorage.getItem('usertoken')
    this.setState({ isLoading: true })
    if (user) {
      try {
        const response = await apiRequest({
          method: 'put',
          url: 'users/' + user.userId,
          data: user,
        })

        if (response.status === 200) {
          this.getProfileData(usertoken)
        }
      } catch (err) {
        this.setState({ isLoading: false, errorMessage: 'There was an error.' })
      }
    }
  }

  render() {
    let user = this.state.user
    const { history } = this.props;
    return (
      <div className="main-content">
        {console.log("%c USER", "color: red;")}
        {console.log(user)}
        <Grid fluid>
          <Row>
            <Col md={8}>
              <Card
                title="My Profile"
                content={
                  <form>
                    <FormInputs
                      ncols={['col-md-6', 'col-md-6']}
                      proprieties={[
                        {
                          label: 'Company (disabled)',
                          type: 'text',
                          bsClass: 'form-control',
                          placeholder: 'Company',
                          value: this.state.userCompany.length > 0 ? this.state.userCompany : 'Loading',
                          disabled: true,
                        },
                        {
                          label: 'Email address',
                          type: 'email',
                          bsClass: 'form-control',
                          placeholder: 'Email',
                          disabled: true,
                          value: user && user.email,
                        },
                      ]}
                    />
                    <FormInputs
                      ncols={['col-md-6', 'col-md-6']}
                      proprieties={[
                        {
                          label: 'First name',
                          type: 'text',
                          bsClass: 'form-control',
                          placeholder: 'First name',
                          defaultValue: user && user.firstName,
                          onChange: (e) => (user.firstName = e.target.value),
                        },
                        {
                          label: 'Last name',
                          type: 'text',
                          bsClass: 'form-control',
                          placeholder: 'Last name',
                          defaultValue: user && user.lastName,
                          onChange: (e) => (user.lastName = e.target.value),
                        },
                      ]}
                    />
                    <FormInputs
                      ncols={['col-md-6', 'col-md-6']}
                      proprieties={[
                        {
                          label: 'Phone',
                          type: 'text',
                          bsClass: 'form-control',
                          placeholder: 'Phone',
                          defaultValue: user && user.phone,
                          onChange: (e) => (user.phone = e.target.value),
                        },
                        {
                          label: 'Date of Birth',
                          type: 'date',
                          bsClass: 'form-control',
                          placeholder: 'Date of Birth',
                          defaultValue: user && user.dob,
                          onChange: (e) => (user.dob = e.target.value),
                        },
                      ]}
                    />
                    <FormInputs
                      ncols={['col-md-12',]}
                      proprieties={[
                        {
                          label: 'Adress',
                          type: 'text',
                          bsClass: 'form-control',
                          placeholder: 'Home Adress',
                          defaultValue: user && user.address,
                          onChange: (e) => (user.address = e.target.value),
                        }
                      ]}
                    />
                    <FormInputs
                      ncols={  localStorage.getItem('role') === 'admin' ? ['col-md-4', 'col-md-4', 'col-md-4'] : ['col-md-6','col-md-6']}
                      proprieties={[
                        {
                          label: 'City',
                          type: 'text',
                          bsClass: 'form-control',
                          placeholder: 'City',
                          defaultValue: user && user.city,
                          onChange: (e) => (user.city = e.target.value),
                        },
                        {
                          label: 'Postal Code',
                          type: 'text',
                          bsClass: 'form-control',
                          placeholder: 'Postal Code',
                          defaultValue: user && user.postalCode,
                          onChange: (e) => (user.postalCode = e.target.value),
                        },
                        localStorage.getItem('role') === 'admin' ?( 
                        {
                          label: 'Province',
                          type: 'text',
                          bsClass: 'form-control',
                          placeholder: 'Country',
                          defaultValue: user && user?.province?.label,
                          onChange: (e) => (user.province.label = e.target.value),
                        }) : null,
                        
                      ]}
                    />

                    {/*<div className="row">
                      <div className="col-md-12">
                        <FormGroup controlId="formControlsTextarea">
                          <ControlLabel>About Me</ControlLabel>
                          <FormControl
                            rows="5"
                            componentClass="textarea"
                            bsClass="form-control"
                            placeholder="Here can be your description"
                            defaultValue="Lamborghini Mercy, Your chick she so thirsty, I'm in that two seat Lambo."
                          />
                        </FormGroup>
                      </div>
                    </div> */}
                    <Button
                      onClick={() => this.updateProfile(user)}
                      bsStyle="info"
                      pullRight
                      fill
                      type="submit">
                      Update Profile
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
            <Col md={4}>
              <UserCard
                avatar={avatar}
                name={user && user.firstName + ' ' + user.lastName}
                userName={'@' + (user && user.firstName + user.lastName)}
                description={
                  <span>
                    <br />
                    {localStorage.getItem('role') !== 'superAdmin' ? 
                    <Button
                      onClick={() => history.push('/admin/messenger')}
                      bsStyle="default">
                      OPEN MESSENGER
                    </Button> : null}
                  </span>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

export default withRouter(UserPage)
