import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React from 'react'
import PaymentForm from './PaymentForm'
import './StripeStyle.css'

const PUBLIC_KEY =
  'pk_test_51IV1w4D4eQqWKI3jMn9NVmIfOvTqAShOIW8XITx8FH3cHDfCO64AspHFx5Fofb2lIALaKrnO209fE2CHGL4QYFbh00cfMYlbXg'

const stripeTestPromise = loadStripe(PUBLIC_KEY)

export default function StripeContainer(props) {
  return (
    <Elements stripe={stripeTestPromise}>
      <PaymentForm refreshCards={() => props.refreshCards()} />
    </Elements>
  )
}

// <style>

// </style>
