import { apiRequest } from 'helpers';
import React, { useState, useEffect } from 'react'
import Button from 'components/CustomButton/CustomButton'

import { ReactComponent as Loader } from 'assets/img/loader-preview.svg'
import SweetAlert from 'react-bootstrap-sweetalert'

/*React Quill*/
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const SetTOS = () => {

    const [text, setText] = useState('');
    const [alert, setAlert] = useState(null)

    useEffect(() => {
        loadingAlert()
        getTOS()
    }, [])

    const getTOS = async() => {
        try{
            const tos = await apiRequest({
                url: 'firms/getMyFirm',
                method: 'get'
            })
            if(tos.status === 200){
                setText(tos.data.tos)
                setAlert(null)
            }
        }catch(err){

        }  
    }

    const sendTos = async () => {
        loadingAlert()
        let formData = new FormData();
        formData.append('tos', text)
        const data = formData
        try{
            const tos = await apiRequest({
                url: `firms/${localStorage.getItem('companyId')}`,
                method: 'put',
                data
            })
            if(tos.status === 200){
                setAlert(null)
                setTimeout(() => {successAlert()}, 500)
            }
        }catch(err){
            setAlert(null)
            errorAlert(err.response.data.message || err.response.data.msg)
        }  
    }
    const loadingAlert = () => {
        setAlert(
            <SweetAlert
                style={{
                display: 'block',
                marginTop: '-100px',
                }}
                title="Processing"
                showConfirm={false}>
                <div>
                    <Loader style={{ height: 75, width: 100 }} />
                </div>
            </SweetAlert>
        )
    }
    const successAlert = (msg="Done") => {
        setAlert(
            <SweetAlert
                success
                style={{
                display: 'block',
                marginTop: '-100px',
                }}
                title={msg}
                showConfirm={true}
                onConfirm={() => setAlert(null)}
                >
            </SweetAlert>
        )
    }
    const errorAlert = (msg = 'Something went wrong') => {
        setAlert(
            <SweetAlert
                warning
                style={{
                display: 'block',
                marginTop: '-100px',
                }}
                title={msg}
                showConfirm={true}
                onConfirm={() => setAlert(null)}
                >
            </SweetAlert>
        )
    }

    const handleChange = (value) => {
        console.log(value);
        setText(value)
      }
      const modules = {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image'],
          ['clean']
        ],
      }
     
      const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
      ]

    return (
        <>
        <ReactQuill
            theme="snow" 
            onChange={handleChange}   
            value={text}
            className='tos-container'
            placeholder="Here you can make your own Terms of Service"
            modules={modules}
            formats={formats}
            >
        </ReactQuill>
        <div className='save-button-cont'>
            <Button onClick={sendTos} className='save-button'>Send</Button>  
        </div> 
       
        {alert}
        </>
    )
}

export default SetTOS
