import React from 'react'

export default (notification) => {
  let notificationMessage = ''
  let level = ''
  let icon = 'pe-7s-gift'
  let redirectRoute = null
  let activityId = null
  let notificationKey = null

  switch (notification.notificationKey) {
    case 'newTaxRequest':
      notificationMessage = (
        <span style={{ marginRight: 10 }}>
          New tax request has been{' '}
          started by:
          <span style={{ fontWeight: '600' }}>{' ' + notification.clientName}</span>
        </span>
      )
      level = 'success'
      icon = 'pe-7s-note2'
      redirectRoute = `/admin/clients/${notification.clientId}`
      activityId = notification.activityId
      notificationKey = notification.notificationKey
      break
    case 'taxFormUpdated':
      notificationMessage = (
        <span style={{ marginRight: 10 }}>
          Tax form has be updated{' '}
          by:
          <span style={{ fontWeight: '600' }}>{' ' + notification.clientName}</span>
        </span>
      )
      level = 'success'
      icon = 'pe-7s-note2'
      redirectRoute = `/admin/clients/${notification.clientId}`
      activityId = notification.activityId
      notificationKey = notification.notificationKey
      break
    case 'newUser':
      notificationMessage = (
        <span style={{ marginRight: 10 }}>
          <span style={{ fontWeight: '600' }}>New registration:</span>{' '}
          {notification.clientName} has registered. Check more info.
        </span>
      )
      level = 'success'
      icon = 'pe-7s-chat'
      redirectRoute = `/admin/clients/${notification.clientId}`
      activityId = notification.activityId
      notificationKey = notification.notificationKey
      break
    case 'signedDocument':
      notificationMessage = (
        <span style={{ marginRight: 10 }}>
          <span style={{ fontWeight: '600' }}>New signature:</span> A document has been signed
          by {notification.clientName}
        </span>
      )
      level = 'info'
      icon = 'pe-7s-file'
      redirectRoute = `/admin/requests/${notification.requestId}`
      activityId = notification.activityId
      notificationKey = notification.notificationKey
      break
    case 'rejectedDocument':
      notificationMessage = (
        <span style={{ marginRight: 10 }}>
          Document has been <span style={{ fontWeight: '600' }}>rejected by: </span>
          {notification.clientName}
        </span>
      )
      level = 'warning'
      icon = 'pe-7s-file'
      redirectRoute = `/admin/requests/${notification.requestId}`
      activityId = notification.activityId
      notificationKey = notification.notificationKey
      break
    case 'scannedDocument':
      notificationMessage = (
        <span style={{ marginRight: 10 }}>
          <span style={{ fontWeight: '600' }}>New scan:</span> A document has been scanned by{' '}
          {notification.clientName}
        </span>
      )
      level = 'info'
      icon = 'pe-7s-upload'
      redirectRoute = `/admin/requests/${notification.requestId}`
      activityId = notification.activityId
      notificationKey = notification.notificationKey
      break
    case 'newFirmApplication':
      notificationMessage = (
        <span style={{ marginRight: 10 }}>
          <span style={{ fontWeight: '600' }}>A new application has been submited from {notification.clientName}.{' '}</span>
        </span>
      )
      level = 'info'
      icon = 'pe-7s-upload'
      redirectRoute = `/admin/dashboard`
      activityId = notification.activityId
      notificationKey = notification.notificationKey
      break
    case 'documentUpload':
      notificationMessage = (
        <span style={{ marginRight: 10 }}>
          <span style={{ fontWeight: '600' }}>New scan:</span> A document has been scanned by{' '}
          {notification.clientName}
        </span>
      )
      level = 'info'
      icon = 'pe-7s-upload'
      redirectRoute = `/admin/requests/${notification.requestId}`
      activityId = notification.activityId
      notificationKey = notification.notificationKey
      break
    case 'sentToApprove':
      notificationMessage = (
        <span style={{ marginRight: 10 }}>
          The request has been{' '}
          <span style={{ fontWeight: '600' }}>submitted for approval by: </span>
          {notification.clientName}
        </span>
      )
      level = 'warning'
      icon = 'pe-7s-note2'
      redirectRoute = `/admin/requests/${notification.requestId}`
      activityId = notification.activityId
      notificationKey = notification.notificationKey
      break
    case 'newMessage':
      notificationMessage = (
        <span style={{ marginRight: 10 }}>
          You got <span style={{ fontWeight: '600' }}>a new message from: </span>
          {notification.clientName}
        </span>
      )
      level = 'info'
      icon = 'pe-7s-chat'
      redirectRoute = `/admin/messenger`
      notificationKey = notification.notificationKey
      break
    case 'paymentSubmitted':
      notificationMessage = (
        <span style={{ marginRight: 10 }}>
          <span style={{ fontWeight: '600' }}>New payment:</span> A new payment has been
          submitted by {notification.clientName}
        </span>
      )
      level = 'success'
      icon = 'pe-7s-wallet'
      redirectRoute = `/admin/requests/${notification.requestId}`
      activityId = notification.activityId
      notificationKey = notification.notificationKey
      break
    case 'clientProfileUpdate':
      notificationMessage = (
        <span style={{ marginRight: 10 }}>
          <span style={{ fontWeight: '600' }}>Client profile updated:</span>{' '}
          {notification?.clientName} has updated his profile
        </span>
      )
      level = 'info'
      icon = 'pe-7s-id'
      redirectRoute = `/admin/clients/${notification.clientId}`
      activityId = notification.activityId
      notificationKey = notification.notificationKey
      break
    case 'newApplication':
      notificationMessage = (
        <span style={{ marginRight: 10 }}>
          <span style={{ fontWeight: '600' }}>New application has been submitted by {notification?.companyName}</span>{' '}
        </span>
      )
      level = 'info'
      icon = 'pe-7s-id'
      redirectRoute = `/admin/company-application`
      activityId = null
      notificationKey = notification.notificationKey
      break
    case 'requestQuery':
      notificationMessage = (
        <span style={{ marginRight: 10 }}>
          <span style={{ fontWeight: '600' }}>New question:</span> There is a new inquiry by{' '}
          {notification.clientName}
        </span>
      )
      level = 'info'
      icon = 'pe-7s-chat'
      redirectRoute = `/admin/clients/${notification.clientId}`
      activityId = notification.activityId
      notificationKey = notification.notificationKey
      break
    default:
      level = 'info'
      icon = 'pe-7s-bell'
      break
  }

  return { notificationMessage, level, icon, redirectRoute, activityId, notificationKey }
}
