/*import Buttons from 'views/Components/Buttons.jsx'
import GridSystem from 'views/Components/GridSystem.jsx'
import Panels from 'views/Components/Panels.jsx'
import SweetAlert from 'views/Components/SweetAlertPage.jsx'
import Notifications from 'views/Components/Notifications.jsx'
import Icons from 'views/Components/Icons.jsx'
import Typography from 'views/Components/Typography.jsx'
import RegularForms from 'views/Forms/RegularForms.jsx'
import ExtendedForms from 'views/Forms/ExtendedForms.jsx'
import ValidationForms from 'views/Forms/ValidationForms.jsx'
import Wizard from 'views/Forms/Wizard/Wizard.jsx'
import RegularTables from 'views/Tables/RegularTables.jsx'
import ExtendedTables from 'views/Tables/ExtendedTables.jsx'
import ReactTables from 'views/Tables/ReactTables.jsx'
import GoogleMaps from 'views/Maps/GoogleMaps.jsx'
import FullScreenMap from 'views/Maps/FullScreenMap.jsx'
import VectorMap from 'views/Maps/VectorMap.jsx'
import Charts from 'views/Charts.jsx'
import Calendar from 'views/Calendar.jsx' */
import Dashboard from 'views/Dashboard.jsx'
import UserPage from 'views/UserInformations/MyProfileRoot'
import LoginPage from 'views/Pages/LoginPage.jsx'
import WorkerFirstLogin from 'views/Pages/WorkerFirstLogin.jsx'
import RegisterPage from 'views/Pages/RegisterPage.jsx'
import LockScreenPage from 'views/Pages/LockScreenPage.jsx'
import FormBuild from 'views//ManageConsole/FormBuild.jsx'
import FormEdit from 'views//ManageConsole/FormEdit.jsx'
import Console from 'views/ManageConsole/Console.jsx'
import Clients from 'views/ClientsManagement/Clients.jsx'
import SingleClient from 'views/ClientsManagement/SingleClient.jsx'
import Workers from 'views/WorkersManagement/Workers.jsx'
import Requests from 'views/Requests/Requests.jsx'
import SingleRequest from 'views/Requests/SingleRequest.jsx'
import FormAnswers from 'views/ManageConsole/FormAnswers'
import Messenger from 'views/Messenger/Messenger'
import NotificationsActivity from 'views/Pages/NotificationsActivity'
import PrivacyPolicy from 'views/Pages/PrivacyPolicy'
import Redirection from 'views/Pages/Redirection'
import TaxFiles from 'views/TaxFiles/TaxFiles'
import SingleTaxFile from 'views/TaxFiles/SingleTaxFile'
import Firms from 'views/FirmsManagement/Firms'
import CompanyAplication from 'views/CompanyAplication/CompanyAplication'
import SingleFirm from 'views/FirmsManagement/SingleFirm/SingleFirm'
import SingleWorker from 'views/WorkersManagement/single-worker/SingleWorker'
import { getRole } from 'helpers/getRole'
import NotAllowed from 'views/Pages/NotAllowed'
import FormBuilder from 'views/ManageConsole/FormBuilder'

import StripeAccount from 'views/BasicSettings/StripeAccount'
import CompanyInformations from 'views/CompanyInformations/CompanyInfoRoot'
var routes = [
  {
    path: '/dashboard',
    layout: '/admin',
    name: 'Dashboard',
    icon: 'pe-7s-graph',
    component: Dashboard,
  },
  {
    path: '/themes',
    layout: '/admin',
    name: 'Manage themes',
    icon: 'pe-7s-paint-bucket',
    component: UserPage,
    invisible: getRole() === 'superAdmin' ? false : true
  },
  {
    path: '/company-info',
    layout: '/admin',
    name: 'Firm management',
    icon: 'pe-7s-portfolio',
    component: CompanyInformations,
    invisible: getRole() === 'admin' ? false : true,
  },
  {
    collapse: true,
    path: '/manageconsole',
    name: 'Management Console',
    state: 'openManageConsole',
    icon: 'pe-7s-rocket',
    views: [
      {
        path: '/formanswers/:formId/:requestId',
        hidden: true,
        layout: '/admin',
        name: 'Answers',
        icon: 'pe-7s-paper-plane',
        component: FormAnswers,
      },
      {
        path: '/formedit/:formId',
        hidden: true,
        layout: '/admin',
        name: 'Edit form',
        icon: 'pe-7s-paper-plane',
        component: FormEdit,
      },
      {
        path: '/console',
        layout: '/admin',
        name: 'Console',
        icon: 'pe-7s-box2',
        component: Console,
      },
      {
        path: '/formbuild/:id',
        hidden: true,
        layout: '/admin',
        name: 'Build form',
        icon: 'pe-7s-paper-plane',
        invisible: getRole() === 'admin' ? false : true,
        component: FormBuilder,
      },
      {
        path: '/formbuild',
        layout: '/admin',
        name: 'Build form',
        icon: 'pe-7s-paper-plane',
        invisible: getRole() === 'admin' ? false : true,
        component: FormBuilder,
      },
    ],
  },
  {
    collapse: true,
    path: '/clientsmanagement',
    name: 'Users management',
    state: 'openClientsManagement',
    icon: 'pe-7s-users',

    views: [
      {
        path: '/clients/:id',
        hidden: true,
        layout: '/admin',
        name: 'Single Client',
        icon: 'pe-7s-user-female',
        component: SingleClient,
      },
      {
        path: '/clients/',
        layout: '/admin',
        name: 'Clients',
        icon: 'pe-7s-users',
        invisible: getRole() === 'superAdmin' ? true : false,
        component: Clients,
      },
      {
        path: '/users/:id',
        hidden: true,
        layout: '/admin',
        name: 'Worker',
        icon: 'pe-7s-user',
        component: SingleWorker,
        invisible: getRole() === 'worker' ? true : false,
      },
      {
        path: '/workers/',
        layout: '/admin',
        name: 'Workers',
        icon: 'pe-7s-user',
        component: Workers,
        invisible: getRole() === 'worker' || getRole() === 'superAdmin' ? true : false,
      },
      {
        path: '/firms/',
        layout: '/admin',
        name: 'Accountant Firms',
        icon: 'pe-7s-culture',
        component: Firms,
        invisible: (getRole() === 'superAdmin') ? false : true
      }
    ],
  },
  {
    path: '/taxfiles/:id',
    hidden: true,
    layout: '/admin',
    name: 'Single Tax File',
    icon: 'pe-7s-box2',
    component: SingleTaxFile,
  },
  {
    path: '/taxfiles',
    layout: '/admin',
    name: 'Tax Files',
    icon: 'pe-7s-cash',
    invisible: getRole() === 'superAdmin' ? true : false,
    component: TaxFiles,
  },
  {
    path: '/requests/:id',
    hidden: true,
    layout: '/admin',
    name: 'Single Request',
    icon: 'pe-7s-box2',
    component: SingleRequest,
  },
  {
    path: '/requests',
    layout: '/admin',
    name: 'Other requests',
    icon: 'pe-7s-note2',
    invisible: getRole() === 'superAdmin' ? true : false,
    component: Requests,
  },
  {
    path: '/messenger',
    layout: '/admin',
    name: 'Messenger',
    icon: 'pe-7s-chat',
    component: Messenger,
    invisible: getRole() === 'superAdmin' ? true : false
  },
  {
    path: '/company-application/',
    layout: '/admin',
    name: 'Company Application',
    icon: 'pe-7s-next-2',
    invisible: localStorage.getItem('role') === 'superAdmin' ? false : true,
    component: CompanyAplication,
  },
  {
    path: '/not-allowed',
    layout: '/admin',
    name: 'Not Allowed',
    icon: 'pe-7s-next-2',
    hidden: true,
    component: NotAllowed,
  },
  {
    path: '/single-firm/:id',
    hidden: true,
    layout: '/admin',
    name: 'Single Firm',
    icon: 'pe-7s-box2',
    component: SingleFirm,
  },
  {
    collapse: true,
    hidden: true,
    path: '/pages',
    name: 'Pages',
    state: 'openPages',
    icon: 'pe-7s-gift',
    views: [
      {
        path: '/set-account/:token?/:email?/:companyCode?',
        layout: '/auth',
        name: 'Set Account',
        mini: 'SA',
        component: WorkerFirstLogin,
      },
      {
        path: '/redirect/:url?',
        layout: '/auth',
        name: 'Redirection',
        mini: 'R',
        component: Redirection,
      },
      {
        path: '/user-page',
        layout: '/admin',
        name: 'My Profile',
        mini: 'UP',
        component: UserPage,
      },
      {
        path: '/login-page',
        layout: '/auth',
        name: 'Login Page',
        mini: 'LP',
        component: LoginPage,
      },
      {
        path: '/register-page',
        layout: '/auth',
        name: 'Register',
        mini: 'RP',
        component: RegisterPage,
      },
      {
        path: '/privacy-policy',
        layout: '/auth',
        name: 'Privacy Policy',
        mini: 'PP',
        component: PrivacyPolicy,
      },
      {
        path: '/lock-screen-page',
        layout: '/auth',
        name: 'Lock Screen Page',
        mini: 'LSP',
        component: LockScreenPage,
      },
      {
        path: '/notifications-activity',
        layout: '/admin',
        name: 'Notifications Activity',
        mini: 'NA',
        component: NotificationsActivity,
      },
    ],
  },
  {
    collapse: true,
    path: '/basic-settings',
    layout: '/admin',
    name: 'Basic settings',
    icon: 'pe-7s-tools',
    invisible: getRole() === 'admin' ? false : true,
    views: [
      {
        path: '/stripe-account',
        layout: '/admin',
        name: 'Bank Account',
        icon: 'pe-7s-user',
        component: StripeAccount,
      },
    ],
  },
  /* {
    collapse: true,
    path: '/components',
    name: 'Components',
    state: 'openComponents',
    icon: 'pe-7s-plugin',
    views: [
      {
        path: '/buttons',
        layout: '/admin',
        name: 'Buttons',
        mini: 'B',
        component: Buttons,
      },
      {
        path: '/grid-system',
        layout: '/admin',
        name: 'Grid System',
        mini: 'GS',
        component: GridSystem,
      },
      {
        path: '/panels',
        layout: '/admin',
        name: 'Panels',
        mini: 'P',
        component: Panels,
      },
      {
        path: '/sweet-alert',
        layout: '/admin',
        name: 'Sweet Alert',
        mini: 'SA',
        component: SweetAlert,
      },
      {
        path: '/notifications',
        layout: '/admin',
        name: 'Notifications',
        mini: 'N',
        component: Notifications,
      },
      {
        path: '/icons',
        layout: '/admin',
        name: 'Icons',
        mini: 'I',
        component: Icons,
      },
      {
        path: '/typography',
        layout: '/admin',
        name: 'Typography',
        mini: 'T',
        component: Typography,
      },
    ],
  },
  {
    collapse: true,
    path: '/forms',
    name: 'Forms',
    state: 'openForms',
    icon: 'pe-7s-note2',
    views: [
      {
        path: '/regular-forms',
        layout: '/admin',
        name: 'Regular Forms',
        mini: 'RF',
        component: RegularForms,
      },
      {
        path: '/extended-forms',
        layout: '/admin',
        name: 'Extended Forms',
        mini: 'EF',
        component: ExtendedForms,
      },
      {
        path: '/validation-forms',
        layout: '/admin',
        name: 'Validation Forms',
        mini: 'VF',
        component: ValidationForms,
      },
      {
        path: '/wizard',
        layout: '/admin',
        name: 'Wizard',
        mini: 'W',
        component: Wizard,
      },
    ],
  },
  {
    collapse: true,
    path: '/tables',
    name: 'Tables',
    state: 'openTables',
    icon: 'pe-7s-news-paper',
    views: [
      {
        path: '/regular-tables',
        layout: '/admin',
        name: 'Regular Tables',
        mini: 'RT',
        component: RegularTables,
      },
      {
        path: '/extended-tables',
        layout: '/admin',
        name: 'Extended Tables',
        mini: 'ET',
        component: ExtendedTables,
      },
      {
        path: '/react-table',
        layout: '/admin',
        name: 'React Table',
        mini: 'RT',
        component: ReactTables,
      },
    ],
  },
  {
    collapse: true,
    path: '/maps',
    name: 'Maps',
    state: 'openMaps',
    icon: 'pe-7s-map-marker',
    views: [
      {
        path: '/google-maps',
        layout: '/admin',
        name: 'Google Maps',
        mini: 'GM',
        component: GoogleMaps,
      },
      {
        path: '/full-screen-maps',
        layout: '/admin',
        name: 'Full Screen Map',
        mini: 'FSM',
        component: FullScreenMap,
      },
      {
        path: '/vector-maps',
        layout: '/admin',
        name: 'Vector Map',
        mini: 'VM',
        component: VectorMap,
      },
    ],
  },
  {
    path: '/charts',
    layout: '/admin',
    name: 'Charts',
    icon: 'pe-7s-graph1',
    component: Charts,
  },
  {
    path: '/calendar',
    layout: '/admin',
    name: 'Calendar',
    icon: 'pe-7s-date',
    component: Calendar,
  },
  {
    collapse: true,
    path: '/pages',
    name: 'Pages',
    state: 'openPages',
    icon: 'pe-7s-gift',
    views: [
      {
        path: '/user-page',
        layout: '/admin',
        name: 'User Page',
        mini: 'UP',
        component: UserPage,
      },
      {
        path: '/login-page',
        layout: '/auth',
        name: 'Login Page',
        mini: 'LP',
        component: LoginPage,
      },
      {
        path: '/register-page',
        layout: '/auth',
        name: 'Register',
        mini: 'RP',
        component: RegisterPage,
      },
      {
        path: '/lock-screen-page',
        layout: '/auth',
        name: 'Lock Screen Page',
        mini: 'LSP',
        component: LockScreenPage,
      },
    ],
  },*/
]
export default routes
