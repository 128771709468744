import React, {Component} from 'react';
import {Modal} from 'react-bootstrap'
import AsyncSelect from 'react-select';
import { apiRequest } from 'helpers'
import Button from '../../components/CustomButton/CustomButton';



class AssignModal extends Component {

    state = {
        requests: [],
        searchParam: "",
        workers: [],
        selectedWorker: null,
        showSubmit: false,
        alert: null,
        workersAssigned: null,
        alertMessage: null,
        proceed: false
    }

    
    
    getWorkers = async (inputValue) => {
    let page = 0;
    const response = await apiRequest({
      method: 'get',
      url: `users?page=${
        page + 1
      }&pageSize=${10}&order=${"firstName"}&orderType=${"ASC"}&searchQuery=${""}`,
    })

    if (response.status === 200) {
      let arrClients = []
      response.data.pageOfItems.forEach((client) => {
        arrClients.push({
          value: client.userId,
          label: client.firstName + " " + client.lastName,
        })
      })
      this.setState({ workers: arrClients })
    }
      return response
    
  }
    handleChange = (inputValue) => {
        this.getWorkers(inputValue);
    }


    sendWorkerRequest = async (val) =>{
      const data = {
        userId: this.state.selectedWorker.value,
        requests: []
      }
      this.props.requests.forEach(elem =>{data.requests.push({requestId: elem.requestId})})

      try{
        const sendReq = await apiRequest({
          url: 'requests/user',
          method: 'post',
          data,
        })
  
        if (sendReq.status === 200) {
          console.log('Request sent')
          console.log(sendReq)
          this.props.closeModal();
          window.location.reload()          
        }
      }catch (err){
        console.log(err)
      }
    }

    checkAssignedRequests = () =>{
      const assignedUsers = [];
      this.props.requests.forEach(elem =>{
        if(elem.userId === this.state.selectedWorker.value){
          assignedUsers.push(elem)
          
        }   
      })
      return (
        assignedUsers.map(elem =>{
          return(
              <div 
                key={elem.requestId}
                style={{fontSize: "14px", marginLeft: "10px", borderRadius: "10px",  color: "#ffffff",}}
                className="alert alert-danger"
                >Request: <b>"{elem.requestName}"</b>, has already been assinged to <b>{elem.user.firstName + " " + elem.user.lastName}</b></div>
          )
        })
        
        );
      //console.log(this.state.selectedWorker)
    }
    componentDidMount(){
      this.getWorkers()
    }

    render(){
        return(
            <Modal show={this.props.showModal}>
                <Modal.Header className="blueModal">
                <span className="modal-title customTitle" style={{ color: '#FFF' }}>
                    Selected {this.props.requestLength} {this.props.requestLength === 1 ? "request." : "requests."}
                </span>
                <br/>
                {/* <small style={{color: "#ffffff"}}>Assigned <b>{this.props.requestLength}</b> requests.</small> */}
                <small onClick={this.props.closeModal} className="modalTitle customTitle" style={{float: "right", color:"#ffffff", cursor: "pointer", fontWeight: "bold", fontSize: "13px", marginTop: "-25px"}}> X Cancel</small>
                </Modal.Header>
                    <Modal.Body>
                      <AsyncSelect
                        onInputChange={this.handleChange}
                        options={this.state.workers}
                        isClearable={true}
                        className="basic-multi-select"
                        cursor="pointer"
                        value={this.state.selectedWorker}
                        onChange={(value) => {
                          this.setState({ selectedWorker: value, showSubmit: true })
                        }}
                      />
                    <div style={{marginTop: "10px"}}>
                    {this.state.selectedWorker ? this.checkAssignedRequests() : null}  
                    </div>
                    </Modal.Body>
                    {this.props.requestLength !== 0 ? 
                    <Modal.Footer>
                      <p style={{float: "left"}}>Are you sure you want to proceed?</p>
                      <Button bsStyle="primary" onClick={this.sendWorkerRequest}>Submit</Button>
                    </Modal.Footer> : null}
            </Modal>
        )
    }
}

export default AssignModal;
