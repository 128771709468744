import React, { Component } from 'react'
import { Modal, FormControl } from 'react-bootstrap'
import Button from 'components/CustomButton/CustomButton.jsx'
import Select from 'react-select'
import Upload from 'components/Upload/Upload.jsx'
import { apiRequest } from 'helpers'

class NewRequestModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: true,
      title: '',
      message: '',
      publicDocuments: [],
      selectedPublicDocuments: [],
      newDocuments: [],
      forms: [],
      selectedForms: [],
      errorMessage: null,
    }
  }

  componentDidMount() {
    this.getAllPublicDocuments()
    this.getAllForms()
  }

  closeModal = () => {
    this.setState({ showModal: false })
    this.props.removeModal()
  }

  sendRequest = () => {
    const { title, message, newDocuments } = this.state
    //selectedPublicDocuments selectedForms
    if (title !== '' && message !== '') {
      if (
        newDocuments.length <= 0 
      ) {
        this.setState({
          errorMessage: (
            <small className="text-danger">
              You can't add new request with only a title and message. Please select some
              documents or add at least one form.
            </small>
          ),
        })
        return
      }

      /* if (newDocuments.length > 0) {
        const documentsFormData = new FormData()
        newDocuments.forEach((doc) => {
          documentsFormData.append('file', doc, doc.name)
        })
      }*/
      // console.log('NEW DOCUMENTS')
      // console.log(newDocuments)
      //selectedForms
      //selectedPublicDocuments,
      this.props.sendRequest(
        title,
        message,
        newDocuments,
      )
      this.setState({ showModal: false })
    } else {
      this.setState({
        errorMessage: (
          <small className="text-danger">
            You need to type title and message to be able to send request.
          </small>
        ),
      })

      return
    }
  }

  getAllPublicDocuments = async () => {
    const response = await apiRequest({
      method: 'get',
      url: 'documents/getAllGlobalDocuments',
    })

    if (response.status === 200) {
      let arrDocuments = []
      response.data.forEach((doc) => {
        arrDocuments.push({
          value: doc.documentId,
          label: doc.documentName,
          isDefault: doc.isDefault,
        })
      })

      this.setState({ publicDocuments: arrDocuments })
    }
  }

  getAllForms = async () => {
    const response = await apiRequest({
      method: 'get',
      url: 'forms?withoutJSON=true',
    })

    if (response.status === 200) {
      let arrForms = []
      response.data.forEach((form) => {
        arrForms.push({
          value: form.formId,
          label: form.formName,
        })
      })
      this.setState({ forms: arrForms })
    }
  }

  addNewDocuments = (documents) => {
    this.setState({ newDocuments: documents })
  }

  render() {
    const { title, message, publicDocuments, forms } = this.state
    let options = [
      {
        value: '',
        label: ' You can choose multiple options',
        isDisabled: true,
      },
    ]
    let optionsDocuments = [...options, ...publicDocuments]
    let optionsForms = [...options, ...forms]
    return (
      <Modal show={this.state.showModal} onHide={() => this.closeModal()}>
        <Modal.Header className="blueModal">
          <span className="modal-title customTitle" style={{ color: '#FFF' }}>
            Send new request
          </span>
          <div className="pull-right">
            <Button bsStyle="primary" fill onClick={() => this.sendRequest()}>
              <span className="btn-label">
                <i className="fa fa-paper-plane" />
                {'  '}
              </span>
              <span style={{ fontWeight: 'bold' }}>Send</span>
            </Button>
            <Button bsStyle="primary" fill onClick={() => this.closeModal()}>
              <span className="btn-label">
                <i className="fa fa-times" />
                {'  '}
              </span>
              <span style={{ fontWeight: 'bold' }}>Cancel</span>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage}
          <FormControl
            className="modalTitleBox"
            placeholder="Enter title"
            value={title}
            type="text"
            onChange={(event) => {
              this.setState({ title: event.target.value })
            }}
          />
          <FormControl
            rows="5"
            className="modalMessageBox top10"
            componentClass="textarea"
            bsClass="form-control"
            value={message}
            onChange={(event) => {
              this.setState({ message: event.target.value })
            }}
            placeholder="Type your message..."
          />
          {/* <h3 className="customSubtitle top10">Add documents to request</h3>
          <Select
            className="react-select info"
            classNamePrefix="react-select"
            placeholder="Choose Document"
            name="multipleSelect"
            closeMenuOnSelect={false}
            isMulti
            value={this.state.selectedPublicDocuments}
            onChange={(value) => {
              this.setState({ selectedPublicDocuments: value })
            }}
            options={optionsDocuments}
          /> */}
          <div className="top20"></div>
          <Upload noUpload={true} addNewDocuments={this.addNewDocuments} />
          {/* <h3 className="customSubtitle top10">Add forms to request</h3>
          <Select
            className="react-select info"
            classNamePrefix="react-select"
            placeholder="Choose Form"
            name="multipleSelect"
            closeMenuOnSelect={false}
            isMulti
            value={this.state.selectedForms}
            onChange={(value) => {
              this.setState({ selectedForms: value })
            }}
            options={optionsForms}
          /> */}
        </Modal.Body>
      </Modal>
    )
  }
}

export default NewRequestModal
