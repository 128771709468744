import $ from 'jquery'

import React, { Component } from 'react'
import { Grid, Row, Col, FormGroup, FormControl, ControlLabel } from 'react-bootstrap'
import Slugify from 'slugify'

import Card from 'components/Card/Card'

import { apiRequest } from 'helpers'

import mandatoryDocuments from 'variables/mandatoryDocuments.js'

import SweetAlert from 'react-bootstrap-sweetalert'

import Checkbox from 'components/CustomCheckbox/CustomCheckbox.jsx'

import DefaultData from 'config/defaultFormData'

window.jQuery = $
window.$ = $
require('jquery-ui')
require('jquery-ui-sortable')
require('formBuilder')
require('formBuilder/dist/form-render.min.js')

/*const formData = [
  {
    type: 'newPage',
    label: 'New Page',
  }
]*/

const options = {
  // formData: formData,
  disabledActionButtons: ['clear'],
  editOnAdd: true,
  fields: DefaultData.customFields,
  // fieldRemoveWarn: true,
  controlPosition: 'right',
  controlOrder: DefaultData.controlOrder,
  disabledAttrs: DefaultData.disabledAttrs,
  disableFields: DefaultData.disableFields,
  inputSets: DefaultData.inputSets,
  typeUserEvents: {
    'checkbox-group': {
      onadd: function (fld) {
        $('.option-value').toggle()
      },
    },
    'radio-group': {
      onadd: function (fld) {
        $('.option-value').toggle()
      },
    },
    select: {
      onadd: function (fld) {
        $('.option-value').toggle()
      },
    },
    autocomplete: {
      onadd: function (fld) {
        $('.option-value').toggle()
      },
    },
  },
  templates: {
    newPage: function (fieldData) {
      return {
        field:
          '<hr id="' +
          fieldData.name +
          '" style="border: 2px solid #DFD7CB; border-radius: 3px;">',
        onRender: function () {
          //$(document.getElementById(fieldData.name)).rateYo({ rating: 3.6 })
        },
      }
    },
    inputsGroupName: function (fieldData) {
      return {
        field:
          '<hr id="' +
          fieldData.name +
          '" style="border: 2px solid #DFD7CB; border-radius: 3px;">',
        onRender: function () {
          //$(document.getElementById(fieldData.name)).rateYo({ rating: 3.6 })
        },
      }
    },
  },
  defaultFields: DefaultData.defaultFields,
}

class FormBuild extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: null,
      alert: null,
      isCheckedDefault: false,
      formName: '',
      errorformName: null,
    }
  }

  fb = React.createRef()
  fbPreview = React.createRef()

  componentDidMount() {
    this.formCreator = $(this.fb.current).formBuilder(options)
  }

  saveForm = () => {
    console.log('==== SAVE form ====')
    // console.log(this.fb);
    console.log(this.formCreator)

    $(this.fb.current).toggle()
    $('#btn-save-form').toggle()

    $(this.fbPreview.current)
      .toggle()
      .ready(() => {
        $('select').removeAttr('multiple')
      })

    $('#btn-clear-form').toggle()

    const formData = JSON.parse(this.formCreator.formData)
    console.log('FORM DATAA')
    console.log(formData)

    // CHECK IF THERE IS NO PAGE DEFINED
    if (formData.length > 0 && formData[0].type !== 'newPage') {
      formData.unshift({
        type: 'newPage',
        required: false,
        label: 'Fill page name',
        className: 'form-control',
        name: 'newPage-1586280841739',
        slug: 'page-1',
      })
    }

    formData.forEach((el, index) => {
      if (el)
        el.slug = Slugify(el.label, {
          lower: true,
        })
      if (el.type !== 'newPage') el.name = 'question' + (index + 1)
      if (
        el.type === 'autocomplete' ||
        el.type === 'radio-group' ||
        el.type === 'select' ||
        el.type === 'checkbox-group'
      ) {
        el.values.forEach((opt) => {
          // TODO
          console.log('MANDATORY DOCS')
          console.log(mandatoryDocuments)

          if (mandatoryDocuments.docs.find((el) => el === opt.label)) {
            // Mark it as a document
            opt.isMandatory = true
          }
          opt.value = Slugify(opt.label, {
            lower: true,
          })
        })
      }
    })

    const templates = options.templates

    $('form', $(this.fbPreview.current)).formRender({
      formData: formData,
      templates,
    })

    // Set New Page labels
    $('.rendered-form .formbuilder-newPage-label').each(function (index) {
      console.log('INDEX: ', index)
      let text = $(this).text()
      $(this).text(text + ' - Page ' + (index + 1))
    })

    // SAVE FORM
    console.log('===== SAVE FORM =====')
    console.log(formData)

    /* PREPARE FORM DATA FOR UPLOAD */
    this.prepareFormDataForUpload(formData)
  }

  prepareFormDataForUpload = (formData) => {
    const _formData = [...formData]
    let indexArr = [] /// 0, 5, 8, 12
    let jsonForm = []
    let groupInputs = []
    let groupInputIndexes = []
    let groupMasterIndex = 0

    // Date groups
    let dateGroupMasterIndex = 0
    let dateGroupIndexes = []
    let dateGroupInputs = []

    let customIndexesData = this.returnCustomIndexes('group1-master', 'group1', _formData)

    // GROUP INPUTS
    groupMasterIndex = customIndexesData.groupMasterIndex
    groupInputIndexes = customIndexesData.groupSingleIndexes
    groupInputs = customIndexesData.groupSingleInputs

    console.log('GROUP INPUTS')
    console.log(groupInputs)

    console.log('GROUP INPUTS INDEXES')
    console.log(groupInputIndexes)

    // Clean single group items from main arr
    let br = 0
    groupInputIndexes.forEach((element, index) => {
      _formData.splice(element - br, 1)
      br++
    })

    // GROUP DATES
    customIndexesData = this.returnCustomIndexes('date-group', 'date-group-single', _formData)
    dateGroupMasterIndex = customIndexesData.groupMasterIndex
    dateGroupIndexes = customIndexesData.groupSingleIndexes
    dateGroupInputs = customIndexesData.groupSingleInputs

    // Clean single date items from main arr
    let brDates = 0
    console.log('DATE GROUP INDEXES')
    console.log(dateGroupIndexes)
    dateGroupIndexes.forEach((element, index) => {
      _formData.splice(element - brDates, 1)
      brDates++
    })

    console.log('FORM DATA SINGLE GROUPPED ITEMS REMOVED')
    console.log(_formData)
    console.log('MASTER GROUP INDEX: ', groupMasterIndex)
    console.log('MASTER DATE GROUP INDEX: ', dateGroupMasterIndex)

    // GROUPING GROUPS
    const arrGroups = []
    arrGroups.push(groupInputs)

    _formData[groupMasterIndex].data = arrGroups
    _formData[dateGroupMasterIndex].data = dateGroupInputs

    console.log('=== FORM DATA NAKON DODAVANJA ====')
    console.log(_formData)

    // Fill page indexes
    _formData.forEach((el, index) => {
      if (el.type === 'newPage') {
        indexArr.push(index)
      }
    })

    indexArr.forEach((current, index) => {
      if (index !== indexArr.length) {
        let dataPart = _formData.slice(current, indexArr[index + 1])
        console.log('== FORM DATA CURRENT ==')
        console.log(_formData[current])
        jsonForm.push({
          page: index + 1,
          pageName: _formData[current].label,
          data: dataPart,
        })
      }
    })

    console.log('ZAVRSENO')
    console.log(jsonForm)

    this.setState({ form: jsonForm })
  }

  returnCustomIndexes = (groupMasterName, groupSingleName, _formData) => {
    let groupMasterIndex = 0
    let groupSingleInputs = []
    let groupSingleIndexes = []
    _formData.forEach((el, index) => {
      console.log('== ELEMENT CLASS NAME ===')
      let elClassNameArr = el.className && el.className.split(' ')

      console.log(elClassNameArr)
      if (el.className && elClassNameArr[0] === groupMasterName) {
        groupMasterIndex = index
      }

      if (
        el.className &&
        (elClassNameArr[1] === groupSingleName ||
          (elClassNameArr[0] !== groupMasterName && elClassNameArr[1] === groupSingleName))
      ) {
        groupSingleInputs.push(el)
        groupSingleIndexes.push(index)
      }

      /* if (el.className && el.className.includes('group1-master')) {
        groupMasterIndex = index
        console.log('PRONADJEN INDEX')
        console.log(index)
      }

      if (
        el.className &&
        el.className !== 'group1-master' &&
        el.className.includes('group1')
      ) {
        groupInputs.push(el)
        groupInputIndexes.push(index)
      } */
    })

    console.log('RETURN CUSTOM INDEXES')
    let data = {
      groupMasterIndex,
      groupSingleInputs,
      groupSingleIndexes,
    }

    console.log(data)

    return data
  }

  uploadForm = async () => {
    const { form, formName, isCheckedDefault } = this.state
    console.log('(UPLOAD CALL)')
    const data = {
      userId: 1,
      formName: formName,
      description: '',
      isActive: 1,
      isDefault: isCheckedDefault,
      formJSON: JSON.stringify(form),
    }

    const response = await apiRequest({
      method: 'post',
      url: 'forms',
      data,
    })

    if (response.status === 200) {
      this.successAlert('Your form has been saved!', 'Done')
    }
  }

  editForm = () => {
    $(this.fbPreview.current).toggle()
    $(this.fb.current).toggle()
    $('#btn-save-form').toggle()
    $('#btn-clear-form').toggle()
  }

  clearForm = () => {
    this.formCreator.actions.clearFields()
    const field = {
      type: 'newPage',
      class: 'form-control',
      label: 'Fill page name',
    }
    this.successAlert()
    setTimeout(() => {
      this.formCreator.actions.addField(field)
    }, 1000)
  }

  /* ALERTS */

  fillFormDataAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          style={{ display: 'block', marginTop: '-100px' }}
          title="You need to fill form data before submit."
          onConfirm={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        />
      ),
    })
  }

  uploadAlert = () => {
    const { form } = this.state
    if (form.length === 1 && form[0].data.length === 1) {
      this.fillFormDataAlert()
      return
    }
    this.setState({
      alert: (
        <SweetAlert
          style={{ display: 'block', marginTop: '-100px' }}
          title=""
          onConfirm={() => this.inputConfirmAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnText="Save form"
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger">
          <Row>
            <FormGroup>
              <ControlLabel>Enter form name:</ControlLabel>
              <FormControl
                placeholder="Form 1"
                type="text"
                autoComplete="off"
                onChange={(event) => {
                  this.setState({ formName: event.target.value })
                  event.target.value === ''
                    ? this.setState({
                        errorformName: (
                          <small className="text-danger">Form name is required.</small>
                        ),
                      })
                    : this.setState(
                        { errorformName: null },
                        () => this.uploadAlert() // REFRESH ALERT
                      )
                }}
              />
              {this.state.errorformName}
            </FormGroup>
            <FormGroup>
              <Checkbox
                onClick={() =>
                  this.setState({
                    isCheckedDefault: !this.state.isCheckedDefault,
                  })
                }
                isChecked={this.state.isCheckedDefault}
                number="1"
                label="Make this form default"
              />
            </FormGroup>
          </Row>
        </SweetAlert>
      ),
    })
  }
  inputConfirmAlert = async () => {
    let isValid = await this.handleValidate()
    console.log('IS VALID')
    console.log(isValid)
    if (isValid) {
      this.uploadForm()
    }
    //this.setState({ alert: e })
    //setTimeout(this.inputConfirmAlertNext, 200)
  }

  handleValidate = async () => {
    if (this.state.formName === '') {
      await this.setStateAsync({
        errorformName: <small className="text-danger">Form name is required.</small>,
      })

      this.uploadAlert()
      return false
    } else {
      await this.setStateAsync({ errorformName: null })
      return true
    }
  }

  // ASYNC SETSTATE
  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  successAlert = (msg = 'Your form has been cleared.', title = 'Cleared!') => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title={title}
          onConfirm={() => this.hideAlert(true)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info">
          {msg}
        </SweetAlert>
      ),
    })
  }

  warningWithConfirmMessage = () => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: 'block', marginTop: '-100px' }}
          title="Are you sure?"
          onConfirm={() => this.clearForm()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, clear it!"
          cancelBtnText="Cancel"
          showCancel>
          You will not be able to recover cleared data.
        </SweetAlert>
      ),
    })
  }

  hideAlert(redirect = false) {
    this.setState(
      {
        alert: null,
      },
      () => (redirect ? this.props.history.push('/admin/console') : null)
    )
  }

  render() {
    console.log('RENDER')
    console.log(this.formCreator)
    return (
      <div className="content">
        {this.state.alert}
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                plain
                title="Build a form"
                ctAllIcons
                category={<span>Use the form below to create a new form </span>}
                content={
                  <Row style={{ marginLeft: 5 }}>
                    <div id="fb-editor" ref={this.fb} />
                    <div
                      id="fb-rendered-form"
                      ref={this.fbPreview}
                      style={{ display: 'none' }}>
                      <form action="#"></form>
                      <button
                        className="btn btn-success"
                        id="btn-upload-form"
                        onClick={this.uploadAlert}>
                        Save form
                      </button>
                      <button
                        style={{ marginLeft: 5 }}
                        className="btn btn-default"
                        id="btn-edit-form"
                        onClick={this.editForm}>
                        Edit
                      </button>
                    </div>
                    <button
                      className="btn btn-default"
                      id="btn-save-form"
                      onClick={this.saveForm}>
                      Preview form
                    </button>
                    <button
                      style={{ marginLeft: 5 }}
                      className="btn btn-danger"
                      id="btn-clear-form"
                      onClick={() => this.warningWithConfirmMessage()}>
                      Clear
                    </button>
                  </Row>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

export default FormBuild
