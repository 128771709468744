import React, { Component } from 'react'
// react component for creating dynamic tables
import ReactTable from 'react-table'
import { Grid, Row, Col, FormControl, FormGroup, InputGroup } from 'react-bootstrap'
import { Button as Btn } from 'react-bootstrap';

import Card from 'components/Card/Card.jsx'
import Button from 'components/CustomButton/CustomButton.jsx'

import SweetAlert from 'react-bootstrap-sweetalert'

import { Link, Redirect } from 'react-router-dom'

import { apiRequest } from 'helpers'
import moment from 'moment'

import TaxFilesModal from '../Components/TaxFilesModal';

class TaxFiles extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      loading: false,
      pages: 0,
      searchParam: '',
      //data: [],
      tableState: null,
      createProfileModal: null,
      alert: null,
      yearFilter: moment().year(),
      availableYears: [],
      taxFiles: [],
      taxFilesLength: 0,
      showTaxModal: false,
      notAllowedRedirect: null
    }
  }

  componentDidMount() {
    // CHECK IF USER HAS PERMISSION TO SEE THIS PAGE
    const userRole = localStorage.getItem('role');
    if(userRole === 'superAdmin'){
          this.setState({notAllowedRedirect: <Redirect to='/admin/not-allowed' />})
        }
  }

  getTaxFiles = async (page = 0, pageSize = 10, sorted, filtered) => {
    let orderId = null
    let orderType = null
    const { searchParam, yearFilter } = this.state

    if (sorted && sorted.length > 0) {
      orderId = sorted[0].id
      orderType = sorted[0].desc ? 'DESC' : 'ASC'
    }

    if (orderId === null && orderType === null) {
      orderId = 'year'
      orderType = 'ASC'
    }

    const response = await apiRequest({
      method: 'get',
      url: `taxfiles/getFileTaxPagination?page=${page + 1
        }&pageSize=${pageSize}&order=${orderId}&orderType=${orderType}&searchQuery=${searchParam}&yearFilter=${yearFilter}`,
    })

    if (response.status === 200) {
      console.log('Response')
      console.log(response)
      if (response.data.availableYears) {
        this.setState({ availableYears: response.data.availableYears })
      }
      return response
    }
  }

  changeCheckbox = (item, event) => {
    if (event.target.checked) {
      let localRequests = [...this.state.taxFiles];
      let newList = localRequests.concat(item)
      console.log(newList)
      this.setState({
        taxFiles: newList,
        taxFilesLength: newList.length

      })
    } else {
      let localRequests = [...this.state.taxFiles];
      localRequests.forEach(elem => {
        if (elem.requestId === item.requestId) {
          localRequests.splice(elem, 1);
          console.log(localRequests)
          this.setState({
            taxFiles: localRequests,
            taxFilesLength: localRequests.length

          })
        }
      })
    }
  }

  onFetchData = async (state, instance = null) => {
    this.setState({ loading: true })
    console.log('STATE')
    console.log(state)
    const res = await this.getTaxFiles(
      state.page,
      state.pageSize,
      state.sorted,
      state.filtered
    )
    console.log('== RES ==')
    console.log(res)
    if (res) {
      let data = res.data.taxFiles.map((item, key) => {
        return {
          id: item.requestId,
          requestName: item.requestName,
          dateCreated: moment.utc(item.dateCreated).format('L'),
          dateFinished: item.dateFinish
            ? moment.utc(item.dateFinish).format('L')
            : 'Not finished',
          client: item.client.firstName + ' ' + item.client.lastName,
          // checkBox: (
          //   <div style={{ textAlign: "right" }}>
          //     <input type="checkbox" onChange={(event) => this.changeCheckbox(item, event)} />
          //   </div>

          // ),
          status: (
            <span
              style={{
                fontWeight: '600',
                color: this.colorForStatus(item.requeststatus.statusKey),
              }}>
              {item.requeststatus.status}
            </span>
          ),
          actions: (
            // we've added some custom button actions
            <div style={{textAlign: 'right'}}>
              {/* use this button to add a like kind of action */}
              <Link to={`/admin/taxfiles/${item.requestId}`}>
                <Button bsStyle="primary">Open</Button>
              </Link>{' '}
            </div>
          ),
        }
      })

      this.setState({
        data,
        pages: res.data.pager.totalPages,
        loading: false,
        tableState: state,
      })
    }
  }

  createProfile = async (email) => {
    console.log('== CALLED ==')
    const data = {
      email,
    }

    try {
      const response = await apiRequest({
        url: 'users/addWorker',
        method: 'post',
        data,
      })
      console.log('RESPONSE')
      console.log(response)
      if (response.status === 200) {
        this.successAlert('New profile for worker has been created.', true)
      }
    } catch (err) {
      console.log('==ERROR==')
      this.errorAlert(err.response.data.message)
    }
  }

  toggleActive = async (userId) => {
    console.log('== POZVANO ==')
    try {
      const response = await apiRequest({
        method: 'PUT',
        url: 'users/makeUserInactiveActive/' + userId,
      })
      console.log('== RESP ==')
      console.log(response)
      if (response.status === 203 || response.status === 200) {
        this.successAlert('The profile has been changed.', true)
      }
    } catch (err) {
      this.errorAlert()
    }
  }

  warningWithConfirmMessage = (status, userId) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title={`Are you sure you want to make this profile ${status ? ' inactive' : 'active'
            } ?`}
          onConfirm={() => this.toggleActive(userId)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes"
          cancelBtnText="Cancel"
          showCancel>
          This profile will be {status ? 'disabled' : 'enabled'}.
        </SweetAlert>
      ),
    })
  }

  removeModal = () => {
    this.setState({ createProfileModal: null })
  }

  colorForStatus = (status) => {
    switch (status) {
      case 'pending':
        return '#EC5758'
      case 'completed':
        return '#29AE60'
      case 'scanned':
        return '#29AE60'
      case 'signed':
        return '#29AE60'
      default:
        return '#F2994B'
    }
  }

  /* SUCCESS AND ERROR ALERTS */
  successAlert(msg = '', refresh = false) {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{
            display: 'block',
            marginTop: '-100px',
          }}
          title="Done"
          onConfirm={() => this.hideAlert(refresh)}
          confirmBtnBsStyle="info">
          {msg}
        </SweetAlert>
      ),
    })
  }

  errorAlert(message = 'There was an error. Please try again.') {
    this.setState(
      {
        alert: (
          <SweetAlert
            danger
            style={{
              display: 'block',
              marginTop: '-100px',
            }}
            title="Error"
            onConfirm={() => this.hideAlert()}
            confirmBtnBsStyle="info">
            {message}
          </SweetAlert>
        ),
      },
      () => {
        this.removeModal()
      }
    )
  }

  hideAlert(reloadData = false) {
    this.setState(
      {
        alert: null,
      },
      () => {
        if (reloadData) {
          this.removeModal()
          this.onFetchData(this.state.tableState)
        }
      }
    )
  }

  renderFilterButtons = () => {
    const { yearFilter, availableYears } = this.state
    return availableYears.map((year, index) => {
      return (
        year !== null && (
          <Button
            key={index}
            bsStyle="primary"
            style={{ marginBottom: 5, marginRight: 7 }}
            fill={year === yearFilter}
            onClick={() => {
              this.setState(
                {
                  yearFilter: year,
                },
                () => this.onFetchData(this.state.tableState)
              )
            }}>
            {year.toString()}
          </Button>
        )
      )
    })
  }

  closeModal = () => {
    this.setState({
      showTaxModal: false
    })
  }

  render() {
    const { data, pages, loading, notAllowedRedirect } = this.state
    return (
      <div className="main-content">
        {notAllowedRedirect}
        {this.state.showTaxModal ?
          <TaxFilesModal
            showModal={this.state.showTaxModal}
            closeModal={this.closeModal}
            taxFiles={this.state.taxFiles}
            taxFilesLength={this.state.taxFilesLength} />
          : null}
        {this.state.alert}
        <Grid fluid>
          <Row style={{ marginBottom: 10 }}>
            <Col md={6}>
              <div className="text-left">
                <span className="title customTitle">Tax Files Management Console</span>
              </div>
            </Col>
            <Col md={6}>
              <div className="text-right">
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Addon>
                      <i className="fa fa-search" />
                    </InputGroup.Addon>
                    <FormControl
                      onChange={(event) => {
                        this.setState({ searchParam: event.target.value }, () => {
                          this.onFetchData(this.state.tableState)
                        })
                      }}
                      type="text"
                      placeholder="Search..."
                    />
                  </InputGroup>
                </FormGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div>
                    <Row style={{ marginBottom: 10 }}>
                      <Col md={6}>
                        <div className="text-left">
                          <h4 className="title">Tax Files List</h4>
                        </div>
                      </Col>
                      <Col md={6}>

                        <div className="text-right" >{this.renderFilterButtons()}</div>
                        {this.state.taxFilesLength > 0 ?
                          <div className="text-right" style={{ marginRight: "5px" }}>
                            <Btn
                              bsStyle="default"
                              onClick=
                              {
                                () => this.setState({ showTaxModal: true })
                              }>
                              Assign selected</Btn>
                          </div>
                          : null}
                      </Col>
                    </Row>
                    <ReactTable
                      data={data}
                      ref={(refReactTable) => {
                        this.refReactTable = refReactTable
                      }}
                      filterable
                      columns={[
                        {
                          Header: 'Request Name',
                          sortable: false,
                          filterable: false,
                          accessor: 'requestName',
                        },
                        {
                          Header: 'Client',
                          sortable: false,
                          filterable: false,
                          accessor: 'client',
                        },
                        {
                          Header: 'Created Date',
                          sortable: false,
                          filterable: false,
                          accessor: 'dateCreated',
                        },
                        {
                          Header: 'Finished Date',
                          sortable: false,
                          filterable: false,
                          accessor: 'dateFinished',
                        },
                        {
                          Header: 'Request Status',
                          sortable: false,
                          filterable: false,
                          accessor: 'status',
                        },
                        {
                          Header: 'Actions',
                          accessor: 'actions',
                          sortable: false,
                          filterable: false,
                        },
                        // {
                        //   Header: 'Select',
                        //   accessor: 'checkBox',
                        //   sortable: false,
                        //   filterable: false,

                        // },
                      ]}
                      defaultPageSize={10}
                      //showPaginationTop
                      pages={pages}
                      manual
                      showPaginationBottom={true}
                      loading={loading}
                      showPagination={true}
                      onFetchData={this.onFetchData}
                      className="-striped -highlight"
                    />
                  </div>
                }
              />
            </Col>
          </Row>
          {this.state.createProfileModal}
        </Grid>
      </div>
    )
  }
}

export default TaxFiles
