import React from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'assets/sass/light-bootstrap-dashboard-pro-react.scss?v=1.2.0'
import 'assets/css/demo.css'
import 'assets/css/pe-icon-7-stroke.css'

import AuthLayout from 'layouts/Auth.jsx'
import AdminLayout from 'layouts/Admin.jsx'

const App = () => {
  return (
    <HashRouter>
      <Switch>
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Redirect from="/" to="/auth/login-page" />
      </Switch>
    </HashRouter>
  )
}

export default App
