import React, { Component } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

import Checkbox from 'components/CustomCheckbox/CustomCheckbox.jsx'
import Button from 'components/CustomButton/CustomButton.jsx'

import { connect } from 'react-redux'
import moment from 'moment'
import { generateNotificationLayout, apiRequest } from 'helpers'
import { setSeenNotification } from 'redux/actions/notificationsActions'

export class Tasks extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleCheckbox = (event) => {
    const target = event.target
    console.log(event.target)
    this.setState({
      [target.name]: target.checked,
    })
  }

  formatNotificationsMessage = (notification) => {
    const { notificationMessage } = generateNotificationLayout(notification)
    return notificationMessage
  }

  componentDidUpdate() {
    console.log('=== STATE UPDATE ==')
    console.log(this.props.notifications[0])

    // Set update time
    if (this.props.notifications[0]) {
      this.props.lastUpdateTime(moment())
    }
  }

  redirectToRoute = async (route, activityId) => {
    console.log('=== REDFIRECT ==')
    console.log(activityId)
    console.log(route)
    const data = {
      activityId,
    }

    try {
      const response = await apiRequest({
        method: 'post',
        url: 'activities/removeSeen',
        data,
      })

      if (response.status === 200 && route) {
        this.props.setSeenNotification(activityId)
        this.props.history.push(route)
      }
    } catch (err) {
      console.log('Error: ', err)
    }
  }

  render() {
    const edit = <Tooltip id="edit_tooltip">Check more info</Tooltip>
    const { showAllNotifications } = this.props
    // Collect notifications from redux
    const notifications = showAllNotifications
      ? this.props.notifications
      : this.props.notifications.length > 6
      ? this.props.notifications.slice(0, 6)
      : this.props.notifications

    console.log('=== RERENDER ==')
    console.log(this.props)
    var tasks = []
    var number
    for (var i = 0; i < notifications.length; i++) {
      if (notifications[i].notificationKey === 'newMessage') {
        continue
      }

      const { notificationMessage, redirectRoute, activityId } = generateNotificationLayout(
        notifications[i]
      )

      console.log(' == ACTIVITY ==')
      console.log(notifications[i])
      number = 'checkbox' + i
      tasks.push(
        <tr key={i}>
          <td style={{ display: 'flex', flexDirection: 'row' }}>
            <Checkbox disabled number={number} isChecked={notifications[i].seen} />
            {!notifications[i].seen && (
              <small style={{ fontWeight: 'bold', color: '#FB414B' }}>NEW</small>
            )}
          </td>
          <td>{notificationMessage}</td>
          <td className="text-right">
            <OverlayTrigger placement="top" overlay={edit}>
              <Button
                onClick={() => this.redirectToRoute(redirectRoute, activityId)}
                bsSize="sm"
                fill>
                Open
              </Button>
            </OverlayTrigger>
          </td>
        </tr>
      )
    }
    return <tbody>{tasks}</tbody>
  }
}

const mapStateToProps = (state) => ({
  loading: state.notifications.loading,
  notifications: state.notifications.notifications,
  hasErrors: state.notifications.hasErrors,
})

const mapDispatchToProps = (dispatch) => ({
  setSeenNotification: (state) => dispatch(setSeenNotification(state)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tasks))
